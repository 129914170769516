import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks/store-hook';
import TopBarMobile from './TopBarMobile';
import { useNavigationState } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import { navBarActions } from '../../../store/navBarSlice';

const TopBar = () => {
  const topBarVisibility = useAppSelector((state) => state.topBar);
  const navState = useNavigationState((state) => state?.routes);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (navState && navState[navState.length - 1]) {
      dispatch(navBarActions.setCurrentPath(navState[navState.length - 1].name));
    }
  }, [navState]);

  useEffect(() => {
    setVisible(topBarVisibility.visible);
    console.log('topBarVisibility', topBarVisibility.visible);
  }, [topBarVisibility.visible]);

  return visible ? <TopBarMobile></TopBarMobile> : <></>;
};

export default TopBar;

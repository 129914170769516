import NetInfo, { useNetInfo } from '@react-native-community/netinfo';
import { LinearGradient } from 'expo-linear-gradient';
import { NavigationContainer, createNavigationContainerRef } from '@react-navigation/native';
import { Fragment, useEffect, useState } from 'react';
import { StatusBar, SafeAreaView, useWindowDimensions, Platform, Dimensions, View } from 'react-native';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Drawer, { DrawerElement } from './components/common/Drawer';
import CustomModal from './components/common/CustomModal';
import MyAccount from './components/myaccount/MyAccount';
import NavBar from './components/common/navbar/NavBar';
import TopBar from './components/common/topbar/TopBar';
import Notification from './components/common/Notification';
import { NotificationOpenState } from './store/notificationSlice';
import useCustomTheme from './hooks/theme-hook';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { Query, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import LightBox from './components/common/lightBox/LightBox';
import MainBody from './pages/MainBody';
import ErrorBoundary from 'react-native-error-boundary';
import CustomErrorFallback from './components/common/CustomErrorFallback';
import OffLinePopup from './components/common/OffLinePopup';
import CookiesPopup from './components/myaccount/cookies/CookiesPopup';
import Constants from 'expo-constants';

export default function App() {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();
  const navigationRef = createNavigationContainerRef();

  const { isConnected } = useNetInfo();

  const linking = {
    prefixes: ['https://ms.hulk.cool', 'lanla://'],
    config: {
      screens: {
        entryPage: '',
        survey: 'survey',
        map: 'map',
        validation: 'validation',
        mymissions: 'mymissions',
        remotemissions: 'remotemissions',
        useractionreturn: 'useractionreturn',
        unsubscribe: 'unsubscribe',
      },
    },
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 3,
        retryDelay: 1000,
        // staleTime: 60000,
        // cacheTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        refetchOnMount: false,
        useErrorBoundary: (error: any, query: Query) => {
          // console.log('Error', error);
          // console.log('Error code', error.code);
          // // console.log('Error.status', error.status);
          // console.log('Query', query.queryKey);

          if (error.code === 'ERR_CANCELED') {
            console.log('Query cancelled', error.status);
            return false;
          }

          return true;
        },
      },
    },
  });

  useEffect(() => {
    console.log(' isConnected', isConnected);
  }, [isConnected]);

  return (
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={CustomErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <SafeAreaProvider>
              <LinearGradient end={{ x: 1.4, y: 0 }} style={{ height: '100%', width: '100%' }} colors={['#035164', '#43a49e']}>
                <Fragment>
                  <SafeAreaView style={{ flex: 0 }} />
                  <SafeAreaView style={{ flex: 1, backgroundColor: theme.background.navBar }}>
                    <StatusBar animated={false} backgroundColor={theme.mapControls} barStyle={'light-content'} hidden={false} />
                    <NavigationContainer ref={navigationRef} linking={linking as any}>
                      {!isConnected && <OffLinePopup />}
                      {Platform.OS === 'web' && Constants.expoConfig.extra.appUrl === 'https://ms.hulk.cool' && false && <CookiesPopup />}
                      <TopBar />
                      <MainBody />
                      {layout.width <= 1200 ? <NavBar /> : <></>}
                      <Notification openState={NotificationOpenState.hidden} />
                      <MyAccount />
                      <Drawer
                        drawerElementType={DrawerElement.container}
                        dockBottom={Platform.OS !== 'web' || Dimensions.get('window').width <= 768}
                        //dockRight={Platform.OS === 'web' && Dimensions.get('window').width > 768}
                        asPopup={Platform.OS === 'web' && Dimensions.get('window').width > 768}
                      >
                        <Drawer drawerElementType={DrawerElement.header}></Drawer>
                        <Drawer drawerElementType={DrawerElement.content}></Drawer>
                      </Drawer>
                    </NavigationContainer>
                    <CustomModal />
                    <LightBox />
                  </SafeAreaView>
                </Fragment>
              </LinearGradient>
            </SafeAreaProvider>
          </GestureHandlerRootView>
        </QueryClientProvider>
      </ErrorBoundary>
    </Provider>
  );
}

import { MysteryShopperAddressType } from '../api/api.g';
import useCustomTheme from './theme-hook';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBriefcase, faCirclePlus, faEllipsis, faGraduationCap, faHouseChimney, faHouseTree } from '@fortawesome/pro-regular-svg-icons';
import { ColorValue } from 'react-native';

const useAddressIcon = (size?: number, color?: ColorValue) => {
  const theme = useCustomTheme();

  return (type: number) => {
    switch (type) {
      case MysteryShopperAddressType.Home:
        return <FontAwesomeIcon icon={faHouseChimney} size={size ?? 24} color={color?.toString() ?? theme.text.primary.toString()} style={{ marginRight: 8 }} />;
      case MysteryShopperAddressType.Work:
        return <FontAwesomeIcon icon={faBriefcase} size={size ?? 24} color={color?.toString() ?? theme.text.primary.toString()} style={{ marginRight: 8 }} />;
      case MysteryShopperAddressType.School:
        return <FontAwesomeIcon icon={faGraduationCap} size={size ?? 24} color={color?.toString() ?? theme.text.primary.toString()} style={{ marginRight: 8 }} />;
      case MysteryShopperAddressType.Other:
        return <FontAwesomeIcon icon={faEllipsis} size={size ?? 24} color={color?.toString() ?? theme.text.primary.toString()} style={{ marginRight: 8 }} />;
      case MysteryShopperAddressType.SecondaryHome:
        return <FontAwesomeIcon icon={faHouseTree} size={size ?? 24} color={color?.toString() ?? theme.text.primary.toString()} style={{ marginRight: 8 }} />;
      default:
        return <FontAwesomeIcon icon={faCirclePlus} size={size ?? 24} color={color?.toString() ?? theme.text.primary.toString()} style={{ marginRight: 8 }} />;
    }
  };
};

export default useAddressIcon;

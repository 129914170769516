import React from 'react';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { View, Image, Pressable } from 'react-native';
import CustomButton from '../CustomButton';
import Label, { LabelType } from '../Label';
import useTranslation from '../../../hooks/translation-hook';
import useCustomTheme from '../../../hooks/theme-hook';

export interface SurveyErrorProps {
  onTryAgainClick?(): void;
  onContactClick?(): void;
}

const SurveyError = (props: SurveyErrorProps) => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const tMission = useTranslation({ defaultPath: 'mission' });

  return (
    <View
      style={{
        height: '100%',
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        ...StylesHelper.padding(16, 24),
      }}
    >
      <View style={{ flexGrow: 1, flexShrink: 1, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', marginTop: 16 }}>
        <View style={{ alignContent: 'center', justifyContent: 'center', flexShrink: 1 }}>
          <Image style={{ width: 229, height: 274, alignSelf: 'center' }} source={require('../../../../assets/SurveyError.png')} />
          <Label variant={LabelType.titleLargeNoCapsPrimary} style={{ textAlign: 'center', fontWeight: '800', fontSize: 30, flexShrink: 1, marginTop: 16 }}>
            {'Ooops!'}
          </Label>
          <Label variant={LabelType.titleLargeLight} style={{ textAlign: 'center', fontWeight: '800', fontSize: 20, flexShrink: 1 }}>
            {tMission('notable-to-save')}
          </Label>
          <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 200, paddingVertical: 24, flexShrink: 1 }}>
            {tMission('sorry-notable-to-save')}
          </Label>
          <CustomButton
            title={t('retry')}
            onPress={() => {
              if (props.onTryAgainClick) {
                props.onTryAgainClick();
              }
            }}
            style={{ flexShrink: 1 }}
          />
          <Pressable
            onPress={() => {
              if (props.onContactClick) {
                props.onContactClick();
              }
            }}
          >
            <Label variant={LabelType.body1} style={{ color: theme.colorErrorPrimary, textAlign: 'center', marginTop: 8 }}>
              {tMission('still-problems-contact')}
            </Label>
          </Pressable>
        </View>
      </View>
    </View>
  );
};

export default SurveyError;

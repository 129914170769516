import React, { useCallback, useEffect, useState } from 'react';
import { TextProps, Text } from 'react-native';
import { useAppSelector } from '../../hooks/store-hook';
import useStyles from '../../hooks/styles-hook';
import { _getCurrentWatchId } from 'expo-location';

export enum LabelType {
  title,
  titleDark,
  titleLight,
  titleSmall,
  titlePrimarySmall,
  titleDarkSmall,
  titleWhiteSmall,
  body1,
  bodyWhiteSmall,
  caption,
  captionPrimary,
  captionSmall,
  cancelButton,
  okButton,
  navigationTitle,
  titleLarge,
  titleLargeNoCaps,
  titleLargeNoCapsPrimary,
  titleLargeDark,
  titleLargeLight,
  subTitleBlack,
  myAccountLink,
  labelWhite,
  infoBoxTitle,
  viewDetails,
  cardAnswer,
  validatorQuestion,
  myRewardsCard,
  loginLoading,
  validationError,
  titleMyRewardsCard,
  validatorQ,
  validatorQText,
  validatorR,
  validatorRText,
  valueLargeMission,
}

export interface LabelProps extends TextProps {
  variant?: LabelType;
}

const Label = (props: LabelProps) => {
  const userPrefs = useAppSelector((state) => state.userPrefs);

  const getStyleCallback = useCallback(
    (variant) => {
      switch (variant ?? LabelType.body1) {
        case LabelType.titleDark:
          return styles.titleDark;
        case LabelType.titleLight:
          return styles.titleLight;
        case LabelType.title:
          return styles.title;
        case LabelType.titleSmall:
          return styles.titleSmall;
        case LabelType.titlePrimarySmall:
          return styles.titlePrimarySmall;
        case LabelType.titleWhiteSmall:
          return styles.titleWhiteSmall;
        case LabelType.titleDarkSmall:
          return styles.titleDarkSmall;
        case LabelType.titleDarkSmall:
          return styles.titleDarkSmall;
        case LabelType.caption:
          return styles.caption;
        case LabelType.captionPrimary:
          return styles.captionPrimary;
        case LabelType.captionSmall:
          return styles.captionSmall;
        case LabelType.captionSmall:
          return styles.captionSmall;
        case LabelType.title:
          return styles.title;
        case LabelType.cancelButton:
          return styles.cancelButton;
        case LabelType.okButton:
          return styles.confirmButton;
        case LabelType.bodyWhiteSmall:
          return styles.bodyWhiteSmall;
        case LabelType.navigationTitle:
          return styles.navigationTitle;
        case LabelType.titleLarge:
          return styles.titleLarge;
        case LabelType.titleLargeNoCaps:
          return styles.titleLargeNoCaps;
        case LabelType.titleLargeNoCapsPrimary:
          return styles.titleLargeNoCapsPrimary;
        case LabelType.titleLargeDark:
          return styles.titleLargeDark;
        case LabelType.titleLargeLight:
          return styles.titleLargeLight;
        case LabelType.subTitleBlack:
          return styles.subTitleBlack;
        case LabelType.myAccountLink:
          return styles.myAccountLink;
        case LabelType.labelWhite:
          return styles.labelWhite;
        case LabelType.infoBoxTitle:
          return styles.infoBoxTitle;
        case LabelType.viewDetails:
          return styles.viewDetails;
        case LabelType.body1:
          return styles.body1;
        case LabelType.cardAnswer:
          return styles.cardAnswer;
        case LabelType.validatorQuestion:
          return styles.validatorQuestion;
        case LabelType.myRewardsCard:
          return styles.myRewardsCard;
        case LabelType.loginLoading:
          return styles.loginLoading;
        case LabelType.validationError:
          return styles.validationError;
        case LabelType.titleMyRewardsCard:
          return styles.titleMyRewardsCard;
        case LabelType.validatorQ:
          return styles.validatorQ;
        case LabelType.validatorQText:
          return styles.validatorQText;
        case LabelType.validatorR:
          return styles.validatorR;
        case LabelType.validatorRText:
          return styles.validatorRText;
        case LabelType.valueLargeMission:
          return styles.valueLargeMission;
        default:
          return styles.body1;
      }
    },
    [userPrefs.theme]
  );

  const styles = useStyles((section) => section.label);
  const [currentStyle, setCurrentStyle] = useState(getStyleCallback(props.variant));
  const [variant, setVariant] = useState(props.variant);

  useEffect(() => {
    setVariant(props.variant);
  }, [props.variant]);

  useEffect(() => {
    // if (!variant) {
    setCurrentStyle(getStyleCallback(variant));
    // }
  }, [variant, userPrefs.theme]);

  return (
    <Text textBreakStrategy="highQuality" numberOfLines={props.numberOfLines ?? 2} style={props.style ? [currentStyle, props.style] : currentStyle}>
      {props.children}
    </Text>
  );
};

export default Label;

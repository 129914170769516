import React, { useEffect, useState } from 'react';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import useTranslation from '../../../hooks/translation-hook';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import NavigationElements from '../../../models/NavigationElements';
import { View } from 'react-native';
import Label, { LabelType } from '../../common/Label';
import CustomSwitch from '../../common/CustomSwitch';
import { GetNotificationSettingsDto, MysteryShopperClient, MysteryShopperNotificationType, SaveNotificationSettingsRequest } from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import Loading from '../../common/Loading';
import useCustomTheme from '../../../hooks/theme-hook';

export interface SettingsNotificationsProps {
  noWrapper?: boolean;
  authToken?: string;
  onSaveSettings?(): void;
  onLoad?(): void;
}

const SettingsNotifications = (props: SettingsNotificationsProps) => {
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useCustomTheme();
  const msApi = useApi(MysteryShopperClient);
  const authStore = useAppSelector((selector) => selector.auth);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  const [notifications, setNotifications] = useState<GetNotificationSettingsDto[]>();
  const [loading, setLoading] = useState<boolean>();
  const [settingsComponent, setSettingsComponent] = useState(<></>);

  const [customToken, setCustomToken] = useState<string>();

  const saveNotificationSettingMutation = useMutation({
    mutationFn: (data: SaveNotificationSettingsRequest) => {
      return msApi.saveNotificationSettings(data, msApi.tokenSource?.token);
    },
  });

  useEffect(() => {
    setLoading(false);

    if (saveNotificationSettingMutation.isSuccess) {
      if (props.onSaveSettings) {
        props.onSaveSettings();
      }
    }
  }, [saveNotificationSettingMutation.isSuccess]);

  useEffect(() => {
    setCustomToken(props.authToken);
  }, [props.authToken]);

  const notifcationSettingsQuery = useQuery({
    enabled: !!authStore.idToken || !!customToken,
    queryKey: ['notifcationSettingsQuery', userPrefs.uiLanguage],
    retry: customToken ? 0 : 3,
    queryFn: ({ signal }) => {
      if (customToken) {
        msApi.setCustomToken(customToken);
        msApi.setCustomCulture(userPrefs.uiLanguage);
      }

      signal?.addEventListener('abort', () => {
        msApi.tokenSource?.cancel('Query was cancelled by React Query');
        msApi.onCancel();
      });

      setLoading(true);
      return msApi.getNotificationSettings(msApi.tokenSource?.token);
    },
  });

  useEffect(() => {
    if (notifcationSettingsQuery.isSuccess) {
      setNotifications(notifcationSettingsQuery.data.notificationSettings);
      setLoading(false);
    }
    props.onLoad && props.onLoad();
  }, [notifcationSettingsQuery.isSuccess, notifcationSettingsQuery.data]);

  useEffect(() => {
    if (props.noWrapper) {
      setSettingsComponent(
        <View>
          {!loading ? (
            <>
              {notifications?.map((x, i) => {
                return (
                  <View key={`notificationSetting_${i}`}>
                    <Label variant={LabelType.titleSmall} style={{ color: theme.text.titleDark }}>
                      {x.subjectTitle}
                    </Label>
                    <View key={x.subject} style={{ flexDirection: 'row', alignItems: 'center', gap: 16, paddingVertical: 6, marginBottom: 20 }}>
                      <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                        <Label style={{ fontSize: 14 }}>{t('email')}</Label>
                        <CustomSwitch isEnabled={x.emailEnabled} onPress={(e: any) => handleEmailToggle(x.subject)} />
                      </View>
                      {x.subject !== 2 && x.subject !== 0 && (
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                          <Label style={{ fontSize: 14 }}>{t('Mobile')}</Label>
                          <CustomSwitch isEnabled={x.pushNotificationEnabled} onPress={(e: any) => handleMobileToggle(x.subject)} />
                        </View>
                      )}
                    </View>
                  </View>
                );
              })}
            </>
          ) : (
            <Loading style={{ marginTop: 24 }} />
          )}
        </View>
      );
    } else {
      setSettingsComponent(
        <MyAccountPageWrapper
          navigationTitle={t('settings')}
          title={t('Notifications')}
          navigationAction={NavigationElements.settings}
          titleStyle={{ textTransform: 'none', marginBottom: 20 }}
        >
          <View>
            {!loading ? (
              <>
                {notifications?.map((x, i) => {
                  return (
                    <View key={`notificationSetting_${i}`}>
                      <Label variant={LabelType.titleSmall} style={{ color: theme.text.titleDark }}>
                        {x.subjectTitle}
                      </Label>
                      <View key={x.subject} style={{ flexDirection: 'row', alignItems: 'center', gap: 16, paddingVertical: 6, marginBottom: 20 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                          <Label style={{ fontSize: 14 }}>{t('email')}</Label>
                          <CustomSwitch isEnabled={x.emailEnabled} onPress={(e: any) => handleEmailToggle(x.subject)} />
                        </View>
                        {x.subject !== 2 && x.subject !== 0 && (
                          <View style={{ flexDirection: 'row', alignItems: 'center', gap: 8 }}>
                            <Label style={{ fontSize: 14 }}>{t('Mobile')}</Label>
                            <CustomSwitch isEnabled={x.pushNotificationEnabled} onPress={(e: any) => handleMobileToggle(x.subject)} />
                          </View>
                        )}
                      </View>
                    </View>
                  );
                })}
              </>
            ) : (
              <Loading style={{ marginTop: 24 }} />
            )}
          </View>
        </MyAccountPageWrapper>
      );
    }
  }, [notifications, props.noWrapper, props.authToken, userPrefs.uiLanguage]);

  const handleEmailToggle = (subject) => {
    setNotifications((prev) => {
      let item = prev.find((x) => x.subject == subject);
      item.emailEnabled = !item.emailEnabled;

      let data = new SaveNotificationSettingsRequest();

      data.subject = subject;
      data.type = MysteryShopperNotificationType.Email;
      data.enabled = item.emailEnabled;

      saveNotificationSettingMutation.mutate(data);

      return prev.slice();
    });
  };

  const handleMobileToggle = (subject) => {
    setNotifications((prev) => {
      let item = prev.find((x) => x.subject == subject);
      item.pushNotificationEnabled = !item.pushNotificationEnabled;

      let data = new SaveNotificationSettingsRequest();

      data.subject = subject;
      data.type = MysteryShopperNotificationType.PushNotification;
      data.enabled = item.emailEnabled;

      saveNotificationSettingMutation.mutate(data);

      return prev.slice();
    });
  };

  return settingsComponent;
};

export default SettingsNotifications;

import Label, { LabelType } from './Label';
import { SafeAreaView, View } from 'react-native';
import CustomButton from './CustomButton';
import React, { useEffect } from 'react';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import useCustomTheme from '../../hooks/theme-hook';
import useStyles from '../../hooks/styles-hook';
import useTranslation from '../../hooks/translation-hook';
import { FallbackComponentProps } from 'react-native-error-boundary';
import { useDispatch } from 'react-redux';
import { errorActions } from '../../store/errorSlice';
import { useAppSelector } from '../../hooks/store-hook';
import { authActions } from '../../models/helpers/platformImportHelper';
import { LinearGradient } from 'expo-linear-gradient';
import { useNavigation } from '@react-navigation/native';

const CustomErrorFallback = (props: FallbackComponentProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((selector) => selector.restrictedArea);
  const t = useTranslation();
  const dispatch = useDispatch();
  const authStore = useAppSelector((selector) => selector.auth);

  useEffect(() => {
    if ((props.error as any).status == 401) {
      dispatch(authActions.getToken());
    }
  }, []);

  const resetBoundry = () => {
    props.resetError();
  };

  useEffect(() => {
    dispatch(errorActions.setResetError(resetBoundry));
  }, []);

  useEffect(() => {
    if (props.error) {
      console.log('Error', props.error);
      console.log('Error status', (props.error as any).status);
      if ((props.error as any).code === 'ERR_NETWORK') {
        console.log('Network error');
        props.resetError();
      }

      dispatch(errorActions.setHasError(true));
    }
  }, [props.error]);

  return (
    <LinearGradient end={{ x: 0.1, y: 0.4 }} style={{ height: '100%', width: '100%' }} colors={['#035164', '#43a49e']}>
      <SafeAreaView style={[{ alignItems: 'center' }, styles.missionNotLogged.containerBase]}>
        <FontAwesomeIcon style={[{ marginTop: 24 }, styles.missionNotLogged.container.icon]} size={48} icon={faWarning} color={theme.primary.toString()} />
        <Label variant={LabelType.titleLargeDark} style={{ textAlign: 'center', flexShrink: 1, color: 'white', paddingHorizontal: 16 }} numberOfLines={5}>
          {(props.error as any).status === 404 ? t('error-404') : (props.error as any).status === 498 ? t('token-expired') : props.error.toString()}
        </Label>
        {(props.error as any).status !== 498 && (
          <View style={styles.missionNotLogged.container.button}>
            <CustomButton secondaryColor title={t('retry')} onPress={resetBoundry} />
          </View>
        )}
      </SafeAreaView>
    </LinearGradient>
  );
};

export default CustomErrorFallback;

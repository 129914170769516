import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import React, { ReactElement } from 'react';
import NavigationElements from '../models/NavigationElements';
import { StringHelper } from '../helpers/stringHelper';

interface MyAccountState {
  visible: boolean;
  content: ReactElement;
  props?: any;
  myRewardsSeed: string;
}

// Define the initial state using that type
const initialState: MyAccountState = {
  visible: false,
  content: NavigationElements.menu,
  props: undefined,
  myRewardsSeed: StringHelper.generateRandomString(),
};

export const myAccountSlice = createSlice({
  name: 'myaccount',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setContent: (state, action: PayloadAction<ReactElement>) => {
      state.content = action.payload;
    },
    setProps: (state, action: PayloadAction<any>) => {
      state.props = action.payload;
    },
    navigate: (state, action: PayloadAction<ReactElement>) => {
      if (action.payload === <></>) {
        state.visible = false;
      } else {
        state.content = action.payload as ReactElement;
      }
    },
    setMyRewardsSeed: (state, action: PayloadAction<string>) => {
      state.myRewardsSeed = action.payload;
    },
  },
});

export default myAccountSlice.reducer;
export const myAccountActions = myAccountSlice.actions;

import React from 'react';
import useMyAccountActions from '../../hooks/myAccountActions-hook';
import { useAppSelector } from '../../hooks/store-hook';
import Overlay from '../common/Overlay';

const MyAccount = () => {
  const myAccountStore = useAppSelector((selector) => selector.myAccount);
  const myAccountActions = useMyAccountActions();

  return (
    <>
      {myAccountStore.visible ? (
        <>
          <Overlay showOverLay={myAccountStore.visible} zIndex={1001} onPress={() => myAccountActions.setVisibility(false)} />
          {myAccountStore.content}
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MyAccount;

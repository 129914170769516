import React, { ReactElement, useEffect, useState } from 'react';
import { View, Image, Pressable, useWindowDimensions, ScrollView } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import useCustomTheme from '../../hooks/theme-hook';
import useTranslation from '../../hooks/translation-hook';
import { lightBoxActions } from '../../store/lightBoxSlice';
import Label from './Label';
import CustomCheckbox from './CustomCheckbox';

export interface ModalProps {
  onConfirm?(): void;
  title?: string;
  subtitle?: string;
  buttonText?: string;
  children?: ReactElement;
  hasDontShowAgain?: boolean;
  onClose?(dontShowAgain: boolean): void;
}

const ModalContainer = (props: ModalProps) => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const layout = useWindowDimensions();
  const dispatch = useAppDispatch();
  const [checked, setChecked] = useState<boolean>();

  useEffect(() => {
    dispatch(
      lightBoxActions.setOnClose(() => {
        dispatch(lightBoxActions.setOnClose(undefined));
        props.onClose && props.onClose(checked);
        setChecked(false);
      })
    );
  }, [checked]);

  const handleOnClosePress = () => {
    if (props.onClose) {
      props.onClose(checked);
    } else {
      dispatch(lightBoxActions.setVisibility(false));
      dispatch(lightBoxActions.setOnClose(undefined));
    }
  };

  const handleOnChange = (checked, value) => {
    setChecked(checked);
  };

  const handleOnConfirm = () => {
    props.onConfirm && props.onConfirm();
  };

  return (
    <View
      style={{
        position: 'relative',
        width: layout.width > 1200 ? 530 : 320,
        overflow: 'hidden',
        flexShrink: 1,
        backgroundColor: theme.background.modal,
        padding: 16,
      }}
    >
      {false && <Image style={{ position: 'absolute', top: -1, right: -1, width: 116, height: 102 }} source={require('../../../assets/questionBubble.png')} />}
      <Pressable style={{ position: 'absolute', top: 10, right: 10, zIndex: 2001 }} onPress={handleOnClosePress}>
        <FontAwesomeIcon icon={faXmark} size={20} style={{ color: theme.background.sea.toString() }} />
      </Pressable>
      {props.title != undefined && (
        <Label style={{ width: 185, fontSize: 18, fontWeight: '600', color: theme.text.darkTurquoiseTwo }} numberOfLines={5}>
          {props.title}
        </Label>
      )}
      {props.subtitle != undefined && (
        <Label style={{ width: 220, fontSize: 14, marginTop: 16 }} numberOfLines={5}>
          {props.subtitle}
        </Label>
      )}
      <ScrollView style={{ flexGrow: 1, marginTop: props.title != undefined || props.subtitle != undefined ? 16 : 0, flexShrink: 1 }}>
        {props.children}
      </ScrollView>
      <View style={{ marginTop: 16, justifyContent: 'center', alignItems: 'center' }}>
        <Pressable
          style={{
            paddingHorizontal: 20,
            paddingVertical: 12,
            borderRadius: 100,
            backgroundColor: theme.background.darkTurquoise,
            margin: 'auto',
          }}
          onPress={handleOnConfirm}
        >
          <Label style={{ fontSize: 14, fontWeight: '600', color: theme.text.inverse }}>{props.buttonText ? props.buttonText : t('confirm')}</Label>
        </Pressable>
      </View>
      {props.hasDontShowAgain && <CustomCheckbox value={'dontShowAgain'} label={t('Dont-show-this-again')} onChange={handleOnChange} />}
    </View>
  );
};

export default ModalContainer;

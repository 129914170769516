import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Themes } from '../styles/theme';
import { DeviceTheme, GetMysteryShopperAddressDto, GetMysteryShopperProfileDto, GetMysteryShopperProfileMemberDto } from '../api/api.g';
import { MapLocation } from '../models/MapModels';

export interface UserPreferencesState {
  currentLocation?: MapLocation;
  theme: DeviceTheme;
  uiLanguage: string;
  languages: string[];
  email: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  addresses?: GetMysteryShopperAddressDto[];
  profiles?: GetMysteryShopperProfileDto[];
  fullName?: string;
  profileMembers?: GetMysteryShopperProfileMemberDto[];
  phoneNumberVerified?: boolean;
  lastSendEmailTime?: Date;
}

// Define the initial state using that type
const initialState: UserPreferencesState = {
  theme: DeviceTheme.Light,
  languages: ['fr'],
  uiLanguage: '',
  email: '',
};

export const userPreferencesSlice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<DeviceTheme>) => {
      state.theme = action.payload;
      Themes.getInstance().setTheme(state.theme);
    },
    setLanguages: (state, action: PayloadAction<string[]>) => {
      state.languages = action.payload;
      // state.uiLanguage = action.payload[0];
    },
    setUiLanguage: (state, action: PayloadAction<string>) => {
      // const tempLanguages = state.languages.filter((item) => item !== action.payload);
      // tempLanguages.unshift(action.payload);
      // state.languages = tempLanguages;
      state.uiLanguage = action.payload;
    },
    setUiLanguageAndLanguages: (state, action: PayloadAction<string>) => {
      const tempLanguages = state.languages.filter((item) => item !== action.payload);
      tempLanguages.unshift(action.payload);
      state.languages = tempLanguages;

      state.uiLanguage = action.payload;
    },
    setBasicInfo: (state, action: PayloadAction<UserPreferencesState>) => {
      state.addresses = action.payload?.addresses ?? undefined;
      state.email = action.payload?.email ?? '';
      state.firstName = action.payload?.firstName ?? '';
      state.lastName = action.payload?.lastName ?? '';
      state.fullName = `${state.firstName}${state.lastName ? ' ' + state.lastName : ''}`;
      state.languages = action.payload?.languages ?? [];
      state.phoneNumber = action.payload?.phoneNumber ?? '';
      state.profiles = action.payload?.profiles ?? [];
      state.uiLanguage = action.payload?.languages[0] ?? process.env.REACT_APP_DEFAULT_LANG;
      state.theme = action.payload?.theme ?? DeviceTheme.Light;
      Themes.getInstance().setTheme(state.theme);
    },
    setProfileMembers: (state, action: PayloadAction<GetMysteryShopperProfileMemberDto[]>) => {
      state.profileMembers = action.payload;
    },
    setLocation: (state, action: PayloadAction<MapLocation>) => {
      state.currentLocation = action.payload;
    },
    setPhoneNumberVerified: (state, action: PayloadAction<boolean>) => {
      state.phoneNumberVerified = action.payload;
    },
    setLastSendEmailTime: (state, action: PayloadAction<Date>) => {
      state.lastSendEmailTime = action.payload;
    }
  },
});

export default userPreferencesSlice.reducer;
export const userPrefsActions = userPreferencesSlice.actions;

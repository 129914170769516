import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { ScrollView, StyleProp, TextStyle, useWindowDimensions, View, ViewProps } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import Label, { LabelType } from '../common/Label';
import MyAccountNavigationButton from './MyAccountNavigationButton';
import MoreInfoButton, { MoreInfoButtonProps } from '../common/MoreInfoButton';

export interface PageWrapperButtonProps extends MoreInfoButtonProps {
  externalLink?: string;
}

export interface MyAccountPageWrapperProps extends ViewProps {
  title: string;
  titleStyle?: StyleProp<TextStyle>;
  navigationTitle: string;
  navigationAction: ReactElement;
  moreInfoComponent?: PageWrapperButtonProps;
  scrollOnChange?: boolean;
  footerElement?: ReactElement;
}

const MyAccountPageWrapper = (props: MyAccountPageWrapperProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.myAccount);
  const layout = useWindowDimensions();

  const [scrollOnChange, setScrollOnChange] = useState(props.scrollOnChange);

  const scrollView = useRef<ScrollView>();

  useEffect(() => {
    setScrollOnChange(props.scrollOnChange);
  }, [props.scrollOnChange]);

  return (
    <View style={[styles.pageWrapperBase, layout.width > 1200 ? { width: 375, right: 0, left: 'auto' } : {}]}>
      <View style={styles.pageWrapper.titleBar}>
        <MyAccountNavigationButton title={props.navigationTitle} action={props.navigationAction} />
      </View>
      <ScrollView
        style={{ padding: 16 }}
        contentContainerStyle={{ flexGrow: 1 }}
        ref={scrollView}
        onContentSizeChange={() => (scrollOnChange ? scrollView.current.scrollToEnd({ animated: true }) : null)}
      >
        <View style={[props.titleStyle, styles.pageWrapper.title]}>
          <Label variant={LabelType.titleLargeNoCapsPrimary}>{props.title}</Label>
        </View>
        {props.children}
      </ScrollView>
      {props.footerElement && props.footerElement}
      {props.moreInfoComponent && (
        <MoreInfoButton
          style={[styles.pageWrapper.infoButton, props.moreInfoComponent.style]}
          title={props.moreInfoComponent.title}
          showTitle={props.moreInfoComponent.showTitle}
          externalLink={props.moreInfoComponent.externalLink}
        >
          {props.moreInfoComponent.children}
        </MoreInfoButton>
      )}
    </View>
  );
};

export default MyAccountPageWrapper;

import React, { useCallback, useEffect, useState } from 'react';
import useApi from './api-hook';
import { GetAdvertisementTrackingListAdvertisementDto, MysteryShopperClient, UpdateAdvertisementTrackingByCodeDto } from '../api/api.g';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useAppSelector } from './store-hook';
import { StringHelper } from '../helpers/stringHelper';

export interface updateAdvertisementTrackingDto {
  code: string;
  data: UpdateAdvertisementTrackingByCodeDto;
}

const useAdvertisementHook = () => {
  const authStore = useAppSelector((selector) => selector.auth);
  const mysteryShopperApi = useApi(MysteryShopperClient);

  const [querySeed, setQuerySeed] = useState<string>(StringHelper.generateRandomString());
  const [adList, setAdList] = useState<GetAdvertisementTrackingListAdvertisementDto[]>();
  const [loaded, setLoaded] = useState<boolean>();

  const updateAdvertisementTrackingMutation = useMutation({
    mutationFn: (dto: updateAdvertisementTrackingDto) => {
      return mysteryShopperApi.updateAdvertisementTracking(dto.code, dto.data, mysteryShopperApi.tokenSource?.token);
    },
    onSuccess() {
      setQuerySeed(StringHelper.generateRandomString());
    },
  });

  const getAdListQuery = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['getAdListQuery', querySeed],
    queryFn: ({ signal }) => {
      signal?.addEventListener('abort', () => {
        mysteryShopperApi.tokenSource?.cancel('Query was cancelled by React Query');
        mysteryShopperApi.onCancel();
      });

      return mysteryShopperApi.getAdvertisementTracking(mysteryShopperApi.tokenSource?.token);
    },
  });

  const getAdvertisementCallback = useCallback(
    (code) => {
      return !adList?.find((x) => x.code === code)?.doNotShowAgain;
    },
    [adList]
  );

  const dontShowAgain = (code) => {
    let dto = new UpdateAdvertisementTrackingByCodeDto();
    dto.doNotShowAgain = true;

    updateAdvertisementTrackingMutation.mutate({
      code: code,
      data: dto,
    });
  };

  useEffect(() => {
    if (getAdListQuery.isSuccess) {
      setAdList(getAdListQuery.data.items);
      setLoaded(true);
    }
  }, [getAdListQuery.isSuccess, getAdListQuery.data]);

  return { showAdvertisement: getAdvertisementCallback, dontShowAgain: dontShowAgain, loaded: loaded };
};

export default useAdvertisementHook;

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCrosshairs } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { Platform, Pressable, View } from 'react-native';
import useStyles from '../../hooks/styles-hook';

export interface MapControlsProps {
  hidePanToOrigin?: boolean;
  onPanToOrigin?(): void;
  onZoomIn?(): void;
  onZoomOut?(): void;
}

function MapControls(props: MapControlsProps) {
  const styles = useStyles((style) => style.mapControl);

  return (
    <>
      {/* <Pressable style={[styles.roundButton, styles.info]}>
        <Image style={styles.image} source={require('../../../assets/information.png')} />
      </Pressable> */}
      <View style={styles.container}>
        {!props.hidePanToOrigin && (
          <Pressable style={styles.roundButton} onPress={() => props.onPanToOrigin()}>
            <FontAwesomeIcon size={32} icon={faCrosshairs} color="white" />
          </Pressable>
        )}
        {Platform.OS === 'web' && (
          <View style={styles.roundButtonGroupedBase}>
            <Pressable onPress={() => props.onZoomIn()}>
              <FontAwesomeIcon
                size={18}
                icon={faPlus}
                color="white"
                style={[styles.roundButtonGrouped.iconBase, styles.roundButtonGrouped.icon.bottomBorder]}
              />
            </Pressable>
            <Pressable onPress={() => props.onZoomOut()}>
              <FontAwesomeIcon size={18} icon={faMinus} color="white" style={styles.roundButtonGrouped.iconBase} />
            </Pressable>
          </View>
        )}
      </View>
    </>
  );
}

export default MapControls;

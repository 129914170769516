import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { Pressable, View, Text, LayoutChangeEvent, useWindowDimensions } from 'react-native';
import useCustomTheme from '../../hooks/theme-hook';
import useTranslation from '../../hooks/translation-hook';
import Label from '../common/Label';
import CustomCarouselPagination from './CarouselPagination';
import { useAppDispatch } from '../../hooks/store-hook';
import { lightBoxActions } from '../../store/lightBoxSlice';
import { myAccountActions } from '../../store/myAccountSlice';
import NavigationElements from '../../models/NavigationElements';
import { navBarActions } from '../../store/navBarSlice';
import { useNavigation } from '@react-navigation/native';

export interface CustomCarouselProps {
  children: any;
  onClose?(): void;
  bigView?: boolean;
  onPageChange?(currentPage: number): void;
}

const bulletColors = ['#43a49e', '#abadf0', '#f5a523', '#1473be'];
const bulletActiveColors = ['#035164', '#5e51a3', '#8e183d', '#00324a'];

const CustomCarousel = (props: CustomCarouselProps) => {
  const theme = useCustomTheme();
  const dispatch = useAppDispatch();
  const style = useCarouselStyles();
  const t = useTranslation();
  const [numberOfPages, setNumberOfPages] = useState<number>(4);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [showCloseButton, setShowCloseButton] = useState<boolean>(props.onClose ? true : false);
  const [isVisible, setIsVisible] = useState(true);

  const handleOnPagePress = (page) => {
    setCurrentPage(page);
  };

  const handleOnPreviousPress = () => {
    if (currentPage !== 0) {
      setCurrentPage(currentPage - 1);

      if (props.onPageChange) {
        props.onPageChange(currentPage - 1);
      }
    }
  };

  const handleOnNextPress = () => {
    if (currentPage < numberOfPages - 1) {
      setCurrentPage(currentPage + 1);

      if (props.onPageChange) {
        props.onPageChange(currentPage + 1);
      }
    }
  };

  const handleNavigateToLink = (page: string) => {
    dispatch(lightBoxActions.setVisibility(false));
    dispatch(myAccountActions.setVisibility(true));
    if (page === 'myRewards') {
      dispatch(myAccountActions.navigate(NavigationElements.myRewards));
    } else if (page === 'myProfil') {
      dispatch(myAccountActions.navigate(NavigationElements.myProfile));
    }
  };

  const handleOnClosePress = () => {
    props.onClose && props.onClose();
  };

  useEffect(() => {
    if (!isVisible) {
      handleOnClosePress();
    }
  }, [isVisible]);

  return (
    <View style={style.container}>
      {React.Children.map(props.children, (child, index) => {
        if (index === currentPage) {
          return (
            <View key={`validation_card_${index}`} style={style.pageContainer}>
              {React.cloneElement(child, { onNavigateToLink: handleNavigateToLink })}
            </View>
          );
        } else return <></>;
      })}
      {showCloseButton && (
        <Pressable style={style.closeButton} onPress={handleOnClosePress}>
          <FontAwesomeIcon style={style.closeButton.xMark} icon={faXmark} size={30} color={theme.background.primary.toString()} />
        </Pressable>
      )}
      {currentPage !== 0 && (
        <Pressable style={props.bigView ? style.previousButtonDesktop : style.previousButton} onPress={handleOnPreviousPress}>
          <Label style={style.previousButton.label}>{t('back')}</Label>
        </Pressable>
      )}
      {currentPage < numberOfPages - 1 && (
        <Pressable style={props.bigView ? style.nextButtonDesktop : style.nextButton} onPress={handleOnNextPress}>
          <FontAwesomeIcon style={style.nextButton.arrow} icon={faArrowRight} size={25} color={bulletActiveColors[currentPage]} />
        </Pressable>
      )}
      <CustomCarouselPagination
        bulletActiveColors={bulletActiveColors}
        bulletColors={bulletColors}
        numberOfPages={numberOfPages}
        currentPage={currentPage}
        onPagePress={handleOnPagePress}
      />
    </View>
  );
};

const useCarouselStyles = () => {
  const theme = useCustomTheme();

  let carousel = {
    pageContainer: {
      flexGrow: 1,
    },
    container: { position: 'relative' as any, flexGrow: 1, backgroundColor: theme.background.modal },
    closeButton: {
      position: 'absolute' as any,
      top: 20,
      right: 20,
      xMark: {
        outline: 'none' as any,
      } as any,
    },
    previousButton: {
      position: 'absolute' as any,
      left: 12,
      bottom: 10,
      label: {
        fontSize: 14,
        color: theme.text.darkTurquoiseTwo,
        textDecorationLine: 'underline' as any,
      },
    },
    nextButton: {
      display: 'flex' as any,
      justifyContent: 'center' as any,
      position: 'absolute' as any,
      right: 24,
      top: '40%' as any,
      transform: [{ translateY: -50 }],
      width: 56,
      height: 56,
      borderRadius: 28,
      backgroundColor: 'white' as any,
      alignItems: 'center' as any,
      elevation: 4,
      shadowColor: 'rgba(0, 0, 0, 0.16)' as any,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 1,
      shadowRadius: 6,
      arrow: {
        margin: 'auto' as any,
        outline: 'none' as any,
      },
    },
    previousButtonDesktop: {
      position: 'absolute' as any,
      left: 34,
      bottom: 10,
    },
    nextButtonDesktop: {
      position: 'absolute' as any,
      right: 38,
      bottom: 0,
      transform: [{ translateY: -50 }],
      width: 56,
      height: 56,
      borderRadius: 30,
      backgroundColor: 'white' as any,
      alignItems: 'center' as any,
      elevation: 4,
      shadowColor: 'rgba(0, 0, 0, 0.16)' as any,
      shadowOffset: { width: 0, height: 3 },
      shadowOpacity: 1,
      shadowRadius: 6,
      arrow: {
        margin: 'auto' as any,
        outline: 'none' as any,
      },
    },
  };

  return carousel as typeof carousel;
};

export default CustomCarousel;

import { faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { ReactElement, useEffect, useState } from 'react';
import { Linking, Modal, Pressable, StyleProp, View, ViewStyle, useWindowDimensions } from 'react-native';
import useTranslation from '../../hooks/translation-hook';
import Label, { LabelType } from './Label';
import useCustomTheme from '../../hooks/theme-hook';
import CustomMarkdown from './CustomMarkDown';
import ModalContainer from './ModalContainer';
import Overlay from './Overlay';
import Popup from './Popup';

export interface MoreInfoButtonProps {
  style?: StyleProp<ViewStyle>;
  children: string;
  title?: string;
  showTitle?: boolean;
  color?: string;
  size?: number;
  externalLink?: string;
  openURL?: string;
  icon?: ReactElement;
}

const MoreInfoButton = (props: MoreInfoButtonProps) => {
  const layout = useWindowDimensions();
  const t = useTranslation();
  const theme = useCustomTheme();

  const [title, setTitle] = useState(props.title);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  const handleOnConfirm = () => {
    setVisible(false);
  };

  const handleOpenInfo = () => {
    if (props.externalLink) {
      Linking.openURL(props.externalLink);
      return;
    } else {
      setVisible(true);
    }
  };

  return (
    <>
      <Pressable style={[{ flexDirection: 'row', alignItems: 'center' }, props.style]} onPress={handleOpenInfo}>
        {props.icon ? props.icon : <FontAwesomeIcon size={props.size ?? 22} icon={faCircleQuestion} color={props.color ?? theme.text.titleDark.toString()} />}
        {props.showTitle && (
          <Label style={{ fontSize: 16, marginLeft: 8 }} variant={LabelType.titleDarkSmall}>
            {title}
          </Label>
        )}
      </Pressable>
      <Modal animationType="none" visible={visible} transparent={true}>
        <Overlay
          showOverLay={true}
          zIndex={1998}
          onPress={() => {
            setVisible(false);
          }}
        >
          <Popup>
            <View style={[{ flexShrink: 1 }, props.style ?? {}]}>
              <ModalContainer
                buttonText={t('got-it')}
                onConfirm={handleOnConfirm}
                onClose={() => {
                  setVisible(false);
                }}
              >
                <>
                  <CustomMarkdown>{props.children}</CustomMarkdown>
                  {props.openURL && (
                    <Pressable
                      onPress={() => {
                        Linking.openURL(t(props.openURL));
                      }}
                    >
                      <Label
                        numberOfLines={1}
                        style={{
                          width: layout.width > 1200 ? 460 : '100%',
                          fontSize: 16,
                          textAlign: layout.width > 1200 ? 'left' : 'center',
                          color: theme.text.sea,
                        }}
                      >
                        {t('learn-more')}
                      </Label>
                    </Pressable>
                  )}
                </>
              </ModalContainer>
            </View>
          </Popup>
        </Overlay>
      </Modal>
    </>
  );
};

export default MoreInfoButton;

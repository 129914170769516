import React, { useEffect } from 'react';
import { Dimensions, ScrollView, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import useCustomTheme from '../../hooks/theme-hook';
import useTranslation from '../../hooks/translation-hook';
import { StylesHelper } from '../../models/helpers/stylesHelper';
import { modalActions } from '../../store/modalSlice';
import ButtonBar from './ButtonBar';
import Label, { LabelType } from './Label';
import Overlay from './Overlay';

const CustomModal = () => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const modalStore = useAppSelector((selector) => selector.modal);
  const dispatch = useAppDispatch();
  const modalDimensionWidth = Dimensions.get('window').width - 32;
  const modalDimensionHeight = Dimensions.get('window').height - 72;

  useEffect(() => {
    // console.log('ModalStore', modalStore.visible);
  }, [modalStore.visible]);

  return (
    <>
      <Overlay showOverLay={modalStore.visible} zIndex={1999} onPress={() => dispatch(modalActions.setVisibility(false))} />
      <View
        style={{
          position: 'absolute',
          width: modalDimensionWidth,
          maxHeight: modalDimensionHeight,
          borderRadius: 8,
          display: modalStore.visible ? 'flex' : 'none',
          flexDirection: 'column',
          ...StylesHelper.padding(16),
          backgroundColor: theme.background.modal,
          overflow: 'hidden',
          top: 32,
          left: 16,
          zIndex: 2000,
        }}
      >
        <Label variant={LabelType.title} style={{ paddingBottom: 8 }}>
          {modalStore.title}
        </Label>
        <ScrollView style={{ flexGrow: 1 }}>{modalStore.content}</ScrollView>
        <ButtonBar
          leftButtonLabel={modalStore.buttonBarConfig.cancelButtonLabel}
          rightButtonLabel={modalStore.buttonBarConfig.confirmButtonLabel}
          onLeftButtonClick={modalStore.buttonBarConfig.onCancelButtonClick}
          onRightButtonClick={modalStore.buttonBarConfig.onConfirmButtonClick}
        />
      </View>
    </>
  );
};

export default CustomModal;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { FirebaseService } from '../services/FirebaseService';
import { AuthStateWeb } from '../models/AuthModels';
import { LoggedState, LoginPayload } from '../models/AuthModelsCommon';
import { User } from 'firebase/auth';

const firebaseService = new FirebaseService();

function checkOS(n) {
  if (n.userAgentData) {
    const hints = ['architecture', 'model', 'platform', 'platformVersion', 'uaFullVersion'];
    n.userAgentData.getHighEntropyValues(hints).then((ua) => {
      console.log(ua);
      return false;
    });
  } else {
    console.log(n.userAgent);
    return true;
  }
}

// Define the initial state using that type
const initialState: AuthStateWeb = {
  returnPath: 'map',
  logged: undefined,
  idToken: undefined,
  user: firebaseService.currentUser,
  multifactor: undefined,
  isLanla: false,
  params: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSignUpSource: (state, action: PayloadAction<string>) => {
      state.signUpSource = action.payload;
    },
    loginWithGoogle: (state, action: PayloadAction<LoginPayload>) => {
      const usePopup = checkOS(navigator);

      firebaseService
        .loginWithGoogle(usePopup)
        .then(() => { })
        .catch((error) => {
          console.log('Error', action.payload);
          action.payload?.callback(error);
        });
    },
    reauthenticate: (state, action: PayloadAction<LoginPayload>) => {
      firebaseService.setLoginCallback(action.payload.callback);
      (async () => {
        await firebaseService.reauthenticate(action.payload.email, action.payload.password);
      })();
    },
    loginPassword: (state, action: PayloadAction<LoginPayload>) => {
      firebaseService.setLoginCallback(action.payload.callback);
      state.idToken = undefined;

      (async () => {
        await firebaseService.login(action.payload.email, action.payload.password);
      })();
    },
    anonymousLogin: (state, action: PayloadAction<LoginPayload>) => {
      state.idToken = undefined;

      firebaseService.setLoginCallback(action.payload.callback);
      (async () => {
        await firebaseService.loginAnonymously();
      })();
    },
    register: (state, action: PayloadAction<LoginPayload>) => {
      state.idToken = undefined;
      firebaseService.setLoginCallback(action.payload.callback);

      (async () => {
        await firebaseService.signUp(action.payload.email, action.payload.password);
      })();
    },
    fetchSignInMethods: (state, action: PayloadAction<LoginPayload>) => {
      (async () => {
        await firebaseService.getSignInMethods(action.payload.email).then((methods) => {
          action.payload.callback(methods);
        });
      })();
    },
    sendPasswordResetEmail: (state, action: PayloadAction<{ email: string; lang: string; callback(e: any): void }>) => {
      firebaseService.setLoginCallback(action.payload.callback);

      (async () => {
        await firebaseService.sendPasswordResetEmail(action.payload.email, action.payload.lang);
      })();
    },
    confirmThePasswordReset: (state, action: PayloadAction<{ oobCode: string; newPassword: string; callback(e: any): void }>) => {
      if (!action.payload.oobCode && !action.payload.newPassword) return;

      firebaseService.setLoginCallback(action.payload.callback);
      (async () => {
        await firebaseService.confirmPasswordReset(action.payload.oobCode, action.payload.newPassword);
      })();
    },
    applyActionCode: (state, action: PayloadAction<{ oobCode: string; callback(): void }>) => {
      firebaseService.applyActionCode(action.payload.oobCode).then(() => action.payload.callback());
    },
    changeEmail: (state, action: PayloadAction<{ email: string; callback(e): void }>) => {
      firebaseService.verifyChangeEmail(action.payload.email, action.payload.callback);
    },
    changePassword: (state, action: PayloadAction<{ password: string; callback(e): void }>) => {
      firebaseService.changePassword(action.payload.password, action.payload.callback);
    },
    logout: (state) => {
      state.onAuthStateChanged = undefined;
      state.idToken = undefined;
      state.user = undefined;
      state.multifactor = undefined;
      state.logged = LoggedState.NotLogged;
      state.returnPath = '';

      (async () => {
        await firebaseService.logout();
      })();
    },
    setLogged: (state, action: PayloadAction<{ idToken: string; user: any }>) => {
      state.idToken = action.payload?.idToken;
      state.user = action.payload?.user;
      state.isLanla = action.payload?.user.email?.indexOf('@lanla.com') > -1 ?? false;

      console.log('IsLanla', state.isLanla);

      if (state.idToken) {
        if (state.user && state.user.isAnonymous) {
          state.logged = LoggedState.Anonymous;
        } else {
          state.logged = LoggedState.Logged;
        }
      } else {
        state.logged = LoggedState.NotLogged;
      }

      console.log('Logged State:', LoggedState[state.logged]);
      console.log('User:', state.user);
      console.log('token Id:', !!state.idToken);
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.idToken = action.payload;
    },
    getToken: (state) => {
      (async () => {
        const newToken = await firebaseService?.getIdToken();
        console.log('%cGet IdToken', 'color: #ffa600;');

        if (newToken != state.idToken) {
          console.log('%cToken refreshed', 'color: #ffa600;');
          state.idToken = newToken;
        }
      })();
    },
    setUser: (state, action: PayloadAction<User>) => {
      if (action.payload === null) {
        state.user = null;
        state.idToken = undefined;
        state.logged = LoggedState.NotLogged;
        state.isLanla = false;

        console.log('state not logged', state.logged);
      } else {
        state.user = action.payload;
      }
    },
    setAuthStateChanged: (state, action: PayloadAction<any>) => {
      state.onAuthStateChanged = action.payload;
      firebaseService.onAuthStateChanged(state.onAuthStateChanged);
    },
    setIdTokenChanged: (state, action: PayloadAction<(e: User) => void>) => {
      state.onIdTokenChanged = action.payload;
      firebaseService.onIdTokenChanged(state.onIdTokenChanged);
    },
    setReturnPath: (state, action: PayloadAction<string>) => {
      state.returnPath = action.payload;
    },
    setParams: (state, action: PayloadAction<any>) => {
      state.params = action.payload;
    },
    setMultifactor: (state, action: PayloadAction<any>) => {
      state.multifactor = action.payload;
    }
  },
});

export const FirebaseReference = firebaseService;
export default authSlice;

import React from 'react';
import { Platform, View, Image } from 'react-native';
import Label, { LabelType } from './Label';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCloudSlash } from '@fortawesome/pro-regular-svg-icons';
import useCustomTheme from '../../hooks/theme-hook';
import { LinearGradient } from 'expo-linear-gradient';
import Logo from '../../../assets/login-page-logo.svg';
import useTranslation from '../../hooks/translation-hook';

const OffLinePopup = () => {
  const theme = useCustomTheme();
  const t = useTranslation();

  return (
    <LinearGradient
      end={{ x: 0.1, y: 0.4 }}
      style={{
        zIndex: 5000,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      colors={['#022d38', '#034050', '#044c5e', '#04647c', '#43a49e']}
    >
      {Platform.OS !== 'web' ? (
        <View style={{ position: 'absolute', top: 32, left: 32 }}>
          <Logo width={85} height={40} />
        </View>
      ) : (
        <Image style={{ position: 'absolute', top: 32, left: 32, width: 85, height: 40 }} source={require('../../../assets/login-page-logo.svg')} />
      )}
      <FontAwesomeIcon icon={faCloudSlash} size={64} color={theme.primary.toString()} />
      <View>
        <Label variant={LabelType.titleLargeNoCaps} style={{ color: 'white' }}>
          Oops...
        </Label>
        <Label variant={LabelType.titleLargeDark} style={{ color: 'white' }}>
          {t('not-connected-message')}
        </Label>
      </View>
    </LinearGradient>
  );
};

export default OffLinePopup;

import React, { useEffect, useState } from 'react';
import { Pressable, useWindowDimensions, View, Image, ScrollView } from 'react-native';
import Label, { LabelType } from '../../common/Label';
import useTranslation from '../../../hooks/translation-hook';
import { LinearGradient } from 'expo-linear-gradient';
import MenuItem from './MenuItem';
import MyAccountNavigationButton from '../MyAccountNavigationButton';
import useStyles from '../../../hooks/styles-hook';
import NavigationElements from '../../../models/NavigationElements';
import useMyAccountActions from '../../../hooks/myAccountActions-hook';
import {
  faArrowRightArrowLeft,
  faArrowRightFromBracket,
  faAward,
  faBadgeCheck,
  faCircleQuestion,
  faGear,
  faGlobePointer,
  faSealExclamation,
  faUser,
} from '@fortawesome/pro-regular-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { GetMyRewardsViewModel, MyRewardsClient } from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import { useAppSelector } from '../../../hooks/store-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import { LoggedState } from '../../../models/AuthModelsCommon';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import MoreInfoButton from '../../common/MoreInfoButton';
import { StylesHelper } from '../../../models/helpers/stylesHelper';

const Menu = () => {
  const t = useTranslation();
  const theme = useCustomTheme();

  const styles = useStyles((section) => section.myAccount);
  const layout = useWindowDimensions();
  const myAccountActions = useMyAccountActions();
  const authStore = useAppSelector((selector) => selector.auth);
  const accountStore = useAppSelector((selector) => selector.myAccount);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const apiRewards = useApi(MyRewardsClient);

  const [rewards, setRewards] = useState<GetMyRewardsViewModel>();
  const [userInitials, setUserInitials] = useState('');

  const dimensions = useWindowDimensions();

  const getMyRewards = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['myRewardsQuery', authStore.logged, authStore.idToken, accountStore.myRewardsSeed],
    queryFn: ({ signal }) => {
      const promise = apiRewards.getMyRewards(apiRewards.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiRewards.tokenSource?.cancel('Query was cancelled by React Query');
        apiRewards.onCancel();
      });

      return promise;
    },
    onError: (err) => {
      throw err;
    },
  });

  useEffect(() => {
    setRewards(getMyRewards.data);
  }, [getMyRewards.isSuccess, getMyRewards.data]);

  useEffect(() => {
    if (userPrefs) {
      const initials = `${userPrefs.firstName ? userPrefs.firstName.substring(0, 1) : '-'}${userPrefs.lastName ? userPrefs.lastName.substring(0, 1) : '-'}`;
      setUserInitials(initials);
    }
  }, [userPrefs.firstName, userPrefs.lastName]);

  const handleMyRewardsClick = () => {
    myAccountActions.navigate(NavigationElements.myRewards);
  };

  return (
    <View style={[styles.menu.container, layout.width > 1200 ? { width: 375, right: 0, left: 'auto' } : {}]}>
      <LinearGradient
        colors={['#035164', '#43a49e']}
        end={{ x: 1.4, y: 0 }}
        style={[
          styles.menu.container,
          { ...StylesHelper.padding(16) },
          layout.width > 1200 ? { width: 375, right: 0, left: 'auto' } : { width: '100%', height: '100%' },
        ]}
      >
        <ScrollView contentContainerStyle={{ minHeight: '100%' }}>
          <View style={{ flexGrow: 1 }}>
            <View>
              <MyAccountNavigationButton
                title={t('back')}
                action={<></>}
                navigationTitleColor={'white'}
                iconColor={'white'}
                onPress={() => myAccountActions.setVisibility(false)}
              />
            </View>
            <View style={styles.loggedInfo.container}>
              <View style={styles.loggedInfo.initials}>
                {authStore.logged !== LoggedState.Anonymous && userInitials !== '--' && (
                  <Label numberOfLines={1} variant={LabelType.titleLargeNoCaps} style={styles.loggedInfo.initialsLabel}>
                    {userInitials}
                  </Label>
                )}
                {(authStore.logged === LoggedState.Anonymous || userInitials === '--') && (
                  <FontAwesomeIcon icon={faUser} style={{ color: 'white' }} size={24} />
                )}
              </View>
              <View style={[styles.loggedInfo.info]}>
                {authStore.user?.email && (
                  <>
                    {userPrefs?.firstName && userPrefs?.lastName && (
                      <Label numberOfLines={1} variant={LabelType.titleLargeNoCaps} style={styles.loggedInfo.initialsLabel}>
                        {`${userPrefs.firstName} ${userPrefs.lastName}`}
                      </Label>
                    )}
                    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <Label numberOfLines={1} variant={LabelType.titleLargeNoCaps} style={styles.loggedInfo.initialsLabelEmail}>
                        {`${authStore.user?.email}`}
                      </Label>
                      {/* {authStore.user.emailVerified ? (
                        <MoreInfoButton style={{ marginLeft: 4 }} icon={<FontAwesomeIcon icon={faBadgeCheck} color="white" size={20} />}>
                          {t('Email-verified-Your-email-has-been-verified-which')}
                        </MoreInfoButton>
                      ) : (
                        <MoreInfoButton style={{ marginLeft: 4 }} icon={<FontAwesomeIcon icon={faSealExclamation} color="white" size={20} />}>
                          {t('Email-unverified-Your-email-is-currently-unverifie')}
                        </MoreInfoButton>
                      )} */}
                    </View>
                  </>
                )}
                {!authStore.user?.email && (
                  <Label numberOfLines={1} variant={LabelType.titleLargeNoCaps} style={styles.loggedInfo.initialsLabelEmail}>
                    {t(`anonymous`)}
                  </Label>
                )}
              </View>
            </View>
            <View style={{ flexDirection: 'row' }}>
              <Label style={styles.menu.myProfileTitle} variant={LabelType.titleLargeNoCaps}>
                {t('my_profile')}
              </Label>
              <MoreInfoButton style={{ marginLeft: 8 }} title={''} color={'white'} openURL={'my-profile-lanla-website-suuport-link'}>
                {t('help.my-profile')}
              </MoreInfoButton>
            </View>
            {authStore.logged !== LoggedState.Anonymous && (
              <Pressable onPress={handleMyRewardsClick} style={styles.menu.whiteCard}>
                <View style={styles.menu.whiteCard.myRewards}>
                  {/* <FontAwesomeIcon icon={faAward} size={64} style={styles.menu.whiteCard.myRewards.icon} color={theme.mapControls.toString()} /> */}
                  <Image style={{ width: 61, height: 61, marginRight: 12 }} source={require('../../../../assets/my-reward-55.png')}></Image>
                  <View style={{ flexGrow: 1 }}>
                    <Label variant={LabelType.titleMyRewardsCard} style={{ alignSelf: 'flex-end' }}>
                      {t('my_rewards')}
                    </Label>
                    <View style={styles.menu.whiteCard.points}>
                      <Label variant={LabelType.myRewardsCard}>{t('currency-value', { value: rewards?.amountCurrency ?? '-' })}</Label>
                      <Label style={styles.menu.whiteCard.items} variant={LabelType.titleLargeLight}>{`(${t('points-value', {
                        value: rewards?.amountPts ?? '-',
                      })})`}</Label>
                    </View>
                  </View>
                </View>
                {/* Card - Not available for V1 */}
                {/* <View style={styles.menu.whiteCard.cardItemLeft}>
              <Label style={styles.menu.whiteCard.marginBottom8} variant={LabelType.titleLargeDark}>
                {t('earn_more_points')}
              </Label>
              <Label variant={LabelType.subTitleBlack} style={styles.menu.whiteCard.cardItemContent}>
                {t('by_answering_popup_surveys')}
              </Label>
              <CustomButton secondaryColor title={t('answer_now')} style={styles.menu.whiteCard.cardItemButton} />
            </View>
            <View style={styles.menu.whiteCard.separator}></View>
            <View style={styles.menu.whiteCard.cardItemRight}>
              <Label style={styles.menu.whiteCard.marginBottom8} variant={LabelType.titleLargeLight}>
                {t('complete_your_profile')}
              </Label>
              <Label variant={LabelType.subTitleBlack} style={styles.menu.whiteCard.cardItemContent}>
                {t('to_get_missions_you_will_like')}
              </Label>
              <CustomButton secondaryColorLight title={t('complete')} style={styles.menu.whiteCard.cardItemButton} />
            </View> */}
              </Pressable>
            )}
            {/* Menu */}
            <View style={styles.menu.menuList}>
              {authStore.logged !== LoggedState.Anonymous && (
                <MenuItem bottomLine icon={faAward} title={t('my_rewards')} action={NavigationElements.myRewards} />
              )}
              {authStore.logged !== LoggedState.Anonymous && (
                <MenuItem bottomLine icon={faUser} title={t('my_profile_information')} action={NavigationElements.myProfile} />
              )}
              {authStore.logged !== LoggedState.Anonymous && (
                <MenuItem bottomLine icon={faArrowRightArrowLeft} title={t('my_transactions')} action={NavigationElements.myTransactions} />
              )}
              <MenuItem icon={faGear} title={t('settings')} action={NavigationElements.settings} />
            </View>
            {/* Bottom items */}
            <View>
              {/* <MenuItem bottomLine icon={faBug} title={'Test 401'} action={NavigationElements.test401} /> */}
              <MenuItem bottomLine icon={faCircleQuestion} title={t('help_support')} externalLink={t('lanla_website_support')} />
              <MenuItem bottomLine icon={faGlobePointer} title={t('lanla-website')} externalLink={`${t('lanla_website_url')}`} />
              <View style={styles.menu.logoutButton}>
                <MenuItem
                  icon={faArrowRightFromBracket}
                  mirrorIcon
                  title={authStore.logged === LoggedState.Anonymous ? t('login_title') : t('logout')}
                  action={null}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </LinearGradient>
    </View>
  );
};

export default Menu;

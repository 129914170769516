import React, { ReactNode, useEffect, useRef, useState } from 'react';
import {
  TextInput,
  TextInputProps,
  Image,
  View,
  Pressable,
  TextInputFocusEventData,
  NativeSyntheticEvent,
  ImageSourcePropType,
  StyleProp,
  TextStyle,
} from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import useStyles from '../../hooks/styles-hook';
import IconClose from '../../../assets/icon-close.png';
import IconBack from '../../../assets/icon-back.png';
import useTranslation from '../../hooks/translation-hook';
import { useAppSelector } from '../../hooks/store-hook';
import MaskInput, { Masks } from 'react-native-mask-input';

export interface ValidationResult {
  valid: boolean;
  message: string;
}

export enum InputTypeValidation {
  int,
  date,
  email,
  phone,
  luckyNumber,
}

interface CustomTextInputProps extends TextInputProps {
  containerStyle?: StyleProp<TextStyle> | undefined;
  steadyIcon?: ReactNode;
  showClearIcon?: boolean;
  showBackIcon?: boolean;
  debounceBack?: number;
  onBack?(): void;
  onClear?(): void;

  required?: boolean;
  requiredMessage?: string;
  inputTypeValidation?: InputTypeValidation;
  maxLength?: number;
  minLength?: number;
  validateOnBlur?: boolean;
  onValidationChange?(validation: ValidationResult): void;
  forceValidation?: boolean;
}

let bounceBack = 0;
let bounceBackTimer: any = undefined;

function callChangeOnDebounce(onTextChange: (value: string) => void, value: string, skipDebounce: boolean = false) {
  clearTimeout(bounceBackTimer);

  if (skipDebounce) {
    bubbleChangeEvent();
  } else {
    bounceBackTimer = window.setTimeout(() => {
      bubbleChangeEvent();
    }, bounceBack);
  }

  function bubbleChangeEvent() {
    if (onTextChange) {
      onTextChange(value);
    }
  }
}

const CustomTextInput = React.forwardRef((props: CustomTextInputProps, ref) => {
  const t = useTranslation();

  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const styles = useStyles((section) => section.customTextInput);
  const initialValue = props.value ?? '';

  const inputText = React.createRef<TextInput>();

  const [validation, setValidation] = useState<ValidationResult>({ valid: true, message: undefined });
  const [value, setValue] = useState<string>();
  const [hasValue, setHasValue] = useState<boolean>(props.value ? true : false);
  const [dirty, setDirty] = useState(false);
  const [focus, setFocus] = useState(false);
  const [inputStyle, setInputStyle] = useState([styles.input]);
  const [backButton, setBackButton] = useState(<></>);
  const [clearButton, setClearButton] = useState(<></>);
  const [steadyIcon, setSteadyIcon] = useState(<></>);
  const [validateOnBlur, setValidateOnBlur] = useState(props.validateOnBlur ?? true);

  bounceBack = props.debounceBack ?? 0;

  useEffect(() => {
    if (props.steadyIcon && !focus && !hasValue) {
      setSteadyIcon(
        <View style={[styles.iconBase, styles.icon.steady]} focusable={false}>
          {/* <Image source={props.steadyIcon} style={styles.icon.steady.dimension} /> */}
          {props.steadyIcon}
        </View>
      );
    } else {
      setSteadyIcon(<></>);
    }

    if (props.showBackIcon && (focus || hasValue)) {
      setBackButton(
        <Pressable style={[styles.iconBase, styles.icon.back]} onPress={handleBack} focusable={false}>
          <Image source={IconBack} style={styles.icon['back.dimension']} />
        </Pressable>
      );
    } else {
      setBackButton(<></>);
    }

    if (props.showClearIcon && hasValue) {
      setClearButton(
        <Pressable style={[styles.iconBase, styles.icon.clear]} onPress={handleClearText} focusable={false}>
          <Image source={IconClose} style={styles.icon['clear.dimension']} />
        </Pressable>
      );
    } else {
      setClearButton(<></>);
    }

    let currentStateStyle: any = [styles.input];

    if (hasValue) {
      currentStateStyle = [styles.inputTouched];

      if (props.showClearIcon) {
        currentStateStyle.push(styles['inputTouched.clearIconPadding']);
      }
    }

    if (props.steadyIcon && !focus && !hasValue) {
      currentStateStyle.push(styles['inputTouched.steadyIconPadding']);
    }

    if (focus || hasValue) {
      if (props.showBackIcon) {
        currentStateStyle.push(styles['inputTouched.backIconPadding']);
      }
    }

    if (validation && !validation.valid) {
      currentStateStyle.push(styles['input.error']);
    }

    setInputStyle(currentStateStyle);
  }, [hasValue, focus, validation, validation?.valid, props.showBackIcon, props.showClearIcon, userPrefs.theme]);

  useEffect(() => {
    if (!validation) {
      return;
    }

    if (props.onValidationChange) {
      props.onValidationChange(validation);
    }
  }, [validation]);

  useEffect(() => {
    if (!validateOnBlur) {
      if (validateFieldRequired(dirty, !!props.value)) {
        if (validateFieldValueLength(dirty, !!props.value, props.value) && props.inputTypeValidation !== undefined) {
          validateInputType(dirty, !!props.value, props.value);
        }
      }
    }

    if (props.value) {
      setHasValue(true);
      setValue(props.value);
    } else {
      //if (props.value === '') {
      setHasValue(false);
      setValue('');
    }
  }, [props.value, validateOnBlur]);

  useEffect(() => {
    if (props.forceValidation) {
      if (validateFieldRequired(true, !!value)) {
        if (validateFieldValueLength(true, !!value, value) && props.inputTypeValidation !== undefined) {
          validateInputType(true, !!value, value);
        }
      }
    }
  }, [props.forceValidation]);

  const validateFieldRequired = (isDirty: boolean, hasValue: boolean): boolean => {
    if (props.required) {
      if (isDirty) {
        if (props.required && !hasValue) {
          setValidation({
            ...{
              valid: false,
              message: props.requiredMessage ?? t('field_required'),
            },
          });
          return false;
        }
      } else {
        if (!validation || !validation.valid) {
          setValidation({
            ...{
              valid: true,
              message: undefined,
            },
          });
        }
      }
    } else {
      setValidation({
        ...{
          valid: true,
          message: undefined,
        },
      });
    }

    return true;
  };

  const validateFieldValueLength = (isDirty: boolean, hasValue: boolean, value: string): boolean => {
    if (props.minLength === undefined && props.maxLength === undefined) {
      setValidation({
        ...{
          valid: true,
          message: undefined,
        },
      });
      return true;
    }

    if (isDirty && hasValue) {
      let validationResult: ValidationResult = { valid: true, message: '' };

      if (props.minLength) {
        if (value.trim().length < props.minLength) {
          validationResult.valid = false;
          validationResult.message = t('field_min_length').format(props.minLength.toString());

          setValidation({
            ...validationResult,
          });

          return false;
        }
      }

      if (props.maxLength) {
        if (value.trim().length > props.maxLength) {
          validationResult.valid = false;
          validationResult.message = t('field_max_length').format(props.maxLength.toString());
          setValidation({
            ...validationResult,
          });

          return false;
        }
      }

      setValidation({
        ...validationResult,
      });

      return true;
    }
  };

  const validateInputType = (isDirty: boolean, hasValue: boolean, value: string): boolean => {
    if (isDirty && hasValue) {
      let validationResult: ValidationResult = { valid: true, message: '' };

      switch (props.inputTypeValidation) {
        case InputTypeValidation.int:
          if (!Number.isNaN(Number(value)) && value.indexOf('.') === -1) {
            validationResult.valid = true;
            validationResult.message = '';
          } else {
            validationResult.valid = false;
            validationResult.message = t('field_value_not_valid');
          }
          break;
        case InputTypeValidation.email:
          const regexp = new RegExp(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
          var isValidEmail: boolean;

          isValidEmail = regexp.test(value.trim());

          if (isValidEmail) {
            validationResult.valid = true;
            validationResult.message = '';
          } else {
            validationResult.valid = false;
            validationResult.message = t('Invalid-email-address');
          }
          break;
        case InputTypeValidation.date:
          const date: Date = new Date(value);

          //@ts-ignore
          if (date instanceof Date && !isNaN(date)) {
            validationResult.valid = true;
            validationResult.message = '';
          } else {
            validationResult.valid = false;
            validationResult.message = t('field_value_not_valid');
          }

          break;
        case InputTypeValidation.phone:
          if (value.length === 14) {
            validationResult.valid = true;
            validationResult.message = '';
          } else {
            validationResult.valid = false;
            validationResult.message = t('field_value_not_valid');
          }
          break;
      }

      setValidation({
        ...validationResult,
      });

      return validationResult.valid;
    }
  };

  const handleTextChange = (e: string) => {
    let newDirtyValue = false;
    let newHasValue = false;

    if (e.trim() !== initialValue.trim()) {
      newDirtyValue = true;
    } else {
      newDirtyValue = false;
    }

    if (e.trim() !== '') {
      newHasValue = true;
    } else {
      newHasValue = false;
    }

    if (!validateOnBlur) {
      if (validateFieldRequired(newDirtyValue, newHasValue)) {
        if (validateFieldValueLength(newDirtyValue, newHasValue, e) && props.inputTypeValidation !== undefined) {
          validateInputType(newDirtyValue, newHasValue, e);
        }
      }
    }

    setHasValue(newHasValue);
    setDirty(newDirtyValue);
    setValue(e);

    callChangeOnDebounce(props.onChangeText, e);
  };

  const handleBlur = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocus(false);

    if (validateOnBlur) {
      if (validateFieldRequired(true, !!value)) {
        if (validateFieldValueLength(true, !!value, value) && props.inputTypeValidation !== undefined) {
          validateInputType(true, !!value, value);
        }
      }
    }

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocus(true);

    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const handleClearText = () => {
    inputText.current.focus();
    setValue('');
    setFocus(true);
    setHasValue(false);
    setDirty(false);

    if (props.onClear) {
      props.onClear();
    }
  };

  const handleBack = () => {
    if (props.onBack) {
      props.onBack();
    }
  };

  return (
    <View style={props.containerStyle ?? {}}>
      <View style={styles.container}>
        {backButton}
        {steadyIcon}
        {props.inputTypeValidation !== InputTypeValidation.date &&
          props.inputTypeValidation !== InputTypeValidation.phone &&
          props.inputTypeValidation !== InputTypeValidation.luckyNumber && (
            <TextInput
              {...props}
              style={[inputStyle, props.style]}
              onChangeText={handleTextChange}
              onFocus={handleFocus}
              onBlur={handleBlur}
              value={value}
              ref={inputText}
            />
          )}
        {props.inputTypeValidation === InputTypeValidation.date && (
          <MaskInput
            {...props}
            style={[inputStyle, props.style]}
            selectTextOnFocus={true}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            ref={inputText}
            maskAutoComplete={true}
            mask={Masks.DATE_YYYYMMDD}
            onChangeText={handleTextChange}
            keyboardType="numeric"
          />
        )}
        {props.inputTypeValidation === InputTypeValidation.luckyNumber && (
          <MaskInput
            {...props}
            style={[inputStyle, props.style]}
            selectTextOnFocus={true}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            ref={inputText}
            maskAutoComplete={true}
            mask={[/\d/, /\d/, /\d/]}
            onChangeText={handleTextChange}
            keyboardType="numeric"
          />
        )}
        {props.inputTypeValidation === InputTypeValidation.phone && (
          <MaskInput
            {...props}
            style={[inputStyle, props.style]}
            selectTextOnFocus={true}
            onFocus={handleFocus}
            onBlur={handleBlur}
            value={value}
            ref={inputText}
            maskAutoComplete={true}
            mask={Masks.USA_PHONE}
            onChangeText={handleTextChange}
          />
        )}
        {clearButton}
      </View>
    </View>
  );
});

export default CustomTextInput;

import { FirebaseApp, initializeApp } from 'firebase/app';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { environment } from '../environment';
import Constants from 'expo-constants';

export class AnalyticsHelper {
  private static webAnalytics;

  private static getInstance = (): Analytics => {
    if (!this.webAnalytics) {
      const app = initializeApp(Constants.expoConfig.extra.firebaseConfig);
      this.webAnalytics = getAnalytics(app);
    }

    return this.webAnalytics;
  };

  public static logSignUp = async (method: string): Promise<void> => {
    await logEvent(AnalyticsHelper.getInstance(), 'sign_up' as any, {
      method,
    });
  };

  public static logScreenView = async (page: string): Promise<void> => {
    await logEvent(AnalyticsHelper.getInstance(), 'screen_view' as any, {
      screen_name: page,
      screen_class: page,
    });
  };
}

import { I18n } from 'i18n-js';
import { useCallback, useEffect, useMemo, useState } from 'react';
import translations from '../../assets/i18/localise.json';
import { useAppSelector } from './store-hook';

const useTranslation = (props?: { defaultPath: string } | undefined) => {
  const i18n = useMemo(() => new I18n(translations), []);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  i18n.enableFallback = true;
  i18n.locale = userPrefs.uiLanguage ?? process.env.REACT_APP_DEFAULT_LANG ?? 'en';

  const handleTranslationCallback = useCallback(
    () => (key: string, formatting?: any) => {
      let prefix = 'pm_mysteryshopping';

      if (props?.defaultPath) {
        prefix = `${prefix}.${props.defaultPath}`;
      }

      if (!formatting) {
        return i18n.t(`${prefix}.${key}`, { defaultValue: key });
      } else {
        return i18n.t(`${prefix}.${key}`, { defaultValue: key, ...formatting });
      }
    },
    [i18n, i18n.locale]
  );

  const [i18Translations, setI18Translations] = useState(handleTranslationCallback);

  useEffect(() => {
    setI18Translations(handleTranslationCallback);
  }, [handleTranslationCallback]);

  useEffect(() => {
    if (userPrefs.uiLanguage) {
      i18n.locale = userPrefs.uiLanguage;
    }
  }, [userPrefs.uiLanguage]);

  return i18Translations;
};

export default useTranslation;

import React, { useEffect, useState } from 'react';
import { ButtonProps, Pressable, Text, ColorValue, View } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import { NamedStyles } from 'react-dom';
import { StylesHelper } from '../../models/helpers/stylesHelper';

export enum PillButtonVariant {
  normal,
  outline,
}

interface PillButtonProps extends ButtonProps {
  icon?: React.ReactNode;
  style?: NamedStyles<any>;
  textStyle?: NamedStyles<any>;
  variant?: PillButtonVariant;
  outlineButtonColor?: ColorValue;
  noPadding?: boolean;
}

const PillButton = (props: PillButtonProps) => {
  const styles = useStyles((section) => section.pillButton);
  const [buttonStyle, setButtonStyle] = useState<NamedStyles<any>>();

  useEffect(() => {
    if (props.variant === PillButtonVariant.outline) {
      setButtonStyle(styles.button.outlineButton);
    } else {
      setButtonStyle(styles.button.main);
    }
  }, [props.variant]);

  return (
    <Pressable {...props} style={[props.noPadding ? '' : { ...StylesHelper.padding(16) }]}>
      <View
        style={[
          buttonStyle,
          props.style ?? {},
          props.variant === PillButtonVariant.outline && props.outlineButtonColor ? { borderColor: props.outlineButtonColor } : {},
          { ...StylesHelper.padding(8, 12) },
        ]}
      >
        {props.icon}
        <Text
          style={[
            styles.text.main,
            props.variant === PillButtonVariant.outline && props.outlineButtonColor ? { color: props.outlineButtonColor } : {},
            props.icon ? { marginLeft: 4 } : {},
            props.textStyle ?? {},
          ]}
        >
          {props.title}
        </Text>
      </View>
    </Pressable>
  );
};

export default PillButton;

import { DeviceTheme } from '../api/api.g';
import { CustomTheme } from '../hooks/theme-hook';

export class Themes {
  private static instance: Themes;

  private constructor(init: any) {
    this.light = init.light;
    this.dark = init.dark;
    this.currentTheme = DeviceTheme.Light;
  }

  public static createTheme(init: any): Themes {
    if (!Themes.instance) {
      Themes.instance = new Themes(init);
    }

    return Themes.instance;
  }

  public static getInstance(): Themes {
    return Themes.instance;
  }

  public currentTheme: DeviceTheme;
  public light: CustomTheme;
  public dark: CustomTheme;

  public setTheme(theme: DeviceTheme): void {
    this.currentTheme = theme;
  }

  private getTheme(subCategory: string) {
    let themeResult = undefined;

    themeResult = subCategory ? this.light[subCategory] : this.light;

    if (this.currentTheme === DeviceTheme.Dark) {
      themeResult = subCategory ? this.dark[subCategory] : this.dark;
    }

    return themeResult as typeof themeResult;
  }

  public get background() {
    const backgroundStyles = this.getTheme('background');

    return backgroundStyles as typeof backgroundStyles;
  }

  public get text() {
    const textStyles = this.getTheme('text');

    return textStyles as typeof textStyles;
  }

  public get menu() {
    const menuStyles = this.getTheme('menu');

    return menuStyles as typeof menuStyles;
  }

  public get primary() {
    const primaryStyles = this.getTheme('primary');

    return primaryStyles as typeof primaryStyles;
  }

  public get secondary() {
    const secondaryStyles = this.getTheme('secondary');

    return secondaryStyles as typeof secondaryStyles;
  }

  public get secondaryLight() {
    const secondaryLightStyles = this.getTheme('secondaryLight');

    return secondaryLightStyles as typeof secondaryLightStyles;
  }

  public get colorErrorPrimary() {
    const colorErrorPrimaryStyles = this.getTheme('colorErrorPrimary');

    return colorErrorPrimaryStyles as typeof colorErrorPrimaryStyles;
  }

  public get borderColor() {
    const borderColorStyles = this.getTheme('borderColor');

    return borderColorStyles as typeof borderColorStyles;
  }

  public get borderColorLight() {
    const borderColorLightStyles = this.getTheme('borderColorLight');

    return borderColorLightStyles as typeof borderColorLightStyles;
  }

  public get infoCell() {
    const infoCellStyles = this.getTheme('infoCell');

    return infoCellStyles as typeof infoCellStyles;
  }

  public get inputTextPlaceholder() {
    const inputTextPlaceholderStyles = this.getTheme('inputTextPlaceholder');

    return inputTextPlaceholderStyles as typeof inputTextPlaceholderStyles;
  }

  public get overlayColor() {
    const overlayColorStyles = this.getTheme('overlayColor');

    return overlayColorStyles as typeof overlayColorStyles;
  }

  public get colorErrorPrimaryBackground() {
    const errorBackgroundStyles = this.getTheme('colorErrorPrimaryBackground');

    return errorBackgroundStyles as typeof errorBackgroundStyles;
  }
  
  public get indicatorColor() {
    const indicatorColorStyles = this.getTheme('indicatorColor');

    return indicatorColorStyles as typeof indicatorColorStyles;
  }

  public get timelineIcon() {
    const timelineIconStyles = this.getTheme('timelineIcon');

    return timelineIconStyles as typeof timelineIconStyles;
  }
  
  public get mapControls() {
    const mapControlsStyles = this.getTheme('mapControls');

    return mapControlsStyles as typeof mapControlsStyles;
  }
}

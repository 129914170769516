import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NavBarState {
  visible: boolean;
  path: string;
  currentPath: string;
  isWebView: boolean;
}

// Define the initial state using that type
const initialState: NavBarState = {
  visible: false,
  path: 'map',
  currentPath: 'map',
  isWebView: false,
};

export const navBarSlice = createSlice({
  name: 'navBar',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setPath: (state, action: PayloadAction<string | undefined>) => {
      state.path = action.payload ?? 'login';
    },
    setCurrentPath: (state, action: PayloadAction<string | undefined>) => {
      state.currentPath = action.payload;
    },
    setIsWebView: (state, action: PayloadAction<boolean>) => {
      state.isWebView = action.payload;
    }
  },
});

export default navBarSlice.reducer;
export const navBarActions = navBarSlice.actions;

import { configureStore } from '@reduxjs/toolkit';
import userPreferencesReducer from './userPrefsSlice';
import drawerReducer from './drawerSlice';
import navBarReducer from './navBarSlice';
import topBarReducer from './topBarSlice';
import notificationReducer from './notificationSlice';
import myAccountReducer from './myAccountSlice';
import modalReducer from './modalSlice';
import missionsReducer from './missionsSlice';
import lightBoxReducer from './lightBoxSlice';
import errorReducer from './errorSlice';

import { authReducer } from '../models/helpers/platformImportHelper';

export const store = configureStore({
  reducer: {
    userPrefs: userPreferencesReducer,
    drawer: drawerReducer,
    navBar: navBarReducer,
    notification: notificationReducer,
    auth: authReducer,
    myAccount: myAccountReducer,
    topBar: topBarReducer,
    modal: modalReducer,
    missions: missionsReducer,
    lightBox: lightBoxReducer,
    error: errorReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

import React, { useEffect, useRef, useState } from 'react';
import { Platform, Pressable } from 'react-native';
import useCustomTheme from '../../hooks/theme-hook';
import { SwitchElement } from '../../models/helpers/platformImportHelper';

export interface CustomSwitchProps {
  onPress?(e: boolean): void;
  isEnabled?: boolean;
}

const CustomSwitch = (props) => {
  const theme = useCustomTheme();
  const switchRef = useRef(null);

  const [isEnabled, setIsEnabled] = useState<boolean>(props.isEnabled);

  const handleOnPress = (e: any) => {
    if (props.onPress) {
      props.onPress(e);
    }
  };

  useEffect(() => {
    setIsEnabled(props.isEnabled);
  }, [props.isEnabled]);

  if (Platform.OS === 'web') {
    useEffect(() => {
      if (!switchRef) {
        return;
      }

      const target = switchRef.current;
      const thumbElement = target?.previousSibling as HTMLElement;
      const trackElement = thumbElement?.previousSibling as HTMLElement;
      const containerElement = trackElement?.parentElement as HTMLElement;

      if (containerElement) {
        containerElement.style.paddingLeft = '2px';
        containerElement.style.paddingRight = '2px';
      }

      if (trackElement) {
        trackElement.style.height = '24px';
        trackElement.style.borderRadius = '12px';
      }

      if (thumbElement) {
        thumbElement.style.backgroundColor = 'white';
      }
    }, [isEnabled]);
  }

  return (
    <Pressable onPress={handleOnPress}>
      <SwitchElement
        ref={switchRef}
        trackColor={{ false: theme.borderColor, true: theme.primary }}
        value={isEnabled}
        //@ts-ignore
        isOn={isEnabled}
        onColor={theme.primary}
        offColor={theme.borderColor}
        size="medium"
        onToggle={handleOnPress}
      />
    </Pressable>
  );
};

export default CustomSwitch;

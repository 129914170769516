import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { View } from 'react-native';
import { MysteryShopperClient } from '../../../../api/api.g';
import useApi from '../../../../hooks/api-hook';
import { useAppSelector } from '../../../../hooks/store-hook';
import useCustomTheme from '../../../../hooks/theme-hook';
import useTranslation from '../../../../hooks/translation-hook';
import { UserPrefsHelper } from '../../../../models/helpers/userPrefsHelper';
import CustomTextInput, { ValidationResult } from '../../../common/CustomTextInput';
import Label from '../../../common/Label';
import ProfileModal from '../ProfileModal';
import Popup from '../../../common/Popup';
import useUserPrefsService from '../../../../hooks/userPrefsService-hook';

const NamePopup = (props) => {
  const t = useTranslation();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const theme = useCustomTheme();
  const userPrefService = useUserPrefsService();
  const apiMysteryShopper = useApi(MysteryShopperClient);
  const [userPrefsValue, setUserPrefsValue] = useState(userPrefs);
  const [validation, setValidation] = useState<ValidationResult>();
  const [validation2, setValidation2] = useState<ValidationResult>();
  const [forceValidation, setForceValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const saveBasicMutation = useMutation({
    mutationFn: () => {
      const data = UserPrefsHelper.mapToSaveBasicInformationDto(userPrefsValue);
      return apiMysteryShopper.saveBasicInformation(data).then(() => {
        userPrefService.updateUserProfileInfo((userPrefs) => {
          setIsLoading(false);
          props.onClose && props.onClose();
          props.onConfirm && props.onConfirm(userPrefs);
        });
      });
    },
  });

  const handleOnConfirm = () => {
    setValidation(undefined);
    if (userPrefsValue.lastName && userPrefsValue.firstName) {
      saveBasicMutation.mutate();
    } else {
      setForceValidation(true);
    }
  };

  return (
    <Popup loading={isLoading}>
      <ProfileModal
        title={t('My-informations')}
        subtitle={t('it_would_be_more_friendly_to_know_your_name')}
        height={props.height}
        pageNumber={props.currentPage}
        maxPageNumber={props.maxPage}
        onClose={() => {
          props.onClose && props.onClose();
        }}
        onConfirm={() => {
          handleOnConfirm();
        }}
      >
        <View>
          <Label style={{ marginBottom: 8 }}>{t('first_name')}</Label>
          <CustomTextInput
            required
            forceValidation={forceValidation}
            validateOnBlur
            placeholder=""
            value={userPrefs.firstName}
            onChangeText={(t) =>
              setUserPrefsValue((current) => {
                return { ...current, firstName: t };
              })
            }
            onValidationChange={(validation) => {
              setForceValidation(false);
              setValidation(validation);
            }}
          />
          {validation?.valid === false && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{validation.message}</Label>}
          <Label style={{ marginTop: 16, marginBottom: 8 }}>{t('last_name')}</Label>
          <CustomTextInput
            forceValidation={forceValidation}
            required
            validateOnBlur
            placeholder=""
            value={userPrefs.lastName}
            onChangeText={(t) =>
              setUserPrefsValue((current) => {
                return { ...current, lastName: t };
              })
            }
            onValidationChange={(validation) => {
              setForceValidation(false);
              setValidation2(validation);
            }}
          />
          {validation2?.valid === false && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{validation2.message}</Label>}
        </View>
      </ProfileModal>
    </Popup>
  );
};

export default NamePopup;

import React from 'react';
import { View, ViewProps } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import Label, { LabelType } from './Label';
import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';

export interface InfoBoxProps extends ViewProps {
  icon?: IconDefinition;
  title: string;
  variant?: LabelType;
}

const InfoBox = (props: InfoBoxProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.infoBox);

  return (
    <View {...props} style={[props.style, styles.container]}>
      <View style={styles.title}>
        {props.icon && <FontAwesomeIcon icon={props.icon} style={styles.icon} />}
        <Label variant={props.variant ?? LabelType.titleDark}>{props.title}</Label>
      </View>
      {props.children}
    </View>
  );
};

export default InfoBox;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { ButtonBarConfig } from '../models/ButtonBarModels';
import { NamedStyles } from 'react-dom';

export enum DrawerOpenState {
  closed,
  opened,
  minimized,
}

interface DrawerState {
  state: DrawerOpenState;
  title: string;
  content: ReactElement;
  showCloseButton: boolean;
  showIcon: boolean;
  showTitle: boolean;
  showButtonBar: boolean;
  resizable: boolean;
  buttonBarConfig: ButtonBarConfig;
  icon: ReactElement;
  contentStyle?: NamedStyles;
  forceDockBottom?: boolean;
  onClose?(): void;
}

// Define the initial state using that type
const initialState: DrawerState = {
  state: DrawerOpenState.closed,
  title: '',
  content: <></>,
  showCloseButton: true,
  showIcon: false,
  showButtonBar: false,
  showTitle: false,
  buttonBarConfig: undefined,
  icon: undefined,
  resizable: true,
  forceDockBottom: false,
  onClose: undefined,
  contentStyle: undefined,
};

export const drawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setState: (drawerState, action: PayloadAction<DrawerOpenState>) => {
      drawerState.state = action.payload;

      if (drawerState.state === DrawerOpenState.closed) {
        drawerState.showButtonBar = false;
        drawerState.forceDockBottom = false;

        if (drawerState.onClose) {
          drawerState.onClose();
        }
      }
    },
    setConfig: (drawerState, action: PayloadAction<DrawerState>) => {
      drawerState.buttonBarConfig = action.payload.buttonBarConfig;
      drawerState.state = action.payload.state;
      drawerState.title = action.payload.title;
      drawerState.content = action.payload.content;
      drawerState.showCloseButton = action.payload.showCloseButton;
      drawerState.showButtonBar = action.payload.showButtonBar;
      drawerState.icon = action.payload.icon;
      drawerState.showIcon = action.payload.showIcon;
      drawerState.showTitle = action.payload.showTitle;
      drawerState.resizable = action.payload.resizable;
      drawerState.contentStyle = undefined;
      drawerState.forceDockBottom = action.payload.forceDockBottom;
      drawerState.onClose = action.payload.onClose;
    },
    resetState: (drawerState) => {
      drawerState.state = initialState.state;
      drawerState.buttonBarConfig = initialState.buttonBarConfig;
      drawerState.title = initialState.title;
      drawerState.content = initialState.content;
      drawerState.showCloseButton = initialState.showCloseButton;
      drawerState.showButtonBar = initialState.showButtonBar;
      drawerState.icon = initialState.icon;
      drawerState.showIcon = initialState.showIcon;
      drawerState.showTitle = initialState.showTitle;
      drawerState.resizable = initialState.resizable;
      drawerState.contentStyle = undefined;
      drawerState.forceDockBottom = initialState.forceDockBottom;

      if (drawerState.onClose) {
        drawerState.onClose();
      }

      drawerState.onClose = initialState.onClose;
    },
    setTitle: (drawerState, action: PayloadAction<string>) => {
      drawerState.title = action.payload;
    },
    setContent: (drawerState, action: PayloadAction<ReactElement>) => {
      drawerState.contentStyle = undefined;
      drawerState.content = action.payload;
    },
    setContentWithButtonBar: (drawerState, action: PayloadAction<ReactElement>) => {
      drawerState.contentStyle = undefined;
      drawerState.content = action.payload;
      drawerState.showButtonBar = true;
    },
    showCloseButton: (drawerState, action: PayloadAction<boolean>) => {
      drawerState.showCloseButton = action.payload;
    },
    showButtonBar: (drawerState, action: PayloadAction<boolean>) => {
      drawerState.showButtonBar = action.payload;
    },
    setButtonBarConfig: (drawerState, action: PayloadAction<ButtonBarConfig>) => {
      drawerState.buttonBarConfig = { ...action.payload };
    },
    setResizable: (drawerState, action: PayloadAction<boolean>) => {
      drawerState.resizable = action.payload;
    },
    showIcon: (drawerState, action: PayloadAction<boolean>) => {
      drawerState.showIcon = action.payload;
    },
    showTitle: (drawerState, action: PayloadAction<boolean>) => {
      drawerState.showTitle = action.payload;
    },
    setContentStyle: (drawerState, action: PayloadAction<NamedStyles>) => {
      drawerState.contentStyle = action.payload;
    },
    setOnClose: (drawerState, action: PayloadAction<() => void>) => {
      drawerState.onClose = action.payload;
    },
  },
});

export default drawerSlice.reducer;
export const drawerActions = drawerSlice.actions;

import React from 'react';
import { View } from 'react-native';
import useTranslation from '../../hooks/translation-hook';
import NavigationElements from '../../models/NavigationElements';
import MyAccountPageWrapper from './MyAccountPageWrapper';

const Help = () => {
  const t = useTranslation();

  return (
    <MyAccountPageWrapper navigationTitle={t('my_profile')} title={t('help_support')} navigationAction={NavigationElements.menu}>
      <View style={{ flexGrow: 1 }}></View>
    </MyAccountPageWrapper>
  );
};

export default Help;

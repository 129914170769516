export enum LoggedState {
  LoggingIn,
  NotLogged,
  Anonymous,
  Logged,
}

export interface AuthState {
  params: any;
  returnPath: string;
  logged?: LoggedState;
  idToken: string;
  signUpSource?: string;
  onAuthStateChanged?(user: any): void;
}

export interface LoginPayload {
  email?: string;
  password?: string;
  callback(e: any): void;
}

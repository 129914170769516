export class StringHelper {
  public static generateRandomString = (length: number = 5) => {
    var result = '';
    var characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  public static getInitials = (name: string) => {
    const names = name.split(' ');

    const firstInitial = names[0].substring(0, 1);
    let secondInitial = '';

    if (names.length > 1) {
      secondInitial = names[1].substring(0, 1);
    }

    return `${firstInitial}${secondInitial}`;
  };

  public static format = (value: string, ...args: string[]) => {
    return value.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != 'undefined' ? args[number] : match;
    });
  };
}

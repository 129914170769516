import { useNavigation } from '@react-navigation/native';
import OnARollPopup from '../components/common/mission/MissionOnARollPopup';
import lightBoxSlice, { lightBoxActions } from '../store/lightBoxSlice';
import { useAppDispatch } from './store-hook';
import { missionsActions } from '../store/missionsSlice';
import AccountVerificationPopup from '../components/myaccount/AccountVerificationPopup';

const useSharedPopup = () => {
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const showOnARollPopup = () => {
    setTimeout(() => {
      dispatch(missionsActions.setShowOnARollPopup(false));
      dispatch(
        lightBoxActions.setContent(
          <OnARollPopup
            onClose={() => {
              dispatch(lightBoxActions.setOnClose(undefined));
              dispatch(lightBoxActions.setVisibility(false));
            }}
            onMissionSelect={(missions) => {
              navigation.navigate({
                name: 'map',
                params: { pos: missions.branchId, location: `${missions.geocoordinate.y}|${missions.geocoordinate.x}` },
              } as never);

              dispatch(lightBoxActions.setVisibility(false));
            }}
          />
        )
      );
      dispatch(
        lightBoxActions.setOnClose(() => {
          dispatch(lightBoxActions.setOnClose(undefined));
        })
      );
      dispatch(lightBoxActions.setVisibility(true));
    }, 2000);
  };

  const showAccountVerificationPopup = () => {
    setTimeout(() => {
      dispatch(lightBoxActions.setIsLoading(false));
      dispatch(missionsActions.setShowOnARollPopup(false));
      dispatch(
        lightBoxActions.setContent(
          <AccountVerificationPopup
            onClose={() => {
              dispatch(lightBoxActions.setOnClose(undefined));
              dispatch(lightBoxActions.setVisibility(false));
            }}
          />
        )
      );
      dispatch(
        lightBoxActions.setOnClose(() => {
          dispatch(lightBoxActions.setOnClose(undefined));
        })
      );
      dispatch(lightBoxActions.setVisibility(true));
    }, 2000);
  };

  const sharedPopup = {
    showOnARollPopup: showOnARollPopup,
  };

  return sharedPopup;
};

export default useSharedPopup;

import React, { ReactElement } from 'react';
import { ColorValue, GestureResponderEvent, PressableProps } from 'react-native';
import { LabelType } from '../common/Label';
import useMyAccountActions from '../../hooks/myAccountActions-hook';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import NavigationButton from '../common/NavigationButton';
import useTranslation from '../../hooks/translation-hook';

export interface MyAccountNavigationButtonProps extends PressableProps {
  title: string;
  action: ReactElement;
  iconColor?: ColorValue;
  navigationTitleColor?: ColorValue;
}

const MyAccountNavigationButton = (props: MyAccountNavigationButtonProps) => {
  const t = useTranslation();
  const myAccountActions = useMyAccountActions();

  const onPressHandle = (e: GestureResponderEvent) => {
    myAccountActions.navigate(props.action);

    if (props.onPress) {
      props.onPress(e);
    }
  };

  return (
    <NavigationButton
      icon={faCircleChevronLeft}
      iconSize={24}
      iconColor={props.iconColor}
      labelVariant={LabelType.titleSmall}
      onPress={(e) => {
        onPressHandle(e);
      }}
      text={props.title}
      color={props.navigationTitleColor}
    />
  );
};

export default MyAccountNavigationButton;

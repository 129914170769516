import { useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';
import { Platform, View } from 'react-native';
import { MysteryShopperClient, MysteryShopperProfileMemberType, SaveProfileValueDto } from '../../../../api/api.g';
import useApi from '../../../../hooks/api-hook';
import useCustomTheme from '../../../../hooks/theme-hook';
import useTranslation from '../../../../hooks/translation-hook';
import CustomTextInput, { ValidationResult, InputTypeValidation } from '../../../common/CustomTextInput';
import Label from '../../../common/Label';
import ProfileModal from '../ProfileModal';
import Popup from '../../../common/Popup';
import useUserPrefsService from '../../../../hooks/userPrefsService-hook';
import CustomCheckbox from '../../../common/CustomCheckbox';
import CustomDatePicker from '../../../common/CustomDatePicker';
import CustomRadioButton from '../../../common/CustomRadioButton';
import { ProfileMemberItemProps } from '../ProfileMemberItem';

export interface ProfileMemberPopupProps {
  profileProps: ProfileMemberItemProps;
  height?: number;
  currentPage?: number;
  maxPage?: number;
  onClose?: () => void;
  onConfirm?: (userPrefs) => void;
}

const ProfileMemberPopup = (props: ProfileMemberPopupProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const userPrefService = useUserPrefsService();
  const apiMysteryShopper = useApi(MysteryShopperClient);
  const [forceValidation, setForceValidation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileProps, setProfileProps] = useState<ProfileMemberItemProps>(props.profileProps);

  const saveProfileMutation = useMutation({
    mutationFn: (mutationProps: ProfileMemberItemProps) => {
      const profileValue = new SaveProfileValueDto();

      profileValue.init({
        key: mutationProps.profileMember.key,
        value: profileProps.value,
      });

      return apiMysteryShopper
        .saveProfile(profileValue)
        .then(() => {
          userPrefService.updateUserProfileInfo((userPrefs) => {
            props.onClose && props.onClose();
            props.onConfirm && props.onConfirm(userPrefs);
            setIsLoading(false);
            setProfileProps(undefined);
          });
        })
        .catch(() => {
          setIsLoading(false);
        });
    },
  });

  const handleOnConfirm = () => {
    if (profileProps.profileMember.valueType === MysteryShopperProfileMemberType.Date) {
      if (!profileProps.value) {
        return;
      }
      if (profileProps.value.trim().length < 10) {
        return;
      }
      const date: Date = new Date(profileProps.value);
      //@ts-ignore
      if (!(date instanceof Date && !isNaN(date))) {
        return;
      }
    }

    if (
      profileProps.profileMember.valueType === MysteryShopperProfileMemberType.SingleChoice ||
      profileProps.profileMember.valueType === MysteryShopperProfileMemberType.MultipleChoices
    ) {
      if (!profileProps.value) {
        setProfileProps((prev) => {
          let newProps = { ...prev };
          newProps.validation = { valid: false, message: t('Please-fill-the-form') };
          return newProps;
        });
        return;
      }
    }

    setIsLoading(true);
    saveProfileMutation.mutate(profileProps);
  };

  const handleTextChange = (value: string) => {
    setProfileProps((prev) => {
      let props = { ...prev };
      props.value = value;

      return props;
    });
  };

  const multiChoiceChange = (key: number, checked: boolean) => {
    setProfileProps((prev) => {
      let newProps = { ...prev };
      let values = newProps.value?.split(',') ?? [];

      const i = values?.findIndex((x) => x === key.toString()) ?? -1;

      if (i > -1) {
        values.splice(i, 1);
      }

      if (checked) {
        values.push(key.toString());
      }

      if (values && values.length > 1) {
        newProps.value = values?.join(',') ?? '';
      } else {
        newProps.value = values[0];
      }

      return newProps;
    });
  };

  const getPlaceHolder = (type: MysteryShopperProfileMemberType) => {
    switch (type) {
      case MysteryShopperProfileMemberType.Date:
        return 'YYYY/MM/DD';
      default:
        return '';
    }
  };

  const handleValidationChange = (v: ValidationResult) => {
    setForceValidation(false);
    setProfileProps((prev) => {
      let props = { ...prev };
      props.validation = { ...v };

      return props;
    });
  };

  const getDrawerContent = (props: ProfileMemberItemProps) => {
    switch (props.profileMember.valueType) {
      case MysteryShopperProfileMemberType.SingleChoice:
        return (
          <View>
            {props.profileMember?.choices.map((c, i) => {
              return (
                <CustomRadioButton
                  key={c.key}
                  label={c.label}
                  value={c.key}
                  checked={c.key.toString() === props.value}
                  onChange={(checked) => {
                    if (checked) {
                      setProfileProps((prev) => {
                        let props = { ...prev };
                        props.value = c.key.toString();
                        props.validation = { valid: true, message: '' };

                        return props;
                      });
                    }
                  }}
                />
              );
            })}
            {props.validation && !props.validation.valid && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{props.validation.message}</Label>}
          </View>
        );
      case MysteryShopperProfileMemberType.Date:
        return (
          <View>
            {props.profileMember.key === 'BirthDate' && (
              <Label style={{ fontSize: 13, marginBottom: 8, maxWidth: 250, fontStyle: 'italic', marginTop: -8 }} numberOfLines={5}>
                {t('age-requirement')}
              </Label>
            )}
            {Platform.OS === 'web' && (
              <>
                <CustomTextInput
                  forceValidation={forceValidation}
                  required
                  style={{ marginTop: 10 }}
                  inputTypeValidation={InputTypeValidation.date}
                  value={props.value as string}
                  minLength={10}
                  maxLength={10}
                  validateOnBlur
                  placeholder={getPlaceHolder(props.profileMember.valueType)}
                  onChangeText={handleTextChange}
                  onValidationChange={handleValidationChange}
                />
                {props.validation && !props.validation.valid && (
                  <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{props.validation.message}</Label>
                )}
              </>
            )}
            {Platform.OS !== 'web' && <CustomDatePicker containerStyle={{ marginTop: 10 }} selectedDate={props.value} onChangeText={handleTextChange} />}
          </View>
        );
      case MysteryShopperProfileMemberType.Int32:
        return (
          <View>
            <CustomTextInput
              forceValidation={forceValidation}
              style={{ marginTop: 10 }}
              inputTypeValidation={props.profileMember.key === 'LuckyNumber' ? InputTypeValidation.luckyNumber : InputTypeValidation.int}
              value={props.value as string}
              keyboardType="numeric"
              onChangeText={handleTextChange}
              onValidationChange={handleValidationChange}
              validateOnBlur
            />
            {props.validation && !props.validation.valid && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{props.validation.message}</Label>}
          </View>
        );
      case MysteryShopperProfileMemberType.String:
        return (
          <View>
            <CustomTextInput
              forceValidation={forceValidation}
              style={{ marginTop: 10 }}
              value={props.value as string}
              maxLength={props.profileMember.maxValue ? parseInt(props.profileMember.maxValue) : null}
              minLength={props.profileMember.minValue ? parseInt(props.profileMember.minValue) : null}
              onChangeText={handleTextChange}
              onValidationChange={handleValidationChange}
              validateOnBlur
            />
          </View>
        );
      case MysteryShopperProfileMemberType.MultipleChoices:
        return (
          <View>
            {props.profileMember?.choices.map((c, i) => {
              return (
                <CustomCheckbox
                  key={c.key}
                  label={c.label}
                  value={c.key}
                  checked={props.value?.split(',').findIndex((m) => m === c.key.toString()) > -1 ?? false}
                  onChange={(checked) => multiChoiceChange(c.key, checked)}
                />
              );
            })}
            {props.validation && !props.validation.valid && <Label style={{ color: theme.colorErrorPrimary, marginTop: 8 }}>{props.validation.message}</Label>}
          </View>
        );
      default:
        return <></>;
    }
  };

  return (
    <Popup loading={isLoading}>
      <>
        {profileProps != undefined && (
          <ProfileModal
            title={t('My-informations')}
            subtitle={profileProps.profileMember.question}
            height={props.height}
            pageNumber={props.currentPage}
            maxPageNumber={props.maxPage}
            onClose={() => {
              props.onClose && props.onClose();
            }}
            onConfirm={() => {
              handleOnConfirm();
            }}
          >
            {getDrawerContent(profileProps)}
          </ProfileModal>
        )}
      </>
    </Popup>
  );
};

export default ProfileMemberPopup;

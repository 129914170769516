import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Pressable, Image, View, ImageSourcePropType } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import useStyles from '../../../hooks/styles-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import { navBarActions } from '../../../store/navBarSlice';

import Label, { LabelType } from '../Label';
import { drawerActions } from '../../../store/drawerSlice';
import LoggedInRestrictedArea from '../LoggedInRestrictedArea';
import { LoggedState } from '../../../models/AuthModelsCommon';
import useTranslation from '../../../hooks/translation-hook';

export interface NavBarProps {
  icon: IconProp;
  image?: ImageSourcePropType;
  title: string;
  screen: 'home' | 'map' | 'validation' | 'mymissions' | 'remotemissions';
  params?: any;
  showIndicator?: boolean;
}

const NavBarButton = (props: NavBarProps) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const navigation = useNavigation();
  const navigationState = useNavigationState((state) => state?.routes);
  const styles = useStyles((section) => section.navBar);
  const theme = useCustomTheme();
  const authStore = useAppSelector((selector) => selector.auth);

  const [showIndicator, setShowIndicator] = useState(props.showIndicator);
  const [active, setActive] = useState(false);

  const [shouldShowAnonymousPopup, setShouldShowAnonymousPopup] = useState(false);

  useEffect(() => {
    setShowIndicator(props.showIndicator);
  }, [props.showIndicator]);

  const getActivePagePath = (referenceMenu: string) => {
    if (referenceMenu === 'mymissions') {
      return (
        (navigationState && navigationState[navigationState.length - 1].name) === 'mymissions' ||
        (navigationState && navigationState[navigationState.length - 1].name) === 'survey'
      );
    } else {
      return referenceMenu === (navigationState && navigationState[navigationState.length - 1].name);
    }
  };

  return (
    <>
      {shouldShowAnonymousPopup && (
        <LoggedInRestrictedArea
          onlyPopup
          onPopupShow={() => setShouldShowAnonymousPopup(false)}
          message={t('need-login-before', { action: t('see-your-missions') })}
        />
      )}
      <Pressable
        onPress={() => {
          const isRestrictedNavigation = props.screen != 'map';

          if (isRestrictedNavigation && authStore.logged === LoggedState.Anonymous) {
            setShouldShowAnonymousPopup(true);
            return;
          } else {
            setShouldShowAnonymousPopup(false);
          }

          dispatch(navBarActions.setPath(props.screen));
          dispatch(drawerActions.setContent());
          navigation.navigate(props.screen as never);
        }}
        style={styles.buttonBase}
      >
        {props.image != undefined ? (
          <Image style={{ height: 20, width: 60 }} resizeMode={'contain'} source={props.image} />
        ) : (
          <View>
            {showIndicator && <FontAwesomeIcon size={10} icon={faCircle} color={theme.indicatorColor.toString()} style={styles.button.indicator} />}
            <FontAwesomeIcon icon={props.icon} size={active ? 28 : 18} color={theme.text.primary.toString()} style={styles.button.image} />
          </View>
        )}
        <View style={{ padding: 4, paddingBottom: 0 }}>
          <View
            style={{ width: 4, height: 4, borderRadius: 2, backgroundColor: getActivePagePath(props.screen) ? theme.indicatorColor.toString() : 'transparent' }}
          ></View>
        </View>
        <Label variant={LabelType.titleSmall}>{props.title}</Label>
      </Pressable>
    </>
  );
};

export default NavBarButton;

import { faCheck, faX } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { View, Image } from 'react-native';
// import { PeerReviewDto, PeerReviewsClient, PeerReviewValidationViewModel, PeerReviewVote } from '../../api/api.g';
import { StringHelper } from '../../helpers/stringHelper';
import useApi from '../../hooks/api-hook';
import { useAppSelector } from '../../hooks/store-hook';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import useTranslation from '../../hooks/translation-hook';
import Label, { LabelType } from '../common/Label';
import Loading from '../common/Loading';
import PillButton, { PillButtonVariant } from '../common/PillButton';
import RoundButton, { RoundButtonType } from '../common/RoundButton';
import ValidationQuestion from './ValidationQuestion';
import { StylesHelper } from '../../models/helpers/stylesHelper';
import CustomButton from '../common/CustomButton';
import { useNavigation } from '@react-navigation/native';

const ValidationList = () => {
  const t = useTranslation();
  const styles = useStyles((section) => section.validation);
  const theme = useCustomTheme();
  const authStore = useAppSelector((selector) => selector.auth);
  // const apiPeerReviews = useApi(PeerReviewsClient);
  const navigation = useNavigation();

  // const [vote, setVote] = useState<PeerReviewVote | undefined>(undefined);
  const [loading, setLoading] = useState(true);
  // const [validation, setValidation] = useState<PeerReviewValidationViewModel>();
  const [querySeed, setQuerySeed] = useState<string>(StringHelper.generateRandomString());

  const [validationCount, setValidationCount] = useState(0);
  const [possiblePoints, setPossiblePoints] = useState(0);

  // const addPeerReviewMutation = useMutation({
  //   retry: false,
  //   mutationFn: (payload: { peerReview: PeerReviewDto; potentialPoints: number }) => {
  //     return apiPeerReviews.addPeerReview(payload.peerReview, axios.CancelToken.source().token);
  //   },
  //   onSuccess(data, variables) {
  //     if (variables.peerReview.vote !== PeerReviewVote.Skip) {
  //       setValidationCount((prev) => (prev = prev + 1));
  //       setPossiblePoints((prev) => (prev += variables.potentialPoints));
  //     }
  //     setQuerySeed(StringHelper.generateRandomString(100));
  //   },
  //   onError: (err) => {
  //     throw err;
  //   },
  // });

  // const getNextCardQuery = useQuery({
  //   enabled: !!authStore.idToken,
  //   queryKey: ['peerReviews', 'next', querySeed, authStore.idToken],
  //   queryFn: ({ signal }) => {
  //     setLoading(true);
  //     setValidation(undefined);
  //     setVote(undefined);
  //     const promise = apiPeerReviews.getNextPeerReviewValidation(apiPeerReviews.tokenSource?.token);

  //     // Cancel the request if React Query signals to abort
  //     signal?.addEventListener('abort', () => {
  //       apiPeerReviews.tokenSource?.cancel('Query was cancelled by React Query');
  //       apiPeerReviews.onCancel();
  //     });

  //     return promise;
  //   },
  //   onError: (err) => {
  //     throw err;
  //   },
  //   onSettled: () => {
  //     setLoading(false);
  //   },
  // });

  // useEffect(() => {
  //   if (getNextCardQuery.isSuccess) {
  //     setValidation(getNextCardQuery.data);
  //   }
  // }, [getNextCardQuery.isSuccess, getNextCardQuery.data]);

  // useEffect(() => {
  //   if (vote !== undefined && validation) {
  //     const peerReview = new PeerReviewDto();
  //     peerReview.peerReviewRoundId = validation.peerReviewRoundId;
  //     peerReview.vote = vote;

  //     addPeerReviewMutation.mutate({ peerReview, potentialPoints: validation.potentialPoints });
  //   }
  // }, [vote]);

  // const handleSkipPress = () => {
  //   if (vote === undefined && !loading) {
  //     setVote(PeerReviewVote.Skip);
  //   }
  // };

  // const handleYesPress = () => {
  //   if (vote === undefined && !loading) {
  //     setVote(PeerReviewVote.Yes);
  //   }
  // };

  // const handleNoPress = () => {
  //   if (vote === undefined && !loading) {
  //     setVote(PeerReviewVote.No);
  //   }
  // };

  return (
    <View style={{ height: '100%', backgroundColor: theme.background.primaryLight }}>
      {loading ? (
        <View style={{ flexGrow: 1, ...StylesHelper.padding(20) }}>
          <Loading />
        </View>
      ) : (
        <>
          {/* {validation ? (
            <ValidationQuestion validation={validation} validationCount={validationCount} possiblePoints={possiblePoints} />
          ) : ( */}
          <View style={{ flexGrow: 1, ...StylesHelper.padding(10, 32, 24, 32), flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
            <View style={{ flexGrow: 0, flexShrink: 0 }}>
              <Label variant={LabelType.titleLargeNoCapsPrimary}>{t('validation', { count: 2 })}</Label>
            </View>
            <View style={{ flexGrow: 1, flexShrink: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Image style={{ width: 229, height: 274 }} source={require('../../../assets/NoValidations.png')} />
                <Label variant={LabelType.titleLargeNoCapsPrimary} style={{ textAlign: 'center', fontSize: 30 }}>
                  {t('oh')}
                </Label>
                <Label variant={LabelType.titleLargeLight} style={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>
                  {t('no-validations-to-do')}
                </Label>
                <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 300, paddingTop: 24 }} numberOfLines={3}>
                  {t('Come-back-to-check-this-page-later-New-validations')}
                </Label>
                <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 175, paddingVertical: 24 }}>
                  {t('oppotunity-explore-missions')}
                </Label>
                <CustomButton
                  title={t('missions-available')}
                  onPress={() => {
                    navigation.navigate('map' as never);
                  }}
                />
              </View>
            </View>
          </View>
          {/* )} */}
        </>
      )}
      {/* <View>
        <LinearGradient
          style={{ ...StylesHelper.padding(20), width: '100%', height: 168 }}
          colors={['#024252', '#035164', '#43A49E']}
          start={{ x: 0, y: -0.5 }}
          end={{ x: 1, y: 1 }}
        >
          {validation && (
            <View style={{ width: '100%', gap: 20, alignItems: 'center' }}>
              <View style={{ flexDirection: 'row', gap: 32 }}>
                <RoundButton
                  icon={<FontAwesomeIcon icon={faX} size={20} color={theme.colorErrorPrimary.toString()} />}
                  variant={RoundButtonType.negative}
                  onPress={handleNoPress}
                />
                <RoundButton
                  icon={<FontAwesomeIcon icon={faCheck} size={24} color={theme.secondaryLight.toString()} />}
                  variant={RoundButtonType.positive}
                  onPress={handleYesPress}
                />
              </View>
              <View>
                <PillButton outlineButtonColor={'white'} variant={PillButtonVariant.outline} title={t('skip')} onPress={handleSkipPress} />
              </View>
            </View>
          )}
        </LinearGradient>
      </View> */}
    </View>
  );
};

export default ValidationList;

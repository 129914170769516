import { DimensionValue } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useMissionCardStyles = () => {
  const theme = useCustomTheme();

  let mapControl = {
    containerBase: {
      ...StylesHelper.padding(12),
      borderBottomWidth: 1,
      borderColor: theme.borderColorLight,
      flexGrow: 1,
      width: '100%' as DimensionValue,
    },
    container: {
      drawer: {
        backgroundColor: theme.background.modal,
        borderBottomWidth: 0,
      },
      card: {
        borderWidth: 1,
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: '48%',
        maxWidth: 400,
        minWidth: 320,
        ...StylesHelper.margin(0, 4, 8, 4),
      },
      selected: {
        borderColor: theme.secondaryLight,
      },
      pinned: {
        backgroundColor: theme.background.missionCard,
        borderColor: theme.background.primaryLight,
      },
    },
    cardBase: {
      display: 'flex' as any,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
    },
    header: {
      logoBase: {
        display: 'flex' as any,
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
        height: 48,
        width: 48,
        marginRight: 12,
        borderRadius: 24,
        borderWidth: 1,
        borderColor: theme.borderColorLight,
        overflow: 'hidden' as any,
        backgroundColor: theme.background.secondary,
      },
      logo: {
        image: {
          width: '100%' as DimensionValue,
          height: '100%' as DimensionValue,
        },
        icon: {
          padding: 12,
          color: theme.text.titleLight.toString(),
          width: '100%' as DimensionValue,
          height: '100%' as DimensionValue,
        },
      },
    },
    info: {
      flexGrow: 1,
      flexShrink: 1,
      marginRight: 8,
    },
    missionName: {
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
    },
    missionHiddenIcon: {
      marginLeft: 4,
      color: theme.borderColor as any
    },
    preTitle: {
      flexDirection: 'row' as any,
      gap: 8,
      justifyContent: 'space-between' as any,
      flexShrink: 1,
    },
  };

  return mapControl as typeof mapControl;
};

export default useMissionCardStyles;

import useCustomTheme from '../hooks/theme-hook';

const useCustomRadioButtonStyles = () => {
  const theme = useCustomTheme();

  const customRadioButton = {
    base: {
      justifyContent: 'center' as any,
      alignItems: 'center' as any,
      borderRadius: 50,
      borderWidth: 1,
      borderColor: theme.background.sea,
      backgroundColor: theme.background.primaryLight,
      width: 20,
      height: 20,
    },

    checked: {
      backgroundColor: theme.borderColorLight,
    },

    marker: {
      width: 14,
      height: 14,
      borderRadius: 50,
      backgroundColor: theme.background.darkTurquoise,
    },

    container: {
      flexDirection: 'row-reverse' as any,
      alignItems: 'center' as any,
      justifyContent: 'space-between' as any,
      height: 28,
      width: '100%' as any,
      gap: 8
    },
    label: {
      flexGrow: 1,
    },

    radioButton2: {
      container: {
        display: 'flex' as any,
        alignItems: 'center' as any,
        flexDirection: 'row' as any,
        gap: 8
      },
      outerCircle: {
        display: 'flex' as any,
        width: 20,
        height: 20,
        borderRadius: 10,
        padding: 2,
        borderWidth: 1,
        borderColor: theme.background.sea
      },
      innerCircle: {
        width: '100%' as any,
        height: '100%' as any,
        borderRadius: 100,
        margin: 'auto' as any,
        backgroundColor: theme.background.darkTurquoise,
      }
    }
  };

  return customRadioButton as typeof customRadioButton;
};

export default useCustomRadioButtonStyles;

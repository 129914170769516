import React, { useEffect, useState } from 'react';
import { View, ViewProps } from 'react-native';
import { useAppSelector } from '../../../hooks/store-hook';
import useCustomTheme, { CustomTheme } from '../../../hooks/theme-hook';
import { MissionFilter } from '../../../models/MapModels';
import MissionCard, { MissionCardProps } from './MissionCard';
import Label, { LabelType } from '../Label';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import useTranslation from '../../../hooks/translation-hook';
import MoreInfoButton from '../MoreInfoButton';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFilterSlash } from '@fortawesome/pro-regular-svg-icons';
import Loading from '../Loading';

export interface MissionListProps extends ViewProps {
  pinnedMissionCards: MissionCardProps[];
  missionCards: MissionCardProps[];
  drawerVersion?: boolean;
  showAsCards?: boolean;
  selected?: MissionFilter;
  loading?: MissionCardProps;
  loadingSpin?: boolean;
}

const MissionList = (props: MissionListProps) => {
  const theme: CustomTheme = useCustomTheme();
  const t = useTranslation();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const [missionCardComponent, setMissionCardComponent] = useState(<></>);
  const [selected, setSelected] = useState(props.selected);
  const [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setSelected(props.selected);
  }, [props.selected]);

  useEffect(() => {
    setMissionCardComponent(
      <>
        {!props.loadingSpin ? (
          <>
            {props.pinnedMissionCards != undefined && props.pinnedMissionCards.length > 0 && (
              <>
                <View style={{ paddingHorizontal: 16, paddingVertical: 16, paddingTop: 0 }}>
                  <Label style={{ color: theme.text.sea, fontSize: 12, fontWeight: '500' }}>{t('Pinned-missions')}</Label>
                </View>
                {props.pinnedMissionCards.map((cardProps) => (
                  <MissionCard
                    {...cardProps}
                    drawerVersion={props.drawerVersion}
                    showAsCards={props.showAsCards}
                    selectedMission={selected}
                    loading={loading?.id === cardProps?.id}
                    key={`${cardProps.id}}`}
                    pinned
                  />
                ))}
              </>
            )}
            {props.missionCards != undefined && props.missionCards.length > 0 && (
              <>
                <View style={{ paddingHorizontal: 16, paddingVertical: 16, borderBottomWidth: 1, borderColor: theme.borderColorLight }}>
                  <Label style={{ color: theme.text.sea, fontSize: 12, fontWeight: '500' }}>{t('Missions')}</Label>
                </View>
                {props.missionCards.map((cardProps) => (
                  <MissionCard
                    {...cardProps}
                    drawerVersion={props.drawerVersion}
                    showAsCards={props.showAsCards}
                    selectedMission={selected}
                    loading={loading?.id === cardProps?.id}
                    key={`${cardProps.id}}`}
                  />
                ))}
              </>
            )}
            {(props.missionCards == undefined || props.missionCards.length <= 0) &&
              (props.pinnedMissionCards == undefined || props.pinnedMissionCards.length <= 0) && (
                <View style={{ padding: 16, paddingTop: 16, alignItems: 'center', gap: 24 }}>
                  <FontAwesomeIcon icon={faFilterSlash} size={50} color={theme.text.darkTurquoiseTwo.toString()} />
                  <Label numberOfLines={5} style={{ color: theme.text.darkTurquoiseTwo, fontSize: 14 }}>
                    {t('no-mission-filter')}
                  </Label>
                </View>
              )}
          </>
        ) : (
          <View style={{ padding: 16, paddingTop: 16, alignItems: 'center' }}>
            <Loading />
          </View>
        )}
      </>
    );
  }, [props.missionCards, selected, loading, userPrefs.theme, props.loadingSpin]);

  return (
    <View
      style={{
        backgroundColor: theme.background.primaryLight,
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        flexDirection: props.showAsCards ? 'row' : 'column',
      }}
    >
      <View style={{ flexDirection: 'row', alignItems: 'center' }}>
        <Label variant={LabelType.titleLargeNoCapsPrimary} style={{ ...StylesHelper.padding(16), marginTop: -4 }}>
          {t('Missions')}
        </Label>
        <MoreInfoButton title={t('help_support')} openURL={'missions-lanla-website-info-link'}>{`${t(
          'browse-through-the-available-missions'
        )}`}</MoreInfoButton>
      </View>
      {missionCardComponent}
    </View>
  );
};

export default MissionList;

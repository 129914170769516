import React, { useEffect, useState } from 'react';
import { View, Image, Pressable, Platform, Linking, useWindowDimensions } from 'react-native';
import Label from './Label';
import CustomCheckbox from './CustomCheckbox';
import AppStoreBadgeEn from '../../../assets/app-store-badge-en.svg';
import AppStoreBadgeFr from '../../../assets/app-store-badge-fr.svg';
import useTranslation from '../../hooks/translation-hook';
import { useAppSelector } from '../../hooks/store-hook';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import useAdvertisementHook from '../../hooks/advertisement-hook';
import { LoggedState } from '../../models/AuthModelsCommon';

const MobileAppCallToAction = () => {
  const t = useTranslation();
  const layout = useWindowDimensions();
  const authStore = useAppSelector((selector) => selector.auth);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const advertisementTracking = useAdvertisementHook();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [showCallToAction, setShowCallToAction] = useState(false);

  useEffect(() => {
    if (authStore.logged === LoggedState.Logged) {
      if (advertisementTracking.loaded) {
        if (advertisementTracking.showAdvertisement('MobileAppLink') && Platform.OS === 'web') {
          setShowCallToAction(true);
        }
      }
    }
  }, [advertisementTracking.loaded, authStore.logged]);

  const getAppleBadge = () => {
    switch (userPrefs.uiLanguage) {
      case 'en':
        return Platform.OS !== 'web' ? (
          <AppStoreBadgeEn width={126} height={42} />
        ) : (
          <Image style={{ width: 126, height: 42 }} source={require(`../../../assets/app-store-badge-en.svg`)} />
        );
      default:
        return Platform.OS !== 'web' ? (
          <AppStoreBadgeFr width={150} height={47} />
        ) : (
          <Image style={{ width: 150, height: 47 }} source={require(`../../../assets/app-store-badge-fr.svg`)} />
        );
    }
  };

  const getGoogleBadge = () => {
    switch (userPrefs.uiLanguage) {
      case 'en':
        return <Image style={{ width: 160, height: 60, borderWidth: 0, marginLeft: -8 }} source={require(`../../../assets/google-play-badge-en.png`)} />;
      default:
        return <Image style={{ width: 160, height: 60, borderWidth: 0 }} source={require(`../../../assets/google-play-badge-fr.png`)} />;
    }
  };

  const handleOnClose = () => {
    setShowCallToAction(false);
    if (dontShowAgain) {
      advertisementTracking.dontShowAgain('MobileAppLink');
    }
  };

  const handleOnCheck = (checked: boolean, value: string) => {
    setDontShowAgain(checked);
  };

  return (
    <>
      {showCallToAction && (
        <View style={{ position: 'absolute', zIndex: 1000, width: '100%', padding: 4 }}>
          <View
            style={{
              position: 'relative',
              backgroundColor: '#e6e7ff',
              display: 'flex',
              flexWrap: 'wrap',
              gap: 8,
              flexShrink: 1,
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              borderRadius: 10,
              paddingTop: 16,
              paddingBottom: 16,
            }}
          >
            <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexShrink: 1, flexGrow: 1 }}>
              <Image style={{ width: 118, height: 68 }} source={require('../../../assets/mobile-phone.png')} />
              <View style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flexShrink: 1, flexGrow: 1 }}>
                <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1, gap: 4, paddingRight: 16 }}>
                  <Label style={{ fontSize: 26, fontWeight: '700', flexShrink: 1 }} numberOfLines={3}>
                    {t('Download-our-mobile-app')}
                  </Label>
                  <Label style={{ fontSize: 16, fontWeight: '700', color: '#5e51a3', flexShrink: 1 }} numberOfLines={3}>
                    {t('More-features-easier-and-smarter')}
                  </Label>
                  {layout.width >= 975 && (
                    <CustomCheckbox color={'#5e51a3'} value={'dontShowAgain'} label={t('Dont-show-this-again')} onChange={handleOnCheck} />
                  )}
                </View>
              </View>
            </View>
            <View
              style={{ flexDirection: layout.width < 975 ? 'column' : 'row-reverse', alignItems: 'center', flexGrow: 1, flexShrink: 1, paddingHorizontal: 16 }}
            >
              <View style={{ flexDirection: 'row', alignItems: 'center', gap: 16 }}>
                <Pressable
                  onPress={() => {
                    Linking.openURL('https://play.google.com/store/apps/details?id=com.union.msreact&pli=1');
                  }}
                >
                  {getGoogleBadge()}
                </Pressable>
                <Pressable
                  onPress={() => {
                    Linking.openURL('https://apps.apple.com/us/app/lanla/id6470310829');
                  }}
                >
                  {getAppleBadge()}
                </Pressable>
              </View>
            </View>
            {layout.width < 975 && (
              <CustomCheckbox
                style={{ paddingLeft: 16 }}
                color={'#5e51a3'}
                value={'dontShowAgain'}
                label={t('Dont-show-this-again')}
                onChange={handleOnCheck}
              />
            )}
            <Pressable style={{ height: 20, position: 'absolute', top: 8, right: 8 }} onPress={handleOnClose}>
              <FontAwesomeIcon size={20} icon={faXmark} color={'black'} />
            </Pressable>
          </View>
        </View>
      )}
    </>
  );
};

export default MobileAppCallToAction;

import useCustomTheme from '../hooks/theme-hook';

const useMoreInfoButtonStyles = () => {
  const theme = useCustomTheme();

  let overlay = {
    container: {
      outlineStyle: 'none',
    },
  };

  return overlay as typeof overlay;
};

export default useMoreInfoButtonStyles;

import React from 'react';
import { View, Image, Pressable } from 'react-native';
import Label from '../../common/Label';
import useTranslation from '../../../hooks/translation-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import CustomMarkdown from '../../common/CustomMarkDown';

const OnboardingTwo = () => {
  const theme = useCustomTheme();
  const t = useTranslation({ defaultPath: 'onboarding.page2' });
  return (
    <View style={{ backgroundColor: '#eeeef0', position: 'relative', flexGrow: 1, width: 625 }}>
      <Image
        source={require('../../../../assets/onBoarding_desktop_2.png')}
        style={{ position: 'absolute', top: 0, right: -1, aspectRatio: '1/1', maxWidth: 462, height: 496 }}
      />
      <View style={{ position: 'absolute', bottom: 59, left: 36, flexGrow: 1 }}>
        <View>
          <Label style={{ fontSize: 26, color: '#5e52a3', fontWeight: '600', width: 240 }}>{t('title')}</Label>
          <Label style={{ marginTop: 20, fontSize: 18, color: '#33264d', fontWeight: '400', width: 240 }}>{t('subtitle')}</Label>
          <View style={{ marginTop: 12, width: 320 }}>
            <CustomMarkdown style={{ body: { fontSize: 15, color: 'black' } }}>{t('description')}</CustomMarkdown>
          </View>
        </View>
      </View>
    </View>
  );
};

export default OnboardingTwo;

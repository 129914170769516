import React, { useEffect, useState } from 'react';
import { Pressable, PressableProps, StyleProp, TextStyle, View } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import Label, { LabelType } from './Label';

export interface CheckboxProps<T = string | number> extends PressableProps {
  value: T;
  label: string;
  checked?: boolean;
  bottomLine?: boolean;
  textStyle?: StyleProp<TextStyle>;
  onChange?(checked: boolean, value: T): void;
}

const CustomRadioButton = (props: CheckboxProps) => {
  const theme = useCustomTheme();
  const [checked, setChecked] = useState(props.checked ?? false);
  const styles = useStyles((section) => section.radioButton);

  const bottomLine = props.bottomLine
    ? {
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColor,
      }
    : {};

  const onCheckmarkPress = () => {
    if (!checked) {
      setChecked(!checked);
    }
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(checked, props.value);
    }
  }, [checked]);

  return (
    <Pressable style={{ ...bottomLine, ...styles.container }} onPress={onCheckmarkPress} {...props}>
      <Label style={[{ fontSize: 14 }, styles.label, props.textStyle]}>{props.label}</Label>
      <View style={[styles.base, checked && styles.checked]}>{checked && <View style={styles.marker} />}</View>
    </Pressable>
  );
};

export default CustomRadioButton;

import { FirebaseApp, initializeApp, getApp } from 'firebase/app';
import 'firebase/messaging';
import {
  Auth,
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  createUserWithEmailAndPassword,
  getAuth,
  linkWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  User,
  confirmPasswordReset,
  signInAnonymously,
  signOut,
  onAuthStateChanged,
  fetchSignInMethodsForEmail,
  signInWithRedirect,
  onIdTokenChanged,
  signInWithPopup,
  Unsubscribe,
  updatePassword,
  verifyBeforeUpdateEmail,
  updateEmail,
  applyActionCode,
  reauthenticateWithCredential,
} from 'firebase/auth';
import Constants from 'expo-constants';

export class FirebaseService {
  private readonly loggerFrom: string = 'FirebaseService';
  public messaging;
  private googleProvider;
  //private facebookProvider;
  private microsoftProvider;
  public user: User | null | undefined;
  private callback;

  private firebaseApp: FirebaseApp;
  private auth: Auth;
  private onAuthStateChangedUsubscribe: Unsubscribe;

  constructor() {
    initializeApp(Constants.expoConfig.extra.firebaseConfig);
    this.firebaseApp = getApp();
    this.auth = getAuth(this.firebaseApp);

    // const auth = firebase.auth();
    // auth.useEmulator('http://localhost:9099');
    // if (firebase.messaging.isSupported()) {
    //   this.messaging = firebase.messaging();
    //   this.messaging.onMessage((payload) => {
    //     console.log('Got FCM message', payload);
    //   });
    // }

    this.googleProvider = new GoogleAuthProvider();
    this.googleProvider.setCustomParameters({
      prompt: 'select_account',
    });

    //this.facebookProvider = new FacebookAuthProvider();

    this.microsoftProvider = new OAuthProvider('microsoft.com');

    //firebase.auth().useDeviceLanguage();
  }

  public async getToken(): Promise<string | undefined> {
    if (this.messaging) {
      return this.messaging
        .getToken({ vapidKey: Constants.expoConfig.extra.vapidKey })
        .then((token) => {
          console.log('Got FCM token', token);
          return token;
        })
        .catch((error) => {
          console.log("Couldn't get FCM token", error);
          return undefined;
        });
    } else {
      console.log('FCM not supported', this.loggerFrom);
      return undefined;
    }
  }

  public getIdToken(): Promise<string | null> {
    return new Promise((resolve, reject) => {
      this.auth.onAuthStateChanged((user) => {
        if (user) {
          user.getIdToken().then((idToken) => resolve(idToken));
        } else {
          resolve(null);
        }
      });
    });
  }

  public async verifyChangeEmail(newEmail: string, callback: any) {
    return verifyBeforeUpdateEmail(this.auth.currentUser, newEmail, { handleCodeInApp: false, url: `${Constants.expoConfig.extra.appUrl}/logout` })
      .then(() => {
        callback(true);
      })
      .catch((error) => {
        callback(error.code);
      });
  }

  public applyActionCode(oobCode: string) {
    return applyActionCode(this.auth, oobCode);
  }

  public async changePassword(password: string, callback: any) {
    return updatePassword(this.auth.currentUser, password)
      .then(() => {
        callback(true);
      })
      .catch((error) => {
        callback(error.code);
      });
  }

  public async signUp(email: string, password: string): Promise<User | any> {
    return createUserWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        if (this.callback) {
          this.callback(userCredential.user);
        }
      })
      .catch((error) => {
        if (this.callback) {
          this.callback(error);
        }
      });
  }

  public async reauthenticate(email: string, password: string): Promise<User | any> {
    return reauthenticateWithCredential(this.user, EmailAuthProvider.credential(email, password))
      .then((userCredential) => {
        if (this.callback) {
          this.callback(userCredential.user);
        }
      })
      .catch((error) => {
        if (this.callback) {
          this.callback(error);
        }
      });
  }

  public async login(email: string, password: string): Promise<User | any> {
    if (this.isAnonymous) {
      return linkWithCredential(this.user, EmailAuthProvider.credential(email, password))
        .then((userCredential) => {
          if (this.callback) {
            this.callback(userCredential.user);
          }
        })
        .catch((error) => {
          if (this.callback) {
            this.callback(error);
          }
        });
    }

    return signInWithEmailAndPassword(this.auth, email, password)
      .then((userCredential) => {
        if (this.callback) {
          this.callback(userCredential.user);
        }
      })
      .catch((error) => {
        if (this.callback) {
          console.log('Error callback', error);
          this.callback(error);
        }
      });
  }

  public async sendPasswordResetEmail(email: string, lang: string) {
    this.auth.languageCode = lang;
    return sendPasswordResetEmail(this.auth, email)
      .then((ok) => {
        this.callback(ok);
      })
      .catch((error) => this.callback(error));
  }

  public async confirmPasswordReset(oobCode: string, newPassword: string) {
    if (!oobCode && !newPassword) return;

    return confirmPasswordReset(this.auth, oobCode, newPassword)
      .then((ok) => {
        this.callback(ok);
      })
      .catch((error) => this.callback(error));
  }

  public setLoginCallback(callback) {
    this.callback = callback;
  }

  public async loginAnonymously(): Promise<User | any> {
    return signInAnonymously(this.auth)
      .then((userCredential) => {
        if (this.callback) {
          this.callback(userCredential.user);
        }
      })
      .catch((error) => {
        if (this.callback) {
          this.callback(error);
        }
      });
  }

  public async logout(): Promise<boolean> {
    return signOut(this.auth)
      .then(() => {
        this.user = undefined;
        return true;
      })
      .catch((error) => {
        return false;
      });
  }

  public get isAnonymous(): boolean {
    this.user = this.auth.currentUser;

    if (this.user?.isAnonymous) {
      return true;
    } else {
      return false;
    }
  }

  public get currentUser(): User {
    this.user = this.auth.currentUser;
    return this.user;
  }

  public onAuthStateChanged(callback): void {
    if (this.onAuthStateChangedUsubscribe) {
      console.log('onAuthStateChanged', 'Unsubscribe old call');
      this.onAuthStateChangedUsubscribe();
    }

    this.onAuthStateChangedUsubscribe = onAuthStateChanged(this.auth, callback);
  }

  public onIdTokenChanged(callback): void {
    onIdTokenChanged(this.auth, callback);
  }

  public getSignInMethods(email: string) {
    return fetchSignInMethodsForEmail(this.auth, email)
      .then((result) => {
        return result;
      })
      .catch((error) => {
        throw error;
      });
  }

  public async loginWithGoogle(usePopup: boolean) {
    if (usePopup) {
      return signInWithPopup(this.auth, this.googleProvider);
    } else {
      return signInWithRedirect(this.auth, this.googleProvider);
    }
  }

  // public async loginWithFacebook() {
  //   return signInWithRedirect(this.auth, this.facebookProvider);
  // }

  public async loginWithMicrosoft() {
    return signInWithRedirect(this.auth, this.microsoftProvider);
  }
}

import React from 'react';
import { View, Image, Pressable } from 'react-native';
import Label from '../../common/Label';
import { useTheme } from '@react-navigation/native';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';

const OnboardingThree = ( ) => {
  const theme = useCustomTheme();
  const t = useTranslation({ defaultPath: 'onboarding.page3' });
  return (
    <View style={{ backgroundColor: '#fef1dd', position: 'relative', flexGrow: 1, width: 625 }}>
      <Image
        source={require('../../../../assets/onBoarding_desktop_3.png')}
        style={{ position: 'absolute', top: 0, right: -1, aspectRatio: '1/1', maxWidth: 462, height: 496 }}
      />
      <View style={{ position: 'absolute', bottom: 59, left: 36, flexGrow: 1 }}>
        <View>
          <Label style={{ fontSize: 26, color: '#f5a523', fontWeight: '600', width: 240 }}>{t('title')}</Label>
          <Label style={{ marginTop: 20, fontSize: 18, color: '#8e183d', fontWeight: '400', width: 240 }}>{t('subtitle')}</Label>
          <Label style={{ fontSize: 15, marginTop: 24, color: 'black', width: 320 }} numberOfLines={10}>
            {t('description')}
          </Label>
        </View>
      </View>
    </View>
  );
};

export default OnboardingThree;

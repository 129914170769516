import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const usePillButtonStyles = () => {
  const theme = useCustomTheme();

  const commonButtonFeatures = {
    display: 'flex' as any,
    flexDirection: 'row' as any,
    alignItems: 'center' as any,
    justifyContent: 'center' as any,
    borderRadius: 50,
    ...StylesHelper.padding(4, 16),
  };

  const pillButton = {
    button: {
      main: {
        ...commonButtonFeatures,
        backgroundColor: 'white',
        ...StylesHelper.shadow({ width: 2, height: 3 }, 0.2, 5, 'black', 3),
      },
      outlineButton: { ...commonButtonFeatures, borderWidth: 1, borderColor: theme.text.primary },
    },
    text: {
      main: {
        color: 'black',
        fontSize: 14,
        fontWeight: '600' as any,
      },
    },
  };

  return pillButton as typeof pillButton;
};

export default usePillButtonStyles;

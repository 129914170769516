import useCustomTheme from '../hooks/theme-hook';

const useCustomDatePickerStyles = () => {
  const theme = useCustomTheme();

  const inputCommon = {
    position: 'relative' as any,
    fontSize: 17,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.borderColor,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 8,
    paddingLeft: 8,
    backgroundColor: theme.background.primary,
    height: 40,
  };

  const customDatePicker = {
    container: {
      position: 'relative' as any,
    },
    input: {
      ...inputCommon,
      color: theme.inputTextPlaceholder,
    },
    ['input.error']: {
      backgroundColor: theme.colorErrorPrimaryBackground,
      borderColor: theme.colorErrorPrimary,
    },
    inputTouched: {
      ...inputCommon,
      color: theme.text.primary,
    },
    ['inputTouched.clearIconPadding']: {
      paddingRight: 34,
    },
    ['inputTouched.steadyIconPadding']: {
      paddingLeft: 34,
    },
    ['inputTouched.backIconPadding']: {
      paddingLeft: 34,
    },    
  };

  return customDatePicker as typeof customDatePicker;
};
export default useCustomDatePickerStyles;

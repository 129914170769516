import React from 'react';
import { ButtonProps, ImageBackground, Pressable, StyleProp, Text, TextStyle } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import { NamedStyles } from 'react-dom';

interface CustomButtomProps extends ButtonProps {
  secondary?: boolean;
  secondaryColor?: boolean;
  secondaryColorLight?: boolean;
  style?: NamedStyles<any> | NamedStyles<any>[];
  textStyle?: StyleProp<TextStyle>;
}

const CustomButton = (props: CustomButtomProps) => {
  const styles = useStyles((section) => section.customButton);
  let buttonStyle = [styles.button.main];
  let textStyle = [styles.text.main];

  if (props.secondary) {
    buttonStyle.push(styles.button.secondary as any);
    textStyle.push(styles.text.secondary as any);
  }

  if (props.secondaryColor) {
    buttonStyle.push(styles.button.secondaryColor as any);
    textStyle.push(styles.text.secondaryColor as any);
  }

  if (props.secondaryColorLight) {
    buttonStyle.push(styles.button.secondaryColorLight as any);
    textStyle.push(styles.text.secondaryColor as any);
  }

  if (props.style) {
    buttonStyle.push(props.style);
  }

  if (props.textStyle) {
    textStyle.push(props.textStyle as any);
  }

  if (props.disabled) {
    buttonStyle.push(styles.button.disabled as any);
    textStyle.push(styles.text.disabled as any);
  }

  return (
    <Pressable {...props} style={buttonStyle}>
      <ImageBackground
        source={require('../../../assets/disabledBars.png')}
        style={styles.button.backgroundImage}
        imageStyle={{ resizeMode: 'repeat', opacity: props.disabled ? 1 : 0 }}
      >
        <Text style={textStyle}>{props.title}</Text>
      </ImageBackground>
    </Pressable>
  );
};

export default CustomButton;

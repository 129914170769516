import { DimensionValue, Platform, useWindowDimensions } from 'react-native';

import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useValidationStyles = () => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();

  let general = {
    loading: {
      zIndex: 1002,
      backgroundColor: theme.background.primary,
      ...StylesHelper.shadow({ width: 3, height: 3 }, 0.2, 6, 'black', 2),
      ...StylesHelper.borderRadius(8),
      height: 48,
      width: 160,
      top: Platform.OS === 'web' ? 'calc(50% - 24px)' : layout.height / 2 - 80,
      left: Platform.OS === 'web' ? 'calc(50% - 80px)' : layout.width / 2 - 80,
    },
    deck: {
      container: { backgroundColor: theme.background.primary, flexGrow: 1 },
      cardSwipeContainerBase: {
        flexGrow: 1,
        width: '100%' as DimensionValue,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
        padding: 16,
        backgroundColor: theme.background.secondary,
      },
      cardSwipeContainer: {
        card: {
          width: '100%' as DimensionValue,
          padding: 16,
          flexDirection: 'row' as any,
          textAlign: 'center' as any,
          alignItems: 'center' as any,
          justifyContent: 'center' as any,
        },
        questionContainerBase: {
          padding: 16,
          marginTop: 32,
          textAlign: 'center' as any,
          backgroundColor: theme.background.secondary,
        },
        questionContainer: {
          question: {
            marginTop: 24,
            fontSize: 15,
            color: theme.text.primary,
          },
        },
      },
      footerContainerBase: {
        backgroundColor: theme.background.secondary,
        flexGrow: 0,
      },
      footerContainer: {
        gradient: {
          ...StylesHelper.padding(16),
          width: '100%' as DimensionValue,
          // height: '100%',
          alignItems: 'center' as any,
        },
        question: {
          paddingHorizontal: 16,
        },
        buttonContainerBase: {
          textAlign: 'center' as any,
          alignItems: 'center' as any,
        },
        buttonContainer: {
          buttonBarBase: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            alignItems: 'center' as any,
            paddingTop: 16,
          },
          buttonBar: {
            button: {
              display: 'flex' as any,
              justifySelf: 'flex-end' as any,
              flexDirection: 'row' as any,
              alignItems: 'center' as any,
              justifyContent: 'center' as any,
              width: 72,
              height: 72,
              backgroundColor: 'white' as any,
              ...StylesHelper.padding(6),
              ...StylesHelper.borderRadius(50),
              ...StylesHelper.shadow({ width: 3, height: 3 }, 0.2, 6, 'black', 2),
              negativeButton: { icon: { outlineWidth: 0 } },
              positiveButton: { icon: { outlineWidth: 0 } },
              flexGrow: 0,
              flexShrink: 0,
            },
            ['buttonBar.bigger']: {
              width: 100,
              height: 100,
            },
          },
          neutralButton: {},
        },
      },
    },
    card: {
      containerBase: {
        width: '100%' as DimensionValue,
        maxWidth: 600,
        minHeight: 64,
        display: 'flex' as any,
        flexDirection: 'column' as any,
        alignItems: 'stretch' as any,
        justifyContent: 'center' as any,
        backgroundColor: theme.background.modal,
        ...StylesHelper.borderRadius(8),
        ...StylesHelper.shadow({ width: 3, height: 3 }, 0.2, 6, 'black', 2),
      },
      container: {
        content: {
          width: '100%' as DimensionValue,
          maxWidth: 600,
          minHeight: 64,
          display: 'flex' as any,
          flexDirection: 'column' as any,
          alignItems: 'stretch' as any,
          justifyContent: 'center' as any,
          backgroundColor: theme.background.modal,
        },
      },
      header: {
        display: 'flex' as any,
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
        justifyContent: 'flex-end' as any,
        width: '100%' as DimensionValue,
        ...StylesHelper.padding(16),
        borderBottomWidth: 1,
        borderBottomColor: theme.borderColorLight,
        pill: {
          backgroundColor: theme.secondaryLight,
          borderRadius: 16,
          ...StylesHelper.padding(2, 8),
        },
      },
      content: {
        flexGrow: 1,
        ...StylesHelper.padding(16),
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
      },
    },
    emptyCardBase: {
      flexGrow: 1,
      display: 'flex' as any,
      flexDirection: 'column' as any,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
      ...StylesHelper.padding(16),
      textAlign: 'center' as any,
    },
    emptyCard: {
      icon: {
        color: theme.text.titleLight,
      },
    },
    marginRight: {
      marginRight: 24,
    },
    question: {
      containerDesktop: {
        flexGrow: 1,
        flexDirection: 'row' as any,
        sidePanel: {
          width: 250,
          content: {
            marginLeft: 'auto' as any,
            marginRight: 'auto' as any,
            marginTop: 24,
            borderRadius: 10,
            overflow: 'hidden' as any,
            width: 200,
            height: 380
          }
        },
        center: {
          flexGrow: 1,
          padding: 20,
          shadowColor: theme.borderColorLight,
          shadowOffset: { width: 0, height: 3 },
          shadowOpacity: 1,
          shadowRadius: 6,
          container: {
            gap: 20,
            flexDirection: 'row' as any
          }
        }
      },
      scrollContainer: {
        flexGrow: 1,
        padding: 20
      },
      container: {
        gap: 20
      },
      questionBlockDesktop: {
        flexShrink: 1,
        height: 'fit-content' as any,
        flexGrow: 1,
        backgroundColor: '#e9f5f3' as any,
        borderRadius: 10,
        paddingLeft: 20,
        paddingRight: 20
      },
      questionBlock: {
        flexShrink: 1,
        backgroundColor: '#e9f5f3' as any,
        borderRadius: 10,
        paddingLeft: 20,
        paddingRight: 20
      },
      questionBlockLabel: {
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
        gap: 16,
        paddingTop: 20,
        paddingBottom: 20
      },
      questionBlockResponseLabelContainer: {
        gap: 16,
        paddingTop: 20,
        paddingBottom: 20
      },
      questionBlockResponseLabelMultiChoiceContainer: {
        gap: 16,
      },
      questionBlockResponseLabel: {
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
        gap: 16,
      },
      questionBlockLabelLetter: {
        flexShrink: 0,
        width: 24
      }
    }
  };

  return general as typeof general;
};
export default useValidationStyles;

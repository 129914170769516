import axios, { CancelTokenSource } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { BaseApi } from '../api/api.g';
import { ApiConfig } from '../api/BaseApi';
import { useAppSelector } from './store-hook';
import { FirebaseReference } from '../store/authSlice';

const useApi = <T extends BaseApi>(api: new (config: ApiConfig) => T): T => {
  const authStore = useAppSelector((selector) => selector.auth);
  const navStore = useAppSelector((selector) => selector.navBar);
  const apiInstance: T = useMemo<T>(() => new api({ accessToken: () => Promise.resolve('') }), [api]);
  const [isCancel, setIsCancel] = useState(false);
  const [tokenSource, setTokenSource] = useState<CancelTokenSource | undefined>(undefined);

  const [customTokenValue, setCustomTokenValue] = useState<string>();

  apiInstance.tokenSource = tokenSource;

  const getAccessToken = (): Promise<string> => {
    let tokenPromise: Promise<string> = undefined;

    try {
      tokenPromise = FirebaseReference.currentUser.getIdToken(false);
      return tokenPromise;
    } catch {
      return undefined;
    }
  };

  // Flag that the token was cancelled, so it can be used to renew the cancellation token
  apiInstance.onCancel = () => {
    apiInstance.isCancelled = true;
    setIsCancel(true);
  };

  useEffect(() => {
    apiInstance.config.accessToken = getAccessToken;
  }, []);

  useEffect(() => {
    if (navStore.isWebView && authStore.idToken) {
      apiInstance.config.accessToken = () =>
        new Promise<string>((resolve, reject) => {
          resolve(authStore?.idToken);
        });
    }
  }, [authStore?.idToken, navStore.isWebView]);

  useEffect(() => {
    setTokenSource(axios.CancelToken.source());
  }, [api]);

  useEffect(() => {
    setIsCancel(false);
    apiInstance.isCancelled = false;
  }, [tokenSource]);

  // Returning the isCancel to false
  useEffect(() => {
    if (isCancel) {
      const newCancellationToken = axios.CancelToken.source();
      setTokenSource(newCancellationToken);
      apiInstance.tokenSource = newCancellationToken;
    }
  }, [isCancel, apiInstance]);

  return apiInstance;
};

export default useApi;

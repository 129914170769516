import useCustomTheme from '../hooks/theme-hook';

const useCustomTextInputStyles = () => {
  const theme = useCustomTheme();

  const inputCommon = {
    position: 'relative' as any,
    fontSize: 17,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: theme.borderColor,
    paddingVertical: 8,
    paddingHorizontal: 8,
    backgroundColor: theme.background.primary,
    height: 40,
  };

  const customTextInput = {
    container: {
      position: 'relative' as any,
    },
    input: {
      ...inputCommon,
      color: theme.inputTextPlaceholder,
    },
    ['input.error']: {
      backgroundColor: theme.colorErrorPrimaryBackground,
      borderColor: theme.colorErrorPrimary,
    },
    inputTouched: {
      ...inputCommon,
      color: theme.text.primary,
    },
    ['inputTouched.clearIconPadding']: {
      paddingRight: 34,
    },
    ['inputTouched.steadyIconPadding']: {
      paddingLeft: 34,
    },
    ['inputTouched.backIconPadding']: {
      paddingLeft: 34,
    },
    iconBase: {
      height: '100%' as any as any,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
    },
    icon: {
      steady: {
        zIndex: 10,
        position: 'absolute' as any,
        left: 8,
      },
      ['steady.dimension']: {
        width: 24,
        height: 22,
      },
      clear: {
        zIndex: 10,
        position: 'absolute' as any,
        right: 8,
      },
      ['clear.dimension']: {
        width: 24,
        height: 24,
      },
      back: {
        zIndex: 10,
        position: 'absolute' as any,
        left: 8,
      },
      ['back.dimension']: {
        width: 9,
        height: 15,
      },
    },
  };

  return customTextInput as typeof customTextInput;
};
export default useCustomTextInputStyles;

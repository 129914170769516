import React, { useEffect, useState } from 'react';
import { Pressable, StyleProp, View, ViewStyle } from 'react-native';
import { ViewProps } from 'react-native/types';
import useStyles from '../../hooks/styles-hook';

export interface CustomRadioButton2Props extends ViewProps {
  value?: any;
  checked?: boolean;
  readOnly?: boolean;
  onPress?(checked: boolean): void;
  style?: StyleProp<ViewStyle>
}

const CustomRadioButton2 = (props: CustomRadioButton2Props) => {
  const styles = useStyles((section) => section.radioButton);

  const [checked, setChecked] = useState<boolean>(props.checked);
  const [disabled, setDisabled] = useState<boolean>(props.readOnly);

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  useEffect(() => {
    setDisabled(props.readOnly);
  }, [props.readOnly]);

  const getDisplay = (visible: boolean) => {
    return visible ? 'flex' : 'none';
  };

  const handleOnPress = () => {
    setChecked(!checked);
    props.onPress && props.onPress(!checked);
  };

  return (
    <Pressable disabled={disabled} onPress={handleOnPress} style={props.style}>
      <View style={styles.radioButton2.container}>
        <View style={styles.radioButton2.outerCircle}>
          <View style={[styles.radioButton2.innerCircle, { display: getDisplay(checked) }]}></View>
        </View>
        <View>{props.children}</View>
      </View>
    </Pressable>
  );
};

export default CustomRadioButton2;

import React, { useEffect, useState } from 'react';
import { ScrollView, View } from 'react-native';
import MapControls from './MapControls';
import useStyles from '../../hooks/styles-hook';
import pinImage from '../../../assets/pin_smallhit.png';
import pinGreyImage from '../../../assets/pin_grey_smallhit.png';
// import pinCurrentLocationImage from '../../../assets/current-position.svg';
import pinSelectedImage from '../../../assets/pin-selected-smallhit.png';
import { MapMarker, MapLocation, MapProps } from '../../models/MapModels';
import Constants from 'expo-constants';
import PillButton from '../common/PillButton';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faList, faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import useTranslation from '../../hooks/translation-hook';
import MissionList from '../common/mission/MissionList';
import { Cluster, ClusterStats, MarkerClusterer, Renderer, SuperClusterViewportAlgorithm } from '@googlemaps/markerclusterer';

let selectedMarker;
let maps: { [id: string]: any } = {};
let placeService;
let autoCompleteService;
let markers = [];
let myGoogle = (window as any).google;
let zoom = 8;
let nextZIndex = 1;
let markerClusterer: MarkerClusterer;

const pinCurrentLocationImage = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path d="M464 256A208 208 0 1 0 48 256a208 208 0 1 0 416 0zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256-96a96 96 0 1 1 0 192 96 96 0 1 1 0-192z"/></svg>`;

class CustomRenderer implements Renderer {
  render(cluster: Cluster, stats: ClusterStats, map: any) {
    let myGoogle = (window as any).google;

    const count = cluster.count;

    // create svg url with fill color
    const svg = window.btoa(`
    <svg fill="rgb(67, 164, 158)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
    <circle cx="120" cy="120" opacity=".6" r="90" />
    <circle cx="120" cy="120" opacity=".3" r="100" />
    <circle cx="120" cy="120" opacity=".2" r="110" />
    <circle cx="120" cy="120" opacity=".1" r="120" />
    </svg>`);

    // create marker using svg icon
    return new myGoogle.maps.Marker({
      position: cluster.position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new myGoogle.maps.Size(60, 60),
      },
      label: {
        text: String(count),
        color: 'rgba(255,255,255,0.9)',
        fontSize: '16px',
        fontWeight: '600',
      },
      // adjust zIndex to be above other markers
      zIndex: 1000 + count,
    });
  }
}

const panToPosition = (mapId: string, lat: number, lng: number) => {
  let position = new myGoogle.maps.LatLng(lat, lng);
  maps[mapId]?.panTo(position);
};

const selectMarker = (mapId: string, marker: any) => {
  if (!marker) {
    return;
  }

  //marker.setIcon(pinSelectedImage);

  // Change the pin background to selected
  marker.content.children[0].src = pinSelectedImage;

  // Setting zIndex
  marker.zIndex = nextZIndex;
  nextZIndex++;

  selectedMarker = marker;
  panToPosition(mapId, marker.position.lat, marker.position.lng);
  maps[mapId]?.setZoom(16);
};

const parser = new DOMParser();

const getDefaultPinImage = (marker: string | any) => {
  let defaultPin = typeof marker === 'string' ? marker : marker?.content?.dataset.defaultPin;

  return defaultPin === 'grey' ? pinGreyImage : pinImage;
};

const addMarkers = async (mapId: string, mapMakers: MapMarker[], currentLocation?: MapLocation, selectedId?: string) => {
  const { AdvancedMarkerElement } = await myGoogle.maps.importLibrary('marker');

  if (currentLocation) {
    const pinElement = document.createElement('div');
    const pinSvg = parser.parseFromString(pinCurrentLocationImage, 'image/svg+xml').documentElement;
    pinSvg.style.fill = '#0c87da';
    pinSvg.style.height = '24px';
    pinSvg.style.width = '24px';
    pinElement.appendChild(pinSvg);

    let currentLocationMarker = new AdvancedMarkerElement({
      position: {
        lat: isNaN(currentLocation.lat) ? 0 : currentLocation.lat,
        lng: isNaN(currentLocation.lng) ? 0 : currentLocation.lng,
      },
      content: pinElement,
      map: maps[mapId],
    });
  }

  mapMakers.forEach((obj) => {
    let marker = new AdvancedMarkerElement({
      position: {
        lat: isNaN(obj.coordinate?.latitude) ? 0 : obj.coordinate?.latitude,
        lng: isNaN(obj.coordinate?.longitude) ? 0 : obj.coordinate?.longitude,
      },
      content: getPinElement(obj),
    });

    marker.id = obj.id;

    marker.addListener('click', () => {
      if (selectedMarker) {
        // selectedMarker.setIcon(pinImage);

        //Returning to unselected pin
        selectedMarker.content.children[0].src = getDefaultPinImage(selectedMarker);
      }

      selectMarker(mapId, marker);

      obj.onClick(marker.id, { latitude: marker.position.lat, longitude: marker.position.lng });
    });

    markers.push(marker);

    if (marker.id === selectedId) {
      selectMarker(mapId, marker);
    }
  });

  var algorithm = new SuperClusterViewportAlgorithm({ radius: 250 } as any);

  markerClusterer = new MarkerClusterer({ markers, map: maps[mapId], algorithm: algorithm, renderer: new CustomRenderer() });
};

const getPinElement = (obj?: MapMarker) => {
  const pinElement = document.createElement('div');
  pinElement.dataset.defaultPin = obj.defaultPin;
  const pinBackImage = document.createElement('img');
  pinBackImage.src = getDefaultPinImage(obj.defaultPin);
  pinBackImage.style.maxHeight = '60px';
  pinBackImage.style.maxWidth = '60px';

  pinElement.appendChild(pinBackImage.getRootNode());
  const pinSvgString = obj?.svgIcon ?? undefined;

  if (pinSvgString) {
    const pinSvg = parser.parseFromString(pinSvgString, 'image/svg+xml').documentElement;
    pinSvg.style.fill = '#000000';
    pinSvg.style.position = 'absolute';
    pinSvg.style.height = '16px';
    pinSvg.style.width = '16px';
    pinSvg.style.top = 'calc(50% - 14px)';
    pinSvg.style.left = 'calc(50% - 8px)';
    pinElement.appendChild(pinSvg);
  }

  return pinElement;
};

const clearMap = () => {
  try {
    markerClusterer?.clearMarkers();
  } catch (err) {
    console.error('clearMap error: ', err);
  }

  markers.forEach((m) => m.setMap(null));
  markers = [];
};

let cancelOnBound = false;

const WebMap = (props: MapProps) => {
  const t = useTranslation();
  const styles = useStyles((style) => style.customMap);
  const [selectedId, setSelectedId] = useState(props.selectedId);
  const [cleanSelection, setCleanSelection] = useState(props.cleanSelection);
  const [center, setCenter] = useState<MapLocation>(props.referencePosition ?? Constants.expoConfig.extra.defautlLocation);
  const [showMissionList, setShowMissionList] = useState(false);
  // const [cancelOnBound, setCancelOnBound] = useState(false);
  const [refreshMap, setRefreshMap] = useState(props.refreshMap);
  const [mapId, setMapId] = useState(props.mapId);
  const [markerItems, setMarkerItems] = useState(props.markerItems);

  useEffect(() => {
    setRefreshMap(props.refreshMap);
  }, [props.refreshMap]);

  useEffect(() => {
    setMapId(props.mapId);
  }, [props.mapId]);

  useEffect(() => {
    setMarkerItems(props.markerItems);
  }, [props.markerItems]);

  useEffect(() => {
    if (refreshMap) {
      zoom = props.zoom ?? 8;

      maps[mapId] = new myGoogle.maps.Map(document.getElementById(mapId), {
        center,
        zoom: zoom,
        disableDefaultUI: true,
        mapId: '4504f8b37365c3d0',
        clickableIcons: false,
      });

      maps[mapId].addListener('click', () => {
        if (selectedMarker) {
          selectedMarker.content.children[0].src = getDefaultPinImage(selectedMarker);
          selectedMarker = null;

          if (props.onCleanSelection) {
            props.onCleanSelection(true);
          }
        }
      });

      var boundTimer;

      maps[mapId].addListener('bounds_changed', function () {
        if (boundTimer) {
          clearTimeout(boundTimer);
        }

        // This function will be called whenever the bounds of the map change
        if (props.onBoundsChange) {
          boundTimer = setTimeout(() => {
            try {
              var bounds = maps[mapId].getBounds();

              if (bounds) {
                var ne = bounds.getNorthEast();
                var sw = bounds.getSouthWest();

                var topRightLat = ne.lat();
                var topRightLng = ne.lng();

                var bottomLeftLat = sw.lat();
                var bottomLeftLng = sw.lng();

                if (!cancelOnBound) {
                  props.onBoundsChange && props.onBoundsChange(topRightLat, bottomLeftLat, bottomLeftLng, topRightLng);
                } else {
                  cancelOnBound = false;
                }
              }
            } catch (err) {
              console.error('Map error: ', err);
            }
          }, 300);
        }
      });

      placeService = new myGoogle.maps.places.PlacesService(maps[mapId]);
      autoCompleteService = new myGoogle.maps.places.AutocompleteService();
      setRefreshMap(false);
      props.onMapRefreshed && props.onMapRefreshed();
    }
  }, [refreshMap, mapId]);

  useEffect(() => {}, [mapId, cancelOnBound]);

  useEffect(() => {
    if (!selectedMarker) {
      setCenter(props.referencePosition ?? Constants.expoConfig.extra.defautlLocation);
    }
  }, [props.referencePosition]);

  useEffect(() => {
    if (maps) {
      panToPosition(mapId, center.lat, center.lng);
    }
  }, [center]);

  useEffect(() => {
    if (maps) {
      zoom = props.zoom;
      maps[mapId]?.setZoom(zoom);
    }
  }, [props.zoom]);

  useEffect(() => {
    if (markerItems) {
      clearMap();
      let newMarkers = markerItems
        .filter((marker) => !isNaN(marker.coordinate?.latitude) && !isNaN(marker.coordinate?.longitude))
        .map((marker) => {
          return {
            ...marker,
            onClick: (e) => {
              cancelOnBound = true;
              props.markerClick(marker.id, marker.coordinate?.latitude, marker.coordinate?.longitude);
            },
          };
        });
      addMarkers(mapId, newMarkers, center !== Constants.expoConfig.extra.defautlLocation ? center : undefined, selectedId).then(() => {
        myGoogle.maps.event.addListener(markerClusterer, 'click', function (cluster) {
          props.onFilterMissionList && props.onFilterMissionList(cluster.markers.map((x) => x.id));
        });
      });
    }
  }, [markerItems]);

  useEffect(() => {
    setSelectedId(props.selectedId);
  }, [props.selectedId]);

  useEffect(() => {
    setCleanSelection(props.cleanSelection);
  }, [props.cleanSelection]);

  useEffect(() => {
    if (markers.length > 0) {
      const marker = markers.find((m) => m.id === selectedId);

      if (marker) {
        selectMarker(mapId, marker);
      }
    }
  }, [selectedId]);

  useEffect(() => {
    if (cleanSelection) {
      if (selectedMarker) {
        selectedMarker.content.children[0].src = getDefaultPinImage(selectedMarker);
        selectedMarker = null;
      }

      if (props.onCleanSelection) {
        props.onCleanSelection(true);
      }
    }
  }, [cleanSelection]);

  const handleZoomIn = () => {
    let zoom = maps[mapId].getZoom();
    zoom++;
    maps[mapId].setZoom(zoom);
  };

  const handleZoomOut = () => {
    let zoom = maps[mapId].getZoom();
    zoom--;
    maps[mapId].setZoom(zoom);
  };

  const handlePanToOrigin = () => {
    setCleanSelection(true);
    panToPosition(mapId, center.lat, center.lng);
    props.onPanCenter && props.onPanCenter();
    maps[mapId].setZoom(props.zoom);
  };

  const handleViewTypePress = (showList: boolean) => {
    setShowMissionList(showList);

    if (props.onDisplayTypePress) {
      props.onDisplayTypePress(showList);
    }
  };

  return (
    <View style={styles.container}>
      <View style={{ display: showMissionList ? 'none' : 'flex', flexGrow: 1, width: '100%' as any, height: '100%' as any }}>
        <View onLayout={props.onLayout} style={styles.container} nativeID={mapId}></View>
        <MapControls hidePanToOrigin={props.hidePanToOrigin} onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} onPanToOrigin={handlePanToOrigin} />
      </View>

      {props.viewList && (
        <View style={{ display: showMissionList ? 'flex' : 'none', flexGrow: 1, width: '100%' as any, height: '100%' as any }}>
          <ScrollView style={[styles.container]}>
            <MissionList missionCards={props.missionCardProps} pinnedMissionCards={props.pinnedMissionCardProps} />
          </ScrollView>
        </View>
      )}

      {props.viewList && (
        <>
          {!showMissionList ? (
            <View style={{ width: '100%', position: 'absolute', bottom: 0, alignItems: 'center' }}>
              <PillButton
                icon={<FontAwesomeIcon icon={faList} size={20} color="black" />}
                title={t('view_list_button')}
                onPress={() => handleViewTypePress(true)}
              />
            </View>
          ) : (
            <View style={{ width: '100%', position: 'absolute', bottom: 0, alignItems: 'center' }}>
              <PillButton
                icon={<FontAwesomeIcon icon={faMapLocationDot} size={24} color="black" />}
                title={t('view_map_button')}
                onPress={() => handleViewTypePress(false)}
              />
            </View>
          )}
        </>
      )}
    </View>
  );
};

export default WebMap;

// function getStyle(dark) {
//   if (dark) {
//     return [
//       { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
//       { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
//       { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
//       {
//         featureType: 'administrative.locality',
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#d59563' }],
//       },
//       {
//         featureType: 'poi.park',
//         elementType: 'geometry',
//         stylers: [{ color: '#263c3f' }],
//       },
//       {
//         featureType: 'poi.park',
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#6b9a76' }],
//       },
//       {
//         featureType: 'road',
//         elementType: 'geometry',
//         stylers: [{ color: '#38414e' }],
//       },
//       {
//         featureType: 'road',
//         elementType: 'geometry.stroke',
//         stylers: [{ color: '#212a37' }],
//       },
//       {
//         featureType: 'road',
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#9ca5b3' }],
//       },
//       {
//         featureType: 'road.highway',
//         elementType: 'geometry',
//         stylers: [{ color: '#746855' }],
//       },
//       {
//         featureType: 'road.highway',
//         elementType: 'geometry.stroke',
//         stylers: [{ color: '#1f2835' }],
//       },
//       {
//         featureType: 'road.highway',
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#f3d19c' }],
//       },
//       {
//         featureType: 'transit',
//         elementType: 'geometry',
//         stylers: [{ color: '#2f3948' }],
//       },
//       {
//         featureType: 'transit.station',
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#d59563' }],
//       },
//       {
//         featureType: 'water',
//         elementType: 'geometry',
//         stylers: [{ color: '#17263c' }],
//       },
//       {
//         featureType: 'water',
//         elementType: 'labels.text.fill',
//         stylers: [{ color: '#515c6d' }],
//       },
//       {
//         featureType: 'water',
//         elementType: 'labels.text.stroke',
//         stylers: [{ color: '#17263c' }],
//       },
//       {
//         featureType: 'poi',
//         stylers: [
//           {
//             visibility: 'off',
//           },
//         ],
//       },
//     ];
//   } else {
//     return [
//       {
//         featureType: 'poi',
//         stylers: [
//           {
//             visibility: 'off',
//           },
//         ],
//       },
//     ];
//   }
// }

import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { View } from 'react-native';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import Label, { LabelType } from '../Label';

const MissionCardLoading = () => {
  const theme = useCustomTheme();
  const t = useTranslation();

  return (
    <View
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255,255,255,0.8)',
        padding: 8,
        zIndex: 1000,
        top: 0,
        left: 0,
      }}
    >
      <FontAwesomeIcon icon={faRefresh} size={18} color={theme.secondaryLight.toString()} style={{ marginRight: 8 }} />
      <Label variant={LabelType.titleLight}>{`${t('loading')}...`}</Label>
    </View>
  );
};

export default MissionCardLoading;

import { useEffect, useState } from 'react';
import { useAppSelector } from './store-hook';
import useGoogleValidation from './googleValidation-hook';
import useGoogleGeocode from './googleGeocode-hook';
import { MysteryShopperAddressDto, MysteryShopperAddressType, MysteryShopperClient } from '../api/api.g';
import { GeocodeHelper } from '../models/helpers/geocodeHelper';
import { Platform, Pressable } from 'react-native';
import { useDispatch } from 'react-redux';
import { lightBoxActions } from '../store/lightBoxSlice';
import { useMutation } from '@tanstack/react-query';
import AddressRequestPopup from '../components/onboarding/AddressRequestPopup';
import useApi from './api-hook';
import AddressForm from '../components/address/AddressForm';
import Label from '../components/common/Label';
import ProfileModal from '../components/myaccount/myprofile/ProfileModal';
import useTranslation from './translation-hook';
import useCustomTheme from './theme-hook';
import useUserPrefsService from './userPrefsService-hook';

const useAddressMangager = () => {
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const t = useTranslation();
  const apiMysteryShopper = useApi(MysteryShopperClient);
  const googleValidator = useGoogleValidation();
  const userPrefService = useUserPrefsService();
  const dispatch = useDispatch();
  const googleGeocode = useGoogleGeocode();
  const theme = useCustomTheme();
  const [loading, setLoading] = useState(false);
  const [showAddressPopup, setShowAddressPopup] = useState(false);
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [suspiciousAddress, setSuspiciousAddress] = useState(false);
  const [confirmedAddress, setConfirmedAddress] = useState(false);
  const [dto, setDto] = useState<MysteryShopperAddressDto>(undefined);
  const [newDto, setNewDto] = useState<MysteryShopperAddressDto>(undefined);

  useEffect(() => {
    if (userPrefs.currentLocation) {
      googleGeocode(userPrefs.currentLocation, (results: any) => {
        if (Platform.OS === 'web') {
          setDto({
            address: results?.streetNumber && results?.route ? `${results.streetNumber} ${results.route}` : undefined,
            city: results.location,
            postalCode: results.postalCode,
            department: results.province,
            country: results.country,
            longitude: userPrefs.currentLocation.lng,
            latitude: userPrefs.currentLocation.lat,
            type: MysteryShopperAddressType.Home,
          } as MysteryShopperAddressDto);
        } else {
          setDto({
            address: GeocodeHelper.getAddress(results),
            city: GeocodeHelper.getCity(results),
            postalCode: GeocodeHelper.getPostalCode(results),
            department: GeocodeHelper.getProvince(results),
            country: GeocodeHelper.getCountry(results),
            longitude: userPrefs.currentLocation.lng,
            latitude: userPrefs.currentLocation.lat,
            type: MysteryShopperAddressType.Home,
          } as MysteryShopperAddressDto);
        }
      });
    }
  }, [userPrefs.currentLocation]);

  const addAddressMutation = useMutation({
    mutationFn: (dto: MysteryShopperAddressDto) => {
      return apiMysteryShopper.addAddress(dto);
    },
    onSuccess: () => {
      userPrefService.updateUserProfileInfo();
    },
  });

  const handleOnConfirmAddressRequest = () => {
    dispatch(lightBoxActions.setVisibility(false));
    dispatch(lightBoxActions.setNoDismiss(false));
    setShowAddressPopup(false);
    addAddressMutation.mutate(dto);
  };

  const handleOnEditAddressRequest = () => {
    dispatch(lightBoxActions.setNoDismiss(false));
    setConfirmedAddress(false);
    setShowAddressPopup(false);
    setShowAddressForm(true);
    setNewDto(dto);
    dispatch(lightBoxActions.setVisibility(true));
    dispatch(
      lightBoxActions.setOnClose(() => {
        handleOnCloseAddressForm();
      })
    );
  };

  const handleConfirmAddressForm = (newDto, bypassValidation?: boolean) => {
    if (bypassValidation) {
      setSuspiciousAddress(false);
      setConfirmedAddress(true);
      setShowAddressForm(false);
      dispatch(lightBoxActions.setVisibility(false));
      dispatch(lightBoxActions.setOnClose(undefined));
      addAddressMutation.mutate(newDto);
    } else {
      if (newDto && newDto.address && newDto.city && newDto.postalCode && newDto.department && newDto.country) {
        setLoading(true);
        googleValidator(
          {
            address: {
              addressLines: [newDto.address, newDto.city, newDto.postalCode, newDto.department, newDto.country],
            },
            previousResponseId: '',
            enableUspsCass: false,
          },
          (valid) => {
            setLoading(false);
            if (valid) {
              setSuspiciousAddress(false);
              setConfirmedAddress(true);
              setShowAddressForm(false);
              dispatch(lightBoxActions.setVisibility(false));
              dispatch(lightBoxActions.setOnClose(undefined));
              addAddressMutation.mutate(newDto);
            } else {
              setSuspiciousAddress(true);
            }
          }
        );
      } else {
        setSuspiciousAddress(true);
      }
    }
  };

  const handleOnCloseAddressForm = () => {
    setShowAddressForm(false);
    dispatch(lightBoxActions.setVisibility(false));
    dispatch(lightBoxActions.setContent(undefined));

    if (!confirmedAddress) {
      addAddressMutation.mutate(dto);
    }

    dispatch(lightBoxActions.setOnClose(undefined));
  };

  useEffect(() => {
    if (showAddressPopup && dto) {
      dispatch(lightBoxActions.setContent(<AddressRequestPopup dto={dto} onConfirm={handleOnConfirmAddressRequest} onEdit={handleOnEditAddressRequest} />));
    }
  }, [showAddressPopup, dto]);

  useEffect(() => {
    if (showAddressForm) {
      dispatch(
        lightBoxActions.setContent(
          <ProfileModal onClose={handleOnCloseAddressForm} title={t('address')} onConfirm={() => handleConfirmAddressForm(newDto)} loading={loading}>
            {
              <>
                {suspiciousAddress && (
                  <Label style={{ marginBottom: 24, color: theme.text.error, fontSize: 16, fontWeight: '600', flexShrink: 1, maxWidth: 260 }} numberOfLines={5}>
                    {t('address-incorrect-check-again')}
                  </Label>
                )}
                <AddressForm
                  city={newDto.city}
                  postalCode={newDto.postalCode}
                  address={newDto.address}
                  department={newDto.department}
                  country={newDto.country}
                  type={newDto.type}
                  onChange={(result, valid) => {
                    setNewDto((prev) => {
                      const newDto = new MysteryShopperAddressDto();
                      newDto.init(result);
                      return newDto;
                    });
                  }}
                />
                {suspiciousAddress && (
                  <Pressable
                    onPress={() => {
                      handleConfirmAddressForm(newDto, true);
                    }}
                  >
                    <Label style={{ color: theme.text.darkTurquoiseTwo, fontSize: 12, fontWeight: '600', flexShrink: 1 }}>
                      {t('Is-this-the-correct-address-Continue-with-this-add')}
                    </Label>
                  </Pressable>
                )}
              </>
            }
          </ProfileModal>
        )
      );
      dispatch(
        lightBoxActions.setOnClose(() => {
          handleOnCloseAddressForm();
        })
      );
    }
  }, [showAddressForm, newDto, suspiciousAddress, confirmedAddress, loading]);

  const showAddressRequestPopup = () => {
    setShowAddressPopup(true);
    dispatch(lightBoxActions.setNoDismiss(true));
    dispatch(lightBoxActions.setContent(<AddressRequestPopup dto={dto} onConfirm={handleOnConfirmAddressRequest} onEdit={handleOnEditAddressRequest} />));
    dispatch(lightBoxActions.setVisibility(true));
  };

  const addressManager = {
    showAddressRequestPopup: showAddressRequestPopup,
  };

  return addressManager;
};
export default useAddressMangager;

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { SimulateErrorsClient } from '../../api/api.g';
import useApi from '../../hooks/api-hook';
import { useAppSelector } from '../../hooks/store-hook';
import { LoggedState } from '../../models/AuthModelsCommon';

const Test401 = () => {
  const errorApi = useApi(SimulateErrorsClient);
  const authSlice =useAppSelector(selector => selector.auth);

  const error401Query = useQuery({
    enabled:  authSlice.logged !== undefined && authSlice.logged !== LoggedState.NotLogged,
    queryKey: ['test401'],
    retry: 0,
    queryFn: ({ signal }) => {
      const promise = errorApi.get401(errorApi.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        errorApi.tokenSource?.cancel('Query was cancelled by React Query');
        errorApi.onCancel();
      });

      return promise;
    }
  });

  return <></>;
};

export default Test401;

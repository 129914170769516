import React, { useEffect, useState } from 'react';
import { Platform, Pressable, ScrollView, View } from 'react-native';
import useTranslation from '../../hooks/translation-hook';
import useCustomTheme from '../../hooks/theme-hook';
import { MysteryShopperAddressType } from '../../api/api.g';
import CustomTextInput from '../common/CustomTextInput';
import Label, { LabelType } from '../common/Label';
import useAddressIcon from '../../hooks/addressIcon-hook';
import useStyles from '../../hooks/styles-hook';
import useGoogleGeocode from '../../hooks/googleGeocode-hook';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { GeocodeHelper } from '../../models/helpers/geocodeHelper';
import CustomRadioButton2 from '../common/CustomRadioButton2';

export interface AddressFormValues {
  id?: string;
  city?: string;
  postalCode?: string;
  address?: string;
  country?: string;
  department?: string;
  latitude?: number;
  longitude?: number;
  type?: MysteryShopperAddressType;
}

export interface AddressFormProps extends AddressFormValues {
  onChange(address: AddressFormValues, valid: boolean): void;
  // onDelete(id: string): void;
}

interface AddressTypeItem {
  key: number;
  enabled: boolean;
}

const addressTypeOptions = MysteryShopperAddressType;

const AddressForm = (props: AddressFormProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.address);
  const icon = useAddressIcon();
  const dispatch = useAppDispatch();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const googleGeocode = useGoogleGeocode();
  const [id, setId] = useState<string>(props.id);
  const [latitude, setLatitude] = useState<number>();
  const [longitude, setLongitude] = useState<number>();
  const [listComponent, setListComponent] = useState<AddressTypeItem[]>([]);
  const [selectedType, setSelectedType] = useState<MysteryShopperAddressType>(props.type ?? MysteryShopperAddressType.Home);
  const [address, setAddress] = useState<string>(props.address);
  const [city, setCity] = useState<string>(props.city);
  const [postalCode, setPostalCode] = useState<string>(props.postalCode);
  const [province, setProvince] = useState<string>(props.department);
  const [country, setCountry] = useState<string>(props.country);
  const [addressValidationMessage, setAddressValidationMessage] = useState<string>();
  const [countryValidationMessage, setCountryValidationMessage] = useState<string>();
  const [provinceValidationMessage, setProvinceValidationMessage] = useState<string>();
  const [postalCodeValidationMessage, setPostalCodeValidationMessage] = useState<string>();
  const [cityValidationMessage, setCityValidationMessage] = useState<string>();

  useEffect(() => {
    setCity(props.city ?? '');
    setCityValidationMessage(undefined);
  }, [props.city]);

  useEffect(() => {
    setId(props.id);
  }, [props.id]);

  useEffect(() => {
    setPostalCode(props.postalCode ?? '');
  }, [props.postalCode]);

  useEffect(() => {
    setCountry(props.country ?? '');
  }, [props.country]);

  useEffect(() => {
    setAddress(props.address ?? '');
  }, [props.address]);

  useEffect(() => {
    setProvince(props.department ?? '');
  }, [props.department]);

  useEffect(() => {
    setSelectedType(props.type ?? MysteryShopperAddressType.Home);
  }, [props.type]);

  useEffect(() => {
    setLatitude(undefined);
    setLongitude(undefined);
  }, [postalCode, city]);

  const handleTypeChange = (checked: boolean, value: number) => {
    setSelectedType(value);
  };

  const handleUseMyLocationClick = () => {
    googleGeocode(userPrefs.currentLocation, (results: any) => {
      setLatitude(userPrefs.currentLocation.lat);
      setLongitude(userPrefs.currentLocation.lng);

      if (Platform.OS === 'web') {
        setAddress(results.streetNumber && results.route ? `${results.streetNumber} ${results.route}` : undefined);
        setCity(results.location);
        setPostalCode(results.postalCode);
        setProvince(results.province);
        setCountry(results.country);
      } else {
        setAddress(GeocodeHelper.getAddress(results));
        setCity(GeocodeHelper.getCity(results));
        setPostalCode(GeocodeHelper.getPostalCode(results));
        setProvince(GeocodeHelper.getProvince(results));
        setCountry(GeocodeHelper.getCountry(results));
      }
    });
  };

  useEffect(() => {
    const addressesTypes: AddressTypeItem[] = [];

    for (const value in addressTypeOptions) {
      if (Object.prototype.hasOwnProperty.call(addressTypeOptions, value)) {
        const element = addressTypeOptions[value];

        if (parseInt(element) === MysteryShopperAddressType.Current) {
          continue;
        }

        if (typeof element === 'number') {
          addressesTypes.push({ key: element, enabled: element === (selectedType?.valueOf() ?? MysteryShopperAddressType.Home) });
        }
      }
    }

    setListComponent(addressesTypes);
  }, []);

  useEffect(() => {
    for (const component in listComponent) {
      if (Object.prototype.hasOwnProperty.call(listComponent, component)) {
        const element = listComponent[component];

        element.enabled = element.key === (selectedType?.valueOf() ?? MysteryShopperAddressType.Home);
      }
    }

    setListComponent((prev) => prev.slice());
  }, [selectedType]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(
        {
          id,
          city,
          address,
          department: province,
          country,
          postalCode,
          latitude,
          longitude,
          type: selectedType,
        },
        !addressValidationMessage && !cityValidationMessage && !postalCodeValidationMessage && !provinceValidationMessage && !cityValidationMessage
      );
    }
  }, [
    selectedType,
    city,
    postalCode,
    province,
    country,
    address,
    addressValidationMessage,
    cityValidationMessage,
    postalCodeValidationMessage,
    provinceValidationMessage,
    cityValidationMessage,
  ]);

  return (
    <ScrollView>
      <View style={styles['locationForm.container']}>
        <Label style={styles['locationForm.label']}>{t('address')}</Label>
        <CustomTextInput
          placeholder=""
          value={address}
          debounceBack={300}
          validateOnBlur
          required
          onValidationChange={(v) => (v.valid ? setAddressValidationMessage(undefined) : setAddressValidationMessage(v.message))}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setAddress(text);
          }}
        />
        <Label style={[styles['locationForm.label'], styles['locationForm.label.second']]}>{t('city')}</Label>
        <CustomTextInput
          placeholder=""
          value={city}
          debounceBack={300}
          validateOnBlur
          required
          onValidationChange={(v) => (v.valid ? setCityValidationMessage(undefined) : setCityValidationMessage(v.message))}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setCity(text);
          }}
        />
        <Label style={[styles['locationForm.label'], styles['locationForm.label.second']]}>{t('postal_code')}</Label>
        <CustomTextInput
          placeholder=""
          value={postalCode}
          debounceBack={300}
          validateOnBlur
          required
          onValidationChange={(v) => (v.valid ? setPostalCodeValidationMessage(undefined) : setPostalCodeValidationMessage(v.message))}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setPostalCode(text);
          }}
        />
        <Label style={[styles['locationForm.label'], styles['locationForm.label.second']]}>{t('province')}</Label>
        <CustomTextInput
          placeholder=""
          value={province}
          debounceBack={300}
          validateOnBlur
          required
          onValidationChange={(v) => (v.valid ? setProvinceValidationMessage(undefined) : setProvinceValidationMessage(v.message))}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setProvince(text);
          }}
        />
        <Label style={[styles['locationForm.label'], styles['locationForm.label.second']]}>{t('country')}</Label>
        <CustomTextInput
          placeholder=""
          value={country}
          debounceBack={300}
          validateOnBlur
          required
          onValidationChange={(v) => (v.valid ? setCountryValidationMessage(undefined) : setCountryValidationMessage(v.message))}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setCountry(text);
          }}
        />
        {userPrefs.currentLocation && (
          <Pressable onPress={handleUseMyLocationClick} style={{ alignSelf: 'flex-end', marginTop: 4 }}>
            <Label variant={LabelType.caption}>{t('use_my_current_location')}</Label>
          </Pressable>
        )}
      </View>

      {listComponent.map((a, i) => {
        return (
          <CustomRadioButton2
            key={`chk_${a.key}_${i}`}
            checked={a.key === selectedType}
            style={{ marginBottom: 8 }}
            onPress={(e) => {
              if (e) {
                handleTypeChange(e, a.key);
              }
            }}
          >
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              {icon(a.key)}
              <Label style={{ color: 'black' }}>{t(addressTypeOptions[a.key].toLowerCase())}</Label>
            </View>
          </CustomRadioButton2>
        );
      })}
    </ScrollView>
  );
};

export default AddressForm;

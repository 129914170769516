import { NamedStyles } from 'react-dom';
import { Platform } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useMapControlStyles = () => {
  const theme = useCustomTheme();

  let mapControl = {
    container: {
      position: 'absolute' as any,
      bottom: 12,
      right: 0,
      padding: 16,
    },
    roundButton: {
      width: 44,
      height: 44,
      backgroundColor: theme.mapControls,
      borderRadius: 22,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
      marginTop: 8,
      opacity: 0.7,
      marginBottom: Platform.OS !== 'web' ? 40 : 0,
    },
    roundButtonGroupedBase: {
      alignSelf: 'center' as any,
      //   width: 44,
      height: 88,
      backgroundColor: theme.mapControls,
      borderRadius: 22,
      alignItems: 'center' as any,
      justifyContent: 'space-between' as any,
      marginTop: 8,
      opacity: 0.7,
    },
    roundButtonGrouped: {
      iconBase: {
        paddingVertical: 13,
        paddingHorizontal: 8,
      },
      icon: {
        bottomBorder: {
          borderBottomWidth: 1,
          borderBottomColor: 'white',
          borderBottomStyle: 'solid' as any,
        },
      },
    },
    image: {
      width: 24,
      height: 24,
      resizeMode: 'contain' as any,
    },
    info: {
      position: 'absolute' as any,
      bottom: 0,
      left: 0,
      margin: 16,
    },
  };

  return mapControl as typeof mapControl;
};

export default useMapControlStyles;

import React, { useEffect, useState } from 'react';
import { Platform, Pressable, View } from 'react-native';
import Label, { LabelType } from '../../common/Label';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import useCustomTheme from '../../../hooks/theme-hook';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import ProfileModal from '../myprofile/ProfileModal';
import useTranslation from '../../../hooks/translation-hook';
import { useMutation, useQuery } from '@tanstack/react-query';
import { GetTransactionListFilter, MysteryShopperClient, TransactionsClient } from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import { useNavigation } from '@react-navigation/native';
import Loading from '../../common/Loading';
import { myAccountActions } from '../../../store/myAccountSlice';

const SettingsDeleteAccount = () => {
  const theme = useCustomTheme();
  const dispatch = useAppDispatch();
  const authStore = useAppSelector((selector) => selector.auth);
  const apiTransactions = useApi(TransactionsClient);
  const apiMs = useApi(MysteryShopperClient);
  const t = useTranslation();
  const navigation = useNavigation();

  const [fullDelete, setFullDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const myTransactions = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['myTransactionsOnDelete', authStore.idToken],
    queryFn: ({ signal }) => {
      const promise = apiTransactions.getTransactions(undefined, 1, new GetTransactionListFilter(), apiTransactions.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiTransactions.tokenSource?.cancel('Query was cancelled by React Query');
        apiTransactions.onCancel();
      });

      return promise;
    },
  });

  const deleteAccount = useMutation({
    retry: false,
    mutationFn: () => {
      setLoading(true);
      return apiMs.deleteMysteryShopper(apiMs.tokenSource?.token);
    },
    onError: (err) => {
      setLoading(false);
      throw err;
    },
  });

  useEffect(() => {
    if (myTransactions.isSuccess) {
      if (myTransactions.data?.transactions?.items?.length > 0) {
        setFullDelete(false);
      } else {
        setFullDelete(true);
      }
    }
  }, [myTransactions.isSuccess, myTransactions.data]);

  useEffect(() => {
    if (deleteAccount.isSuccess) {
      navigation.navigate({ name: 'logout', params: { accountDeleted: true } } as never);
      dispatch(lightBoxActions.setVisibility(false));
      dispatch(myAccountActions.setVisibility(false));
    }

    if (deleteAccount.isError) {
      navigation.navigate({ name: 'logout', params: { accountDeleted: true } } as never);
      dispatch(lightBoxActions.setVisibility(false));
      dispatch(myAccountActions.setVisibility(false));
    }

    setLoading(false);
  }, [deleteAccount.isSuccess, deleteAccount.isError]);

  const handleDeleteClick = () => {
    dispatch(
      lightBoxActions.setContent(
        <ProfileModal
          showCancel
          title={t('delete-account')}
          onConfirm={() => {
            setLoading(true);
            handleConfirmDeleteClick();
          }}
          onCancel={() => {
            console.log('Cancel');
            dispatch(lightBoxActions.setVisibility(false));
          }}
        >
          <View style={{ width: Platform.OS === 'web' ? 600 : '90%' }}>
            {fullDelete && <Label style={{ marginBottom: 8 }}>{t('delete-account-full-confirm')}</Label>}
            {!fullDelete && <Label style={{ marginBottom: 8 }}>{t('delete-account-confirm')}</Label>}
          </View>
        </ProfileModal>
      )
    );

    dispatch(lightBoxActions.setVisibility(true));
  };

  const handleConfirmDeleteClick = () => {
    dispatch(
      lightBoxActions.setContent(
        <ProfileModal
          hideConfirm
          showCancel
          showDelete
          title={t('delete-account')}
          onDelete={() => {
            setLoading(true);
            dispatch(lightBoxActions.setContent(<Loading />));
            deleteAccount.mutate();
          }}
          onCancel={() => {
            console.log('Cancel');
            dispatch(lightBoxActions.setVisibility(false));
          }}
        >
          <View style={{ width: Platform.OS === 'web' ? 600 : '90%' }}>
            <Label style={{ marginBottom: 8 }}>{t('are_you_sure_delete')}</Label>
          </View>
        </ProfileModal>
      )
    );
  };

  return (
    <View style={{ ...StylesHelper.padding(16), flexDirection: 'row', justifyContent: 'flex-end' }}>
      <Pressable onPress={handleDeleteClick}>
        <Label variant={LabelType.caption} style={{ fontSize: 13, color: theme.colorErrorPrimary }}>
          {t('delete-account')}
        </Label>
      </Pressable>
    </View>
  );
};

export default SettingsDeleteAccount;

import React, { ReactNode, useEffect, useState } from 'react';
import NavigationButton from '../NavigationButton';
import { View, ScrollView } from 'react-native';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import useCustomTheme from '../../../hooks/theme-hook';
import useStyles from '../../../hooks/styles-hook';
import Label, { LabelType } from '../Label';
import useTranslation from '../../../hooks/translation-hook';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';

export interface MissionMoreInfoProps {
  title: string;
  children: JSX.Element;
  onBackPress?(): void;
}

const MissionMoreInfo = (props: MissionMoreInfoProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((sector) => sector.missionDetailCard);
  const t = useTranslation();

  const [title, setTitle] = useState('');
  const [children, setChildren] = useState(<></>);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    setChildren(props.children);
  }, [props.children]);

  const handleOnBackPress = () => {
    if (props.onBackPress) {
      props.onBackPress();
    }
  };

  return (
    <View style={[styles.missionsBranchDetail.container, { flexShrink: 1 }]}>
      <View style={styles.missionsBranchDetail.backButton}>
        <NavigationButton
          icon={faCircleChevronLeft}
          iconSize={24}
          color={theme.text.titleLight}
          labelVariant={LabelType.titleSmall}
          onPress={handleOnBackPress}
          text={t('back')}
        />
      </View>
      <ScrollView style={{ flexShrink: 1, marginBottom: 16, marginHorizontal: -12, paddingHorizontal: 12 }}>
        <View style={styles.missionsBranchDetail.content.body}>
          <View style={styles.missionsBranchDetail.content.moreInfo}>
            <Label variant={LabelType.title} style={{ color: theme.text.darkTurquoiseTwo, marginBottom: 8 }}>
              {title}
            </Label>
            {children}
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

export default MissionMoreInfo;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface MissionsState {
  missionListSeed: string;
  rejectedValidationSeed: string;
  resetMap: boolean;
  recentlyAcceptedMission: string;
  branchId: string;
  lat: number;
  lng: number;
  showOnARollPopup: boolean;
}

// Define the initial state using that type
const initialState: MissionsState = {
  missionListSeed: '12345',
  rejectedValidationSeed: '12345',
  resetMap: false,
  recentlyAcceptedMission: undefined,
  branchId: undefined,
  lat: undefined,
  lng: undefined,
  showOnARollPopup: false,
};

export const missionsSlice = createSlice({
  name: 'missions',
  initialState,
  reducers: {
    setReloadMissionListSeed: (state, action: PayloadAction<string>) => {
      state.missionListSeed = action.payload;
    },
    setReloadRejectedValidationSeed: (state, action: PayloadAction<string>) => {
      state.rejectedValidationSeed = action.payload;
    },
    setResetMap: (state, action: PayloadAction<boolean>) => {
      state.resetMap = action.payload;
    },
    setShowOnARollPopup: (state, action: PayloadAction<boolean>) => {
      state.showOnARollPopup = action.payload;
    },
    setMissionAcceptedId: (state, action: PayloadAction<string>) => {
      state.recentlyAcceptedMission = action.payload;
    },
    setSelectedBranch: (state, action: PayloadAction<{ id: string; lat: number; lng: number }>) => {
      state.branchId = action.payload.id;
      state.lat = action.payload.lat;
      state.lng = action.payload.lng;
    },
  },
});

export default missionsSlice.reducer;
export const missionsActions = missionsSlice.actions;

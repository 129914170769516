import { faCalendarClock, faEyeSlash, faStore, faThumbTack } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Image, Pressable, View } from 'react-native';
import useStyles from '../../../hooks/styles-hook';
import { MissionFilter } from '../../../models/MapModels';
import { InfoBoxProps } from '../InfoBox';
import Label, { LabelType } from '../Label';
import MissionCardLoading from './MissionCardLoading';
import pinSmallOrange from '../../../../assets/pin-small-orange.png';
import useCustomTheme, { CustomTheme } from '../../../hooks/theme-hook';
import { GeoLocation } from '../../../api/api.g';
import moment from 'moment';
import useTranslation from '../../../hooks/translation-hook';

export interface MissionDetailCardProps {
  branchId?: string;
  prof?: string;
  title?: string;
  purchaseRequired?: boolean;
  points?: number;
  tag?: string;
  startTime?: string;
  endTime?: string;
  selected?: boolean;
  // onPress(e: MissionDetailCardProps): void;
}

export interface MissionCardProps extends InfoBoxProps {
  id: string;
  geocoordinate: GeoLocation;
  address?: string;
  logoUrl?: string;
  industry: string;
  distance?: string;
  missionDetailCardProps: MissionDetailCardProps[];
  drawerVersion?: boolean;
  showAsCards?: boolean;
  selectedMission?: MissionFilter;
  loading?: boolean;
  pinned?: boolean;
  isHidden?: boolean;
  canAccept?: boolean;
  upcomingPeriod?: boolean;
  upcomingPeriodStartDate?: Date;
  onPress?(missionDetails: MissionCardProps): void;
}

const MissionCard = (props: MissionCardProps) => {
  const t = useTranslation();
  const theme: CustomTheme = useCustomTheme();
  const [address, setAddress] = useState(<></>);
  const styles = useStyles((section) => section.missionCard);
  const stylesDetail = useStyles((sector) => sector.missionDetailCard);

  const defaultLogo = useMemo(() => <FontAwesomeIcon icon={faStore} style={styles.header.logo.icon} />, [styles]);
  const [logo, setLogo] = useState<ReactNode>(defaultLogo);
  const [drawerVersion, setDrawerVersion] = useState(props.drawerVersion);
  const [showAsCard, setShowAsCard] = useState(props.showAsCards);
  const [selected, setSelected] = useState(false);
  const [selectedMission, setSelectedMission] = useState(props.selectedMission);
  const [loading, setLoading] = useState(props.loading);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    setSelectedMission(props.selectedMission);

    if (props.selectedMission) {
      setSelected(props.id === props.selectedMission.branchId);
    }
  }, [props.selectedMission]);

  useEffect(() => {
    setDrawerVersion(props.drawerVersion);
  }, [props.drawerVersion]);

  useEffect(() => {
    setShowAsCard(props.showAsCards);
  }, [props.showAsCards]);

  useEffect(() => {
    if (props.address) {
      setAddress(<Label variant={LabelType.body1}>{props.address}</Label>);
    } else {
      setAddress(<></>);
    }
  }, [props.address]);

  useEffect(() => {
    if (props.logoUrl) {
      setLogo(<Image style={styles.header.logo.image} source={{ uri: props.logoUrl }} />);
    } else {
      setLogo(defaultLogo);
    }
  }, [props.logoUrl]);

  return (
    <Pressable
      {...props}
      onPress={(e) => {
        // console.log('Mission clicked', props.id);

        if (props.onPress) {
          props.onPress(props);
        }
      }}
      style={[
        styles.containerBase,
        drawerVersion ? styles.container.drawer : {},
        showAsCard ? styles.container.card : {},
        selected ? styles.container.selected : {},
        props.pinned ? styles.container.pinned : {},
      ]}
    >
      {loading && <MissionCardLoading />}
      <View style={[styles.cardBase, { marginBottom: 8, opacity: props.canAccept ? 1 : 0.4 }]}>
        <View style={styles.header.logoBase}>{logo}</View>
        <View style={styles.info}>
          <View style={{ flexDirection: 'row', alignItems: 'center' }}>
            <View style={{ flexGrow: 1, flexShrink: 1 }}>
              <View style={styles.missionName}>
                <Label variant={LabelType.title}>{`${props.title}`}</Label>
                {props.isHidden && <FontAwesomeIcon icon={faEyeSlash} style={styles.missionHiddenIcon} />}
              </View>
              {address}
            </View>
            {props.pinned && <FontAwesomeIcon size={18} color={theme.text.darkTurquoiseTwo.toString()} icon={faThumbTack} />}
          </View>
          <View style={styles.preTitle}>
            <Label variant={LabelType.caption}>{props.industry.length === 0 ? 'N/A' : props.industry}</Label>
            <Label style={{ flexShrink: 0 }} variant={LabelType.caption}>
              {props.distance}
            </Label>
          </View>
        </View>
      </View>
      {props.upcomingPeriod && (
        <View key={`upcomingMissionInfo_${props.id}`} style={stylesDetail.missionsBranchDetail.content.upcoming.container}>
          <View style={stylesDetail.missionsBranchDetail.content.upcoming.date.container}>
            <FontAwesomeIcon
              icon={faCalendarClock}
              style={stylesDetail.missionsBranchDetail.content.upcoming.date.icon}
              color={theme.indicatorColor.toString()}
            />
            <Label variant={LabelType.caption} style={{ color: theme.indicatorColor }}>
              {t('starting-on')}
            </Label>
          </View>
          <Label variant={LabelType.caption} style={{ color: theme.indicatorColor, marginLeft: 4 }}>
            {moment(props.upcomingPeriodStartDate).format('YYYY-MM-DD')}
          </Label>
        </View>
      )}
      {props.missionDetailCardProps &&
        false &&
        props.missionDetailCardProps.map((item, index) => {
          return (
            <View key={`missionDetail${index}`} style={[stylesDetail.missionsBranchDetail.content.infoContainerBase, { marginBottom: 4 }]}>
              <View style={stylesDetail.missionsBranchDetail.content.infoContainer.title}>
                <Image source={pinSmallOrange} style={stylesDetail.missionsBranchDetail.content.infoContainer.icon} />
                <Label variant={LabelType.titleSmall}>{item.title}</Label>
              </View>
            </View>
          );
        })}
    </Pressable>
  );
};

export default MissionCard;

import { ColorValue } from 'react-native';
import { Themes } from '../styles/theme';

export interface CustomTheme {
  primary?: ColorValue;
  secondary?: ColorValue;
  secondaryLight?: ColorValue;
  mapControls?: ColorValue;
  background?: {
    secondary?: ColorValue;
    primary?: ColorValue;
    primaryLight?: ColorValue;
    modal?: ColorValue;
    navBar?: ColorValue;
    missionCard?: ColorValue;
    darkTurquoise?: ColorValue;
    missionDetail?: ColorValue;
    missionDetailSummary?: ColorValue;
    sea?: ColorValue;
    lightSea?: ColorValue;
  };
  text?: {
    primary?: ColorValue;
    inverse?: ColorValue;
    titleDark?: ColorValue;
    titleLight?: ColorValue;
    buttonCancel?: ColorValue;
    buttonConfirm?: ColorValue;
    darkTurquoiseTwo?: ColorValue;
    navItem?: ColorValue;
    navItemSelected?: ColorValue;
    sea?: ColorValue;
    error?: ColorValue;
  };
  menu?: {
    primary?: ColorValue;
    active?: ColorValue;
  };
  infoCell?: ColorValue;
  colorErrorPrimary?: ColorValue;
  colorErrorPrimaryBackground?: ColorValue;
  borderColor?: ColorValue;
  borderColorLight?: ColorValue;
  inputTextPlaceholder?: ColorValue;
  overlayColor?: ColorValue;
  indicatorColor?: ColorValue;
  timelineIcon?: ColorValue;
}

export const lightTheme: CustomTheme = {
  primary: '#f5a523',
  secondary: '#ECF6F5',
  secondaryLight: '#43a49e',
  mapControls: '#025164',
  background: {
    primary: 'white',
    primaryLight: 'white',
    secondary: '#f5f5f5',
    modal: '#e9f5f3',
    missionCard: '#e9f5f3',
    navBar: '#efefef',
    darkTurquoise: '#035164',
    missionDetail: '#ebf4f3',
    missionDetailSummary: '#d9eae7',
    sea: '#43a49e',
    lightSea: '#e9f5f3',
  },
  text: {
    primary: 'black',
    inverse: 'white',
    titleDark: '#025164',
    titleLight: '#43a49e',
    buttonCancel: 'black',
    buttonConfirm: '#43a49e',
    darkTurquoiseTwo: '#035164',
    navItem: '#1a1818',
    navItemSelected: '#43a49e',
    sea: '#43a49e',
    error: '#8e183d',
  },
  menu: {
    primary: 'white',
    active: '#f5a523',
  },
  infoCell: 'white',
  colorErrorPrimary: '#8e183d',
  colorErrorPrimaryBackground: '#ffffff',
  borderColor: '#b6b6b6',
  borderColorLight: '#e9e9e9',
  inputTextPlaceholder: '#989898',
  overlayColor: '#03516484',
  indicatorColor: '#EE6723',
  timelineIcon: 'white',
};

const useCustomTheme = (): CustomTheme => {
  const theme =
    Themes.getInstance() ??
    Themes.createTheme({
      light: lightTheme,
      dark: {
        ...lightTheme,
        secondary: '#4c857f',
        background: {
          ...lightTheme.background,
          primary: '#1a1a1a',
          primaryLight: '#242424',
          secondary: 'black',
          modal: '#e9f5f3',
          missionCard: '#1a1a1a',
          navBar: 'black',
          missionDetail: '#035164',
          missionDetailSummary: '#024252',
          sea: '#43a49e',
          lightSea: '#e9f5f3',
        },
        text: {
          ...lightTheme.text,
          primary: 'white',
          inverse: 'black',
          titleDark: '#e0fffd',
          titleLight: '#65dbd4',
          buttonCancel: '#ffcc85',
          buttonConfirm: 'white',
          darkTurquoiseTwo: '#43a49e',
          navItem: 'white',
        },
        infoCell: 'black',
        borderColorLight: '#666',
        timelineIcon: 'black',
        colorErrorPrimary: '#ffffff',
        colorErrorPrimaryBackground: '#8e183d',
      },
    });

  return theme;
};

export default useCustomTheme;

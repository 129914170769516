import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useAddressStyles = () => {
  const theme = useCustomTheme();

  let address = {
    formContainer: {},
    useMyLocation: {
      ...StylesHelper.padding(24, 0),
    },
    ['useMyLocation.button']: { textTransform: 'uppercase' },
    ['locationForm.container']: { marginBottom: 24 },
    ['locationForm.label']: {
      marginBottom: 8,
    },
    ['locationForm.label.second']: { marginTop: 16 },
    deleteButton: {
      borderRadius: 50,
      paddingTop: 12,
      paddingRight: 20,
      paddingBottom: 12,
      paddingLeft: 21,
      justifyContent: 'center' as any,
      alignItems: 'center' as any,
      backgroundColor: theme.colorErrorPrimary,
      marginTop: 16,
    },
  };

  return address as typeof address;
};

export default useAddressStyles;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TopBarState {
  visible: boolean;
}

// Define the initial state using that type
const initialState: TopBarState = {
  visible: true,
};

export const topBarSlice = createSlice({
  name: 'topbar',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
  },
});

export default topBarSlice.reducer;
export const topBarActions = topBarSlice.actions;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import { useFocusEffect } from '@react-navigation/native';
import SettingsNotifications from '../components/myaccount/settings/SettingsNotifications';
import { Platform, Pressable, View, Image } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { userPrefsActions } from '../store/userPrefsSlice';
import Label, { LabelType } from '../components/common/Label';
import { getLocales } from 'expo-localization';
import { StylesHelper } from '../models/helpers/stylesHelper';
import useCustomTheme from '../hooks/theme-hook';
import useTranslation from '../hooks/translation-hook';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faGlobePointer } from '@fortawesome/pro-regular-svg-icons';
import Loading from '../components/common/Loading';

let timeoutId;

const UnsubscribeNotifications = ({ navigation }) => {
  const dispatch = useAppDispatch();
  const authStore = useAppSelector((selector) => selector.auth);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const theme = useCustomTheme();
  const t = useTranslation();

  const [authToken, setAuthToken] = useState<string>();
  const [settingsComponent, setSettingsComponent] = useState(<></>);
  const [formSaved, setFormSaved] = useState(false);
  const [loading, setLoading] = useState(true);

  useFocusEffect(
    React.useCallback(() => {
      let params = new URLSearchParams(document.location.search);

      if (!params.has('token')) {
        navigation.navigate({ name: 'entryPage', params: {} } as never);
      }

      setAuthToken(params.get('token'));

      setSettingsComponent(
        <SettingsNotifications noWrapper authToken={authToken} onSaveSettings={settingsSaved} onLoad={onLoadSettings} />
      );
    }, [authStore.logged])
  );

  useEffect(() => {
    if (navigation.getState().index === 0) {
      setDefaultLanguageAndTheme();
    } else {
      dispatch(
        userPrefsActions.setBasicInfo({
          firstName: undefined,
          lastName: undefined,
          uiLanguage: userPrefs.uiLanguage,
          email: '',
          theme: undefined,
          languages: userPrefs.languages,
        })
      );
    }
  }, []);

  useEffect(() => {
    setSettingsComponent(<SettingsNotifications noWrapper authToken={authToken} onSaveSettings={settingsSaved} onLoad={onLoadSettings} />);
  }, [userPrefs.uiLanguage]);

  const getLocale = () => {
    const locales = getLocales();
    return locales[0].languageCode;
  };

  const settingsSaved = () => {
    setFormSaved(true);

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setFormSaved(false);
    }, 3000);
  };

  const onLoadSettings = () => {
    setLoading(false);
  };

  const setDefaultLanguageAndTheme = () => {
    const language = Platform.OS === 'web' ? navigator.language : getLocale();

    if (!userPrefs.uiLanguage) {
      const newLanguages = [language.indexOf('-') === -1 ? language : language.substring(0, language.indexOf('-'))];
      dispatch(userPrefsActions.setLanguages(newLanguages));
      dispatch(userPrefsActions.setUiLanguage(newLanguages[0]));
    }
  };

  const getOtherLanguageButton = () => {
    switch (userPrefs.uiLanguage) {
      case 'en':
        return (
          <Pressable
            onPress={() => {
              dispatch(userPrefsActions.setUiLanguageAndLanguages('fr'));
            }}
          >
            <Label variant={LabelType.titleWhiteSmall}>FR</Label>
          </Pressable>
        );
      default:
        return (
          <Pressable
            onPress={() => {
              dispatch(userPrefsActions.setUiLanguageAndLanguages('en'));
            }}
          >
            <Label variant={LabelType.titleWhiteSmall}>EN</Label>
          </Pressable>
        );
    }
  };

  return (
    <LinearGradient end={{ x: 0.1, y: 0.4 }} style={{ height: '100%', width: '100%' }} colors={['#022d38', '#034050', '#044c5e', '#04647c', '#43a49e']}>
      <View style={{ ...StylesHelper.padding(48), display: 'flex', flexDirection: 'column', width: '100%' }}>
        <>
          <Image style={{ position: 'absolute', top: 42, left: 48, width: 85, height: 40 }} source={require('../../assets/login-page-logo.svg')} />
          <View style={{ width: 'auto', position: 'relative', alignSelf: 'flex-end' }}>{getOtherLanguageButton()}</View>
          <View style={{ ...StylesHelper.padding(16), ...StylesHelper.borderRadius(8), backgroundColor: theme.background.primary, marginTop: 32 }}>
            <Label variant={LabelType.titleDark} style={{ marginBottom: 24 }}>
              {t('Notifications')}
            </Label>
            {loading && <Loading />}
            {settingsComponent}
            {formSaved && <Label variant={LabelType.infoBoxTitle}>{t('Notifications settings were saved.')}</Label>}
            <Pressable
              style={{ width: 'auto', position: 'relative', alignSelf: 'flex-end', flexDirection: 'row', alignItems: 'center' }}
              onPress={() => {
                navigation.navigate({ name: 'login', params: {} } as never);
              }}
            >
              <FontAwesomeIcon icon={faGlobePointer} color={theme.text.titleLight.toString()} />
              <Label variant={LabelType.viewDetails} style={{ marginLeft: 4 }}>
                {t('login_title')}
              </Label>
            </Pressable>
          </View>
        </>
      </View>
    </LinearGradient>
  );
};

export default UnsubscribeNotifications;

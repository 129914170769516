import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useInfoboxStyles = () => {
  const theme = useCustomTheme();
  let infoBox = {
    container: {
      backgroundColor: theme.background.missionDetail.toString(),
      ...StylesHelper.borderRadius(5),
      ...StylesHelper.padding(12)
    },
    title: {
      display: 'flex' as any,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
    },
    icon: {
      color: theme.text.titleDark.toString(),
      width: 20,
      height: 20,
      marginRight: 4,
    },
  };

  return infoBox as typeof infoBox;
};
export default useInfoboxStyles;

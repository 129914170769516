import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { MissionCardProps, MissionDetailCardProps } from '../../components/common/mission/MissionCard';
import { faForkKnife, faQuestion, faShield } from '@fortawesome/pro-regular-svg-icons';
import { GetMissionsFromMapDto } from '../../api/api.g';

export class MissionsHelper {
  public static mapToMissionCard(m: GetMissionsFromMapDto, missionPress: (e: MissionCardProps) => void, remoteMission: boolean = false): MissionCardProps {
    return {
      id: remoteMission ? `${m.branchId}` : m.branchId, 
      geocoordinate: m.geocoordinate,
      title: m.name,
      logoUrl: m.hasLogo ? m.logoUrl : undefined,
      address: m.address,
      industry: m.tags,
      isHidden: m.isHidden,
      canAccept: m.canAccept,
      upcomingPeriod: m.upcomingPeriod,
      upcomingPeriodStartDate: m.upcomingPeriodStartDate,
      distance: !remoteMission && m.distance !== undefined ? `${(m.distance / 1000.0).toFixed(2)}km` : undefined,
      onPress: missionPress,
      missionDetailCardProps: m.missionConfigurationNames.map((x) => {
        return { title: x };
      }),
    };
  }

  public static getMissionIcon(tag?: any): { icon: string; faIcon: IconDefinition } {
    if (!tag) {
      return {
        icon: undefined,
        faIcon: undefined,
      };
    }

    switch (tag.tagDescription) {
      case 'Assurance':
        return {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M256 49.4L73 127c-5.9 2.5-9.1 7.8-9 12.8c.5 91.4 38.4 249.3 186.4 320.1c3.6 1.7 7.8 1.7 11.3 0C409.7 389 447.6 231.2 448 139.7c0-5-3.1-10.2-9-12.8L256 49.4zM269.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0s9.2 1 13.4 2.9z"/></svg>`,
          faIcon: faShield,
        };
      case 'Restaurants':
        return {
          icon: `<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M475.3 27.3c5.8-5.8 6.3-15.1 1.1-21.5S462-1.9 455.1 2.7l-106.5 71C320.8 92.3 304 123.6 304 157.1c0 19.3 5.5 37.9 15.7 53.9L73.4 457.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L365 256.3c16 10.2 34.7 15.7 53.9 15.7c33.5 0 64.8-16.8 83.4-44.6l71-106.5c4.6-6.8 3.2-16-3.2-21.2s-15.6-4.8-21.5 1.1L457.4 192c-5.2 5.2-13.6 5.2-18.8 0c-4.9-4.9-5.2-12.8-.6-18.1l86.1-99.4c5.5-6.3 5.2-15.9-.8-21.8s-15.4-6.3-21.8-.8L402.1 138c-5.3 4.6-13.2 4.3-18.1-.6c-5.2-5.2-5.2-13.6 0-18.8l91.3-91.3zM52.5 7.3C47.9 2.6 41.5 0 34.9 0c-11.2 0-21 7.5-23.5 18.4C6.7 38.6 0 71.5 0 96c0 83.3 48.2 130.5 128.9 209.4c6.5 6.4 13.3 13 20.3 19.9c1.9 1.8 3.8 3.5 5.9 5.1L265.4 220.1 52.5 7.3zM457.4 502.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L355.9 310.6l-45.3 45.3L457.4 502.6z"/></svg>`,
          faIcon: faForkKnife,
        };
      case undefined:
        return {
          icon: undefined,
          faIcon: undefined,
        };
    }
  }
}

import { useEffect } from 'react';
import { StyleProp, StyleSheet } from 'react-native';
import { NamedStyles } from 'react-dom';
import useCustomButtonStyles from '../styles/customButtonStyle';
import useGeneralStyles from '../styles/generalStyle';
import useCustomMapStyles from '../styles/customMapStyle';
import usePillButtonStyles from '../styles/pillButtonStyle';
import useCustomTextInputStyles from '../styles/customInputTextStyle';
import useDrawerStyles from '../styles/drawerStyles';
import useLabelStyles from '../styles/labelStyle';
import useMapControlStyles from '../styles/mapControlStyles';
import useCustomCheckboxStyles from '../styles/customCheckboxStyle';
import useCustomRadioButtonStyles from '../styles/customRadioButtonStyle';
import useNavBarStyles from '../styles/navBarStyles';
import useNotificationStyles from '../styles/notificationStyle';
import useRemoteMissionsStyles from '../styles/remoteMissionStyle';
import useMyAccountStyles from '../styles/myAccountStyle';
import useOverlayStyles from '../styles/overlayStyle';
import useAddressStyles from '../styles/addressStyles';
import useSettingsOptionsStyles from '../styles/settingsOptionsStyle';
import useValidationStyles from '../styles/validationStyles';
import useMissionCardStyles from '../styles/missionCardStyles';
import useMissionDetailCardStyles from '../styles/missionDetailCardStyles';
import { useAppSelector } from './store-hook';
import useMyMissionStyles from '../styles/myMissionStyle';
import useRestrictedAreaStyles from '../styles/restrictedAreaStyles';
import useCustomMarkdownStyles from '../styles/customMarkdownStyle';
import useMoreInfoButtonStyles from '../styles/moreInfoButtonStyle';
import useInfoboxStyles from '../styles/infoBoxStyle';
import myRewardsStyles from '../styles/myRewardsStyle';
import useCustomDatePickerStyles from '../styles/customDatePickerStyle';

const useStylesDefinition = () => {
  const customButton = useCustomButtonStyles();
  const general = useGeneralStyles();
  const customMap = useCustomMapStyles();
  const pillButton = usePillButtonStyles();
  const customTextInput = useCustomTextInputStyles();
  const customDatePicker = useCustomDatePickerStyles();
  const drawer = useDrawerStyles();
  const label = useLabelStyles();
  const mapControl = useMapControlStyles();
  const checkbox = useCustomCheckboxStyles();
  const radioButton = useCustomRadioButtonStyles();
  const navBar = useNavBarStyles();
  const notification = useNotificationStyles();
  const remoteMissions = useRemoteMissionsStyles();
  const myAccount = useMyAccountStyles();
  const overlay = useOverlayStyles();
  const address = useAddressStyles();
  const settingsOptions = useSettingsOptionsStyles();
  const validation = useValidationStyles();
  const missionCard = useMissionCardStyles();
  const missionDetailCard = useMissionDetailCardStyles();
  const myMissions = useMyMissionStyles();
  const restrictedArea = useRestrictedAreaStyles();
  const customMarkdown = useCustomMarkdownStyles();
  const moreInfoButton = useMoreInfoButtonStyles();
  const infoBox = useInfoboxStyles();
  const myRewards = myRewardsStyles();

  let styles = {
    general,
    customMap,
    customButton,
    pillButton,
    customTextInput,
    drawer,
    label,
    mapControl,
    checkbox,
    radioButton,
    navBar,
    notification,
    remoteMissions,
    myAccount,
    overlay,
    address,
    settingsOptions,
    validation,
    missionCard,
    missionDetailCard,
    myMissions,
    restrictedArea,
    customMarkdown,
    moreInfoButton,
    infoBox,
    myRewards,
    customDatePicker
  };

  return styles;
};

const useStyles = <R>(section: (x: ReturnType<typeof useStylesDefinition>) => R): R => {
  const stylesDefinitions = useStylesDefinition();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  let styles = StyleSheet.create(section(stylesDefinitions));

  useEffect(() => {
    styles = StyleSheet.create(section(stylesDefinitions));
  }, [stylesDefinitions, userPrefs.theme]);

  return styles;
};

export default useStyles;

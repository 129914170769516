import useCustomTheme from '../hooks/theme-hook';

const useGeneralStyles = () => {
  const theme = useCustomTheme();
  let general = {
    containerBase: {
      flex: 1,
      flexDirection: 'column' as any,
      backgroundColor: theme.background.primary,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
    },
    container: {
      child: {
        marginTop: 8,
        marginBottom: 8,
      },
    },
    title: {
      fontSize: 14,
      color: theme.text.primary,
    },
  };

  return general as typeof general;
};
export default useGeneralStyles;

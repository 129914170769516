import React, { useEffect, useState } from 'react';
import { useRoute } from '@react-navigation/core';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import { navBarActions } from '../store/navBarSlice';
import Label from '../components/common/Label';
import { Pressable, View } from 'react-native';
import { LoggedState } from '../models/AuthModelsCommon';
import LoggedInRestrictedArea from '../components/common/LoggedInRestrictedArea';
import useTranslation from '../hooks/translation-hook';
import ErrorBoundary from 'react-native-error-boundary';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import AuthGuard from '../components/common/AuthGuard';
import { useQueryClient } from '@tanstack/react-query';
import ValidationList from '../components/validation/ValidationList';
import { AnalyticsHelper } from '../helpers/analyticsHelper';

const ValidationErrorFallback = (props) => {
  return (
    <View>
      <Label>Something went wrong:</Label>
      <Label>{props.error.message}</Label>
      <Pressable onPress={props.resetError}>
        <Label>Try again</Label>
      </Pressable>
    </View>
  );
};

const Validation = () => {
  const t = useTranslation();
  const route = useRoute();
  const dispatch = useAppDispatch();
  const authStore = useAppSelector((selector) => selector.auth);

  const [showModal, setShowModal] = useState(false);
  const [navigationEnd, setNavigationEnd] = useState(false);

  const navigation = useNavigation();
  const queryClient = useQueryClient();

  useFocusEffect(
    React.useCallback(() => {
      if (authStore.logged !== undefined && authStore.logged !== LoggedState.NotLogged) {
        console.log('%cNavigation end', 'color: #ffa600;');

        AnalyticsHelper.logScreenView(route.name).then(() => {
          console.log('Analytics', 'Page view: Validation');
        });

        setNavigationEnd(true);
        setShowModal(authStore.logged === LoggedState.Anonymous);
      } else {
        setNavigationEnd(false);
      }
    }, [authStore.logged])
  );

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      console.log(`%cOnBlur - ${route.name}`, 'color: #ffa600;');
      queryClient.cancelQueries({ queryKey: ['peerReviews'] });
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    if (route) {
      dispatch(navBarActions.setPath(route.name));
    }
  }, [route]);

  return (
    <AuthGuard>
      {showModal && <LoggedInRestrictedArea preloaded={false} message={t('need-login-before', { action: t('start_validations').toLocaleLowerCase() })} />}
      {!showModal && (
        <ErrorBoundary FallbackComponent={ValidationErrorFallback}>
          <ValidationList />
        </ErrorBoundary>
      )}
    </AuthGuard>
  );
};

export default Validation;

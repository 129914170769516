import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { View, Image, Pressable, ScrollView, Keyboard, Platform } from 'react-native';
import useCustomTheme from '../../../hooks/theme-hook';
import Label from '../../common/Label';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../../hooks/store-hook';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import useTranslation from '../../../hooks/translation-hook';
import Loading from '../../common/Loading';
import { platform } from 'os';

export interface ProfileModalProps {
  onConfirm?(): void;
  onClose?(): void;
  title?: string;
  subtitle?: string;
  loading?: boolean;
  children?: ReactElement;
  showCancel?: boolean;
  onCancel?(): void;
  showDelete?: boolean;
  onDelete?(): void;
  width?: number;
  hideConfirm?: boolean;
  maxPageNumber?: number;
  pageNumber?: number;
  height?: number;
}

const ProfileModal = (props: ProfileModalProps) => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const scrollViewRef = useRef(null);
  const [flashCount, setFlashCount] = useState(0);

  const handleOnClosePress = () => {
    Keyboard.dismiss();
    if (props.onClose) {
      props.onClose();
    } else {
      dispatch(lightBoxActions.setVisibility(false));
    }
  };

  const handleOnConfirm = () => {
    Keyboard.dismiss();
    props.onConfirm && props.onConfirm();
  };

  const handleOnCancel = () => {
    Keyboard.dismiss();
    props.onCancel && props.onCancel();
  };

  const handleOnDelete = () => {
    Keyboard.dismiss();
    props.onDelete && props.onDelete();
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (scrollViewRef.current && flashCount < 3) {
        scrollViewRef.current.flashScrollIndicators();
        setFlashCount((prevCount) => prevCount + 1);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [flashCount]);

  return (
    <ScrollView
      ref={scrollViewRef}
      keyboardShouldPersistTaps={'handled'}
      contentContainerStyle={{ padding: 16, flexGrow: 1 }}
      style={{
        position: 'relative',
        width: Platform.OS === 'web' ? undefined : 340,
        minWidth: 350,
        maxWidth: props.width ? props.width : Platform.OS === 'web' ? 600 : 350,
        maxHeight: '100%',
        backgroundColor: theme.background.modal,
        flexGrow: 0,
        height: props.height != undefined ? props.height : 'auto',
      }}
    >
      {props.loading && (
        <View style={{ position: 'absolute', top: 0, right: 0, zIndex: 2000, width: '100%', height: '100%', backgroundColor: 'rgba(233, 245, 243, 0.8)' }}>
          <Loading />
        </View>
      )}
      <Image style={{ position: 'absolute', top: -1, right: -1, width: 116, height: 102 }} source={require('../../../../assets/questionBubble.png')} />
      <Pressable style={{ position: 'absolute', top: 10, right: 10 }} onPress={handleOnClosePress}>
        <FontAwesomeIcon icon={faXmark} size={20} style={{ color: 'white' }} />
      </Pressable>
      <Label style={{ width: 185, fontSize: 18, fontWeight: '600', color: theme.text.darkTurquoiseTwo }} numberOfLines={5}>
        {props.title}
      </Label>
      <Label style={{ width: 220, fontSize: 14, marginTop: 16 }} numberOfLines={5}>
        {props.subtitle}
      </Label>
      <View style={{ flexGrow: 1, marginTop: 16 }}>{props.children}</View>
      <View style={{ marginTop: 16, flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        {props.showCancel && (
          <Pressable
            style={{
              paddingHorizontal: 12,
              paddingVertical: 12,
              borderRadius: 100,
              borderColor: theme.background.darkTurquoise,
              borderWidth: 2,
              marginRight: 12,
            }}
            onPress={handleOnCancel}
          >
            <Label style={{ fontSize: 14, fontWeight: '600', color: theme.background.darkTurquoise }}>{t('cancel')}</Label>
          </Pressable>
        )}
        {props.showDelete && (
          <Pressable
            style={{
              paddingHorizontal: 12,
              paddingVertical: 12,
              borderRadius: 100,
              backgroundColor: theme.colorErrorPrimary,
              marginRight: 12,
            }}
            onPress={handleOnDelete}
          >
            <Label style={{ fontSize: 14, fontWeight: '600', color: 'white' }}>{t('delete')}</Label>
          </Pressable>
        )}
        {!props.hideConfirm && (
          <Pressable
            style={{
              paddingHorizontal: props.showCancel ? 12 : 20,
              paddingVertical: 12,
              borderRadius: 100,
              backgroundColor: theme.background.darkTurquoise,
            }}
            onPress={handleOnConfirm}
          >
            <Label style={{ fontSize: 14, fontWeight: '600', color: theme.text.inverse }}>{t('confirm')}</Label>
          </Pressable>
        )}
      </View>
      <View>
        {props.maxPageNumber != undefined && props.pageNumber != undefined && (
          <Label style={{ fontSize: 14, fontWeight: '600', color: theme.text.darkTurquoiseTwo, position: 'absolute', bottom: 0, left: 0 }}>
            {props.pageNumber}/{props.maxPageNumber}
          </Label>
        )}
      </View>
    </ScrollView>
  );
};

export default ProfileModal;

import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { useAppSelector } from '../../../hooks/store-hook';
import useTranslation from '../../../hooks/translation-hook';
import NavigationElements from '../../../models/NavigationElements';
import Label from '../../common/Label';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import moment from 'moment';
import 'moment/min/locales';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { useTheme } from '@react-navigation/native';
import useCustomTheme from '../../../hooks/theme-hook';

const TransactionDetail = () => {
  const t = useTranslation();
  const theme = useTheme();
  const customTheme = useCustomTheme();
  const myAccountStore = useAppSelector((selector) => selector.myAccount);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  const [date, setDate] = useState();
  const [transaction, setTransaction] = useState<any | undefined>();
  const [detailsItemsComponent, setDetailsItemsComponent] = useState(<></>);

  useEffect(() => {
    moment.locale(userPrefs.uiLanguage);
  }, [userPrefs.uiLanguage]);

  useEffect(() => {
    if (myAccountStore.props) {
      setDate(myAccountStore.props.date);
      setTransaction(myAccountStore.props.transaction);
    }
  }, [myAccountStore.props]);

  useEffect(() => {
    if (transaction && transaction.childTransactions && transaction.childTransactions.length > 0) {
      setDetailsItemsComponent(
        <>
          {transaction.childTransactions.map((childT, i) => {
            return (
              <View
                key={`detailItem_${i}`}
                style={{
                  ...StylesHelper.padding(16, 0),
                  borderBottomWidth: 1,
                  borderBottomColor: theme.colors.border,
                  gap: 16,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Label style={{ fontWeight: 'bold', flexGrow: 1 }} textBreakStrategy={'simple'}>{`${childT.transactionTypeId} - ${childT.question}`}</Label>
                <Label style={{ flexGrow: 0, flexShrink: 0, fontSize: 18, fontWeight: 'bold', color: customTheme.text.sea }} textBreakStrategy={'balanced'}>
                  {childT.isRefused ? t('refused') : `${childT.amount ?? 0} pts`}
                </Label>
              </View>
            );
          })}
        </>
      );
    } else {
      setDetailsItemsComponent(<Label>No details</Label>);
    }
  }, [transaction]);

  return (
    <MyAccountPageWrapper navigationTitle={t('my_transactions')} title={''} navigationAction={NavigationElements.myTransactions}>
      {transaction && (
        <>
          <View>
            <Label style={{ fontWeight: 'bold', paddingBottom: 16, borderBottomWidth: 1, borderBottomColor: theme.colors.border }}>{` ${moment(date).format(
              'dddd, MMMM Do YYYY'
            )}`}</Label>
            <Label style={{ fontWeight: 'bold', ...StylesHelper.margin(16, 0, 8, 0) }}>{t('why_a_validation_is_refused')}</Label>
            <Label style={{ paddingBottom: 24 }}>{t('why_a_validation_is_refused_description')}</Label>
          </View>
          <View style={{ display: 'flex', flexBasis: '100%', flexShrink: 0, flexGrow: 0 }}>{detailsItemsComponent}</View>
        </>
      )}
    </MyAccountPageWrapper>
  );
};

export default TransactionDetail;

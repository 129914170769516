import React, { useEffect, useState } from 'react';
import { GestureResponderEvent, ScrollView, View, useWindowDimensions } from 'react-native';
import { useAppSelector, useAppDispatch } from '../../../hooks/store-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import Overlay from '../Overlay';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import Loading from '../Loading';
import { StylesHelper } from '../../../models/helpers/stylesHelper';

const LightBox = () => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const layout = useWindowDimensions();
  const lightBoxStore = useAppSelector((selector) => selector.lightBox);
  const dispatch = useAppDispatch();
  const style = useLightBoxStyles();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(lightBoxStore.isLoading ?? false);
  }, [lightBoxStore.isLoading]);

  useEffect(() => {
    // console.log('ModalStore', modalStore.visible);
  }, [lightBoxStore.visible]);

  const handleOnClosePress = (e: GestureResponderEvent) => {
    if (!lightBoxStore.isLoading && !lightBoxStore.noDismiss) {
      dispatch(lightBoxActions.setVisibility(false));
      dispatch(lightBoxActions.setContent(<></>));
      lightBoxStore.onClose && lightBoxStore.onClose();
    }
  };

  const handleOnSecondClosePress = () => {
    dispatch(lightBoxActions.setSecondVisibility(false));
    dispatch(lightBoxActions.setContent(<></>));
    lightBoxStore.onSecondClose && lightBoxStore.onSecondClose();
  };

  return (
    <>
      <Overlay showOverLay={lightBoxStore.visible} zIndex={1999} onPress={handleOnClosePress}>
        {lightBoxStore.visible && (
          <View
            style={{
              borderRadius: 8,
              display: lightBoxStore.visible ? 'flex' : 'none',
              backgroundColor: theme.background.modal,
              overflow: 'hidden',
              zIndex: 2000,
              maxHeight: layout.height - 40,
            }}
          >
            {isLoading && <Loading style={{ width: '100%', height: '100%', backgroundColor: 'rgba(233, 245, 243, 0.8)', zIndex: 4000 }} />}
            {lightBoxStore.content}
          </View>
        )}
      </Overlay>
      <Overlay showOverLay={lightBoxStore.secondVisible} zIndex={2999} onPress={handleOnSecondClosePress}>
        {lightBoxStore.secondVisible && (
          <View
            style={{
              borderRadius: 8,
              display: lightBoxStore.secondVisible ? 'flex' : 'none',
              backgroundColor: theme.background.modal,
              overflow: 'hidden',
              zIndex: 3000,
              maxHeight: layout.height - 40,
            }}
          >
            {isLoading && (
              <Loading style={{ width: '100%', height: '100%', ...StylesHelper.padding(12), backgroundColor: 'rgba(233, 245, 243, 0.8)', zIndex: 4000 }} />
            )}
            {lightBoxStore.secondContent}
          </View>
        )}
      </Overlay>
    </>
  );
};

const useLightBoxStyles = () => {
  const theme = useCustomTheme();

  let lightBox = {
    container: {},
    closeButton: {
      position: 'absolute' as any,
      top: 20,
      right: 20,
      xMark: {
        outline: 'none' as any,
      } as any,
    },
  };

  return lightBox as typeof lightBox;
};

export default LightBox;

import useCustomTheme from '../hooks/theme-hook';

const useRemoteMissionsStyles = () => {
  const theme = useCustomTheme();

  let remoteMissions = {
    container: {
      width: '100%' as any,
      height: '100%' as any,
      // ...StylesHelper.padding(0, 16),
      backgroundColor: theme.background.primary,
    },
    spinner: {
      width: '100%' as any,
      height: '100%' as any,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
      backgroundColor: theme.background.primary,
    },
    desktop: {
      container: {
        display: 'flex' as any,
        flexDirection: 'row' as any,
        flexGrow: 1,
        height: '100%' as any,
        backgroundColor: theme.background.primary,
      },
    },
  };

  return remoteMissions as typeof remoteMissions;
};

export default useRemoteMissionsStyles;

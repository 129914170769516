import { useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, TouchableWithoutFeedback, View } from 'react-native';
import {
  GetTransactionListFilter,
  GetTransactionListTransactionDto,
  GetTransactionListTransactionType,
  GetTransactionListViewModel,
  TransactionsClient,
} from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import { useAppSelector } from '../../../hooks/store-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import NavigationElements from '../../../models/NavigationElements';
import Label, { LabelType } from '../../common/Label';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import MyTransactionsItem from './MyTransactionsItem';
import { faSliders, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import CustomCheckbox from '../../common/CustomCheckbox';
import useStyles from '../../../hooks/styles-hook';
import CustomButton from '../../common/CustomButton';
import Loading from '../../common/Loading';
import Constants from 'expo-constants';

const filterItems = [
  { key: 1, value: 'transaction-type.adjustment' },
  { key: 2, value: 'transaction-type.validation' },
  { key: 3, value: 'transaction-type.mission' },
  { key: 4, value: 'transaction-type.cashout' },
];

const MyTransactions = () => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const authStore = useAppSelector((selector) => selector.auth);
  const apiTransactions = useApi(TransactionsClient);

  const styles = useStyles((section) => section.myMissions);

  const [transactionsViewModel, setTransactionViewModel] = useState<GetTransactionListViewModel>();
  const [pageSize] = useState(20);
  const [transactionsItems, setTransactionsItems] = useState<GetTransactionListTransactionDto[]>([]);
  const [transactionsComponents, setTransactionsComponents] = useState(<></>);
  const [selectedFilterItems, setSelectedFilterItems] = useState<number[]>([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [showTagPopup, setShowTagPopup] = useState(false);
  const [filterComponent, setFilterComponent] = useState(<></>);
  const [continuationToken, setContinuationToken] = useState<string>();
  const [loading, setLoading] = useState(true);

  const transactionsQuery = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['transactions', authStore.logged, selectedFilterItems, continuationToken],
    queryFn: ({ signal }) => {
      setLoading(true);
      let filterValues: GetTransactionListFilter;
      filterValues = new GetTransactionListFilter();

      if (!isAllSelected && selectedFilterItems.length > 0) {
        filterValues.transactionTypes = selectedFilterItems.map<GetTransactionListTransactionType>((f) => f as GetTransactionListTransactionType);
      } else {
        filterValues.transactionTypes = undefined;
      }

      const promise = apiTransactions.getTransactions(continuationToken, pageSize, filterValues, apiTransactions.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiTransactions.tokenSource?.cancel('Query was cancelled by React Query');
        apiTransactions.onCancel();
      });

      return promise;
    },

    onSettled: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (transactionsQuery.isSuccess) {
      setTransactionViewModel(transactionsQuery.data);
    }
  }, [transactionsQuery.data, transactionsQuery.isSuccess]);

  useEffect(() => {
    if (transactionsViewModel && transactionsViewModel.transactions?.items?.length > 0) {
      setTransactionsItems((prev) => {
        prev.push(...transactionsViewModel.transactions.items);
        return prev.slice();
      });
    }
  }, [transactionsViewModel]);

  useEffect(() => {
    if (transactionsItems && transactionsItems.length > 0) {
      setTransactionsComponents(<MyTransactionsItem key={String.prototype.generateRandomString(5)} transactions={transactionsItems} />);
    } else {
      if (loading) {
        setTransactionsComponents(<Loading />);
      } else {
        setTransactionsComponents(
          <View style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: 16 }}>
            <FontAwesomeIcon icon={faWarning} size={32} color={theme.text.titleLight.toString()} />
            <Label style={{ marginTop: 6 }} variant={LabelType.titlePrimarySmall}>
              {t('no-transactions')}
            </Label>
          </View>
        );
      }
    }
  }, [transactionsItems, loading]);

  useEffect(() => {
    setFilterComponent(
      <>
        {filterItems.map((item) => {
          return (
            <CustomCheckbox
              small
              id={`tag_${item.key}`}
              key={`tag_${item.key}`}
              checked={selectedFilterItems?.findIndex((s) => s === item.key) > -1}
              value={item.key}
              label={t(item.value)}
              style={styles.newVersion.list.popupTag.item}
              onChange={(checked, value) => {
                handleFilterClick(checked, item.key);
              }}
            />
          );
        })}
      </>
    );
  }, [filterItems, selectedFilterItems]);

  const handleFilterClick = (checked: boolean, transactionType: number) => {
    if (checked) {
      setSelectedFilterItems((prev) => {
        if (prev.findIndex((p) => transactionType === p) === -1) {
          prev.push(transactionType);
          resetList();

          if (prev.length >= filterItems.length) {
            setIsAllSelected(true);
          }
        }

        return prev.slice();
      });
    } else {
      setSelectedFilterItems((prev) => {
        const index = prev.findIndex((t) => t === transactionType);

        if (index > -1) {
          resetList();
          prev.splice(index, 1);

          setIsAllSelected(false);
        }

        return prev.slice();
      });
    }
  };

  const resetList = () => {
    setTransactionsItems([]);
    setContinuationToken(undefined);
  };
  const handleFilterButtonPress = () => {
    setShowTagPopup((prev) => !prev);
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    if (isAllSelected) {
      setSelectedFilterItems([]);
      setIsAllSelected(false);
      resetList();
    } else {
      setSelectedFilterItems(filterItems.map((f) => f.key));
      setIsAllSelected(true);
      resetList();
    }
  };

  return (
    <MyAccountPageWrapper
      navigationTitle={t('my_profile')}
      title={t('my_transactions')}
      navigationAction={NavigationElements.menu}
      scrollOnChange={continuationToken !== undefined}
      moreInfoComponent={{
        showTitle: true,
        externalLink: t('lanla_website_support'),
        title: t('help_support'),
        children: `## Rursus septem

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`,
      }}
    >
      <View style={{ flexGrow: 1 }}>
        <Pressable style={styles.newVersion.list.filterTransactions} onPress={handleFilterButtonPress}>
          <FontAwesomeIcon icon={faSliders} style={styles.newVersion.list.filterIcon} />
          <Label variant={LabelType.title}>{t('filter-by-transaction-type')}</Label>
          <View style={[styles.newVersion.list.popupTagFilterBase, !showTagPopup ? styles.newVersion.list.popupTag.hide : styles.newVersion.list.popupTag]}>
            <Pressable id={'selectAll_button'} style={styles.newVersion.list.popupTagFilter.selectAll} onPress={handleSelectAll}>
              <Label variant={LabelType.titleDarkSmall}>{isAllSelected ? t('deselect_all') : t('select_all')}</Label>
            </Pressable>
            {filterComponent}
          </View>
          {showTagPopup && (
            <Pressable
              style={{
                backgroundColor: 'transparent',
                position: Platform.OS === 'web' ? ('fixed' as any) : 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 1000,
              }}
              onPress={() => setShowTagPopup(false)}
            ></Pressable>
          )}
        </Pressable>
        <TouchableWithoutFeedback
          onPress={() => {
            setShowTagPopup(false);
          }}
        >
          <View>
            {transactionsComponents}
            {transactionsViewModel?.transactions?.continuationToken && (
              <CustomButton
                title={t('load-more')}
                style={{ marginBottom: 12, marginTop: 12, height: 40, width: '80%', maxWidth: 200, alignSelf: 'center' }}
                secondary
                onPress={() => setContinuationToken(transactionsViewModel.transactions.continuationToken)}
              />
            )}
          </View>
        </TouchableWithoutFeedback>
      </View>
    </MyAccountPageWrapper>
  );
};

export default MyTransactions;

import React, { ReactElement, useEffect, useState } from 'react';
import { Linking, Pressable, PressableProps } from 'react-native';
import { useAppDispatch } from '../../../hooks/store-hook';
import useStyles from '../../../hooks/styles-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import Label, { LabelType } from '../../common/Label';
import useMyAccountActions from '../../../hooks/myAccountActions-hook';

import { authActions } from '../../../models/helpers/platformImportHelper';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useNavigation } from '@react-navigation/native';

export interface MenuItemProps extends PressableProps {
  title: string;
  action?: ReactElement | null;
  bottomLine?: boolean;
  forwardProps?: any;
  labelType?: LabelType;
  icon?: IconProp;
  mirrorIcon?: boolean;
  externalLink?: string;
}

const MenuItem = (props: MenuItemProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.myAccount);
  const dispatch = useAppDispatch();
  const myAccountActions = useMyAccountActions();
  const navigation = useNavigation();

  const [action, setAction] = useState(props.action);
  const [externalLink, setExternalLink] = useState(props.externalLink);
  const bottomLine = props.bottomLine ?? {};

  useEffect(() => {
    setAction(props.action);
  }, [props.action]);

  useEffect(() => {
    setExternalLink(props.externalLink);
  }, [props.externalLink]);

  const handleClick = () => {
    if (action) {
      myAccountActions.setProps(props.forwardProps);
      myAccountActions.navigate(action);
      return;
    } else {
      if (externalLink) {
        Linking.openURL(externalLink);
        return;
      }
    }

    // LOGOUT DISPATCH HERE
    myAccountActions.setVisibility(false);
    navigation.navigate('logout' as never);
  };

  return (
    <Pressable {...props} style={[styles.menuItem, { ...bottomLine }]} onPress={handleClick}>
      {props.icon && <FontAwesomeIcon icon={props.icon} style={[styles.menuItem.icon, props.mirrorIcon ? styles.menuItem.icon.mirror : {}]} />}
      <Label variant={props.labelType ?? LabelType.myAccountLink}>{props.title}</Label>
    </Pressable>
  );
};

export default MenuItem;

import React, { useEffect, useState } from 'react';
import { ColorValue, StyleProp, View, ViewProps, ViewStyle } from 'react-native';
import { FontAwesome } from '@expo/vector-icons';
import Label, { LabelType } from './Label';
import useCustomTheme from '../../hooks/theme-hook';
import useTranslation from '../../hooks/translation-hook';

export interface LoadingProps extends ViewProps {
  style?: StyleProp<ViewStyle>;
  opacity?: string;
  textVariant?: LabelType;
  iconColor?: ColorValue;
  text?: string;
}

const Loading = (props: LoadingProps) => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const [textVariant, setTextVariant] = useState(props.textVariant);
  const [iconColor, setIconColor] = useState(props.iconColor);

  useEffect(() => {
    setTextVariant(props.textVariant);
  }, [props.textVariant]);

  useEffect(() => {
    setIconColor(props.iconColor);
  }, [props.iconColor]);

  return (
    <View
      {...props}
      style={[
        {
          position: 'absolute',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          flexGrow: 1,
          width: '100%',
          height: '100%',
          backgroundColor: `ffffff${props.opacity ?? '10'}`,
          padding: 8,
        },
        props.style ?? {},
      ]}
    >
      <FontAwesome name="refresh" size={18} color={iconColor?.toString() ?? theme.secondaryLight.toString()} style={{ marginRight: 8 }} />
      <Label variant={textVariant ?? LabelType.titleLight}>{props.text ?? `${t('loading')}...`}</Label>
    </View>
  );
};

export default Loading;

import React, { ReactElement, useEffect, useState } from 'react';
import SettingsOptions from '../../common/SettingsOptions';
import { MysteryShopperAddressDto, MysteryShopperAddressType, MysteryShopperClient } from '../../../api/api.g';
import AddressForm, { AddressFormValues } from '../../address/AddressForm';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import useTranslation from '../../../hooks/translation-hook';
import useApi from '../../../hooks/api-hook';
import useUserPrefsService from '../../../hooks/userPrefsService-hook';
import { Pressable, View } from 'react-native';
import Label, { LabelType } from '../../common/Label';
import useStyles from '../../../hooks/styles-hook';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import ProfileModal from './ProfileModal';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faMapLocationDot } from '@fortawesome/pro-regular-svg-icons';
import useCustomTheme from '../../../hooks/theme-hook';
import useAddressIcon from '../../../hooks/addressIcon-hook';
import useGoogleValidation from '../../../hooks/googleValidation-hook';

export interface AddressItemProps extends AddressFormValues {
  option: string;
  addButton?: boolean;
  readonly?: boolean;
}

const AddressItem = (props: AddressItemProps) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const theme = useCustomTheme();
  const userPrefService = useUserPrefsService();
  const apiMysteryShopper = useApi(MysteryShopperClient);
  const styles = useStyles((section) => section.settingsOptions);
  const lightBoxStore = useAppSelector((selector) => selector.lightBox);
  const addressIcon = useAddressIcon(20, theme.text.titleDark);
  const googleValidator = useGoogleValidation();

  const [dto, setDto] = useState<MysteryShopperAddressDto>(undefined);
  const [confirmed, setConfirmed] = useState(false);
  const [forcedConfirm, setForcedConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState<string | ReactElement>('');
  const [suspiciousAddress, setSuspiciousAddress] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleOnConfirm = () => {
    if (dto && dto.address && dto.city && dto.postalCode && dto.department && dto.country) {
      googleValidator(
        {
          address: {
            addressLines: [dto.address, dto.city, dto.postalCode, dto.department, dto.country],
          },
          previousResponseId: '',
          enableUspsCass: false,
        },
        (valid) => {
          if (valid) {
            setSuspiciousAddress(false);
            setConfirmed(true);
          } else {
            setSuspiciousAddress(true);
            setShowConfirm(true);
          }
        }
      );
    } else {
      setSuspiciousAddress(true);
    }
  };

  useEffect(() => {
    let address: AddressFormValues = {
      id: props.id,
      city: props.city,
      postalCode: props.postalCode,
      address: props.address,
      country: props.country,
      department: props.department,
      type: props.type,
      latitude: props.latitude,
      longitude: props.longitude,
    };

    if (lightBoxStore.visible) {
      dispatch(
        lightBoxActions.setContent(
          <ProfileModal
            onClose={() => {
              emptyLightbox();
            }}
            showDelete={!props.addButton}
            title={t('address')}
            onConfirm={handleOnConfirm}
            onDelete={() => {
              dispatch(lightBoxActions.setIsLoading(true));
              apiMysteryShopper
                .deleteAddress(address.id)
                .then((result) =>
                  userPrefService.updateUserProfileInfo().then(() => {
                    dispatch(lightBoxActions.setIsLoading(false));
                    dispatch(lightBoxActions.setVisibility(false));
                  })
                )
                .catch((error) => console.log('Error Delete', error));
            }}
          >
            {
              <>
                {suspiciousAddress && (
                  <Label style={{ marginBottom: 24, color: theme.text.error, fontSize: 16, fontWeight: '600', flexShrink: 1, maxWidth: 260 }} numberOfLines={5}>
                    {t('address-incorrect-check-again')}
                  </Label>
                )}
                <AddressForm
                  id={address.id}
                  city={address.city}
                  postalCode={address.postalCode}
                  address={address.address}
                  department={address.department}
                  country={address.country}
                  type={address.type}
                  onChange={(result, valid) => {
                    setDto((prev) => {
                      const newDto = new MysteryShopperAddressDto();
                      newDto.init(result);
                      return newDto;
                    });
                  }}
                />

                {showConfirm && (
                  <Pressable
                    onPress={() => {
                      setConfirmed(true);
                      setShowConfirm(false);
                      setSuspiciousAddress(false);
                    }}
                  >
                    <Label style={{ color: theme.text.darkTurquoiseTwo, fontSize: 12, fontWeight: '600', flexShrink: 1 }}>
                      {t('Is-this-the-correct-address-Continue-with-this-add')}
                    </Label>
                  </Pressable>
                )}
              </>
            }
          </ProfileModal>
        )
      );
    }
  }, [dto, suspiciousAddress]);

  const handleAddressManager = () => {
    setSuspiciousAddress(false);

    let address: AddressFormValues = {
      id: props.id,
      city: props.city,
      postalCode: props.postalCode,
      address: props.address,
      country: props.country,
      department: props.department,
      type: props.type,
      latitude: props.latitude,
      longitude: props.longitude,
    };

    dispatch(
      lightBoxActions.setContent(
        <ProfileModal
          onClose={() => {
            emptyLightbox();
          }}
          title={t('address')}
          onConfirm={handleOnConfirm}
          showDelete={!props.addButton}
          onDelete={() => {
            dispatch(lightBoxActions.setIsLoading(true));
            apiMysteryShopper
              .deleteAddress(address.id)
              .then((result) =>
                userPrefService.updateUserProfileInfo().then(() => {
                  dispatch(lightBoxActions.setIsLoading(false));
                  dispatch(lightBoxActions.setVisibility(false));
                })
              )
              .catch((error) => console.log('Error Delete', error));
          }}
        >
          <AddressForm
            id={address.id}
            city={address.city}
            postalCode={address.postalCode}
            address={address.address}
            department={address.department}
            country={address.country}
            type={address.type}
            onChange={(result, valid) => {
              setDto((prev) => {
                const newDto = new MysteryShopperAddressDto();
                newDto.init(result);
                return newDto;
              });
            }}
          />
        </ProfileModal>
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  useEffect(() => {
    if (props.type !== undefined) {
      if (props.type === MysteryShopperAddressType.Current) {
        setTitle(
          <View style={{ flexDirection: 'row' }}>
            <FontAwesomeIcon icon={faMapLocationDot} style={{ marginRight: 4, color: theme.text.titleDark.toString() }} />
            <Label variant={LabelType.titleSmall} style={styles.text.title}>
              {t(MysteryShopperAddressType[props.type].toString().toLowerCase())}
            </Label>
          </View>
        );
      } else {
        setTitle(t(MysteryShopperAddressType[props.type].toString().toLowerCase()));
      }
    } else {
      setTitle('');
    }
  }, [props.type]);

  useEffect(() => {
    if (confirmed) {
      dispatch(lightBoxActions.setIsLoading(true));

      if (props.id) {
        apiMysteryShopper
          .updateAddress(props.id, dto)
          .then((result) => {
            userPrefService.updateUserProfileInfo().then(() => {
              emptyLightbox();
            });
          })
          .catch((error) => {
            console.log('Error', error);
            dispatch(lightBoxActions.setIsLoading(false));
          })
          .finally(() => setConfirmed(false));
      } else {
        apiMysteryShopper
          .addAddress(dto)
          .then((result) => {
            userPrefService.updateUserProfileInfo().then(() => {
              emptyLightbox();
            });
            setConfirmed(false);
          })
          .catch((error) => {
            console.log('Error', error);
            dispatch(lightBoxActions.setIsLoading(false));
          })
          .finally(() => setConfirmed(false));
      }
    }
  }, [confirmed]);

  const emptyLightbox = () => {
    dispatch(lightBoxActions.setIsLoading(false));
    dispatch(lightBoxActions.setContent(<></>));
    dispatch(lightBoxActions.setVisibility(false));
  };

  return (
    <>
      {!props.addButton && (
        <SettingsOptions
          readonly={props.readonly}
          bottomBorder
          icon={addressIcon(props.type)}
          title={title}
          option={props.option}
          onPress={handleAddressManager}
        />
      )}
      {props.addButton && (
        <Pressable style={[styles.container, styles.addButton]} onPress={handleAddressManager}>
          {props.option && <Label style={styles.addButtonLabel}>{props.option}</Label>}
        </Pressable>
      )}
    </>
  );
};

export default AddressItem;

import React from 'react';
import useTranslation from '../../../hooks/translation-hook';
import SettingsOptions, { SettingsOptionsProps } from '../../common/SettingsOptions';
import { GetMysteryShopperProfileMemberDto } from '../../../api/api.g';
import { ValidationResult } from '../../common/CustomTextInput';

export interface ProfileMemberItemProps extends SettingsOptionsProps {
  profileMember: GetMysteryShopperProfileMemberDto;
  value: string;
  validation?: ValidationResult;
  onClick?(): void;
}

const ProfileMemberItem = (props: ProfileMemberItemProps) => {
  const t = useTranslation();

  const handleClick = () => {
    props.onClick && props.onClick();
  };

  return <SettingsOptions bottomBorder={props.bottomBorder} title={props.title} option={props.option ?? t('add')} onPress={handleClick} />;
};

export default ProfileMemberItem;

import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import NavBarButton from './NavBarButton';
import useStyles from '../../../hooks/styles-hook';
import { useAppSelector } from '../../../hooks/store-hook';
import { faBullhorn, faHouseLaptop, faLocationCheck, faStreetView } from '@fortawesome/pro-regular-svg-icons';
import { useQuery } from '@tanstack/react-query';
import { MyMissionClient } from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import useTranslation from '../../../hooks/translation-hook';
import { LoggedState } from '../../../models/AuthModelsCommon';
import { useNavigationState } from '@react-navigation/native';

const NavBar = () => {
  const t = useTranslation();
  const userPrefs = useAppSelector((state) => state.userPrefs);
  const navBarVisibility = useAppSelector((state) => state.navBar);
  const styles = useStyles((section) => section.navBar);
  const apiMyMission = useApi(MyMissionClient);
  const authStore = useAppSelector((selector) => selector.auth);
  const missionsStore = useAppSelector((selector) => selector.missions);

  const [containerStyle, setContainerStyle] = useState(styles.container);
  const [hasMissions, setHasMissions] = useState(false);
  const [isLanla, setIsLanla] = useState(false);

  useEffect(() => {
    setIsLanla(authStore.isLanla);
  }, [authStore]);

  const myMissionsQuery = useQuery({
    enabled: !!authStore.idToken && authStore.logged === LoggedState.Logged,
    queryKey: ['myMissions', missionsStore.missionListSeed],
    queryFn: ({ signal }) => {
      const promise = apiMyMission.getMissionsList(apiMyMission.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMyMission.tokenSource?.cancel('Query was cancelled by React Query');
        apiMyMission.onCancel();
      });

      return promise;
    },
  });

  useEffect(() => {
    if (myMissionsQuery.isSuccess) {
      const value = myMissionsQuery.data;

      if (value.missions?.length > 0) {
        setHasMissions(true);
      } else {
        setHasMissions(false);
      }
    }
  }, [myMissionsQuery.isSuccess, myMissionsQuery.data]);

  const setStyleCallback = useCallback(() => {
    setContainerStyle(styles.container);
  }, [userPrefs.theme, styles.container, navBarVisibility.visible]);

  useEffect(() => {
    setStyleCallback();
  }, [userPrefs.theme]);

  return (
    <View>
      {navBarVisibility.visible && (
        <View style={containerStyle}>
          {/* <NavBarButton iconLightTheme={IconStore} iconDarkTheme={IconStoreDarkTheme} title="In store" screen="map" />          
          <NavBarButton iconLightTheme={IconRemote} iconDarkTheme={IconRemoteDarkTheme} title="Remote" screen="remotemissions" />
          <NavBarButton iconLightTheme={IconValidation} iconDarkTheme={IconValidationDarkTheme} title="Validation" screen="validation" />
          <NavBarButton iconLightTheme={IconMissions} iconDarkTheme={IconMissionsDarkTheme} title="My missions" screen="mymissions" /> */}
          <NavBarButton image={require('../../../../assets/map.png')} icon={faStreetView} title={t('Missions')} screen="map" />
          {isLanla && <NavBarButton icon={faBullhorn} title={t('validation', { count: 1 })} screen="validation" />}
          <NavBarButton
            image={require('../../../../assets/mission.png')}
            icon={faLocationCheck}
            title={t('my_missions')}
            screen="mymissions"
            showIndicator={hasMissions}
          />
        </View>
      )}
    </View>
  );
};

export default NavBar;
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}

import useCustomTheme from '../hooks/theme-hook';

const useOverlayStyles = () => {
  const theme = useCustomTheme();

  let overlay = {
    container: {
      position: 'absolute' as any,
      height: '100%' as any,
      width: '100%' as any,
      justifyContent: 'center' as any,
      alignItems: 'center' as any,
    },
    overlay: {
      height: '100%' as any,
      width: '100%' as any,
      backgroundColor: theme.overlayColor,
    }
  };

  return overlay as typeof overlay;
};

export default useOverlayStyles;

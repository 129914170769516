import { DimensionValue } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useRestrictedAreaStyles = () => {
  const theme = useCustomTheme();

  let mapControl = {
    missionNotLogged: {
      containerBase: {
        flexGrow: 1,
        paddingTop: 40
      },
      container: {
        loading: {
          height: 200,
        },
        icon: {
          paddingTop: 24,
          marginBottom: 16,
        },
        button: {
          flexDirection: 'row' as any,
          gap: 16,
          marginTop: 24,
        },
      },
    },
  };

  return mapControl as typeof mapControl;
};

export default useRestrictedAreaStyles;

import React from 'react';
import { Pressable, PressableProps, View } from 'react-native';
import useCustomTheme from '../../hooks/theme-hook';
import { StylesHelper } from '../../models/helpers/stylesHelper';
import Label, { LabelType } from './Label';

export interface ButtonBarProps extends PressableProps {
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onLeftButtonClick?(): void;
  onRightButtonClick?(): void;
}

const ButtonBar = (props: ButtonBarProps) => {
  const theme = useCustomTheme();

  return (
    <View
      style={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 60,
        bottom: 0,
        borderTopColor: theme.borderColorLight,
        borderTopWidth: 1,
        backgroundColor: theme.borderColorLight,
        //...StylesHelper.margin(0, -16, -8, -16),
      }}
    >
      {props.leftButtonLabel && (
        <Pressable
          style={{ flexGrow: 1, height: '100%', justifyContent: 'center', marginRight: 1, backgroundColor: theme.background.modal }}
          onPress={props.onLeftButtonClick}
        >
          <Label style={{ alignSelf: 'center' }} variant={LabelType.cancelButton}>
            {props.leftButtonLabel}
          </Label>
        </Pressable>
      )}
      {props.rightButtonLabel && (
        <Pressable
          style={{ flexGrow: 1, height: '100%', justifyContent: 'center', backgroundColor: theme.background.modal }}
          onPress={props.onRightButtonClick}
        >
          <Label style={{ alignSelf: 'center' }} variant={LabelType.okButton}>
            {props.rightButtonLabel}
          </Label>
        </Pressable>
      )}
    </View>
  );
};

export default ButtonBar;

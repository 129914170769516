import React from 'react';
import Help from '../components/myaccount/Help';
import Menu from '../components/myaccount/menu/Menu';
import MyProfile from '../components/myaccount/myprofile/MyProfile';
import MyRewards from '../components/myaccount/myrewards/MyRewards';
import MyTransactions from '../components/myaccount/mytransactions/MyTransactions';
import TransactionDetail from '../components/myaccount/mytransactions/TransactionDetail';
import Settings from '../components/myaccount/settings/Settings';
import SettingsLanguage from '../components/myaccount/settings/SettingsLanguage';
import Test401 from '../components/myaccount/Test401';
import SettingsNotifications from '../components/myaccount/settings/SettingsNotifications';

export default class NavigationElements {
  public static menu = (<Menu />);
  public static myProfile = (<MyProfile />);
  public static myRewards = (<MyRewards />);
  public static myTransactions = (<MyTransactions />);
  public static transactionDetail = (<TransactionDetail />);
  public static settings = (<Settings />);
  public static settingsLanguage = (<SettingsLanguage />);
  public static settingsNotifications = (<SettingsNotifications />);
  public static help = (<Help />);

  public static test401 = (<Test401 />);
}

import { DimensionValue, Dimensions, Platform, useWindowDimensions } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useMyMissionStyles = () => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();

  let myMissions = {
    containerBase: {
      backgroundColor: theme.background.primary,
      maxWidth: '100%' as DimensionValue,
      display: 'flex' as any,
      flexDirection: 'row' as any,
      ...Platform.select({
        web: {
          height: '100%' as DimensionValue,
          flexShrink: 1,
        },
        native: {
          flexGrow: 1,
        },
      }),
    },
    containerBaseRemote: {
      backgroundColor: theme.background.primary,
      // maxWidth: '100%' as DimensionValue,
      display: 'flex' as any,
      flexDirection: 'row' as any,
      ...Platform.select({
        web: {
          // height: '100%' as DimensionValue,
          // flexShrink: 1,
        },
        native: {
          flexGrow: 1,
        },
      }),
    },
    container: {
      scrollView: {
        backgroundColor: theme.background.primary,
        flexGrow: 1,
      },
      emptyBase: {
        justifyContent: 'center' as any,
        alignItems: 'center' as any,
        height: '100%' as DimensionValue,
        flexGrow: 1,
        padding: 24,
      },
      empty: {
        linkContainer: {
          marginTop: 4,
          flexDirection: 'row' as any,
          flexWrap: 'wrap' as any,
          justifyContent: 'center' as any,
        },
      },
      loading: {
        flexDirection: 'row' as any,
        justifyContent: 'space-around' as any,
        flexGrow: 1,
        height: '100%' as DimensionValue,
        width: '100%' as DimensionValue,
      },
      sideBar: {
        ...(layout.width <= 768 ? { width: '100%' } : { width: 350, maxWidth: 350, minWidth: 350 }),
        borderRightWidth: 1,
        borderRightColor: theme.borderColorLight,
        flexGrow: 1,
        ...Platform.select({
          web: {
            ...StylesHelper.shadow({ height: 0, width: 2 }, 0.1, 5, 'black', 5),
          },
        }),
      },
    },
    statusBlockBase: {
      paddingHorizontal: 16,
      paddingVertical: 32,
      borderBottomWidth: 1,
      borderBottomColor: theme.borderColorLight,
    },
    statusBlock: {
      titleContainerBase: {
        display: 'flex' as any,
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
      },
      titleContainer: {
        text: {
          fontSize: 20,
          fontWeight: 'bold' as any,
          textTransform: 'uppercase' as any,
        },
        infoButton: {
          marginLeft: 8,
        },
      },
    },
    itemBase: {
      paddingVertical: 24,
      flexGrow: 1,
      flexDirection: 'row' as any,
      flexWrap: 'wrap' as any,
      alignItems: 'center' as any,
      justifyContent: 'space-between' as any,
      borderBottomWidth: 1,
      borderBottomColor: theme.borderColorLight,
    },
    item: {
      remoteIndicator: {
        ...StylesHelper.borderRadius(10),
        paddingHorizontal: 8,
        paddingVertical: 4,
        backgroundColor: theme.background.secondary,
        marginLeft: 8,
      },
      lastItem: {
        borderBottomWidth: 0,
        paddingBottom: 0,
      },
      missionInfoBase: {
        flexGrow: 1,
        minWidth: 200,
        paddingRight: 16,
      },
      missionInfo: {
        logo: {
          padding: 12,
          width: '100%' as DimensionValue,
          height: '100%' as DimensionValue,
        },
        ['missionInfo.default']: {
          color: theme.text.titleLight.toString(),
        },
      },
      actionsContainerBase: {
        flexDirection: 'column' as any,
        flexGrow: 1,
        width: '100%' as DimensionValue,
      },
      actionsContainer: {
        timelineBase: {
          flexDirection: Dimensions.get('window').width >= 592 ? ('row' as any) : ('column' as any),
          minHeight: Dimensions.get('window').width >= 592 ? 60 : 300,
          justifyContent: 'space-between',
          alignItems: 'center' as any,
          paddingTop: 8,
          paddingBottom: 8,
        },
        timeline: {
          itemBase: {
            flexDirection: Dimensions.get('window').width >= 592 ? ('row' as any) : ('column-reverse' as any),
            alignItems: 'center' as any,
          },
          item: {
            icon: {
              marginRight: Dimensions.get('window').width >= 592 ? 4 : 0,
              marginTop: Dimensions.get('window').width >= 592 ? 0 : 4,
              marginBottom: Dimensions.get('window').width >= 592 ? 0 : 4,
            },
            line: {
              backgroundColor: theme.borderColor,
              flexGrow: 1,
              marginLeft: Dimensions.get('window').width >= 592 ? 8 : 0,
              marginRight: Dimensions.get('window').width >= 592 ? 8 : 0,
              marginTop: Dimensions.get('window').width >= 592 ? 0 : 4,
              marginBottom: Dimensions.get('window').width >= 592 ? 0 : 4,
              height: 2,
              width: 2,
            },
          },
        },
        actionsBase: {
          flexGrow: 1,
          maxWidth: '100%' as any,
          flexWrap: 'nowrap' as any,
          display: 'flex' as any,
          flexDirection: 'row' as any,
          justifyContent: 'flex-start' as any,
          aligItems: 'center' as any,
          paddingTop: 16,
        },
        actions: {
          actionBase: {
            flexGrow: 1,
            flexShrink: 1,
            ...StylesHelper.padding(0, Platform.OS === 'web' && Dimensions.get('screen').width <= 792 ? 4 : 16),
          },
          action: {
            buttonBase: {
              alignItems: 'center' as any,
            },
            button: {
              iconBase: {
                color: theme.text.primary.toString(),
              },
              icon: {
                primaryColor: {
                  color: theme.text.titleDark.toString(),
                },
              },
              labelBase: {
                fontSize: 13,
                marginTop: 8,
                ...Platform.select({
                  web: {
                    whiteSpace: 'wrap' as any,
                  },
                }),
                textAlign: 'center' as any,
              },
              label: {
                primaryColor: {
                  color: theme.text.titleDark.toString(),
                },
              },
            },
          },
        },
      },
    },
    actionContainerBase: {
      padding: 24,
    },
    actionContainer: {
      titleContainerBase: {
        display: 'flex' as any,
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
      },
      titleContainer: {
        text: {
          fontSize: 14,
          fontWeight: '600' as any,
          textTransform: 'uppercase' as any,
          color: theme.text.primary,
          flexGrow: 1,
        },
        image: {
          width: 30,
          height: 30,
        },
      },
      posName: {
        fontSize: 14,
        fontWeight: '600' as any,
        textTransform: 'uppercase' as any,
        color: theme.text.sea,
        marginTop: Dimensions.get('screen').width > 1200 ? 24 : 0,
      },
      address: {
        fontSize: 13,
        color: theme.text.primary,
      },
      button: {
        marginTop: 24,
      },
      extend: {
        dateTimeContainerBase: {
          display: 'flex' as any,
          flexDirection: 'row' as any,
          alignItems: 'center' as any,
          paddingVertical: 8,
          fontSize: 13,
          color: theme.text.primary,
        },
        dateTimeContainer: {
          date: {
            marginTop: 24,
            borderBottomColor: theme.borderColorLight,
            borderBottomWidth: 1,
            paddingBottom: 9,
          },
          label: {
            flexGrow: 1,
          },
          value: {
            fontWeight: '600' as any,
          },
        },
        delayMissionTitle: {
          fontSize: 14,
          fontWeight: '600' as any,
          color: theme.text.darkTurquoiseTwo,
          flexGrow: 1,
          marginTop: 24,
        },
        delayMissionButtonsBase: {
          display: 'flex' as any,
          flexDirection: 'row' as any,
          alignItems: 'center' as any,
          justifyContent: 'space-between' as any,
          marginTop: 16,
        },
        delayMissionButtons: {
          itemBase: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            alignItems: 'center' as any,
            justifyContent: 'center' as any,
            width: '20%' as DimensionValue,
          },
          item: {
            buttonContainerBase: {
              display: 'flex' as any,
              flexDirection: 'column' as any,
              alignItems: 'center' as any,
              borderRadius: 8,
              padding: 8,
              width: 52,
              backgroundColor: theme.text.darkTurquoiseTwo,
            },
            buttonContainer: {
              selected: {
                backgroundColor: theme.text.sea,
              },
              value: {
                fontSize: 13,
                color: 'white',
                lineHeight: 13,
              },
            },
          },
        },
      },
    },
    newVersion: {
      list: {
        title: {
          ...StylesHelper.padding(14, 12, 12, 12),
          marginTop: -4,
        },
        status: {
          ...StylesHelper.padding(14, 12, 0, 12),
          marginBottom: 8,
          marginTop: -4
        },
        filter: {
          ...StylesHelper.padding(12, 4, 14, 14),
          display: 'flex' as any,
          flexDirection: 'row' as any,
          alignItems: 'center',
          position: 'relative' as any,
          zIndex: 2001,
        },
        filterTransactions: {
          ...StylesHelper.padding(12, 4, 14, 2),
          display: 'flex' as any,
          flexDirection: 'row' as any,
          alignItems: 'center',
          position: 'relative' as any,
          zIndex: 2001,
        },
        filterIcon: {
          marginRight: 8,
        },
        popupTagFilterBase: {
          position: 'absolute' as any,
          zIndex: 2000,
          // ...(layout.width < 768 ? (layout.width < 590 ? { top: 58, left: 16 } : { top: 58, left: 24 }) : { top: 24, left: 382 }),
          top: 32,
          left: 16,
        },
        popupTagFilter: {
          title: {
            height: 33,
            ...(layout.width < 768 ? { paddingLeft: 12 } : { paddingLeft: 48 }),
            display: 'flex' as any,
            alignItems: 'center' as any,
            borderBottomWidth: 1,
            borderBottomColor: theme.borderColorLight,
            textTransform: 'uppercase' as any,
          },
          selectAll: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            flexGrow: 1,
            justifyContent: 'flex-end' as any,
            ...StylesHelper.padding(8),
          },
        },
        popupTag: {
          backgroundColor: theme.background.modal,
          ...StylesHelper.padding(4, 0),
          ...StylesHelper.borderRadius(5),
          ...StylesHelper.shadow({ width: 0, height: 0 }, 0.2, 4, 'black', 2),
          item: {
            ...StylesHelper.padding(0, 12),
          },
          hide: {
            display: 'none' as any,
          },
        },
        item: {
          position: 'relative' as any,
          display: 'flex' as any,
          flexDirection: 'row' as any,
          ...StylesHelper.padding(14, 12),
          borderBottomWidth: 1,
          borderBottomColor: theme.borderColorLight,
          alignItems: 'center' as any,
        },
        itemSelected: {
          backgroundColor: theme.background.missionDetail,
        },
        logoBase: {
          display: 'flex' as any,
          flexDirection: 'row' as any,
          alignItems: 'center' as any,
          justifyContent: 'center' as any,
          height: 48,
          width: 48,
          marginRight: 12,
          borderRadius: 24,
          borderWidth: 1,
          borderColor: theme.borderColorLight,
          overflow: 'hidden' as any,
          backgroundColor: theme.background.secondary,
        },
        logo: {
          image: {
            width: '100%' as DimensionValue,
            height: '100%' as DimensionValue,
          },
          icon: {
            padding: 12,
            color: theme.text.titleLight.toString(),
            width: '100%' as DimensionValue,
            height: '100%' as DimensionValue,
          },
        },
      },
      detailsWrapperContainer: {
        display: 'flex' as any,
        flexDirection: 'column' as any,
        flexShrink: 1,
        width: '100%' as any,
      },
      detailsWrapper: {
        display: 'flex' as any,
        flexDirection: 'column' as any,
        width: '100%' as any,
        padding: 16
      },
      detailsWrapperRemote: {
        display: 'flex' as any,
        flexDirection: 'column' as any,
        flexShrink: 0,
        width: '100%' as any,
      },
      ['detailsWrapper.backButton']: {
        backgroundColor: theme.background.navBar,
        ...StylesHelper.padding(12),
      },
      detailsContainer: {
        gap: 16,
        flexGrow: 1,
        flexShrink: 1,
      },
      details: {
        header: {
          container: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            alignItems: 'center' as any,
            flexWrap: 'wrap' as any,
            gap: 16
          },
          title: {
            display: 'flex' as any,
            flexDirection: 'column' as any,
            justifyContent: 'center' as any,
            flexShirnk: 1,
            flexGrow: 1,
            flexBasis: 250
          },
          submit: {
            display: 'flex' as any,
            flexDirection: 'row-reverse' as any,
            flexGrow: 1,
          },
          titleNoUpper: {
            flexShrink: 1,
            ...StylesHelper.text(16, theme.text.primary, 600, 'none'),
          },
          subTitle: {
            color: theme.text.darkTurquoiseTwo,
            marginBottom: 8,
          },
          pin: {
            height: 66,
            width: 68,
            marginTop: -8,
            marginLeft: -8,
          },
          pinContainer: {
            icon: {
              position: 'absolute' as any,
              zIndex: 1000,
              ...Platform.select({
                web: {
                  left: 'calc(50% - 12px)' as any,
                  top: 'calc(50% - 12px)' as any,
                },
                native: {
                  left: 18,
                  top: 18,
                },
              }),
            },
          },
        },
        content: {
          container: {
            display: 'flex' as any,
            flexDirection: layout.width <= 572 ? ('column' as any) : ('row' as any),
            flexGrow: 1,
            flexShrink: 1,
            marginTop: 16,
          },
          leftContainer: {
            display: 'flex' as any,
            flexDirection: 'column' as any,
            flexBasis: layout.width <= 572 ? ('auto' as any) : 275,
            marginRight: layout.width <= 572 ? 0 : 16,
            marginBottom: layout.width <= 572 ? 16 : 0,
          },
          rightContainer: {
            display: 'flex' as any,
            flexDirection: 'column' as any,
            flexGrow: 1,
            flexShrink: 1,
            marginBottom: layout.width <= 572 ? 32 : 0,
          },
          details: {
            container: {
              ...StylesHelper.borderRadius(8),
              ...StylesHelper.padding(16),
              backgroundColor: theme.background.missionDetail,
              flexGrow: 1,
              flexShrink: 1,
            },
            containerRemote: {
              ...StylesHelper.borderRadius(8),
              ...StylesHelper.padding(16),
              backgroundColor: theme.background.missionDetail,
              flexGrow: 0,
              flexShrink: 1,
            },
            infoBlocksBase: {
              display: 'flex' as any,
              flexDirection: 'column' as any,
              flexGrow: 0,
            },
            infoBlocks: {
              title: {
                flexDirection: 'row' as any,
                alignItems: 'center' as any,
                gap: 8,
                marginBottom: 6,
              },
              titleIcon: {
                width: 24,
                height: 24,
                color: theme.text.titleDark.toString(),
              },
              content: {
                marginBottom: 24,
                flexGrow: 0,
                flexShrink: 1,
              },
              link: {
                paddingVertical: 8,
                marginBottom: -8,
                textDecorationLine: 'underline' as any,
                textDecorationStyle: 'solid',
                textDecorationColor: theme.text.primary,
              },
              baseLinks: {
                display: 'flex' as any,
                flexDirection: 'row' as any,
                alignItems: 'flex-end' as any,
                justifyContent: 'flex-end' as any,
                flexGrow: 1,
              },
              buttonContainer: {
                display: 'flex' as any,
                flexDirection: 'row' as any,
                justifyContent: 'center' as any,
              },
              button: {
                flexBasis: '50%' as DimensionValue,
                ...StylesHelper.text(13, theme.text.primary, 600, 'none'),
              },
            },
          },
          timeline: {
            container: {
              ...StylesHelper.borderRadius(8),
              backgroundColor: theme.background.missionDetail,
              marginBottom: 16,
              display: 'flex' as any,
              flexDirection: 'column' as any,
              justifyContent: 'space-between' as any,
              overflow: 'hidden' as any,
            },
            item: {
              ...StylesHelper.padding(16, 16),
              display: 'flex' as any,
              flexDirection: 'row' as any,
              alignItems: 'center' as any,
            },
            iconBase: {
              ...StylesHelper.borderRadius(16),

              backgroundColor: theme.text.titleDark,
              position: 'absolute' as any,
              zIndex: 10,
              width: 32,
              height: 32,
              ...Platform.select({
                web: {
                  ...StylesHelper.padding(8, 6),
                },
                native: {
                  ...StylesHelper.padding(7),
                  left: 16,
                },
              }),
            },
            icon: {
              active: {
                backgroundColor: theme.primary,
              },
              error: {
                backgroundColor: theme.text.error,
              },
              disabled: {
                backgroundColor: 'white',
              },
            },
            content: {
              flexGrow: 1,
              display: 'flex' as any,
              flexDirection: 'column' as any,
              marginLeft: 8,
              paddingLeft: 32,
              flexShrink: 1,
            },
            contentDisabled: {
              color: theme.text.titleLight as any,
            },
            contentError: {
              backgroundColor: 'rgb(247, 222, 225)' as any,
            },
            contentActive: {
              backgroundColor: theme.background.missionDetailSummary as any,
            },
            dottedLine: {
              position: 'absolute' as any,
              zIndex: 0,
              left: 31,
              ...Platform.select({
                native: {
                  width: 1,
                  // borderRadius: 1,
                  borderStyle: 'solid' as any,
                  borderWidth: 1,
                  borderColor: theme.text.titleDark,
                },
                web: {
                  width: 1,
                  borderRightStyle: 'dotted' as any,
                  borderRightWidth: 2,
                  borderRightColor: theme.text.titleDark,
                },
              }),
            },
            dottedBottom: {
              top: 32,
              ...Platform.select({
                web: {
                  height: 31,
                },
                native: {
                  height: 36,
                },
              }),
            },
            dottedUp: {
              top: 0,
              ...Platform.select({
                web: {
                  height: 31,
                },
                native: {
                  height: 36,
                },
              }),
            },
            dottedBoth: {
              top: 0,
              ...Platform.select({
                web: {
                  height: 62,
                },
                native: {
                  height: 68,
                },
              }),
            },
          },
          summary: {
            container: {
              ...StylesHelper.borderRadius(8),
              ...StylesHelper.padding(8),
              backgroundColor: theme.background.missionDetail,
              marginBottom: 16,
              display: 'flex' as any,
              flexDirection: layout.width > 572 ? ('column' as any) : ('row' as any),
              flexWrap: 'wrap' as any,
            },
            item: {
              display: 'flex' as any,
              flexDirection: 'row' as any,
              alignItems: 'center' as any,
              padding: 8,
              flexShrink: 1
            },
            icon: {
              marginRight: 8,
              flexShrink: 0
            },
          },
          actionButtonsContainer: {
            display: 'flex' as any,
            flexDirection: layout.width > 572 ? ('column' as any) : ('row' as any),
            flexGrow: 0,
            flexShrink: 1,
          },
          actionButtonsContainerAccept: {
            display: 'flex' as any,
            flexGrow: 0,
            flexShrink: 1,
            alignItems: 'center' as any
          },
          delayMission: {
            container: {
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: '48%' as any,
              ...StylesHelper.borderRadius(8),
              ...StylesHelper.padding(12),
              flexDirection: 'row' as any,
              marginRight: layout.width <= 572 ? 16 : 0,
              marginBottom: layout.width <= 572 ? 0 : 16,
              backgroundColor: 'rgb(210, 237, 253)' as any,
              alignItems: 'center' as any,
            },
            text: {
              ...Platform.select({
                web: {
                  marginLeft: 8,
                },
                native: {
                  marginLeft: 8,
                  flexShrink: 1,
                },
              }),
              ...StylesHelper.text(13, 'rgb(52, 114, 184)', 600, 'none', 'underline'),
            },
          },
          submitMission: {
            container: {
              flexShrink: 1,
              ...StylesHelper.borderRadius(40),
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 8,
              paddingBottom: 8,
              borderWidth: 2,
              borderColor: 'rgb(245, 165, 35)' as any,
              flexDirection: 'row' as any,
              alignItems: 'center' as any,
              margin: layout.width < 1040 ? 'auto' as any : 0
            },
            text: {
              ...Platform.select({
                web: {
                  marginLeft: 8,
                },
                native: {
                  marginLeft: 8,
                  flexShrink: 1,
                },
              }),
              ...StylesHelper.text(16, 'black', 600, 'none'),
              marginTop: -4,
            },
          },
          cancelMission: {
            container: {
              flexGrow: 1,
              flexShrink: 1,
              flexBasis: '48%' as any,
              ...StylesHelper.borderRadius(8),
              ...StylesHelper.padding(12),
              flexDirection: 'row' as any,
              backgroundColor: 'rgb(247, 222, 225)' as any,
              alignItems: 'center' as any,
            },
            text: {
              ...Platform.select({
                web: {
                  marginLeft: 8,
                },
                native: {
                  marginLeft: 8,
                },
              }),
              ...StylesHelper.text(13, 'rgb(138, 47, 72)', 600, 'none', 'underline'),
              flexGrow: 1
            },
          },
        },
      },
    },
  };

  return myMissions as typeof myMissions;
};
export default useMyMissionStyles;

import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Dimensions, Pressable, View } from 'react-native';
import useCustomTheme from '../../hooks/theme-hook';
import Label, { LabelType } from './Label';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { notificationActions, NotificationOpenState } from '../../store/notificationSlice';
import useStyles from '../../hooks/styles-hook';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faChevronCircleRight, faCircle } from '@fortawesome/pro-regular-svg-icons';
import { ViewProps } from 'react-native-svg/lib/typescript/fabric/utils';

export interface NotificationProps extends ViewProps {
  content?: ReactElement;
  count?: string;
  openState?: NotificationOpenState;
}

const Notification = (props: NotificationProps) => {
  const screenHeight = Dimensions.get('window').height;
  const screenWidth = Dimensions.get('window').width;
  const top = screenHeight / 2.0 - 50;

  const styles = useStyles((section) => section.notification);
  const theme = useCustomTheme();
  const dispatch = useAppDispatch();
  const notificationStore = useAppSelector((selector) => selector.notification);
  const [openState, setOpenState] = useState(props.openState ?? notificationStore.openState);
  const [position, setPosition] = useState({ right: '-100%' } as any);
  const [hidden, setHidden] = useState('none' as any);

  const setOpenStateCallBack = useCallback(() => {
    if (notificationStore.openState) {
      setOpenState(notificationStore.openState);
    }

    switch (openState) {
      case NotificationOpenState.open:
        setPosition({ right: 0 });
        setHidden('flex');
        break;
      case NotificationOpenState.closed: {
        setPosition({ left: screenWidth - 50 });
        setHidden('flex');
        break;
      }
      default:
        setPosition({ right: '-100%' });
        setHidden('none');
        break;
    }
  }, [openState, notificationStore.openState]);

  const handleOpenCallBack = useCallback(() => {
    if (notificationStore.openState) {
      dispatch(notificationActions.setOpenState(NotificationOpenState.open));
    } else {
      setOpenState(NotificationOpenState.open);
    }
  }, [openState, notificationStore.openState]);

  const handleCloseCallBack = useCallback(() => {
    if (notificationStore.openState) {
      dispatch(notificationActions.setOpenState(NotificationOpenState.closed));
    } else {
      setOpenState(NotificationOpenState.closed);
    }
  }, [openState, notificationStore.openState]);

  useEffect(() => {
    setOpenStateCallBack();
  }, [openState]);

  useEffect(() => {
    if (notificationStore.openState !== undefined) {
      setOpenState(notificationStore.openState);
    }
  }, [notificationStore.openState]);

  return (
    <View style={[{ ...position, display: hidden, top }, styles.containerBase]}>
      <Pressable onPress={handleOpenCallBack}>
        <View style={styles.button.information}>
          <Label variant={LabelType.caption} style={{ color: theme.colorErrorPrimary }}>
            {notificationStore.count ?? props.count}
          </Label>
        </View>
        <FontAwesomeIcon icon={faCircle} size={48} color={'white'} />
      </Pressable>
      <View style={styles.content}>{notificationStore.content ?? props.content}</View>
      <Pressable onPress={handleCloseCallBack}>
        <FontAwesomeIcon icon={faChevronCircleRight} size={48} color={'white'} style={{ marginLeft: 8 }} />
      </Pressable>
    </View>
  );
};

export default Notification;

import React, { useEffect, useState } from 'react';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { View, Image } from 'react-native';
import CustomButton from '../CustomButton';
import Label, { LabelType } from '../Label';
import useTranslation from '../../../hooks/translation-hook';
import { useNavigation } from '@react-navigation/core';
import { useAppDispatch } from '../../../hooks/store-hook';
import { missionsActions } from '../../../store/missionsSlice';

export interface SurveyCompletedProps {
  isWebview: boolean;
}

const SurveyCompleted = (props: SurveyCompletedProps) => {
  const t = useTranslation();
  const tMission = useTranslation({ defaultPath: 'mission' });
  const navigation = useNavigation();
  const dispatch = useAppDispatch();

  const [isWebview] = useState(props.isWebview);

  useEffect(() => {
    if (!isWebview) {
      dispatch(missionsActions.setShowOnARollPopup(true));
    } else {
      (window as any).ReactNativeWebView.postMessage('showOnARollPopup');
    }
  }, [isWebview]);

  return (
    <View
      style={{
        height: '100%',
        flexGrow: 1,
        flexShrink: 1,
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        ...StylesHelper.padding(16, 24),
      }}
    >
      <View style={{ flexGrow: 1, flexShrink: 1, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', marginTop: 16 }}>
        <View style={{ alignContent: 'center', justifyContent: 'center', flexShrink: 1 }}>
          <Image style={{ width: 229, height: 274, alignSelf: 'center' }} source={require('../../../../assets/SurveyCongrats.png')} />
          <Label variant={LabelType.titleLargeNoCapsPrimary} style={{ textAlign: 'center', fontWeight: '800', fontSize: 30, flexShrink: 1, marginTop: 16 }}>
            {tMission('congratulations')}
          </Label>
          <Label variant={LabelType.titleLargeLight} style={{ textAlign: 'center', fontWeight: '800', fontSize: 20, flexShrink: 1 }}>
            {tMission('survey-completed')}
          </Label>
          <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 175, paddingVertical: 24, flexShrink: 1 }}>
            {tMission('next-mission-cta')}
          </Label>
          <CustomButton
            title={t('missions-available')}
            onPress={() => {
              if (isWebview) {
                (window as any).ReactNativeWebView.postMessage('completed');
              } else {
                navigation.navigate('map' as never);
              }
            }}
            style={{ flexShrink: 1 }}
          />
        </View>
      </View>
    </View>
  );
};

export default SurveyCompleted;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import Label, { LabelType } from '../components/common/Label';
import { userPrefsActions } from '../store/userPrefsSlice';
import { authActions } from '../models/helpers/platformImportHelper';
import { LoggedState } from '../models/AuthModelsCommon';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import { useQueryClient } from '@tanstack/react-query';
import { View } from 'react-native';
import { faArrowRightFromBracket, faDoorOpen, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import useCustomTheme from '../hooks/theme-hook';
import useStyles from '../hooks/styles-hook';
import { AnalyticsHelper } from '../helpers/analyticsHelper';
import useTranslation from '../hooks/translation-hook';
import useMyAccountActions from '../hooks/myAccountActions-hook';

const Logout = ({ route }) => {
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const styles = useStyles((selector) => selector.restrictedArea);
  const theme = useCustomTheme();
  const authSlice = useAppSelector((selector) => selector.auth);
  const navigation = useNavigation();
  const queryClient = useQueryClient();
  const myAccountActions = useMyAccountActions();

  useFocusEffect(
    React.useCallback(() => {
      AnalyticsHelper.logScreenView('logout').then(() => {
        console.log('Analytics', 'Page view: Logout');
      });

      console.log('%cLogin out...', 'color: #ffa600;');

      console.log('%cCancel queries...', 'color: #ffa600;');
      queryClient.cancelQueries();
      console.log('%cRemove queries...', 'color: #ffa600;');
      queryClient.removeQueries();
      console.log('%cInvalidate queries...', 'color: #ffa600;');
      queryClient.invalidateQueries();
      console.log('%cClear queries...', 'color: #ffa600;');
      queryClient.clear();

      myAccountActions.setVisibility(false);

      dispatch(authActions.logout());
    }, [])
  );

  useEffect(() => {
    if (authSlice.logged === LoggedState.NotLogged) {
      console.log('SignUp', route?.params?.signUp);
      console.log('%cLoggedOut', 'color: #ffa600;');

      navigation.navigate({
        name: 'login',
        params: {
          newLogin: 'true',
          signUp: route?.params?.signUp,
          accountDeleted: route?.params?.accountDeleted,
          failToRegister: route?.params?.failToRegister,
          changeEmail: route?.params?.changeEmail,
          emailChanged: route?.params?.emailChanged,
        },
      } as never);
    }
  }, [authSlice.logged]);

  return (
    <View style={[{ backgroundColor: theme.background.primary, alignItems: 'center' }, styles.missionNotLogged.containerBase]}>
      <FontAwesomeIcon style={styles.missionNotLogged.container.icon} size={48} icon={faArrowRightFromBracket} color={theme.text.sea.toString()} />
      <Label style={{ textAlign: 'center', fontSize: 20, fontWeight: '600' }}>{t('Login-out')}</Label>
    </View>
  );
};

export default Logout;

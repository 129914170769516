import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const whiteCardItemCommon = {
  flexGrow: 1,
  flexShrink: 1,
  alignItems: 'baseline' as any,
};

const useMyAccountStyles = () => {
  const theme = useCustomTheme();
  let myAccount = {
    menu: {
      container: {
        position: 'absolute' as any,
        top: 0,
        left: 0,
        zIndex: 1050,
        flexDirection: 'column' as any,
        width: '100%' as any,
        height: '100%' as any,
        backgroundColor: theme.secondary,
      },
      myProfileTitle: { flexGrow: 0, color: 'white' },
      whiteCard: {
        flexGrow: 0,
        flexDirection: 'row' as any,
        justifyContent: 'space-between' as any,
        backgroundColor: 'white',
        marginTop: 16,
        width: '100%' as any,
        ...StylesHelper.borderRadius(8),
        ...StylesHelper.padding(16),
        separator: { borderRightWidth: 1, borderRightColor: theme.borderColor },
        cardItemLeft: { ...whiteCardItemCommon, paddingRight: 16 },
        cardItemRight: { ...whiteCardItemCommon, paddingLeft: 16 },
        marginBottom8: 8,
        cardItemContent: { marginTop: 8, flexGrow: 1 },
        cardItemButton: { marginTop: 16 },
        myRewards: {
          flexDirection: 'row' as any,
          alignItems: 'center' as any,
          flexGrow: 1,
          icon: {
            marginRight: 16,
          },
        },
        points: {
          flexDirection: 'column' as any,
          alignItems: 'flex-end' as any,
          flexGrow: 1,
          widht: '100%' as any,
        },
        items: {
          // marginLeft: 8,
        },
      },
      menuList: { marginTop: 16, flexGrow: 1 },
      logoutButton: {
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
        icon: {
          marginTop: 4,
          marginRight: 10,
          transform: [{ rotateY: '180deg' }],
        },
      },
    },
    menuItem: {
      paddingTop: 10,
      paddingBottom: 10,
      display: 'flex' as any,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
      icon: {
        color: 'white',
        marginRight: 8,
        height: 18,
        width: 18,
        mirror: {
          transform: [{ rotateY: '180deg' }],
        },
      },
    },
    navigationButton: {
      flexDirection: 'row' as any,
      flexGrow: 0,
      alignItems: 'center' as any,
      icon: { marginRight: 6 },
    },
    pageWrapperBase: {
      position: 'absolute' as any,
      top: 0,
      left: 0,
      zIndex: 1001,
      flexDirection: 'column' as any,
      width: '100%' as any,
      height: '100%' as any,
      backgroundColor: theme.background.primary,
    },
    pageWrapper: {
      titleBar: {
        ...StylesHelper.padding(16),
        backgroundColor: theme.background.primary,
      },
      title: {
        marginTop: 0,
        flexGrow: 0,
        flexDirection: 'row' as any,
        alignItems: 'center' as any,
      },
      infoButton: { padding: 16 },
    },
    loggedInfo: {
      container: {
        ...StylesHelper.padding(8, 0),
        flexDirection: 'row' as any,
        marginTop: 16,
        marginBottom: 8,
        alignItems: 'center' as any,
      },
      initials: {
        flexShrink: 0,
        flexGrow: 0,
        ...StylesHelper.borderRadius(50),
        ...StylesHelper.borderWidth(2),
        borderColor: 'white',
        ...StylesHelper.padding(9),
        height: 60,
        width: 60,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
      },
      info: {
        flexShrink: 1,
        flexGrow: 1,
        flexDirection: 'column' as any,
        paddingLeft: 8,
      },
      initialsLabel: { flexGrow: 0, color: 'white', marginTop: -4 },
      initialsLabelEmail: { flexGrow: 0, fontWeight: '400' as any, color: 'white' as any, marginTop: -4 },
    },
  };

  return myAccount as typeof myAccount;
};

export default useMyAccountStyles;

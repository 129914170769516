import React, { useEffect, useRef, useState } from 'react';
import { CustomSurvey, authActions } from '../models/helpers/platformImportHelper';
import CustomMap from './CustomMap';
import Login from './Login';
import Logout from './Logout';
import MyMissions from './MyMissions';
import RemoteMissions from './RemoteMissions';
import UserActionReturn from './UserActionReturn';
import Validation from './Validation';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { FirebaseAuthTypes } from '@react-native-firebase/auth';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { User } from 'firebase/auth';
import { AuthenticateClient, RegistrationDto, DeviceTheme } from '../api/api.g';
import useApi from '../hooks/api-hook';
import { LoggedState } from '../models/AuthModelsCommon';
import { navBarActions } from '../store/navBarSlice';
import { topBarActions } from '../store/topBarSlice';
import EntryPage from './EntryPage';
import { useNavigation, useRoute } from '@react-navigation/core';
import { platform } from 'os';
import { Platform } from 'react-native';
import UnsubscribeNotifications from './UnsubscribeNotifications';

const MainBody = () => {
  const navStore = useAppSelector((selector) => selector.navBar);
  const Stack = createNativeStackNavigator();
  const queryClient = useQueryClient();
  const apiAuth = useApi(AuthenticateClient);
  const authStore = useAppSelector((selector) => selector.auth);
  const dispatch = useAppDispatch();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);

  const userExistQuery = useQuery({
    enabled: !!authStore.idToken && authStore.logged === LoggedState.Logged,
    queryKey: ['userExistQuery'],
    queryFn: () => {
      return apiAuth.getMysteryShopperExists(undefined);
    },
    onError: (err: any) => {
      throw err;
    },
  });

  useEffect(() => {
    if (userExistQuery.isSuccess) {
      if (userExistQuery.data.exists) {
        loginMutation.mutate();
      } else {
        registrationMutation.mutate();
      }
    }
  }, [userExistQuery.isSuccess, userExistQuery.data]);

  const registrationMutation = useMutation({
    retry: false,
    mutationFn: () => {
      const dto = new RegistrationDto();
      dto.language = userPrefs.uiLanguage;
      dto.signUpSource = authStore.signUpSource;

      return apiAuth.registration(dto, apiAuth.tokenSource?.token);
    },
    onSuccess(data, variables) {
      dispatch(authActions.setSignUpSource(undefined));
      loginMutation.mutate();
    },
    onError: (err) => {
      loginMutation.mutate();
      throw err;
    },
  });

  const loginMutation = useMutation({
    retry: false,
    mutationFn: () => {
      return apiAuth.login(undefined);
    },
    onError: (err: any) => {
      throw err;
    },
  });

  useEffect(() => {
    if (loginMutation.isSuccess) {
      console.log('logged in');
    }
  }, [loginMutation.isSuccess, loginMutation.data]);

  useEffect(() => {
    dispatch(authActions.setAuthStateChanged(onAuthStateChanged));
    dispatch(authActions.setIdTokenChanged(updateAuthUser));
  }, []);

  const updateAuthUser = (e: User) => {
    // console.log('updateAuthUser', e);
    dispatch(authActions.setUser(e));
  };

  const onAuthStateChanged = (user: User | FirebaseAuthTypes.User) => {
    console.log('onAuthStateChanged', user);
    if (user) {
      user.getIdToken().then((token) => {
        dispatch(authActions.setLogged({ idToken: token, user }));
      });

      user.getIdTokenResult().then((idTokenResult) => {
        dispatch(authActions.setMultifactor(idTokenResult));
      });
    }
  };

  useEffect(() => {
    if (authStore.logged === LoggedState.NotLogged) {
      console.log('%cReset queries...', 'color: #ffa600;');
      queryClient.resetQueries();
    }
  }, [authStore.logged]);

  useEffect(() => {
    if (authStore.logged !== undefined && (authStore.logged === LoggedState.Logged || authStore.logged === LoggedState.Anonymous)) {
      dispatch(navBarActions.setVisibility(true));
      dispatch(topBarActions.setVisibility(true));
    } else {
      dispatch(navBarActions.setVisibility(false));
      dispatch(topBarActions.setVisibility(false));
    }
  }, [authStore.logged, authStore.idToken]);

  const getSourceParam = () => {
    if (Platform.OS == 'web') {
      let params = new URLSearchParams(document.location.search);

      if (params.has('signUpSource')) {
        let signUpSource = params.get('signUpSource');
        return { signUpSource: signUpSource };
      } else {
        if (params.has('token')) {
          let token = params.get('token');
          return { token: token };
        } else {
          return undefined;
        }
      }
    } else {
      return undefined;
    }
  };

  return (
    <Stack.Navigator initialRouteName={'entryPage'} screenOptions={{ headerShown: false, animation: 'simple_push' }}>
      <Stack.Screen name="entryPage" component={EntryPage} options={{ title: `Lanla - Loading...` }} initialParams={getSourceParam()} />
      <Stack.Screen name="unsubscribe" component={UnsubscribeNotifications} options={{ title: `Lanla` }} initialParams={getSourceParam()} />
      <Stack.Screen name="map" component={CustomMap} options={{ title: `Lanla` }} />
      <Stack.Screen name="login" component={Login} options={{ title: `Lanla` }} />
      <Stack.Screen name="logout" component={Logout} options={{ title: `Lanla` }} />
      <Stack.Screen name="survey" component={CustomSurvey} options={{ title: `Lanla` }} />
      <Stack.Screen name="editSurvey" component={CustomSurvey} options={{ title: `Lanla` }} />
      <Stack.Screen name="validation" component={Validation} options={{ title: `Lanla` }} />
      <Stack.Screen name="mymissions" component={MyMissions} options={{ title: `Lanla` }} />
      <Stack.Screen name="remotemissions" component={RemoteMissions} options={{ title: `Lanla` }} />
      <Stack.Screen name="useractionreturn" component={UserActionReturn} options={{ title: 'Lanla App - User Authentication Confirmation Action' }} />
    </Stack.Navigator>
  );
};

export default MainBody;

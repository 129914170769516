import React, { useEffect, useState } from 'react';
import { Pressable, PressableProps, StyleProp, TextStyle, View } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import Label, { LabelType } from './Label';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

export interface CheckboxProps<T = string | number> extends PressableProps {
  value: T;
  label?: string;
  checked?: boolean;
  textStyle?: StyleProp<TextStyle>;
  small?: boolean;
  color?: string;
  onChange?(checked: boolean, value: T): void;
}

const CustomCheckbox = (props: CheckboxProps) => {
  const [checked, setChecked] = useState(undefined);
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.checkbox);

  const onCheckmarkPress = (e) => {
    setChecked(!checked);
  };

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  useEffect(() => {
    if (checked !== undefined) {
      if (props.onChange) {
        props.onChange(checked, props.value);
      }
    }
  }, [checked]);

  return (
    <Pressable {...props} style={[styles.container, props.small ? styles.containerSmall : {}, props.style as any]} onPress={onCheckmarkPress}>
      {props.label && <Label style={[styles.label, props.textStyle]}>{props.label}</Label>}
      <View style={[styles.base, props.small ? styles.baseSmall : {}, { borderColor: props.color ?? theme.background.sea }]}>
        {checked && (
          <FontAwesomeIcon
            icon={faCheck}
            color={props.color ?? theme.background.darkTurquoise.toString()}
            style={props.small ? styles.markerSmall : styles.marker}
          />
        )}
      </View>
    </Pressable>
  );
};

export default CustomCheckbox;

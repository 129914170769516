import { faStore, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useMemo, useState } from 'react';
import { View, Image, Pressable } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { GetMissionDetailsDto } from '../../../api/api.g';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import CustomButton from '../CustomButton';
import CustomCheckbox from '../CustomCheckbox';
import Label from '../Label';

export interface ComboMissionItem {
  branchName: string;
  dto: GetMissionDetailsDto;
  selected?: boolean;
}

export interface ComboPopupProps {
  onClose?(): void;
  onConfirm?(missions: GetMissionDetailsDto[]): void;
  missions: ComboMissionItem[];
}

const ComboPopup = (props: ComboPopupProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const [missions, setMissions] = useState<ComboMissionItem[]>();
  const defaultLogo = useMemo(
    () => (
      <FontAwesomeIcon
        icon={faStore}
        style={{
          padding: 7,
          color: theme.text.titleLight.toString(),
          width: '100%',
          height: '100%',
        }}
      />
    ),
    [theme]
  );

  useEffect(() => {
    setMissions(props.missions);
  }, [props.missions]);

  const getLogo = (logoUrl) => {
    if (logoUrl) {
      return <Image style={{ width: '100%', height: '100%' }} source={{ uri: logoUrl }} />;
    } else {
      return defaultLogo;
    }
  };

  const handleOnClosePress = () => {
    props.onClose && props.onClose();
  };

  return (
    <View style={{ flexGrow: 1, backgroundColor: '#eaf8ff', maxWidth: 350, maxHeight: 600 }}>
      <Image source={require('../../../../assets/combo-breaker.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto' }} />
      <Pressable style={{ position: 'absolute' as any, top: 20, right: 20 }} onPress={handleOnClosePress}>
        <FontAwesomeIcon icon={faXmark} size={30} color={theme.background.primary.toString()} />
      </Pressable>
      <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8 }}>
        <View style={{ margin: 'auto', paddingLeft: 12, paddingRight: 12, paddingBottom: 22, display: 'flex', flexGrow: 1, flexShrink: 1, width: '100%' }}>
          <Label style={{ fontSize: 28, color: '#1473be', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
            {t('combo-mission-title')}
          </Label>
          <Label style={{ marginTop: 12, fontSize: 18, color: '#00324a', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
            {t('combo-mission-desc')}
          </Label>
          <ScrollView style={{ flexGrow: 1, flexShrink: 1, marginTop: 12 }}>
            {missions?.map((x, i) => (
              <Pressable
                key={x.dto.periodMissionConfigurationId}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: 8,
                  borderTopWidth: i == 0 ? 1 : 0,
                  borderBottomWidth: 1,
                  borderColor: 'rgba(0, 171, 255, 0.2)',
                  paddingHorizontal: 20,
                  paddingVertical: 8,
                  alignItems: 'center',
                  backgroundColor: x.selected ? '#d7f2ff' : undefined,
                }}
                onPress={() => {
                  setMissions((prev) => {
                    let find = prev.find((mission) => mission.dto.periodMissionConfigurationId == x.dto.periodMissionConfigurationId);
                    find.selected = !find.selected;
                    return prev.slice();
                  });
                }}
              >
                <View>
                  <CustomCheckbox
                    small
                    value={'1'}
                    checked={x.selected}
                    onChange={(checked) => {
                      let newList = JSON.parse(JSON.stringify(missions));
                      let find = newList.find((mission) => mission.dto.periodMissionConfigurationId == x.dto.periodMissionConfigurationId);
                      find.selected = checked;
                      setMissions(newList);
                    }}
                  />
                </View>
                <View
                  style={{
                    display: 'flex' as any,
                    flexDirection: 'row' as any,
                    alignItems: 'center' as any,
                    justifyContent: 'center' as any,
                    width: 38,
                    height: 38,
                    borderRadius: 19,
                    borderWidth: 1,
                    borderColor: 'rgba(0, 171, 255, 0.2)',
                    overflow: 'hidden' as any,
                    backgroundColor: theme.background.secondary,
                  }}
                >
                  {getLogo(x.dto.logoUrl)}
                </View>
                <View style={{ gap: 5 }}>
                  <Label style={{ fontSize: 14, fontWeight: '600', textTransform: 'uppercase' }}>{x.dto.missionConfigurationName}</Label>
                  <Label style={{ fontSize: 11, color: '#035164', fontWeight: '600' }}>{x.branchName}</Label>
                </View>
              </Pressable>
            ))}
          </ScrollView>
          <CustomButton
            style={{ marginTop: 16, backgroundColor: '#1473be', borderColor: '#1473be' }}
            textStyle={{ color: 'white' }}
            title={t('combo-mission-accept')}
            onPress={() => {
              props.onConfirm && props.onConfirm(missions.filter((x) => x.selected).map((x) => x.dto));
            }}
          />
        </View>
      </View>
    </View>
  );
};

export default ComboPopup;

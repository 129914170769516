import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import Loading from '../components/common/Loading';
import { LabelType } from '../components/common/Label';
import { useAppSelector } from '../hooks/store-hook';
import { CommonActions, useFocusEffect } from '@react-navigation/native';
import { LoggedState } from '../models/AuthModelsCommon';

const EntryPage = ({ route, routes, navigation }) => {
  const authStore = useAppSelector((selector) => selector.auth);
  const navBarStore = useAppSelector((selector) => selector.navBar);

  useFocusEffect(
    React.useCallback(() => {
      let timerId;

      if (navBarStore.isWebView) {
        //@ts-ignore
        window.ReactNativeWebView.postMessage('error');
      } else {
        if (authStore.user) {
          if (authStore.idToken && (authStore.logged === LoggedState.Anonymous || authStore.logged === LoggedState.Logged)) {
            timerId = setTimeout(() => {
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: navBarStore.currentPath ?? 'map' }],
                })
              );
            }, 1000);
          } else {
            timerId = setTimeout(() => {
              navigation.dispatch(
                CommonActions.reset({
                  index: 0,
                  routes: [{ name: 'login', params: route?.params } as never],
                })
              );
            }, 1000);
          }
        } else {
          timerId = setTimeout(() => {
            navigation.dispatch(
              CommonActions.reset({
                index: 0,
                routes: [{ name: 'login', params: route?.params } as never],
              })
            );
          }, 1000);
        }
      }
    }, [authStore.logged])
  );

  return (
    <LinearGradient end={{ x: 0.1, y: 0.4 }} style={{ height: '100%', width: '100%' }} colors={['#022d38', '#034050', '#044c5e', '#04647c', '#43a49e']}>
      <Loading textVariant={LabelType.loginLoading} iconColor={'white'} />
    </LinearGradient>
  );
};

export default EntryPage;

import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useSettingsOptionsStyles = () => {
  const theme = useCustomTheme();

  let useSettingsOptionsStyles = {
    container: {
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
      ...StylesHelper.padding(16, 0, 8, 0),
    },
    readOnly: {
      opacity: 0.5,
      cursor: 'cursor',
    },
    icon: {
      flexShrink: 0
    },
    textBase: {
      flexDirection: 'column' as any,
      flexGrow: 1,
      flexShrink: 1
    },
    text: {
      title: {
        color: theme.text.titleDark,
      },
      optionLabel: {
        ...StylesHelper.text(16, theme.text.primary, '400'),
        flexShink: 1
      },
      icon: {
        alignSelf: 'flex-end' as any,
      },
    },
    addButton: {
      justifyContent: 'flex-end' as any,
      marginTop: 16,
    },
    addButtonLabel: {
      ...StylesHelper.text(12, theme.text.titleLight, 500, undefined, 'underline'),
    },
  };

  return useSettingsOptionsStyles as typeof useSettingsOptionsStyles;
};

export default useSettingsOptionsStyles;

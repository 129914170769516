import { Platform } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useNotificationStyles = () => {
  const theme = useCustomTheme();

  const notification = {
    containerBase: {
      position: 'absolute' as any,
      minHeight: 50,
      zIndex: 1000,
      maxWidth: '90%' as any,
      backgroundColor: theme.colorErrorPrimary,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
      borderTopLeftRadius: 25,
      borderBottomLeftRadius: 25,
      justifyContent: 'space-between' as any,
    },
    container: {
      ...Platform.select({
        default: {
          width: 'max-content' as any,
          ...StylesHelper.padding(10, 8, 10, 10),
        },
        native: {
          ...StylesHelper.padding(10, 20, 10, 10),
        },
      }),
    },
    content: {
      marginLeft: 16,
      maxWidth: '80%' as any,
      flexShrink: 0,
      flexGrow: 1,
    },
    button: {
      information: {
        borderRadius: 20,
        height: 16,
        width: 16,
        backgroundColor: 'white' as any,
        position: 'absolute' as any,
        right: -4,
        top: 0,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
      },
    },
  };

  return notification as typeof notification;
};

export default useNotificationStyles;

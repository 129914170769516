import React from 'react';
import { ColorValue, Pressable, PressableStateCallbackType, StyleProp, ViewStyle } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import Label, { LabelType } from './Label';
import useCustomTheme from '../../hooks/theme-hook';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { IconDefinition, faCircleArrowLeft } from '@fortawesome/free-solid-svg-icons';

export interface NavigationButtonProps {
  text: string;
  icon?: IconDefinition;
  iconSize?: number;
  iconColor?: ColorValue;
  labelVariant?: LabelType;
  color?: ColorValue;
  onPress(e): void;
  style?: StyleProp<ViewStyle>;
}

const NavigationButton = (props: NavigationButtonProps) => {
  const styles = useStyles((section) => section.myAccount);
  const theme = useCustomTheme();

  const onPressHandle = (e) => {
    props.onPress(e);
  };

  return (
    <Pressable style={[styles.navigationButton, props.style ?? {}]} onPress={onPressHandle}>
      <FontAwesomeIcon
        icon={props.icon ?? faCircleArrowLeft}
        size={props.iconSize ?? 24}
        color={props.iconColor?.toString() ?? theme.secondaryLight.toString()}
        style={styles.navigationButton.icon}
      />
      <Label variant={props.labelVariant ?? LabelType.navigationTitle} style={{ color: props.color ?? theme.text.primary }}>
        {props.text}
      </Label>
    </Pressable>
  );
};

export default NavigationButton;

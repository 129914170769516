
import { useEffect, useState } from 'react';
import { MysteryShopperClient } from '../api/api.g';
import { UserPrefsHelper } from '../models/helpers/userPrefsHelper';
import { UserPreferencesState, userPrefsActions } from '../store/userPrefsSlice';
import useApi from './api-hook';
import { useAppDispatch, useAppSelector } from './store-hook';
import { useMutation } from '@tanstack/react-query';

const useUserPrefsService = () => {
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const dispatch = useAppDispatch();
  const apiMysteryShopper = useApi(MysteryShopperClient);

  const [callOnUpdateDone, setCallOnUpdateDone] = useState<(pref?) => void>(undefined);

  const saveBasicInfo = async (onUpdateDone?: () => void) => {
    const data = UserPrefsHelper.mapToSaveBasicInformationDto(userPrefs);

    try {
      // Updating the DB
      await apiMysteryShopper.saveBasicInformation(data);
    } catch (error) {
      // Handle API Error
      console.log('Error save Profile Basic Info', error);
    } finally {
      setCallOnUpdateDone(onUpdateDone);
    }
  };

  const getMysteryShopperProfileMutation = useMutation({
    mutationFn: () => {
      return apiMysteryShopper.getProfile();
    }
  })

  useEffect(() => {
    if (getMysteryShopperProfileMutation.isSuccess) {
      const userProfile = getMysteryShopperProfileMutation.data;
      const userPref = UserPrefsHelper.mapFromToDto<UserPreferencesState>(userProfile.mysteryShopper);
      dispatch(userPrefsActions.setBasicInfo(userPref));
      userPref.profileMembers = userProfile.availableProfileMembers
      dispatch(userPrefsActions.setProfileMembers(userProfile.availableProfileMembers));

      callOnUpdateDone && callOnUpdateDone(userPref);
    }
  }, [getMysteryShopperProfileMutation.isSuccess, getMysteryShopperProfileMutation.data])

  const updateUserProfileInfo = async (onUpdateDone?: (pref?) => void) => {
    getMysteryShopperProfileMutation.mutate();
    setCallOnUpdateDone(() => onUpdateDone);
  };

  return {
    saveBasicInfo: saveBasicInfo,
    updateUserProfileInfo: updateUserProfileInfo,
  };
};

export default useUserPrefsService;

import { DimensionValue, Platform } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';

const useNavBarStyles = () => {
  const theme = useCustomTheme();

  const navBar = {
    container: {
      backgroundColor: theme.background.navBar,
      display: 'flex' as any,
      flexDirection: 'row' as any,
      paddingTop: 12,
      paddingBottom: 6,
      borderTopWidth: 1,
      borderTopColor: theme.borderColorLight,
    },
    buttonBase: {
      flexDirection: 'column' as any,
      flexGrow: 1,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
    },
    button: {
      image: {
        marginBottom: 2,
        outline: 'none' as any,
      },
      indicator: {
        position: 'absolute' as any,
        zIndex: 1001,
        top: (Platform.OS === 'web' ? '50%' : 2) as DimensionValue,
        right: -4,
      },
    },
  };

  return navBar as typeof navBar;
};

export default useNavBarStyles;

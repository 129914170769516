import React, { ReactElement, useEffect, useState } from 'react';
import { Pressable, View } from 'react-native';
import useCustomTheme from '../../hooks/theme-hook';
import Label, { LabelType } from './Label';
import CustomSwitch, { CustomSwitchProps } from './CustomSwitch';
import useStyles from '../../hooks/styles-hook';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBadgeCheck, faPenToSquare, faSealExclamation } from '@fortawesome/pro-regular-svg-icons';
import MoreInfoButton from './MoreInfoButton';

export interface SettingsOptionsProps extends CustomSwitchProps {
  title?: string | ReactElement;
  option?: string;
  bottomBorder?: boolean;
  onOff?: boolean;
  icon?: ReactElement;
  stickyOn?: boolean;
  readonly?: boolean;
  verified?: boolean;
  verifiedText?: string;
  unverifiedText?: string;
}

const SettingsOptions = (props: SettingsOptionsProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.settingsOptions);
  const [isEnabled, setIsEnabled] = useState<boolean>(props.isEnabled ?? false);
  const bottomBorder = props.bottomBorder ? { borderBottomWidth: 1, borderBottomColor: 'white' } : {};
  const [lastChild, setLastChild] = useState(<></>);
  const [onOff, setOnOff] = useState(props.onOff);

  const [option, setOption] = useState(props.option);

  const handleOnPress = (e: any) => {
    if (props.readonly) {
      return;
    }

    if (onOff) {
      setIsEnabled((current) => {
        const newValue = props.stickyOn ? true : !current;

        if (props.onPress) {
          props.onPress(newValue);
        }

        return newValue;
      });
    } else {
      if (props.onPress) {
        props.onPress(isEnabled);
      }
    }
  };

  useEffect(() => {
    setOption(props.option);
  }, [props.option]);

  useEffect(() => {
    if (!props.readonly) {
      setLastChild(
        onOff ? (
          <CustomSwitch onPress={handleOnPress} isEnabled={isEnabled} />
        ) : (
          <View style={{ display: 'flex', gap: 4 }}>
            {props.verified != undefined && (
              <>
                {props.verified ? (
                  <MoreInfoButton style={{ marginLeft: 4 }} icon={<FontAwesomeIcon icon={faBadgeCheck} color={theme.text.titleLight.toString()} size={16} />}>
                    {props.verifiedText ?? 'verified'}
                  </MoreInfoButton>
                ) : (
                  <MoreInfoButton
                    style={{ marginLeft: 4 }}
                    icon={<FontAwesomeIcon icon={faSealExclamation} color={theme.text.titleLight.toString()} size={16} />}
                  >
                    {props.unverifiedText ?? 'unverified'}
                  </MoreInfoButton>
                )}
              </>
            )}
            <FontAwesomeIcon style={styles.text.icon} icon={faPenToSquare} size={16} color={theme.text.titleLight.toString()} />
          </View>
        )
      );
    } else {
      if (props.verified != undefined) {
        setLastChild(
          <>
            {props.verified ? (
              <MoreInfoButton style={{ marginLeft: 4 }} icon={<FontAwesomeIcon icon={faBadgeCheck} color={theme.text.titleLight.toString()} size={16} />}>
                {props.verifiedText ?? 'verified'}
              </MoreInfoButton>
            ) : (
              <MoreInfoButton style={{ marginLeft: 4 }} icon={<FontAwesomeIcon icon={faSealExclamation} color={theme.text.titleLight.toString()} size={16} />}>
                {props.unverifiedText ?? 'unverified'}
              </MoreInfoButton>
            )}
          </>
        );
      }
    }
  }, [isEnabled, onOff]);

  useEffect(() => {
    setOnOff(props.onOff ?? false);
  }, [props.onOff]);

  useEffect(() => {
    setIsEnabled(props.isEnabled ?? false);
  }, [props.isEnabled]);

  return (
    <Pressable style={{ ...bottomBorder, ...styles.container, ...(props.readonly ? styles.readOnly : {}) }} onPress={handleOnPress}>
      <View style={styles.icon}>{props.icon}</View>
      <View style={styles.textBase}>
        <>
          {props.title && typeof props.title === 'string' && (
            <Label variant={LabelType.titleSmall} style={styles.text.title}>
              {props.title}
            </Label>
          )}
          {props.title && typeof props.title !== 'string' && <>{props.title}</>}
          {option && (
            <Label style={styles.text.optionLabel} numberOfLines={1}>
              {option}
            </Label>
          )}
        </>
      </View>
      {lastChild}
    </Pressable>
  );
};

export default SettingsOptions;

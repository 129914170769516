import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { ButtonBarConfig } from '../models/ButtonBarModels';

interface ModalState {
  title: string;
  visible: boolean;
  content: ReactElement;
  buttonBarConfig: ButtonBarConfig;
  onVisibilityChange?(visible: boolean): void;
}

// Define the initial state using that type
const initialState: ModalState = {
  title: '',
  visible: false,
  content: <></>,
  buttonBarConfig: {},
};

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setConfig: (state, action: PayloadAction<ModalState>) => {
      state.title = action.payload.title;
      state.buttonBarConfig = action.payload.buttonBarConfig;
      state.content = action.payload.content;
      state.onVisibilityChange = action.payload.onVisibilityChange;

      if (state.visible !== action.payload.visible && state.onVisibilityChange) {
        state.onVisibilityChange(action.payload.visible);
      }

      state.visible = action.payload.visible;
    },
    setContent: (state, action: PayloadAction<ReactElement>) => {
      state.content = action.payload;
    },
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;

      if (state.onVisibilityChange) {
        state.onVisibilityChange(state.visible);
      }
    },
    resetModal: (state) => {
      state.title = initialState.title;
      state.buttonBarConfig = initialState.buttonBarConfig;
      state.content = initialState.content;
      state.visible = initialState.visible;

      if (state.onVisibilityChange) {
        state.onVisibilityChange(state.visible);
      }
    },
  },
});

export default modalSlice.reducer;
export const modalActions = modalSlice.actions;

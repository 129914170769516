import React, { useCallback, useEffect, useState } from 'react';
import { Pressable, View, ViewProps, ScrollView, Dimensions, Platform, DimensionValue } from 'react-native';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { drawerActions, DrawerOpenState } from '../../store/drawerSlice';
import ButtonBar from './ButtonBar';
import Label, { LabelType } from './Label';
import useTranslation from '../../hooks/translation-hook';
import Overlay from './Overlay';
import { faCircleXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { useMousePointer } from '../../hooks/mouse-hook';
import Loading from './Loading';
import { StylesHelper } from '../../models/helpers/stylesHelper';
import Animated, { Easing, FadeIn, FadeInDown, FadeInUp, FadeOutDown, FadeOutUp, useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated';

export interface DrawerProps extends ViewProps {
  dockBottom?: boolean;
  dockTop?: boolean;
  dockRight?: boolean;
  dockLeft?: boolean;
  drawerElementType: DrawerElement;
  showCloseButton?: boolean;
  icon?: any;
  asPopup?: boolean;
}

export enum DrawerElement {
  container,
  header,
  content,
}

const Drawer = (props: DrawerProps) => {
  const isWeb = Platform.OS === 'web';
  const screenHeight = Dimensions.get('window').height;
  const screenWidth = Dimensions.get('window').width;

  const headerSize = 94;
  const openedHeight = screenHeight * 0.9;

  const mousePointer = useMousePointer();

  const t = useTranslation();
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.drawer);
  const userPrefs = useAppSelector((state) => state.userPrefs);
  const drawerState = useAppSelector((state) => state.drawer);
  const authState = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  const [closeButton, setCloseButton] = useState(<></>);
  const [icon, setIcon] = useState(<></>);
  const [hanger, setHanger] = useState(<></>);
  const [elStyle, setElStyle] = useState([]);
  const [maxHeight, setMaxHeight] = useState<number>(openedHeight);
  const [maxWidth, setMaxWidth] = useState<DimensionValue>('100%');
  const [position, setPosition] = useState<DimensionValue>(props.dockBottom || drawerState.forceDockBottom ? -maxHeight : '-32%');
  const [topPosition, setTopPosition] = useState<DimensionValue>(0);
  const [children, setChildren] = useState(props.children);

  // const handleMinimizeButton = useCallback(() => {
  //   if (drawerState.state === DrawerOpenState.minimized) {
  //     console.log('here 1', drawerState.state);
  //     dispatch(drawerActions.setState(DrawerOpenState.opened));
  //   } else {
  //     console.log('here 2', drawerState.state);
  //     dispatch(drawerActions.setState(DrawerOpenState.minimized));
  //   }
  // }, [drawerState.state]);

  const setTitleCallback = useCallback(() => {
    if (props.drawerElementType === DrawerElement.header) {
      let innerStyle: any = drawerState.showIcon ? { marginLeft: 8 } : {};
      innerStyle = !drawerState.resizable ? { ...innerStyle } : innerStyle;

      setChildren(
        <Label variant={LabelType.title} style={innerStyle}>
          {drawerState.title}
        </Label>
      );
    }
  }, [drawerState.title, drawerState.content, drawerState.state, drawerState.showIcon, props.drawerElementType]);

  const setButtonBarCallback = useCallback(() => {
    if (drawerState.showButtonBar && drawerState.buttonBarConfig && props.drawerElementType === DrawerElement.content) {
      setChildren((current) => {
        return (
          <>
            <ScrollView style={{ maxHeight: maxHeight - headerSize - 60 }}>{current}</ScrollView>
            <ButtonBar
              leftButtonLabel={drawerState.buttonBarConfig.cancelButtonLabel ?? t('cancel')}
              rightButtonLabel={drawerState.buttonBarConfig.confirmButtonLabel ?? t('confirm')}
              onLeftButtonClick={drawerState.buttonBarConfig.onCancelButtonClick}
              onRightButtonClick={() => {
                setLoading(true);

                if (drawerState.buttonBarConfig.onConfirmButtonClick) {
                  drawerState.buttonBarConfig.onConfirmButtonClick();
                }
              }}
            />
          </>
        );
      });
    }
  }, [
    drawerState.title,
    drawerState.content,
    drawerState.state,
    drawerState.showIcon,
    drawerState.showButtonBar,
    drawerState.buttonBarConfig,
    drawerState.forceDockBottom,
    props.drawerElementType,
    props.dockBottom,
    props.dockRight,
    maxHeight,
    headerSize,
  ]);

  const hangerComponent = drawerState.resizable && (
    <Pressable
      style={styles.container.hanger}
      onPress={() => {
        if (drawerState.state === DrawerOpenState.minimized) {
          dispatch(drawerActions.setState(DrawerOpenState.opened));
        } else {
          dispatch(drawerActions.setState(DrawerOpenState.minimized));
        }
      }}
    >
      <View>
        <View style={styles.container['hanger.bullet']}></View>
      </View>
    </Pressable>
  );

  const stylesCallback = useCallback(() => {
    switch (props.drawerElementType) {
      case DrawerElement.content:
        setElStyle((state) => [...state, [styles.content, drawerState.contentStyle]]);
        setChildren(<View style={{ maxHeight: maxHeight - headerSize }}>{drawerState.content}</View>);
        setButtonBarCallback();
        break;
      case DrawerElement.header:
        if (!drawerState.resizable) {
          setElStyle((state) => [...state, styles.header, styles.header.regularPadding]);
        } else {
          setElStyle((state) => [...state, styles.header]);
        }

        break;
      default:
        setElStyle((state) => [...state, styles.containerBase]);
        setHanger(hangerComponent);

        if (props.dockBottom || drawerState.forceDockBottom) {
          setElStyle((state) => [...state, styles.container.dockBottom]);
        } else {
          if (props.dockRight && !drawerState.forceDockBottom) {
            setElStyle((state) => [...state, styles.container.dockRight]);
          } else {
            if (props.asPopup && !drawerState.forceDockBottom) {
              setElStyle((state) => [...state, styles.container.asPopup]);
            } else {
              setElStyle((state) => [...state, styles.container.dockBottom]);
            }
          }
        }
        break;
    }
  }, [
    drawerState.state,
    drawerState.content,
    drawerState.title,
    props.drawerElementType,
    props.dockBottom,
    drawerState.forceDockBottom,
    props.dockRight,
    // handleMinimizeButton,
    maxHeight,
    userPrefs.theme,
    setButtonBarCallback,
  ]);

  useEffect(() => {
    if (!authState.logged) {
      dispatch(drawerActions.setContent());
      dispatch(drawerActions.setState(DrawerOpenState.closed));
    }
  }, [authState.logged]);

  useEffect(() => {
    setMaxHeight(openedHeight);
    animatedMaxHeight.value = openedHeight;
  }, [screenHeight, screenWidth]);

  useEffect(() => {
    if (drawerState.state === DrawerOpenState.closed) {
      if (!props.asPopup) {
        setPosition(props.dockBottom || drawerState.forceDockBottom ? -maxHeight : '-32%');
      }
    }
  }, [drawerState.state, drawerState.forceDockBottom, props.dockBottom, props.dockRight, screenHeight, screenWidth]);

  useEffect(() => {
    setLoading(false);

    if (props.drawerElementType === DrawerElement.container) {
      // update the hanger button
      // setHanger(hangerComponent);

      let newState = drawerState.state;

      switch (drawerState.state) {
        case DrawerOpenState.opened:
          newState = DrawerOpenState.opened;
          setHanger(hangerComponent);

          if (props.dockRight && !drawerState.forceDockBottom) {
            setMaxHeight(openedHeight);
            animatedMaxHeight.value = openedHeight;
            setMaxWidth('32%');

            if (!props.asPopup) {
              setPosition(0);
              setTopPosition(mousePointer.y);
            }
          } else {
            if (props.asPopup) {
              setMaxHeight(openedHeight);
              animatedMaxHeight.value = openedHeight;
              setMaxWidth('35%');
            } else {
              setMaxHeight(openedHeight);
              animatedMaxHeight.value = openedHeight;
              setMaxWidth('100%');
              if (!props.asPopup) {
                setPosition(0);
                setTopPosition(isWeb ? ('unset' as DimensionValue) : undefined);
              }
            }
          }

          break;
        case DrawerOpenState.minimized:
          newState = DrawerOpenState.minimized;
          setHanger(hangerComponent);

          if (props.dockRight && !drawerState.forceDockBottom) {
            setMaxHeight(openedHeight);
            animatedMaxHeight.value = openedHeight;
            setMaxWidth(0);

            if (!props.asPopup) {
              setPosition(0);
              setTopPosition(isWeb ? ('unset' as DimensionValue) : undefined);
            }
          } else {
            setMaxHeight(headerSize - 24);
            animatedMaxHeight.value = headerSize - 24;
            setMaxWidth('100%');

            if (!props.asPopup) {
              setPosition(0);
              setTopPosition(isWeb ? ('unset' as DimensionValue) : undefined);
            }
          }

          break;
        default:
          newState = DrawerOpenState.closed;
          setHanger(<></>);

          if (props.dockRight && !drawerState.forceDockBottom) {
            setMaxHeight(openedHeight);
            animatedMaxHeight.value = openedHeight;
            setMaxWidth(0);

            if (!props.asPopup) {
              setPosition(`-32%`);
              setTopPosition(isWeb ? ('unset' as DimensionValue) : undefined);
            }
          } else {
            setMaxHeight(0);
            animatedMaxHeight.value = 0;
            setMaxWidth('100%');

            if (!props.asPopup) {
              setPosition(-maxHeight);
              setTopPosition(isWeb ? ('unset' as DimensionValue) : undefined);
            }
          }
          break;
      }
    }
  }, [drawerState.state, drawerState.forceDockBottom, props.dockBottom, props.dockRight, openedHeight, maxHeight, headerSize]);

  useEffect(() => {
    if (props.drawerElementType === DrawerElement.header && drawerState.showIcon && drawerState.icon) {
      setIcon(drawerState.icon);
      setElStyle((state) => [...state, styles.icon.iconExtraPadding]);
    } else {
      setIcon(<></>);
    }
  }, [drawerState.showIcon, drawerState.icon]);

  useEffect(() => {
    if (props.showCloseButton || (drawerState.showCloseButton && props.drawerElementType === DrawerElement.header)) {
      const innerStyles = [styles.iconBase, styles.icon.closeBase];

      setCloseButton(
        <Pressable style={innerStyles} onPress={handleClose} focusable={false}>
          <FontAwesomeIcon icon={faCircleXmark} size={24} color={theme.text.darkTurquoiseTwo.toString()} style={styles.icon.close.dimension} />
        </Pressable>
      );
    } else {
      setCloseButton(<></>);
    }
  }, [props.showCloseButton, drawerState.showCloseButton]);

  useEffect(() => {
    stylesCallback();
  }, [userPrefs.theme, drawerState.contentStyle]);

  useEffect(() => {
    if (props.drawerElementType === DrawerElement.content) {
      if (drawerState.showButtonBar && drawerState.buttonBarConfig) {
        setChildren(drawerState.content);
        setButtonBarCallback();
      } else {
        setChildren(<View style={{ maxHeight: maxHeight - headerSize }}>{drawerState.content}</View>);
      }
    }
  }, [drawerState.content, setButtonBarCallback]);

  useEffect(() => {
    setTitleCallback();
  }, [loading, drawerState.title, drawerState.state, drawerState.content]);

  const handleOnLayout = (event: any) => {
    if (Platform.OS === 'web') {
      // if (drawerState.state === DrawerOpenState.closed) return;

      if (event.nativeEvent?.layout.height) {
        var { height, width } = event.nativeEvent.layout;

        if (props.asPopup) {
          setTopPosition(screenHeight / 2 - height / 2);
          setPosition(screenWidth / 2 - width / 2);
        } else {
          if (height + topPosition > screenHeight) {
            setTopPosition(screenHeight - height);
          }
        }
      }
    }
  };

  const handleClose = () => {
    // if (props.asPopup) {
    //   setTopPosition(screenHeight / 2);
    //   setPosition(screenWidth / 2);
    // }
    dispatch(drawerActions.resetState());
  };

  const animatedMaxHeight = useSharedValue(0);

  const config = {
    duration: 400,
    easing: Easing.bezier(0.03, 0.34, 0.25, 1),
  };

  const style = useAnimatedStyle(() => {
    return {
      maxHeight: withTiming(animatedMaxHeight.value, config),
    };
  });

  // useEffect(() => {
  //   animatedMaxHeight.value = maxHeight;
  // }, [maxHeight]);

  return (
    <>
      {props.drawerElementType === DrawerElement.container && (
        <Overlay showOverLay={drawerState.state === DrawerOpenState.opened} zIndex={1001} onPress={() => dispatch(drawerActions.resetState())} />
      )}
      {
        <Animated.View
          onLayout={
            (props.dockRight && !drawerState.forceDockBottom) || props.asPopup
              ? handleOnLayout
              : () => {
                  return false;
                }
          }
          style={[
            elStyle,
            style,
            { maxWidth: maxWidth },
            props.drawerElementType === DrawerElement.container
              ? {
                  bottom: props.dockBottom || drawerState.forceDockBottom ? position : ('auto' as DimensionValue),
                  right: props.dockRight && !drawerState.forceDockBottom ? position : ('auto' as DimensionValue),
                  left: props.asPopup ? position : ('auto' as DimensionValue),
                  top: topPosition,
                }
              : {},
            props.drawerElementType === DrawerElement.header && !drawerState.showTitle ? { display: 'none' } : {},
          ]}
          // entering={props.drawerElementType === DrawerElement.container ? FadeInDown : undefined}
          // exiting={props.drawerElementType === DrawerElement.container ? FadeOutUp : undefined}
        >
          {props.drawerElementType === DrawerElement.header && icon}
          <View style={styles.innerContainer}>
            {loading && (
              <View style={{ display: 'flex', ...StylesHelper.padding(16), position: 'relative', height: 150 }}>
                <Loading style={{ position: 'relative' }} />
              </View>
            )}
            {!loading && (
              <>
                {props.drawerElementType === DrawerElement.container && hanger}
                {children}
              </>
            )}
          </View>
          {closeButton}
        </Animated.View>
      }
    </>
  );
};

export default Drawer;

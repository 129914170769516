import { faBarsFilter, faMagnifyingGlass, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { View, Pressable, Platform, useWindowDimensions } from 'react-native';
import { MysteryShopperAddressType } from '../../../api/api.g';
import CustomTextInput from '../CustomTextInput';
import Label, { LabelType } from '../Label';
import Loading from '../Loading';
import useStyles from '../../../hooks/styles-hook';
import { useAppSelector } from '../../../hooks/store-hook';
import useAddressIcon from '../../../hooks/addressIcon-hook';
import { FilterItemProps } from './FilterItem';
import useTranslation from '../../../hooks/translation-hook';
import CustomCheckbox from '../CustomCheckbox';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import useCustomTheme from '../../../hooks/theme-hook';
import CustomButton from '../CustomButton';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import ToggleSwitch from 'toggle-switch-react-native';
import CustomSwitch from '../CustomSwitch';

export interface MapFilterProps {
  items: FilterItemProps[];
  showSearchArea?: boolean;
  onlyAcceptableMissions?: boolean;
  showUpcomingMissions?: boolean;
  loading?: boolean;
  open?: boolean;
  onChangeText?(text: string): void;
  onClear?(): void;
  onFocus?(e: any): void;
  onBlur?(e: any): void;
  onKeyPress?(e: any): void;
  onBack?(): void;
  onSelectionChange?(selectedItems: string[]): void;
  onSearchAreaPress?(): void;
  onOpenChange?(state: boolean): void;
  onOnlyAcceptableMissions?(e: any): void;
  onShowUpcomingMissions?(e: any): void;
}

const MapFilter = (props: MapFilterProps) => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.customMap);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const layout = useWindowDimensions();

  const [searchTerm, setSearchTerm] = useState('');
  const addressIcon = useAddressIcon(18, 'black');
  const [filterItems, setFilterItems] = useState<FilterItemProps[]>([]);
  const [loading, setLoading] = useState(props.loading);

  const [showSearchPopup, setShowSearchPopup] = useState(false);
  const [showTagPopup, setShowTagPopup] = useState(false);
  const [showSearchArea, setShowSearchArea] = useState(false);

  const [onlyAcceptableMissions, setOnlyAcceptableMissions] = useState(props.onlyAcceptableMissions);
  const [showUpcomingMissions, setShowUpcomingMissions] = useState(props.showUpcomingMissions);

  useEffect(() => {
    setLoading(props.loading);
  }, [props.loading]);

  useEffect(() => {
    if (props.onChangeText) {
      props.onChangeText(searchTerm);
    }
  }, [searchTerm]);

  useEffect(() => {
    setFilterItems(props.items);
  }, [props.items]);

  useEffect(() => {
    setShowTagPopup(props.open);
  }, [props.open]);

  useEffect(() => {
    setOnlyAcceptableMissions(props.onlyAcceptableMissions);
  }, [props.onlyAcceptableMissions]);

  useEffect(() => {
    setShowUpcomingMissions(props.showUpcomingMissions);
  }, [props.showUpcomingMissions]);

  useEffect(() => {
    props.onOpenChange && props.onOpenChange(showTagPopup);
  }, [showTagPopup]);

  useEffect(() => {
    setShowSearchArea(props.showSearchArea);
  }, [props.showSearchArea]);

  const handleSearchTermChange = (text: string) => {
    setShowSearchPopup(true);
    setSearchTerm(text);

    if (props.onChangeText) {
      props.onChangeText(text);
    }
  };

  const handleSearchOnFocus = (e: any) => {
    setShowSearchPopup(true);

    if (props.onFocus) {
      props.onFocus(e);
    }
  };

  const handleSearchOnBlur = (e: any) => {
    if ((e.relatedTarget as HTMLElement)?.id?.startsWith('address_')) {
      return;
    }

    setShowSearchPopup(false);

    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  const handleSearchTagOnBlur = (e: any) => {
    if ((e.relatedTarget as HTMLElement)?.id?.startsWith('tag_')) {
      return;
    }

    if ((e.relatedTarget as HTMLElement)?.id?.startsWith('selectAll_')) {
      return;
    }

    setShowTagPopup(false);
  };

  const handleKeyPress = (e: any) => {
    if (e.code === 'Escape') {
      setShowSearchPopup(false);
    }

    if (props.onKeyPress) {
      props.onKeyPress(e);
    }
  };

  const handleBack = () => {
    () => setSearchTerm('');

    if (props.onBack) {
      props.onBack();
    }
  };

  const handleClear = () => {
    () => setSearchTerm('');

    if (props.onClear) {
      props.onClear();
    }
  };

  const handleFilterButtonPress = () => {
    setShowTagPopup((prev) => !prev);
  };

  const handleFilterChange = (checked: boolean, value: string) => {
    setFilterItems((prev) => {
      let newFilterItem = prev.find((x) => x.id === value);
      newFilterItem.checked = checked;

      if (prev && prev.length > 0) {
        props.onSelectionChange && props.onSelectionChange(prev.filter((x) => x.checked).map((x) => x.id));
      }

      return prev.slice();
    });
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    setFilterItems((prev) => {
      if (prev.find((x) => !x.checked)) {
        prev.forEach((c) => {
          c.checked = true;
        });
      } else {
        prev.forEach((c) => {
          c.checked = false;
        });
      }

      return prev.slice();
    });
  };

  return (
    <View style={styles.searchFieldContainerMobileFix}>
      <View style={styles.searchFieldContainerBase}>
        <View style={styles.searchFieldContainer.mainContainer}>
          <View style={styles.searchFieldContainer.fieldContainer}>
            <Pressable style={styles.searchFieldContainer.filters} onPress={handleFilterButtonPress}>
              <Label style={{ color: theme.text.darkTurquoiseTwo, flexGrow: 0, flexShrink: 1, fontWeight: '500', maxWidth: 300 }} numberOfLines={1}>
                {filterItems.filter((x) => x.checked).length > 0
                  ? `${t('Filtered-by')} ${filterItems
                      .filter((x) => x.checked)
                      .map((x) => x.name)
                      .join(', ')}`
                  : t('Select-a-filter')}
              </Label>
            </Pressable>
            {false && (
              <CustomTextInput
                style={styles.searchFieldContainer.field}
                containerStyle={styles.searchFieldContainer.fieldContainer}
                steadyIcon={<FontAwesomeIcon icon={faSearch} size={14} color={'rgba(60, 60, 67, 153)'} />}
                placeholder={`${t('search_location')}...`}
                showBackIcon
                showClearIcon
                debounceBack={300}
                onChangeText={handleSearchTermChange}
                onClear={handleClear}
                onFocus={handleSearchOnFocus}
                onBlur={handleSearchOnBlur}
                onKeyPress={handleKeyPress}
                value={searchTerm}
                onBack={handleBack}
              />
            )}
            <View
              style={[styles.searchFieldContainer.popupBase, !showSearchPopup ? styles.searchFieldContainer.popup.hide : styles.searchFieldContainer.popup]}
            >
              {!userPrefs.addresses && <Loading style={styles.searchFieldContainer.popup.loading} />}
              {userPrefs.addresses &&
                userPrefs.addresses?.map((address, index) => {
                  const value = `${address.city}, ${address.postalCode}`;

                  return (
                    <Pressable
                      key={`address_${index}`}
                      nativeID={`address_${index}`}
                      style={[
                        styles.searchFieldContainer.popup.item,
                        index < userPrefs.addresses.length - 1 ? styles.searchFieldContainer.popup['item.bottomBorder'] : {},
                      ]}
                      onPress={(e) => {
                        setSearchTerm(value);
                        handleSearchOnBlur(e);
                      }}
                    >
                      {addressIcon(address.type ?? MysteryShopperAddressType.Home)}
                      <Label style={{ color: 'black' }}>{value}</Label>
                    </Pressable>
                  );
                })}
            </View>
          </View>
          <View style={styles.searchFieldContainer.filterButtonContainer}>
            <Pressable style={styles.searchFieldContainer.filterButton} onPress={handleFilterButtonPress}>
              <View style={styles.searchFieldContainer.filterButtonOverlay}></View>
              <FontAwesomeIcon icon={faBarsFilter} size={16} color={theme.text.primary.toString()} />
              {filterItems.find((x) => x.checked) && (
                <FontAwesomeIcon icon={faCircle} size={6} color={'orange'} style={styles.searchFieldContainer.filterIndicator} />
              )}
            </Pressable>
          </View>
        </View>
      </View>
      <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 8, marginTop: 8 }}>
        <View
          style={{
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            backgroundColor: 'white',
            alignItems: 'center',
            paddingHorizontal: 10,
            paddingVertical: 10,
            flexShrink: 1,
            flexGrow: 0,
            width: 'auto',
            elevation: 3,
            ...StylesHelper.shadow({ height: 2, width: 2 }, 0.4, 6, theme.overlayColor.toString(), 2),
            ...StylesHelper.borderRadius(5),
          }}
        >
          <CustomSwitch isEnabled={!onlyAcceptableMissions} onPress={(checked) => props.onOnlyAcceptableMissions && props.onOnlyAcceptableMissions(checked)} />
          <Label style={{ flexShrink: 0 }} variant={LabelType.caption} numberOfLines={4}>
            {t('available_missions_only')}
          </Label>
        </View>
        <View
          style={{
            display: 'flex',
            gap: 8,
            flexDirection: 'row',
            backgroundColor: 'white',
            alignItems: 'center',
            paddingHorizontal: 10,
            paddingVertical: 10,
            flexShrink: 1,
            flexGrow: 0,
            width: 'auto',
            elevation: 3,
            ...StylesHelper.shadow({ height: 2, width: 2 }, 0.4, 6, theme.overlayColor.toString(), 2),
            ...StylesHelper.borderRadius(5),
          }}
        >
          <CustomSwitch isEnabled={showUpcomingMissions} onPress={(checked) => props.onShowUpcomingMissions && props.onShowUpcomingMissions(checked)} />
          <Label style={{ flexShrink: 0 }} variant={LabelType.caption} numberOfLines={4}>
            {t('upcoming_missions')}
          </Label>
        </View>
      </View>
      <View style={[styles.searchFieldContainer.popupTagFilterBase]}>
        <View style={[!showTagPopup ? styles.searchFieldContainer.popupTag.hide : styles.searchFieldContainer.popupTag]}>
          <Label variant={LabelType.titleDark} style={styles.searchFieldContainer.popupTagFilter.title}>
            {t('filter_by_business_type')}
          </Label>
          <View style={styles.searchFieldContainer.popupTagFilter.selectAll}>
            <Pressable id={'selectAll_button'} onPress={handleSelectAll}>
              <Label variant={LabelType.titleDarkSmall}>{filterItems.find((x) => !x.checked) ? t('select_all') : t('deselect_all')}</Label>
            </Pressable>
          </View>
          {filterItems.map((item) => {
            return (
              <CustomCheckbox
                small
                id={`tag_${item.id}`}
                key={`tag_${item.id}`}
                checked={item.checked}
                value={item.id}
                label={item.name}
                style={styles.searchFieldContainer.popupTag.item}
                onChange={(checked, value) => {
                  handleFilterChange(checked, `${value}`);
                }}
              />
            );
          })}
        </View>
      </View>
      <View style={styles.searchThisAreaContainerBase}>
        {showSearchArea && !showTagPopup && (
          <Pressable
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              paddingHorizontal: 12,
              paddingVertical: 8,
              ...StylesHelper.shadow({ width: 2, height: 3 }, 0.2, 5, 'black', 3),
              backgroundColor: theme.background.lightSea,
              gap: 8,
              borderRadius: 50,
            }}
            onPress={() => {
              props.onSearchAreaPress && props.onSearchAreaPress();
            }}
          >
            <FontAwesomeIcon icon={faMagnifyingGlass} size={20} color={theme.text.darkTurquoiseTwo.toString()} />
            <Label style={{ fontSize: 13, color: theme.text.darkTurquoiseTwo, fontWeight: '600' }}>{t('Search-this-area')}</Label>
          </Pressable>
        )}
      </View>
      {layout.width <= 1200 && loading && !showSearchArea && (
        <View style={{ display: 'flex', alignItems: 'center' }}>
          <View style={{ marginTop: 8, width: 150, height: 50, backgroundColor: 'rgba(255,255,255,0.6)', display: 'flex', borderRadius: 8 }}>
            <Loading />
          </View>
        </View>
      )}
    </View>
  );
};

export default MapFilter;

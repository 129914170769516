import axios from 'axios';
import Constants from 'expo-constants';
require('../models/helpers/string');

const useGoogleValidation = () => {
  const validate = (data, callback: (results: any) => void) => {
    const apiCallUrl = Constants.expoConfig.extra.googleValidationUrl.format(Constants.expoConfig.extra.googleGeocodeApiKey);

    axios.post(apiCallUrl, data).then((res) => {
      callback(
        res.data?.result?.verdict?.addressComplete &&
          (!res.data?.result?.verdict?.hasUnconfirmedComponents ||
            (res.data?.result?.address?.addressComponents?.filter((x) => x.confirmationLevel != 'CONFIRMED').length <= 2 &&
              res.data?.result?.address?.addressComponents?.find((x) => x.componentType === 'street_number')?.confirmationLevel ===
                'UNCONFIRMED_BUT_PLAUSIBLE') ||
            res.data?.result?.address?.addressComponents?.find((x) => x.componentType === 'subpremise')?.confirmationLevel === 'UNCONFIRMED_BUT_PLAUSIBLE')
      );
    });
  };

  return validate;
};

export default useGoogleValidation;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';

interface LightBoxState {
  mobile: boolean;
  visible: boolean;
  content?: ReactElement;
  secondVisible?: boolean;
  secondContent?: ReactElement;
  isLoading?: boolean;
  onClose?(): void;
  onSecondClose?(): void;
  noDismiss?: boolean;
}

// Define the initial state using that type
const initialState: LightBoxState = {
  mobile: false,
  visible: false,
  isLoading: false,
};

export const lightBoxSlice = createSlice({
  name: 'lightBox',
  initialState,
  reducers: {
    setVisibility: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setContent: (state, action: PayloadAction<ReactElement>) => {
      state.content = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSecondVisibility: (state, action: PayloadAction<boolean>) => {
      state.secondVisible = action.payload;
    },
    setSecondContent: (state, action: PayloadAction<ReactElement>) => {
      state.secondContent = action.payload;
    },
    setMobile: (state, action: PayloadAction<boolean>) => {
      state.mobile = action.payload;
    },
    setOnClose: (state, action: PayloadAction<any>) => {
      state.onClose = action.payload;
    },
    setOnSecondClose: (state, action: PayloadAction<any>) => {
      state.onSecondClose = action.payload;
    },
    setNoDismiss: (state, action: PayloadAction<boolean>) => {
      state.noDismiss = action.payload;
    },
  },
});

export default lightBoxSlice.reducer;
export const lightBoxActions = lightBoxSlice.actions;

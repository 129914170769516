import { Platform, useWindowDimensions } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';
import { AnyIfEmpty } from 'react-redux';

const useCustomMapStyles = () => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();

  let customMap = {
    container: {
      flex: 1,
      width: '100%' as any,
      height: '100%' as any,
    },
    spinner: {
      width: '100%' as any,
      height: '100%' as any,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
      backgroundColor: theme.background.primary,
    },
    ['spinner.message']: {
      display: 'flex' as any,
      flexDirection: 'column' as any,
      alignItems: 'center' as any,
      justifyContent: 'center' as any,
    },
    ['spinner.message.icon']: {
      marginBottom: 16,
    },
    permissionOverlay: {
      position: 'absolute' as any,
      top: layout.width < 590 ? 122 : 24,
      right: layout.width < 590 ? 16 : 24,
      marginLeft: 16,
      zIndex: 2100,
      flexDirection: 'row' as any,
      flexShrink: 1,
      alignItems: 'center' as any,
      backgroundColor: theme.primary,
      opacity: 0.9,
      ...StylesHelper.padding(12, 12, 12, 10),
      ...StylesHelper.borderRadius(6),
      ...StylesHelper.shadow({ width: 2, height: 2 }, 0.2, 2, 'black', 2),
    },
    ['permissionOverlay.icon']: {
      marginRight: 8,
      color: 'white',
    },
    ['permissionOverlay.help']: {
      position: 'absolute' as any,
      top: 4,
      right: 4,
    },
    ['permissionOverlay.help.icon']: {
      color: 'white',
    },
    searchFilterContainer: {
      position: 'absolute' as any,
      zIndex: 2001,
      top: 76,
      left: 0,
      paddingHorizontal: layout.width < 590 ? 16 : 24,
      maxWidth: '100%' as any,
    },
    searchFieldContainerMobileFix: {
      position: 'absolute' as any,
      zIndex: 2002,
      top: 24,
      width: '100%' as any,
      // backgroundColor: 'red' as any,
      paddingHorizontal: layout.width < 590 ? 16 : 24,
    },
    searchFieldContainerBase: {
      // position: 'absolute' as any,
      // zIndex: 2002,
      // top: 24,
      // left: layout.width < 590 ? 0 : 24,
      // paddingLeft: layout.width < 590 ? 0 : 24,
      width: layout.width < 590 ? ('100%' as any) : 542,
      // paddingHorizontal: layout.width < 590 ? 16 : 0,
      display: 'flex' as any,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
      elevation: 3,
      ...StylesHelper.shadow({ height: 2, width: 2 }, 0.4, 6, theme.overlayColor.toString(), 2),
      ...StylesHelper.borderRadius(5),
      overflow: 'hidden' as any,
    },
    searchThisAreaContainerBase: {
      position: 'absolute' as any,
      zIndex: 2000,
      elevation: 1,
      top: layout.width < 430 ? 150 : 104,
      left: 0,
      display: 'flex' as any,
      alignItems: 'center' as any,
      width: '100%' as any,
    },
    searchFieldContainer: {
      mainContainer: {
        flexDirection: 'row' as any,
        // ...StylesHelper.shadow({ height: 2, width: 2 }, 0.4, 6, theme.overlayColor.toString(), 2),
        flexGrow: 1,
        flexShrink: 1,
        // flexBasis: 350,
      },
      fieldContainer: {
        flexGrow: 1,
        flexShrink: 1,
        zIndex: 1,
        position: 'relative' as any,
      },
      field: {
        flexShrink: 1,
        height: 40,
        fontSize: 13,
        maxWidth: '100%' as any,
        zIndex: 3,
        backgroundColor: 'white',
        color: 'black',
        ...StylesHelper.padding(10, 8, 10, 32),
        // ...StylesHelper.borderRadius(5, 0, 0, 5),
        ...StylesHelper.borderWidth(0, 1, 0, 0),
      },
      filters: {
        flexShrink: 1,
        height: 40,
        fontSize: 13,
        maxWidth: '100%' as any,
        zIndex: 3,
        backgroundColor: 'white',
        color: 'black',
        ...StylesHelper.padding(10),
        ...StylesHelper.borderRadius(5, 0, 0, 5),
        ...StylesHelper.borderWidth(0, 1, 0, 0),
      },
      filterButtonContainer: {
        height: 40,
        width: 40,
        ...StylesHelper.borderRadius(0, 5, 5, 0),
        backgroundColor: theme.background.primary,
        borderWidth: 0,
        display: 'flex' as any,
        flexDirection: 'row' as any,
        alignItems: 'stretch' as any,
        flexShrink: 0,
        zIndex: 10,
        ...StylesHelper.shadow({ height: 2, width: 2 }, 0.4, 6, theme.overlayColor.toString(), 2),
      },
      filterButton: {
        flexShrink: 0,
        flexGrow: 1,
        justifyContent: 'center' as any,
        alignItems: 'center' as any,
        backgroundColor: theme.background.primary,
        // ...StylesHelper.borderRadius(0, 5, 5, 0),
        zIndex: 10,
      },
      filterButtonOverlay: {
        backgroundColor: 'transparent',
        position: 'absolute' as any,
        top: 0,
        left: 0,
        width: '100%' as any,
        height: '100%' as any,
        zIndex: 1,
      },
      filterIndicator: {
        position: 'absolute' as any,
        ...Platform.select({
          native: {
            top: '50%' as any,
            left: '50%' as any,
            marginLeft: 2,
            marginTop: 2,
          },
          default: {
            marginLeft: 8,
            marginTop: 11,
          },
        }),
        zIndex: 0,
      },
      popupBase: {
        position: 'absolute' as any,
        maxWidth: '100%' as any,
        zIndex: 0,
        top: 40,
        left: 0,
      },
      popup: {
        backgroundColor: theme.background.modal,
        ...StylesHelper.padding(4, 16, 0, 16),
        ...StylesHelper.borderRadius(0, 0, 5, 5),
        ...StylesHelper.shadow({ width: 2, height: 2 }, 0.2, 2, 'black', 2),
        item: {
          flexDirection: 'row' as any,
          alignItems: 'center' as any,
          paddingTop: 16,
          paddingBottom: 16,
        },
        ['item.bottomBorder']: {
          borderBottomWidth: 1,
          borderColor: theme.borderColorLight,
        },
        loading: {
          padding: 16,
          position: 'relative' as any,
        },
        hide: {
          display: 'none' as any,
        },
      },
      popupTagFilterBase: {
        position: 'absolute' as any,
        zIndex: 2001,
        ...(layout.width < 590 ? { top: 42, left: 0 } : { top: 42, left: 24 }),
        width: layout.width < 590 ? ('100%' as any) : 306,
        paddingHorizontal: layout.width < 590 ? 16 : 0,
        elevation: 2,
      },
      popupTagFilter: {
        title: {
          paddingLeft: 12,
          display: 'flex' as any,
          alignItems: 'center' as any,
          borderBottomWidth: 1,
          borderBottomColor: theme.borderColorLight,
          textTransform: 'uppercase' as any,
          paddingVertical: 8,
        },
        selectAll: {
          display: 'flex' as any,
          flexDirection: 'row' as any,
          padding: 12,
        },
      },
      popupTag: {
        zIndex: 2001,
        width: '100%' as any,
        backgroundColor: theme.background.modal,
        ...StylesHelper.padding(4, 0),
        ...StylesHelper.borderRadius(5),
        ...StylesHelper.shadow({ width: 2, height: 2 }, 0.2, 2, 'black', 2),
        item: {
          ...StylesHelper.padding(0, 12),
        },
        hide: {
          display: 'none' as any,
        },
      },
    },
  };

  return customMap as typeof customMap;
};

export default useCustomMapStyles;

import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { Pressable, View, Image, useWindowDimensions, Linking, Platform } from 'react-native';
import Label, { LabelType } from '../Label';
import useTranslation from '../../../hooks/translation-hook';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import { myAccountActions } from '../../../store/myAccountSlice';
import NavigationElements from '../../../models/NavigationElements';
import { Link, useFocusEffect, useNavigation, useNavigationState } from '@react-navigation/native';
import CreateResponsiveStyle from '../../../models/helpers/responsiveStyleHelper';
import { useQuery } from '@tanstack/react-query';
import {
  GetMyRewardsViewModel,
  GetMysteryShopperProfileViewModel,
  MissionAssignationStatus,
  MyMissionClient,
  MyRewardsClient,
  MysteryShopperClient,
} from '../../../api/api.g';
import useApi from '../../../hooks/api-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import { navBarActions } from '../../../store/navBarSlice';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faBullhorn, faLocationDot, faStreetView, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { LoggedState } from '../../../models/AuthModelsCommon';
import { UserPreferencesState, userPrefsActions } from '../../../store/userPrefsSlice';
import { UserPrefsHelper } from '../../../models/helpers/userPrefsHelper';
import { errorActions } from '../../../store/errorSlice';
import LoggedInRestrictedArea from '../LoggedInRestrictedArea';

const TopBarMobile = () => {
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const navigationState = useNavigationState((state) => state?.routes);
  const layout = useWindowDimensions();
  const styles = responsiveStyle(layout);
  const authStore = useAppSelector((selector) => selector.auth);
  const errorStore = useAppSelector((selector) => selector.error);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const apiRewards = useApi(MyRewardsClient);
  const apiMyMission = useApi(MyMissionClient);
  const apiMS = useApi(MysteryShopperClient);
  const theme = useCustomTheme();
  const missionsStore = useAppSelector((selector) => selector.missions);
  const accountStore = useAppSelector((selector) => selector.myAccount);

  const [userInitials, setUserInitials] = useState('');
  const [rewards, setRewards] = useState<GetMyRewardsViewModel>();
  const [hasMissions, setHasMissions] = useState(false);
  const [hasValidation, setHasValidation] = useState(false);
  const [shouldShowAnonymousPopup, setShouldShowAnonymousPopup] = useState(false);

  const [firstMissionToReview, setFirstMissionToReview] = useState<string>();

  const [navigationEnd, setNavigationEnd] = useState(false);

  const [isLanla, setIsLanla] = useState(false);

  const screenWithoutReviewBar = ['mymissions', 'survey', 'editSurvey'];

  useFocusEffect(
    React.useCallback(() => {
      setUserInitials('');
      if (authStore.logged !== undefined && authStore.logged !== LoggedState.NotLogged) {
        console.log('%cNavigation end - TopBarMobile', 'color: #ffa600;');
        setNavigationEnd(true);
      } else {
        setNavigationEnd(false);
      }
    }, [authStore.logged])
  );

  useEffect(() => {
    setIsLanla(authStore.isLanla);
  }, [authStore.isLanla]);

  const profileQuery = useQuery({
    enabled: navigationEnd && !!authStore.idToken && !!authStore.user && !!authStore.user.email,
    queryKey: ['topBarUserProfile', authStore.idToken, authStore.user?.email],
    queryFn: ({ signal }) => {
      console.log('Call profile info', authStore.user?.email);

      const promise = apiMS.getProfile(apiMS.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMS.tokenSource?.cancel('profileQuery - Query was cancelled by React Query');
        apiMS.onCancel();
      });

      return promise;
    },
  });

  const myMissionsQuery = useQuery({
    enabled: navigationEnd && !!authStore.idToken && !!authStore.user && !!authStore.user.email,
    queryKey: ['topBarMyMissions', missionsStore.missionListSeed],
    queryFn: ({ signal }) => {
      const promise = apiMyMission.getMissionsList(apiMyMission.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMyMission.tokenSource?.cancel('topBarMyMissions: Query was cancelled by React Query');
        apiMyMission.onCancel();
      });

      return promise;
    },
  });

  const myMissionHasValidationQuery = useQuery({
    enabled: !!authStore.idToken,
    queryKey: ['myMissionHasValidationQuery', missionsStore.rejectedValidationSeed],
    queryFn: ({ signal }) => {
      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiMyMission.tokenSource?.cancel('myMissionHasValidationQuery: Query was cancelled by React Query');
        apiMyMission.onCancel();
      });

      return apiMyMission.getFailedValidationNotification(apiMyMission.tokenSource?.token);
    },
  });

  useEffect(() => {
    if (myMissionHasValidationQuery.isSuccess) {
      setHasValidation(myMissionHasValidationQuery.data.hasFailedValidationMission);

      if (myMissionHasValidationQuery.data.hasFailedValidationMission) {
        setFirstMissionToReview(myMissionHasValidationQuery.data.failedValidationMissionAssignationIds[0]);
      } else {
        setFirstMissionToReview(undefined);
      }
    }
  }, [myMissionHasValidationQuery.isSuccess, myMissionHasValidationQuery.data]);

  const getMyRewards = useQuery({
    enabled: navigationEnd && !!authStore.idToken && !!authStore.user && !!authStore.user.email,
    queryKey: ['myRewardsQuery', authStore.logged, authStore.idToken, accountStore.myRewardsSeed],
    queryFn: ({ signal }) => {
      const promise = apiRewards.getMyRewards(apiRewards.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiRewards.tokenSource?.cancel('getMyRewards: Query was cancelled by React Query');
        apiRewards.onCancel();
      });

      return promise;
    },
    onError: (err) => {
      throw err;
    },
  });

  useEffect(() => {
    if (profileQuery.isSuccess) {
      const userProfile: GetMysteryShopperProfileViewModel = profileQuery.data;

      if (userProfile.mysteryShopper) {
        dispatch(userPrefsActions.setBasicInfo(UserPrefsHelper.mapFromToDto<UserPreferencesState>(userProfile.mysteryShopper)));
        dispatch(userPrefsActions.setProfileMembers(userProfile.availableProfileMembers));
      }
    } else {
      // dispatch(
      //   userPrefsActions.setBasicInfo({
      //     uiLanguage: process.env.REACT_APP_DEFAULT_LANG,
      //     theme: DeviceTheme.Light,
      //     languages: [process.env.REACT_APP_DEFAULT_LANG],
      //     email: '',
      //   })
      // );
      dispatch(userPrefsActions.setProfileMembers());
    }
  }, [profileQuery.isSuccess, profileQuery.data]);

  useEffect(() => {
    if (myMissionsQuery.isSuccess) {
      const value = myMissionsQuery.data;

      if ((value.missions?.filter((m) => m.status === MissionAssignationStatus.Assigned)?.length ?? 0) > 0) {
        setHasMissions(true);
      } else {
        setHasMissions(false);
      }
    }
  }, [myMissionsQuery.isSuccess, myMissionsQuery.data]);

  useEffect(() => {
    setRewards(getMyRewards.data);
  }, [getMyRewards.isSuccess, getMyRewards.data]);

  useEffect(() => {
    if (userPrefs) {
      const initials = `${userPrefs.firstName ? userPrefs.firstName.substring(0, 1) : '-'}${userPrefs.lastName ? userPrefs.lastName.substring(0, 1) : '-'}`;
      setUserInitials(initials);
    }
  }, [userPrefs.firstName, userPrefs.lastName]);

  const handleMyAccountClick = () => {
    if (errorStore.hasError) {
      handleOnNavigationPress('mymissions');
    }
    dispatch(myAccountActions.navigate(NavigationElements.menu));
    dispatch(myAccountActions.setVisibility(true));
  };

  const handleOnNavigationPress = (screen: string, isSignup: boolean = false, selectMission: boolean = false) => {
    const isRestrictedNavigation = screen != 'map' && screen != 'login' && screen != 'logout';

    if (isRestrictedNavigation && authStore.logged === LoggedState.Anonymous) {
      setShouldShowAnonymousPopup(true);
      return;
    } else {
      setShouldShowAnonymousPopup(false);
    }

    let params = {};

    if (screen === 'mymissions' && firstMissionToReview && selectMission) {
      params = { ...params, id: firstMissionToReview };
    }

    if ((screen === 'login' || screen === 'logout') && isSignup) {
      params = { ...params, signUp: isSignup };
    }

    dispatch(navBarActions.setPath(screen));
    dispatch(errorActions.resetError());
    navigation.navigate({ name: screen, params } as never);
  };

  const getActivePagePath = (menuItem: string) => {
    return menuItem === (navigationState && navigationState[navigationState.length - 1].name);
  };

  return (
    <>
      {shouldShowAnonymousPopup && (
        <LoggedInRestrictedArea
          onlyPopup
          onPopupShow={() => setShouldShowAnonymousPopup(false)}
          message={t('need-login-before', { action: t('see-your-missions') })}
        />
      )}
      <LinearGradient
        colors={['#024252', '#035164', '#43A49E']}
        start={{ x: 0, y: -0.5 }}
        end={{ x: 1, y: 1 }}
        style={[{ borderBottomColor: theme.borderColorLight }, styles('container')]}
      >
        <Pressable
          style={{ marginTop: 0 }}
          onPress={
            () => navigation.navigate('map' as never)
            //   async () => {
            //   const url = t('lanla_website_url');
            //   const supported = Linking.canOpenURL(url);
            //   if (supported) {
            //     // Opening the link with some app, if the URL scheme is "http" the web link should be opened
            //     // by some browser in the mobile
            //     await Linking.openURL(url);
            //   } else {
            //     throw `Can't open URL`;
            //   }
            // }
          }
        >
          <Image style={{ height: 52, width: 80, resizeMode: 'contain' }} source={require('../../../../assets/logo-lanla-rgb-reversed.png')}></Image>
        </Pressable>

        <View style={styles('navBar')}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Pressable style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' as any }} onPress={() => handleOnNavigationPress('map')}>
              <FontAwesomeIcon icon={faStreetView} size={16} color={getActivePagePath('map') ? theme.menu.active.toString() : theme.menu.primary.toString()} />
              <Label style={[{ color: theme.menu.primary }, styles('label'), getActivePagePath('map') ? styles('active') : '']}>{t('Missions')}</Label>
            </Pressable>
            {isLanla && (
              <Pressable
                style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' as any, marginLeft: 32 }}
                onPress={() => handleOnNavigationPress('validation')}
              >
                <FontAwesomeIcon
                  icon={faBullhorn}
                  size={14}
                  color={getActivePagePath('validation') ? theme.menu.active.toString() : theme.menu.primary.toString()}
                />
                <Label style={[{ color: theme.menu.primary }, styles('label'), getActivePagePath('validation') ? styles('active') : '']}>
                  {t('validation', { count: 2 })}
                </Label>
              </Pressable>
            )}
            <Pressable
              style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' as any, marginLeft: 32 }}
              onPress={() => handleOnNavigationPress('mymissions')}
            >
              <View style={{ display: 'flex', flexDirection: 'row' }}>
                <FontAwesomeIcon
                  icon={faLocationDot}
                  size={16}
                  color={getActivePagePath('mymissions') || getActivePagePath('survey') ? theme.primary.toString() : theme.menu.primary.toString()}
                />
                {hasMissions && (
                  <FontAwesomeIcon
                    size={8}
                    icon={faCircle}
                    color={theme.indicatorColor.toString()}
                    style={{ position: 'absolute', right: -2, top: Platform.OS === 'web' ? 12 : 8 }}
                  />
                )}
              </View>
              <Label
                style={[{ color: theme.menu.primary }, styles('label'), getActivePagePath('mymissions') || getActivePagePath('survey') ? styles('active') : '']}
              >
                {t('my_missions')}
              </Label>
            </Pressable>
          </View>
        </View>
        {/* <Pressable onPress={() => handleOnNavigationPress('mymissions')} style={styles('missions')}>
        <FontAwesomeIcon
          icon={faLocationDot}
          color={navStore.path === 'mymissions' ? theme.primary.toString() : theme.menu.primary.toString()}
          style={{ height: 24, marginRight: 4 }}
        />
        <View style={{ marginLeft: 4, alignItems: 'flex-end' }}>
          <Label variant={LabelType.titleDarkSmall} style={[{ color: theme.menu.primary }, navStore.path === 'mymissions' ? styles('active') : '']}>
            {t('my_missions')}
          </Label>
          <Label variant={LabelType.caption} style={[{ color: theme.menu.primary }, navStore.path === 'mymissions' ? styles('active') : '']}>
            0 ongoing
          </Label>
        </View>
      </Pressable> */}
        <Pressable onPress={handleMyAccountClick} style={{ flexDirection: 'row', alignItems: 'center' }}>
          <View style={styles('rewards')}>
            <Label variant={LabelType.titleDarkSmall} style={{ color: theme.menu.primary }}>
              {t('my_rewards')}
            </Label>
            <View style={{ flexDirection: 'row' }}>
              <Label variant={LabelType.caption} style={{ color: theme.menu.primary, fontWeight: '800' }}>
                {t('currency-value', { value: rewards?.amountCurrency ?? '0' })}
              </Label>
              <Label variant={LabelType.caption} style={{ color: theme.menu.primary, marginLeft: 4 }}>{`(${t('points-value', {
                value: rewards?.amountPts ?? '0',
              })})`}</Label>
            </View>
          </View>
          <View style={[{ borderColor: theme.menu.primary }, styles('profile')]}>
            {authStore.logged !== LoggedState.Anonymous && userInitials !== '--' && (
              <Label numberOfLines={1} variant={LabelType.titleWhiteSmall} style={Platform.OS == 'web' ? { lineHeight: 10, fontSize: 11 } : { fontSize: 11 }}>
                {userInitials}
              </Label>
            )}
            {(authStore.logged === LoggedState.Anonymous || userInitials === '--') && <FontAwesomeIcon icon={faUser} color={'white'} />}
          </View>
        </Pressable>
      </LinearGradient>
      {navigationState && screenWithoutReviewBar.indexOf(navigationState[navigationState.length - 1].name) === -1 && hasValidation && (
        <View style={styles('trialBanner')}>
          <Label variant={LabelType.titleDarkSmall} numberOfLines={3} style={{ fontWeight: '700', flexShrink: 1 }}>
            {t('You-have-a-mission-that-needs-your-attention')}
          </Label>
          <View style={styles('loginText')}>
            <Pressable onPress={() => handleOnNavigationPress('mymissions', false, true)}>
              <Label variant={LabelType.titleDarkSmall} style={{ textDecorationLine: 'underline' }}>
                {t('Review-your-missions')}
              </Label>
            </Pressable>
          </View>
        </View>
      )}
      {authStore.logged === LoggedState.Anonymous && (
        <View style={styles('trialBanner')}>
          <Label variant={LabelType.titleDarkSmall} style={{ fontWeight: '700' }}>
            {t('Youre-in-trial-mode')}
          </Label>
          <View style={styles('loginText')}>
            <Pressable onPress={() => handleOnNavigationPress('logout')}>
              <Label variant={LabelType.titleDarkSmall} style={{ textDecorationLine: 'underline' }}>
                {t('login_title')}
              </Label>
            </Pressable>
            <Label style={{ marginLeft: 4, textTransform: 'lowercase' }} variant={LabelType.titleDarkSmall}>
              {t('or')}
            </Label>
            <Pressable onPress={() => handleOnNavigationPress('logout', true)}>
              <Label style={{ marginLeft: 4, textDecorationLine: 'underline', textTransform: 'lowercase' }} variant={LabelType.titleDarkSmall}>
                {t('login.sign_up')}
              </Label>
            </Pressable>
          </View>
        </View>
      )}
    </>
  );
};

const responsiveStyle = CreateResponsiveStyle(
  {
    container: {
      display: 'flex',
      flexDirection: 'row',
      paddingVertical: 8,
      paddingHorizontal: 16,
      alignItems: 'center',
      borderBottomWidth: 1,
      height: 53,
      overflow: 'hidden',
    },
    navBar: {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    label: { fontSize: 16, fontWeight: '600', marginLeft: 8 },
    active: { color: '#f5a523' },
    missions: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    rewards: {
      marginLeft: 32,
      marginRight: 8,
      alignItems: 'flex-end',
    },
    profile: {
      backgroundColor: 'transparent',
      height: 30,
      width: 30,
      borderRadius: 15,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      padding: 4,
    },
    trialBanner: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 28,
      backgroundColor: '#f5a523',
      paddingHorizontal: 24,
      paddingTop: 4,
      paddingBottom: 8,
      marginTop: -1,
    },
    loginText: {
      marginLeft: 12,
      flexDirection: 'row',
    },
  },
  {
    container: {
      justifyContent: 'space-between',
    },
    navBar: {
      display: 'none',
    },
    missions: {
      display: 'none',
    },
    profile: {
      marginRight: -4,
    },
    trialBanner: {
      height: 'auto',
      paddingHorizontal: 12,
      paddingVertical: 8,
    },
    loginText: {
      marginLeft: 8,
    },
  }
);

export default TopBarMobile;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';

interface NotificationState {
  openState: NotificationOpenState;
  content: ReactElement;
  count: number;
}

export enum NotificationOpenState {
  open,
  closed,
  hidden,
}

// Define the initial state using that type
const initialState: NotificationState = {
  openState: NotificationOpenState.hidden,
  content: <></>,
  count: 0,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setOpenState: (state, action: PayloadAction<NotificationOpenState>) => {
      state.openState = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setContent: (state, action: PayloadAction<ReactElement>) => {
      state.content = action.payload;
    },
  },
});

export default notificationSlice.reducer;
export const notificationActions = notificationSlice.actions;

import React, { useEffect, useState } from 'react';
import { useWindowDimensions } from 'react-native';
import { Pressable, View } from 'react-native';
import useCustomTheme from '../../hooks/theme-hook';

export interface CarouselPaginationProp {
  numberOfPages: number;
  currentPage: number;
  onPagePress?(page: number): void;
  bigView?: boolean;
  bulletColors: string[];
  bulletActiveColors: string[];
}

const CustomCarouselPagination = (props: CarouselPaginationProp) => {
  const theme = useCustomTheme();
  const style = useCarouselPaginationStyles();
  const [numberOfPages, setNumberOfPages] = useState<number>(props.numberOfPages);
  const [currentPage, setCurrentPage] = useState<number>(props.currentPage);

  useEffect(() => {
    setCurrentPage(props.currentPage);
  }, [props.currentPage]);

  useEffect(() => {
    setNumberOfPages(props.numberOfPages);
  }, [props.numberOfPages]);

  const handleOnPress = (page: number) => {
    props.onPagePress && props.onPagePress(page);
  };

  let buttons = [];

  for (let i = 0; i < numberOfPages; i++) {
    buttons.push(
      <Pressable
        key={`caroussel_page_${i + 1}`}
        onPress={() => handleOnPress(i)}
        style={[style.button, { backgroundColor: currentPage === i ? props.bulletActiveColors[currentPage] : props.bulletColors[currentPage] }]}
      ></Pressable>
    );
  }

  return <View style={[style.container, { bottom: props.bigView ? 24 : 15 }]}>{buttons}</View>;
};

const useCarouselPaginationStyles = () => {
  const theme = useCustomTheme();

  let pagination = {
    container: { position: 'absolute' as any, bottom: 0, left: '50%' as any, transform: [{ translateX: -26 }], flexDirection: 'row' as any, gap: 8 },
    button: {
      width: 7,
      height: 7,
      borderRadius: 5,
    },
  };

  return pagination as typeof pagination;
};

export default CustomCarouselPagination;

import React, { useEffect, useState } from 'react';
import { View, Image, ScrollView } from 'react-native';
import Label from '../common/Label';
import useTranslation from '../../hooks/translation-hook';
import CustomButton from '../common/CustomButton';
import Loading from '../common/Loading';
import { MysteryShopperAddressDto } from '../../api/api.g';

export interface AddressRequestPopupProps {
  onConfirm?(): void;
  onEdit?(): void;
  dto?: MysteryShopperAddressDto;
}

const AddressRequestPopup = (props: AddressRequestPopupProps) => {
  const t = useTranslation();
  const [dto, setDto] = useState<MysteryShopperAddressDto>(undefined);

  useEffect(() => {
    setDto(props.dto);
  }, [props.dto]);

  return (
    <ScrollView style={{ flexGrow: 0, maxWidth: 350, backgroundColor: '#eeeef0' }}>
      <Image source={require('../../../assets/address-request-popup.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto', marginTop: -1 }} />
      <View style={{ flexGrow: 1, marginTop: 8 }}>
        <View style={{ margin: 'auto', paddingLeft: 12, paddingRight: 12, paddingBottom: 24 }}>
          <Label style={{ fontSize: 28, color: '#5e51a3', fontWeight: '600' }} numberOfLines={3}>
            {t('Attention')}
          </Label>
          <Label style={{ marginTop: 8, fontSize: 22, color: '#33264d', fontWeight: '400' }} numberOfLines={10}>
            {t('one-location-please')}
          </Label>
          <Label style={{ marginTop: 8, fontSize: 16, color: '#33264d', fontWeight: '400' }} numberOfLines={10}>
            {t('We-need-to-confirm-your-address-to-ensure-accurate')}
          </Label>
          <View style={{ marginTop: 20, minHeight: 43, width: 325 }}>
            {dto == undefined ? (
              <Loading style={{ marginLeft: -8 }} />
            ) : (
              <>
                <Label style={{ fontSize: 16, color: '#33264d', fontWeight: '600' }} numberOfLines={10}>
                  {dto.address}
                </Label>

                <Label style={{ fontSize: 16, color: '#33264d', fontWeight: '600' }} numberOfLines={10}>
                  {dto.city}, {dto.postalCode}
                </Label>

                <Label style={{ fontSize: 16, color: '#33264d', fontWeight: '600' }} numberOfLines={10}>
                  {dto.department}, {dto.country}
                </Label>
              </>
            )}
          </View>

          <View style={{ marginTop: 24, display: 'flex', flexDirection: 'row', justifyContent: 'center', gap: 16 }}>
            <CustomButton
              style={{ backgroundColor: '#5e51a3', borderColor: '#5e51a3', width: 120 }}
              textStyle={{ color: 'white' }}
              title={t('edit')}
              onPress={() => {
                props.onEdit && props.onEdit();
              }}
            />
            <CustomButton
              style={{ backgroundColor: '#5e51a3', borderColor: '#5e51a3', width: 120 }}
              textStyle={{ color: 'white' }}
              title={t('confirm')}
              onPress={() => {
                props.onConfirm && props.onConfirm();
              }}
            />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default AddressRequestPopup;

import { ColorValue, Platform } from 'react-native';

export interface Padding {
  paddingLeft?: number | string;
  paddingRight?: number | string;
  paddingTop?: number | string;
  paddingBottom?: number | string;
}

export interface Margin {
  marginLeft?: number | string;
  marginRight?: number | string;
  marginTop?: number | string;
  marginBottom?: number | string;
}

export interface BorderRadius {
  borderTopLeftRadius?: number;
  borderTopRightRadius?: number;
  borderBottomLeftRadius?: number;
  borderBottomRightRadius?: number;
}

export interface BorderWidth {
  borderTopLeftWidth?: number;
  borderTopRightWidth?: number;
  borderBottomLeftWidth?: number;
  borderBottomRightWidth?: number;
}

export interface Dimension {
  width: number;
  height: number;
}

export interface Shadow {
  shadowOffset: Dimension;
  shadowOpacity: number | string;
  shadowRadius: number | string;
  shadowColor: string;
  elevation: number;
}

export interface Text {
  fontSize: number | string;
  color?: ColorValue;
  fontWeight?: number | string;
  textTransform?: string;
  textDecoration?: string;
}

export class StylesHelper {
  public static padding(...args: (number | string)[]): Padding | any {
    if (args.length === 1) {
      return {
        paddingLeft: args[0],
        paddingRight: args[0],
        paddingTop: args[0],
        paddingBottom: args[0],
      };
    }

    if (args.length === 2) {
      return {
        paddingLeft: args[1],
        paddingRight: args[1],
        paddingTop: args[0],
        paddingBottom: args[0],
      };
    }

    if (args.length === 4) {
      return {
        paddingLeft: args[3],
        paddingRight: args[1],
        paddingTop: args[0],
        paddingBottom: args[2],
      };
    }

    return {};
  }

  public static margin(...args: (number | string)[]): Margin | any {
    if (args.length === 1) {
      return {
        marginLeft: args[0],
        marginRight: args[0],
        marginTop: args[0],
        marginBottom: args[0],
      };
    }

    if (args.length === 2) {
      return {
        marginLeft: args[1],
        marginRight: args[1],
        marginTop: args[0],
        marginBottom: args[0],
      };
    }

    if (args.length === 4) {
      return {
        marginLeft: args[3],
        marginRight: args[1],
        marginTop: args[0],
        marginBottom: args[2],
      };
    }

    return {};
  }

  public static borderWidth(...args: number[]): BorderWidth | any {
    if (args.length === 1) {
      return {
        borderTopWidth: args[0],
        borderRightWidth: args[0],
        borderBottomWidth: args[0],
        borderLeftWidth: args[0],
      };
    }

    if (args.length === 2) {
      return {
        borderTopWidth: args[0],
        borderBottomWidth: args[0],
        borderRightWidth: args[1],
        borderLeftWidth: args[1],
      };
    }

    if (args.length === 4) {
      return {
        borderTopWidth: args[0],
        borderRightWidth: args[1],
        borderBottomWidth: args[2],
        borderLeftWidth: args[3],
      };
    }

    return {};
  }

  public static borderRadius(...args: number[]): BorderRadius | any {
    if (args.length === 1) {
      return {
        borderTopLeftRadius: args[0],
        borderTopRightRadius: args[0],
        borderBottomLeftRadius: args[0],
        borderBottomRightRadius: args[0],
      };
    }

    if (args.length === 2) {
      return {
        borderTopLeftRadius: args[0],
        borderTopRightRadius: args[0],
        borderBottomLeftRadius: args[1],
        borderBottomRightRadius: args[1],
      };
    }

    if (args.length === 4) {
      return {
        borderTopLeftRadius: args[0],
        borderTopRightRadius: args[1],
        borderBottomRightRadius: args[2],
        borderBottomLeftRadius: args[3],
      };
    }

    return {};
  }

  public static shadow(offset: Dimension, opacity: number, radius: number, color: string, elevation: number): Shadow | any {
    return {
      shadowOffset: offset,
      shadowOpacity: opacity,
      shadowRadius: radius,
      shadowColor: color,
      elevation: elevation,
    };
  }

  public static text(size?: number | string, color?: ColorValue, weight?: number | string, transform?: string, decoration?: string): Text | any {
    let titleDefinition: any = { fontSize: 15 };

    if (size) {
      titleDefinition = { fontSize: size };
    }

    if (color) {
      titleDefinition = { ...titleDefinition, color };
    }

    if (weight) {
      titleDefinition = { ...titleDefinition, fontWeight: weight };
    }

    if (transform) {
      titleDefinition = { ...titleDefinition, textTransform: transform };
    }

    if (decoration) {
      titleDefinition = {
        ...titleDefinition,
        textDecorationLine: 'underline' as any,
        textDecorationStyle: 'solid',
        textDecorationColor: color,
        // ...Platform.select({
        //   web: {
        //     textDecoration: decoration,
        //   },
        //   native:
        // }),
      };
    }

    return titleDefinition;
  }
}

import { ThemeProvider } from '@react-navigation/native';
import React from 'react';
import { View, Image } from 'react-native';
import Label, { LabelType } from '../../common/Label';
import useTranslation from '../../../hooks/translation-hook';
import CustomMarkdown from '../../common/CustomMarkDown';

const OnboardingMobileFour = () => {
  const t = useTranslation({ defaultPath: 'onboarding.page4' });
  return (
    <View style={{ flexGrow: 1, backgroundColor: '#eaf8ff' }}>
      <Image source={require('../../../../assets/onBoarding_mobile_4.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto' }} />
      <View style={{ flexGrow: 1, marginTop: 8 }}>
        <View style={{ margin: 'auto', paddingLeft: 12, paddingRight: 12, paddingBottom: 42 }}>
          <Label style={{ fontSize: 28, color: '#1473be', fontWeight: '600', width: 250 }} numberOfLines={3}>
            {t('title')}
          </Label>
          <Label style={{ marginTop: 20, fontSize: 18, color: '#00324a', fontWeight: '400', width: 250 }} numberOfLines={4}>
            {t('subtitle')}
          </Label>
          <View style={{ marginTop: 16 }}>
            <CustomMarkdown style={{ body: { fontSize: 15, color: 'black' } }}>{t('description')}</CustomMarkdown>
          </View>
        </View>
      </View>
    </View>
  );
};

export default OnboardingMobileFour;

import authSlice from '../../store/authSlice';
import Map from '../../components/map/WebMap';
import { Switch as ToggleSwitch } from 'react-native';
import Survey from '../../components/common/survey-js/SurveyJs';

export const authReducer = authSlice.reducer;
export const authActions = authSlice.actions;
export const map = Map;
export const SwitchElement = ToggleSwitch;
export const CustomSurvey = Survey;

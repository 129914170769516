import { ThemeProvider } from '@react-navigation/native';
import React from 'react';
import { View, Image } from 'react-native';
import Label, { LabelType } from '../../common/Label';
import useTranslation from '../../../hooks/translation-hook';

const OnboardingMobileOne = () => {
  const t = useTranslation({ defaultPath: 'onboarding.page1' });
  return (
    <View style={{ flexGrow: 1, backgroundColor: '#e9f5f3' }}>
      <Image source={require('../../../../assets/onBoarding_mobile_1.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto' }} />
      <View style={{ flexGrow: 1, marginTop: 8 }}>
        <View style={{ margin: 'auto', paddingLeft: 12, paddingRight: 12, paddingBottom: 42 }}>
          <Label style={{ fontSize: 28, color: '#43a49e', fontWeight: '600', width: 250 }} numberOfLines={5}>
            {t('title')}
          </Label>
          <Label style={{ marginTop: 20, fontSize: 18, color: '#035164', fontWeight: '400', width: 250 }} numberOfLines={3}>
            {t('subtitle')}
          </Label>
          <Label style={{ fontSize: 15, marginTop: 16, color: 'black' }} numberOfLines={10}>
            {t('description')}
          </Label>
        </View>
      </View>
    </View>
  );
};

export default OnboardingMobileOne;

import React, { useEffect, useState } from 'react';
import { Platform, ScrollView, useWindowDimensions } from 'react-native';
import { View, Image } from 'react-native';
import CustomCarousel from '../carousel/CustomCustomCarousel';
import Label from '../common/Label';
import ValidationTutorial from '../validation/pages/ValidationTutorial';
import ValidationTutorialTwo from '../validation/pages/ValidationTutorialTwo';
import OnboardingOne from './pages/OnboardingOne';
import OnboardingTwo from './pages/OnboardingTwo';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import CustomCheckbox from '../common/CustomCheckbox';
import useTranslation from '../../hooks/translation-hook';
import { lightBoxActions } from '../../store/lightBoxSlice';
import OnboardingMobileOne from './pages/OnboardingMobileOne';
import OnboardingMobileTwo from './pages/OnboardingMobileTwo';
import useCustomTheme from '../../hooks/theme-hook';
import OnboardingThree from './pages/OnboardingThree';
import OnboardingFour from './pages/OnboardingFour';
import OnboardingMobileThree from './pages/OnboardingMobileThree';
import OnboardingMobileFour from './pages/OnboardingMobileFour';

export interface OnboardingProps {
  onClose?(dontShowAgain: boolean): void;
}

const Onboarding = (props: OnboardingProps) => {
  const t = useTranslation();
  const dispatch = useAppDispatch();
  const layout = useWindowDimensions();
  const lightBoxStore = useAppSelector((selector) => selector.lightBox);

  const [checked, setChecked] = useState<boolean>();
  const [backgroundColor, setBackgroundColor] = useState('#e9f5f3');

  useEffect(() => {
    dispatch(
      lightBoxActions.setOnClose(() => {
        dispatch(lightBoxActions.setOnClose(undefined));
        props.onClose && props.onClose(checked);
        setChecked(false);
      })
    );
  }, [checked]);

  const handleOnChange = (checked, value) => {
    setChecked(checked);
  };

  const handlePageChange = (currentPage: number) => {
    switch (currentPage) {
      case 0:
        setBackgroundColor('#e9f5f3');
        break;
      case 1:
        setBackgroundColor('#eeeef0');
        break;
      case 2:
        setBackgroundColor('#fef1dd');
        break;
      case 3:
        setBackgroundColor('#eaf8ff');
        break;
    }
  };

  return (
    <ScrollView style={{ flexGrow: 0, height: layout.height < 640 ? layout.height - 40 : 600 }} contentContainerStyle={{ height: '100%' }}>
      <View style={{ width: '100%', maxWidth: lightBoxStore.mobile ? 350 : 675, height: '100%' }}>
        <View style={{ flexGrow: 1, flexShrink: 1 }}>
          {layout.width > 1200 ? (
            <CustomCarousel
              onPageChange={handlePageChange}
              onClose={() => {
                props.onClose && props.onClose(checked);
                setChecked(false);
                dispatch(lightBoxActions.setVisibility(false));
              }}
              bigView
            >
              <OnboardingOne />
              <OnboardingTwo />
              <OnboardingThree />
              <OnboardingFour />
            </CustomCarousel>
          ) : (
            <CustomCarousel
              onPageChange={handlePageChange}
              onClose={() => {
                props.onClose && props.onClose(checked);
                setChecked(false);
                dispatch(lightBoxActions.setVisibility(false));
              }}
            >
              <OnboardingMobileOne />
              <OnboardingMobileTwo />
              <OnboardingMobileThree />
              <OnboardingMobileFour />
            </CustomCarousel>
          )}
        </View>
        <View style={{ padding: layout.width > 1200 ? 16 : 12, paddingTop: layout.width > 1200 ? 16 : 0, backgroundColor: backgroundColor }}>
          <CustomCheckbox value={'dontShowAgain'} label={t('Dont-show-this-again')} onChange={handleOnChange} />
        </View>
      </View>
    </ScrollView>
  );
};

export default Onboarding;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface errorState {
    resetError?(): void
    hasError: boolean;
}

// Define the initial state using that type
const initialState: errorState = {
    hasError: false
};

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        resetError: (state) => {
            state.resetError && state.resetError();
            state.hasError = false;
        },
        setResetError: (state, action: PayloadAction<any>) => {
            state.resetError = action.payload;
        },
        setHasError: (state, action: PayloadAction<boolean>) => {
            state.hasError = action.payload;
        }
    },
});

export default errorSlice.reducer;
export const errorActions = errorSlice.actions;
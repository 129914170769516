import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useLabelStyles = () => {
  const theme = useCustomTheme();

  const titleCommon = {
    ...StylesHelper.text(15, theme.text.primary, '600', 'uppercase'),
  };

  const titleLargeCommon = {
    ...StylesHelper.text(20, theme.text.primary, '400'),
  };

  const label = {
    title: {
      ...titleCommon,
    },
    titleDark: {
      ...titleCommon,
      color: theme.text.titleDark,
    },
    titleLight: {
      ...titleCommon,
      color: theme.text.titleLight,
    },
    titleSmall: {
      ...StylesHelper.text(13, theme.text.primary, '600'),
    },
    titlePrimarySmall: {
      ...StylesHelper.text(13, theme.text.primary, '600'),
      color: theme.text.titleLight,
    },
    titleWhiteSmall: {
      ...StylesHelper.text(13, theme.text.primary, '600'),
      color: 'white',
    },
    titleDarkSmall: {
      ...StylesHelper.text(13, theme.text.titleDark, '600'),
    },
    caption: {
      ...StylesHelper.text(11, theme.text.titleDark, '600'),
    },
    captionPrimary: {
      ...StylesHelper.text(11, theme.text.primary, '600'),
    },
    captionSmall: {
      ...StylesHelper.text(8, theme.text.titleDark, '600'),
    },
    body1: {
      ...StylesHelper.text(13, theme.text.primary),
    },
    bodyWhiteSmall: {
      ...StylesHelper.text(12, theme.text.primary),
      color: 'white',
    },
    cancelButton: {
      ...StylesHelper.text(22, theme.text.buttonCancel),
    },
    confirmButton: {
      ...StylesHelper.text(22, theme.text.buttonConfirm, '600'),
    },
    navigationTitle: {
      ...StylesHelper.text(13, 'white', '600'),
    },
    titleLarge: {
      ...titleLargeCommon,
      textTransform: 'uppercase' as any,
    },
    titleLargeNoCaps: {
      ...titleLargeCommon,
      fontWeight: '700',
    },
    titleLargeNoCapsPrimary: {
      ...titleLargeCommon,
      color: theme.text.titleDark,
      fontWeight: '700',
    },
    valueLargeMission: {
      ...titleLargeCommon,
      fontSize: 22,
      color: theme.text.titleDark,
      fontWeight: '700',
    },
    titleLargeDark: {
      ...titleLargeCommon,
      color: theme.text.titleDark,
    },
    titleLargeLight: {
      ...titleLargeCommon,
      color: theme.text.titleLight,
    },
    subTitleBlack: {
      ...StylesHelper.text(14, 'black', '600'),
    },
    myAccountLink: {
      ...StylesHelper.text(16, 'white', '600'),
      letterSpacing: 1,
    },
    labelWhite: {
      ...StylesHelper.text(16, theme.text.primary),
      color: 'white' as any,
    },
    infoBoxTitle: {
      ...StylesHelper.text(16, theme.text.darkTurquoiseTwo, 'bold'),
    },
    viewDetails: {
      ...StylesHelper.text(13, theme.text.primary, '600'),
      color: theme.text.titleLight,
      padding: 0,
    },
    cardAnswer: {
      ...StylesHelper.text(16, theme.text.titleDark, '600'),
    },
    validatorQuestion: {
      ...StylesHelper.text(18, theme.text.darkTurquoiseTwo, '600'),
    },
    myRewardsCard: {
      ...StylesHelper.text(28, theme.text.titleLight, '800'),
    },
    loginLoading: {
      ...StylesHelper.text(18, 'white', '600', 'uppercase'),
    },
    validationError: {
      ...StylesHelper.text(13, theme.colorErrorPrimaryBackground, '700'),
      backgroundColor: theme.colorErrorPrimary,
      ...StylesHelper.borderRadius(4),
      ...StylesHelper.padding(17, 12, 12, 12),
      marginTop: -4,
    },
    titleMyRewardsCard: {
      ...StylesHelper.text(13, theme.text.primary, '400'),
      color: theme.mapControls,
    },
    validatorQ: {
      ...StylesHelper.text(30, '#43a49e', '900'),
      opacity: 0.5
    },
    validatorQText: {
      ...StylesHelper.text(14, '#035164', '400'),
    },
    validatorR: {
      ...StylesHelper.text(30, '#035164', '900'),
    },
    validatorRText: {
      ...StylesHelper.text(14, 'black', '400'),
    }
  };

  return label as typeof label;
};

export default useLabelStyles;
export type { useLabelStyles };

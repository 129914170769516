import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const myRewardsStyles = () => {
  const theme = useCustomTheme();
  let myRewardsStyle = {
    cashoutContainer: {
      flexDirection: 'row' as any,
      backgroundColor: theme.background.missionDetail.toString(),
      ...StylesHelper.borderRadius(8),
      ...StylesHelper.padding(16),
      flexGrow: 1,
      flexShrink: 1,
      marginTop: 16,
    },
    cashoutSummary: {
      flexGrow: 1,
      flexShrink: 1,
    },
    cashoutValueProgress: {
      // width: 60,
      ...StylesHelper.padding(0, 0, 0, 8),
      flexGrow: 0,
      flexShrink: 1,
      height: '100%' as any,
    },
    cashoutValueProgressBar: {
      flexDirection: 'column' as any,
      backgroundColor: 'transparent' as any,
      ...StylesHelper.borderRadius(50),
      overflow: 'hidden',
      flexShrink: 1,
      width: 28,
    },
    cashoutValueProgressBarMarkers: {
      height: '80%' as any,
      width: 35,
      display: 'flex' as any,
      flexDirection: 'column' as any,
      alignItems: 'flex-start' as any,
      justifyContent: 'space-between' as any,
      marginTop: -5,
    },
    cashoutValueProgressBarFill: {
      flexGrow: 1,
      flexShrink: 1,
      width: 28,
    },
    cashoutValueProgressBarBack: {
      flexGrow: 1,
      flexShrink: 1,
      width: 28,
    },
    cashoutTotalPoints: {
      fontSize: 44,
      color: theme.text.titleDark,
      fontWeight: '800' as any,
      alignSelf: 'center' as any,
    },
    cashoutTotalPointsLabel: {
      fontSize: 16,
      color: theme.text.titleDark,
      fontWeight: '800' as any,
      alignSelf: 'center' as any,
    },
    cashoutPointsRemaining: {
      textAlign: 'center' as any,
      color: theme.text.titleDark,
      ...StylesHelper.padding(16, 8, 16, 0),
      fontSize: 12,
    },
    pointsExplanation: {
      marginTop: 16,
      ...StylesHelper.padding(0, 16),
      paddingBottom: 24
    },
    pointsExplanationTitle: {
      marginBottom: 8,
      fontSize: 15,
    },
    waysOfWin: {
      flexDirection: 'column' as any,
      backgroundColor: theme.background.missionDetail.toString(),
      ...StylesHelper.borderRadius(8),
      ...StylesHelper.padding(16),
      flexGrow: 1,
      flexShrink: 1,
      marginTop: 32,
    },
    waysOfWinTitle: {
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
      marginBottom: 16,
    },
    waysOfWinItems: {
      flexDirection: 'column' as any,
    },
    waysOfWinItem: {
      flexDirection: 'row' as any,
    },
  };

  return myRewardsStyle as typeof myRewardsStyle;
};
export default myRewardsStyles;

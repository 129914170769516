import React, { useEffect, useState } from 'react';
import { Platform, View } from 'react-native';
import CustomButton from '../common/CustomButton';
import Label, { LabelType } from '../common/Label';
import useTranslation from '../../hooks/translation-hook';
import CustomTextInput, { InputTypeValidation } from '../common/CustomTextInput';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { authActions } from '../../models/helpers/platformImportHelper';
import useCustomTheme from '../../hooks/theme-hook';
import { StylesHelper } from '../../models/helpers/stylesHelper';
import Loading from '../common/Loading';

export interface ReauthenticateUserFormProps {
  onReauthenticate: () => void;
}

const ReauthenticateUserForm = (props: ReauthenticateUserFormProps) => {
  const t = useTranslation();
  const tLogin = useTranslation({ defaultPath: 'login' });
  const dispatch = useAppDispatch();
  const theme = useCustomTheme();
  const authStore = useAppSelector((selector) => selector.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userMessage, setUserMessage] = useState<string | undefined>();
  const [emailLoginValidationError, setEmailLoginValidationError] = useState<string | undefined>(undefined);
  const [passwordLoginValidationError, setPasswordLoginValidationError] = useState<string | undefined>(undefined);
  const [passwordLoginInputValidationError, setPasswordLoginInputValidationError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  const gotLoginInfo = (response) => {
    console.log('login response', response);
    setLoading(false);

    if (response.code == 'auth/wrong-password') {
      setUserMessage(tLogin('invalid-credentials'));
      return;
    }

    if (response.code === 'auth/too-many-requests') {
      setUserMessage(tLogin('too-many-request-login'));
      return;
    }

    if (response.code == 'auth/user-not-found') {
      setUserMessage(tLogin('invalid-credentials'));
      return;
    }

    if (Platform.OS == 'web' ? response.email : response.user.email) {
      props.onReauthenticate && props.onReauthenticate();
    }
  };

  const handleReauthenticate = (email, password) => {
    if (!email || email.trim() === '') {
      setEmailLoginValidationError(tLogin('email_required'));
      return;
    }

    if (!password || password.trim() === '') {
      setPasswordLoginValidationError(tLogin('password_required'));
      return;
    }

    if (email?.toLocaleLowerCase() != authStore?.user?.email?.toLocaleLowerCase()) {
      setUserMessage(tLogin('invalid-credentials'));
      return;
    }

    if (email && password) {
      setUserMessage(undefined);
      setLoading(true);
      dispatch(authActions.reauthenticate({ email: email.trim(), password, callback: gotLoginInfo }));
    }
  };

  return (
    <View style={{ flexGrow: 1, flexShrink: 1, marginTop: 8, paddingHorizontal: 16, paddingBottom: 16 }}>
      <View style={{ display: 'flex', flexGrow: 1, flexShrink: 1 }}>
        <Label style={{ fontSize: 28, color: '#1473BD', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={3}>
          {t('Reauthenticate')}
        </Label>
        <>
          {userMessage && (userMessage?.length ?? 0) > 0 && (
            <Label
              numberOfLines={5}
              variant={LabelType.titleSmall}
              style={{ padding: 16, marginTop: 24, backgroundColor: theme.primary, ...StylesHelper.borderRadius(8) }}
            >
              {userMessage}
            </Label>
          )}
        </>
        <CustomTextInput
          style={{ marginTop: 24 }}
          required
          inputTypeValidation={InputTypeValidation.email}
          onValidationChange={(v) => (v.valid ? setEmailLoginValidationError(undefined) : setEmailLoginValidationError(v.message))}
          placeholder={t('email')}
          requiredMessage={tLogin('email_required')}
          key={2}
          value={email}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setEmail(text);
          }}
          onKeyPress={(e) => {
            {
              if ((e as any).code === 'Enter') {
                handleReauthenticate(email, password);
              }
            }
          }}
        />
        <>{emailLoginValidationError && <Label variant={LabelType.validationError}>{emailLoginValidationError}</Label>}</>
        <CustomTextInput
          style={{ marginTop: 16 }}
          required
          requiredMessage={tLogin('password_required')}
          onValidationChange={(v) => (v.valid ? setPasswordLoginInputValidationError(undefined) : setPasswordLoginInputValidationError(v.message))}
          secureTextEntry={true}
          placeholder={t('password')}
          key={1}
          value={password}
          onChange={(e) => {
            const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
            setPassword(text);
          }}
          onKeyPress={(e) => {
            if ((e as any).code === 'Enter') {
              handleReauthenticate(email, password);
            }
          }}
        />
        <>
          {(passwordLoginValidationError || passwordLoginInputValidationError) && (
            <Label variant={LabelType.validationError} numberOfLines={10}>
              {passwordLoginValidationError ? passwordLoginValidationError : passwordLoginInputValidationError}
            </Label>
          )}
        </>
      </View>
      {loading ? (
        <View style={{ width: '100%', height: 50 }}>
          <Loading />
        </View>
      ) : (
        <CustomButton
          style={{ marginTop: 24, backgroundColor: '#1473BD', borderColor: '#1473BD' }}
          textStyle={{ color: 'white' }}
          title={t('Reauthenticate')}
          onPress={() => handleReauthenticate(email, password)}
        />
      )}
    </View>
  );
};

export default ReauthenticateUserForm;

import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { View, Pressable, Image, useWindowDimensions, ScrollView } from 'react-native';
import { useAppSelector, useAppDispatch } from '../../hooks/store-hook';
import useCustomTheme from '../../hooks/theme-hook';
import useTranslation from '../../hooks/translation-hook';
import { lightBoxActions } from '../../store/lightBoxSlice';
import CustomButton from '../common/CustomButton';
import Label from '../common/Label';

export interface LoginPopupProps {
  onLogin?(): void;
  onSignUp?(): void;
}

const LoginPopup = (props: LoginPopupProps) => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();
  const authStore = useAppSelector((selector) => selector.auth);
  const dispatch = useAppDispatch();
  const t = useTranslation();

  return (
    <ScrollView style={{ backgroundColor: theme.background.primary, width: layout.width > 1200 ? 570 : 350, flexShrink: 1 }}>
      {layout.width > 1200 ? (
        <Image style={{ position: 'absolute', top: -1, right: -1, width: 275, height: 300 }} source={require('../../../assets/loginLady.png')} />
      ) : (
        <Image style={{ position: 'absolute', top: -75, right: 0, width: 375, height: 412 }} source={require('../../../assets/loginLady-m.png')} />
      )}
      <Pressable
        style={{ position: 'absolute', top: 8, right: 8, zIndex: 2000 }}
        onPress={() => {
          dispatch(lightBoxActions.setVisibility(false));
        }}
      >
        <FontAwesomeIcon color={'white'} icon={faXmark} size={24} />
      </Pressable>
      <View style={{ padding: 16 }}>
        <Label
          numberOfLines={4}
          style={
            layout.width > 1200
              ? { width: 240, fontSize: 40, color: theme.text.darkTurquoiseTwo, fontWeight: '700' }
              : { width: 'auto', textAlign: 'center', fontSize: 36, color: theme.text.darkTurquoiseTwo, fontWeight: '700', marginTop: 350 }
          }
        >
          {t('Log-in-or-sign-up')}
        </Label>
        <Label
          numberOfLines={3}
          style={layout.width > 1200 ? { width: 300, marginTop: 24, fontSize: 16 } : { width: 'auto', textAlign: 'center', marginTop: 24, fontSize: 16 }}
        >
          {t('Use-your-email-or-another-service-to-continue-with')}
        </Label>
        <View style={{ flexDirection: layout.width > 1200 ? 'row' : 'column', gap: 16, marginTop: layout.width > 1200 ? 24 : 16 }}>
          <CustomButton
            style={{ minWidth: 150 }}
            title={t('login_title')}
            onPress={() => {
              props.onLogin && props.onLogin();
            }}
          />
          <CustomButton
            style={{ minWidth: 150 }}
            secondary
            title={t('login.sign_up')}
            onPress={() => {
              props.onSignUp && props.onSignUp();
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default LoginPopup;

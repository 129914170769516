import axios from 'axios';
import Constants from 'expo-constants';
import { useEffect, useState } from 'react';
import { Platform } from 'react-native';
import { MapLocation } from '../models/MapModels';
require('../models/helpers/string');

const useGoogleGeocode = () => {
  const getGeocode = (location: MapLocation, callback: (results: { postalCode: string; location: string, province: string, country: string, route: string, streetNumber: string }) => void) => {
    if (Platform.OS === 'web') {

      let myGoogle = (window as any).google;
      let g = new myGoogle.maps.Geocoder();

      g.geocode({ location: { lat: location.lat, lng: location.lng } }).then((result) => {
        const streetNumber = result.results[0].address_components.find((t) => t.types[0] === 'street_number')?.short_name;
        const route = result.results[0].address_components.find((t) => t.types[0] === 'route')?.short_name;
        const postalCode = result.results[0].address_components.find((t) => t.types[0] === 'postal_code')?.short_name;
        const location = result.results[0].address_components.find((t) => t.types[0] === 'locality')?.short_name;
        const province = result.results[0].address_components.find((t) => t.types[0] === 'administrative_area_level_1')?.long_name;
        const country = result.results[0].address_components.find((t) => t.types[0] === 'country')?.long_name;


        callback({ postalCode, location, province, country, route, streetNumber });
      });
    } else {
      const apiCallUrl = Constants.expoConfig.extra.googleGeocodeUrl.format(`${location.lat},${location.lng}`, Constants.expoConfig.extra.googleGeocodeApiKey);

      axios.get(apiCallUrl).then((res) => {
        const result = res.data;
        callback(result.results);
      });
    }
  };

  return getGeocode;
};

export default useGoogleGeocode;

import { faWarning, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React, { useEffect, useState } from 'react';
import { ScrollView, View, Image, Pressable } from 'react-native';
import { authActions } from '../../models/helpers/platformImportHelper';
import CustomButton from './CustomButton';
import Label, { LabelType } from './Label';
import useStyles from '../../hooks/styles-hook';
import useCustomTheme from '../../hooks/theme-hook';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { modalActions } from '../../store/modalSlice';
import useTranslation from '../../hooks/translation-hook';
import Loading from './Loading';
import { useNavigation } from '@react-navigation/native';
import CustomMarkDown from './CustomMarkDown';
import { lightBoxActions } from '../../store/lightBoxSlice';
import LoginPopup from '../login/LoginPopup';

export interface LoggedInRestrictedArea {
  preloaded?: boolean;
  message: string;
  onlyPopup?: boolean;
  onPopupShow?(): void;
}

const LoggedInRestrictedArea = (props: LoggedInRestrictedArea) => {
  const styles = useStyles((selector) => selector.restrictedArea);
  const theme = useCustomTheme();
  const dispatch = useAppDispatch();
  const t = useTranslation();
  const navigation = useNavigation();

  const showPopup = () => {
    dispatch(
      lightBoxActions.setContent(
        <LoginPopup
          onLogin={() => {
            dispatch(lightBoxActions.setVisibility(false));
            navigation.navigate('logout' as never);
          }}
          onSignUp={() => {
            dispatch(lightBoxActions.setVisibility(false));
            navigation.navigate({ name: 'logout', params: { signUp: true } } as never);
          }}
        />
      )
    );
    dispatch(lightBoxActions.setVisibility(true));

    if (props.onPopupShow) {
      props.onPopupShow();
    }
  };

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      showPopup();
    });
  }, [navigation]);

  useEffect(() => {
    showPopup();
  }, []);

  return (
    <>
      {props.onlyPopup && <></>}
      {!props.onlyPopup && (
        <View
          style={{
            backgroundColor: theme.background.primary,
            display: 'flex' as any,
            flexGrow: 1,
            maxHeight: '100%',
            alignItems: 'center',
          }}
        >
          <FontAwesomeIcon style={styles.missionNotLogged.container.icon} size={48} icon={faWarning} color={theme.colorErrorPrimary.toString()} />
          <Label variant={LabelType.titleSmall} style={{ textAlign: 'center' }}>
            {props.message}
          </Label>
          <View style={{ marginTop: 16 }}>
            <CustomButton secondaryColor title={t('redirect_to_login')} onPress={() => navigation.navigate('logout' as never)} />
          </View>
        </View>
      )}
    </>
  );
};

export default LoggedInRestrictedArea;

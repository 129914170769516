import * as Location from 'expo-location';
import Constants from 'expo-constants';

import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Image, Platform, Pressable, View, useWindowDimensions, ScrollView } from 'react-native';
import AuthGuard from '../components/common/AuthGuard';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import LoggedInRestrictedArea from '../components/common/LoggedInRestrictedArea';
import { LoggedState } from '../models/AuthModelsCommon';
import useTranslation from '../hooks/translation-hook';
import useStyles from '../hooks/styles-hook';
import Label, { LabelType } from '../components/common/Label';
import {
  AcceptMissionStatus,
  GetAcceptMissionByBranchDto,
  GetMissionDetailsDto,
  // GetMissionsListBranchDto,
  GetMysteryShopperProfileMemberDto,
  GetRemoteMissionsDto,
  MissionConfigurationDetailType,
  MissionsClient,
  MyMissionClient,
} from '../api/api.g';
import { useFocusEffect, useIsFocused, useNavigation, useRoute } from '@react-navigation/native';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import useApi from '../hooks/api-hook';
import { MapLocation } from '../models/MapModels';
import Loading from '../components/common/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faAward,
  faBasketShopping,
  faBullseye,
  faCalendar,
  faChevronLeft,
  faCircleExclamation,
  faDollar,
  faPenNib,
  faSliders,
  faStopwatch,
  faStore,
  faUserSecret,
  faWarning,
} from '@fortawesome/pro-regular-svg-icons';
import pin from '../../assets/pin-selected.png';
import { MissionsHelper } from '../models/helpers/missionsHelper';
import CustomMarkdown from '../components/common/CustomMarkDown';
import useCustomTheme from '../hooks/theme-hook';
import NavigationButton from '../components/common/NavigationButton';
import { faCircleChevronLeft } from '@fortawesome/free-solid-svg-icons';
import CustomButton from '../components/common/CustomButton';
import { FilterItemProps } from '../components/common/filter/FilterItem';
import CustomCheckbox from '../components/common/CustomCheckbox';
import { missionsActions } from '../store/missionsSlice';
import { lightBoxActions } from '../store/lightBoxSlice';
import ModalContainer from '../components/common/ModalContainer';
import { StringHelper } from '../helpers/stringHelper';
import { StylesHelper } from '../models/helpers/stylesHelper';
import MoreInfoButton from '../components/common/MoreInfoButton';
import useAdvertisementTrackingHook from '../hooks/advertisement-hook';
import { AnalyticsHelper } from '../helpers/analyticsHelper';

export enum NavigationState {
  List,
  Details,
}

export enum MissionDetailsState {
  Mission,
  Scenario,
  Conditions,
  Admissibility,
}

export interface DetailStates {
  detail: GetMissionDetailsDto;
  detailState: MissionDetailsState;
}

const RemoteMissions = () => {
  const authStore = useAppSelector((selector) => selector.auth);
  const missionsStore = useAppSelector((selector) => selector.missions);

  const t = useTranslation();
  const tMission = useTranslation({ defaultPath: 'mission' });

  const dispatch = useAppDispatch();
  const layout = useWindowDimensions();
  const styles = useStyles((section) => section.myMissions);
  const navigation = useNavigation();
  const route = useRoute();
  const theme = useCustomTheme();
  const stylesCard = useStyles((section) => section.missionCard);
  const apiMyMission = useApi(MyMissionClient);
  const advertisementTracking = useAdvertisementTrackingHook();

  const apiRemoteMissions = useApi(MissionsClient);

  const [userLocation, setUserLocation] = useState<MapLocation>(undefined);
  const defaultPosition: MapLocation = Constants.expoConfig.extra.defautlLocation;

  const [missionBlocks, setMissionBlocks] = useState<GetRemoteMissionsDto[]>();
  const [selectedMission, setSelectedMission] = useState<GetRemoteMissionsDto>();
  const [preLoaded, setPreLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const [navigationState, setNavigationState] = useState<NavigationState>();
  const [filterItems, setFilterItems] = useState<FilterItemProps[]>([]);
  const [showTagPopup, setShowTagPopup] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState<FilterItemProps[]>([]);
  const [selectedMissionId, setSelectedMissionId] = useState<string>();
  const [detail, setDetail] = useState<DetailStates[]>();
  const [loadingDetail, setLoadingDetail] = useState(true);
  const [acceptMissionViewModels, setAcceptMissionViewModels] = useState<GetAcceptMissionByBranchDto[]>([]);
  const [canAcceptMissionQuerySeed, setCanAcceptMissionQuerySeed] = useState<string>(StringHelper.generateRandomString());

  const defaultLogo = useMemo(() => <FontAwesomeIcon icon={faStore} style={stylesCard.header.logo.icon} />, [stylesCard]);

  const isFocused = useIsFocused();
  const [navigationEnd, setNavigationEnd] = useState(false);
  const queryClient = useQueryClient();

  useFocusEffect(
    React.useCallback(() => {
      if (authStore.logged !== undefined && authStore.logged !== LoggedState.NotLogged) {
        console.log(`%cNavigation end - ${route.name}`, 'color: #ffa600;');

        AnalyticsHelper.logScreenView(route.name).then(() => {
          console.log('Analytics', 'Page view: Remote missions');
        });

        setNavigationEnd(true);
      } else {
        setNavigationEnd(false);
      }
    }, [authStore.logged])
  );

  React.useEffect(() => {
    const unsubscribe = navigation.addListener('blur', () => {
      console.log(`%cOnBlur - ${route.name}`, 'color: #ffa600;');
      queryClient.cancelQueries({ queryKey: ['myMissions'] });
      setNavigationEnd(false);
    });

    return unsubscribe;
  }, [navigation]);

  useEffect(() => {
    Location.requestForegroundPermissionsAsync().then((result) => {
      if (result.status !== Location.PermissionStatus.GRANTED) {
        setUserLocation(defaultPosition);
        return;
      }

      Location.getCurrentPositionAsync({}).then((currentLocation) => {
        setUserLocation({ lat: currentLocation.coords.latitude, lng: currentLocation.coords.longitude });
      });
    });
  }, [detail]);

  useEffect(() => {
    if (!navigationState) {
      if (layout.width < 768) {
        setNavigationState(NavigationState.List);
      }
    }
  }, [layout.width]);

  const handleFilterChange = (checked: boolean, value: string | number) => {
    if (!selectedFilterItems) {
      return;
    }

    setSelectedFilterItems((prev) => {
      var selectedItem = prev.find((s) => s.id === value);
      var selectedItemIndex = prev.findIndex((s) => s.id === value);

      if (checked) {
        if (!selectedItem) {
          prev.push(filterItems.find((s) => s.id === value));
        }
      } else {
        if (selectedItem) {
          prev.splice(selectedItemIndex, 1);
        }
      }

      return prev.slice();
    });
  };

  const handleSelectAll = (e) => {
    e.preventDefault();

    console.log('filters', filterItems?.length);
    console.log('SElected filters', selectedFilterItems?.length);
    console.log('comparison', filterItems?.length ?? 0 > selectedFilterItems?.length ?? 0);

    if (filterItems?.length > selectedFilterItems?.length) {
      setSelectedFilterItems(filterItems);
    } else {
      setSelectedFilterItems([]);
    }
  };

  const handleFilterButtonPress = () => {
    setShowTagPopup((prev) => !prev);
  };

  const handleOnConfirm = () => {};

  const acceptMission = useMutation({
    retry: false,
    mutationFn: (missionInfo: { periodMissionConfigurationId: string; branchId: string }) => {
      return apiRemoteMissions.acceptMission(missionInfo.periodMissionConfigurationId, missionInfo.branchId, apiMyMission.tokenSource?.token);
    },
    onSuccess(data, variable) {
      setLoading(false);

      if (data.success) {
        dispatch(missionsActions.setReloadMissionListSeed(String.prototype.generateRandomString(5)));
        navigation.navigate('mymissions' as never);
      } else {
        setAcceptMissionViewModels((prev) => {
          let newModel = prev.find((x) => x.periodMissionConfigurationId == variable.periodMissionConfigurationId);
          newModel.acceptMissionStatus = data.acceptMissionStatus;
          newModel.missingProfileMembers = data.missingProfileMembers;
          newModel.rotationInfo = data.rotationInfo;

          return prev.slice();
        });
      }
    },
    onError: (err) => {
      setLoading(false);
      throw err;
    },
  });

  const canAcceptMissionQuery = useQuery({
    enabled: !!selectedMission && !!authStore.idToken,
    queryKey: ['canAcceptMissionQuery', selectedMission?.branchId, canAcceptMissionQuerySeed],
    queryFn: () => {
      setLoadingDetail(true);
      return apiRemoteMissions.getAcceptMissions(selectedMission?.branchId, apiRemoteMissions.tokenSource?.token);
    },
    onSuccess(data) {
      setLoadingDetail(false);
      setAcceptMissionViewModels(data.items);
    },
    onError: (err) => {
      setLoading(false);
      throw err;
    },
  });

  const remoteMissionsQuery = useQuery({
    enabled: !!authStore.user && !!authStore.idToken && !!missionsStore.missionListSeed && navigationEnd,
    queryKey: ['remoteMissions', 'list', authStore.idToken, navigationEnd, missionsStore.missionListSeed, selectedFilterItems],
    queryFn: ({ signal }) => {
      setLoading(true);

      const promise = apiRemoteMissions.getRemoteMissions(selectedFilterItems?.map((x) => x.id) ?? [], apiRemoteMissions.tokenSource?.token);

      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiRemoteMissions.tokenSource?.cancel('Query was cancelled by React Query');
        apiRemoteMissions.onCancel();
      });

      return promise;
    },
  });

  const handleModalOnConfirm = (dto: GetMysteryShopperProfileMemberDto) => {
    // profileMember.showProfileMemberDrawer({
    //   value: '',
    //   profileMember: dto,
    // });
  };

  const showExplanationPopup = (dto: GetMysteryShopperProfileMemberDto) => {
    dispatch(
      lightBoxActions.setContent(
        <ModalContainer hasDontShowAgain onConfirm={() => handleModalOnConfirm(dto)}>
          <View style={{ flexShrink: 1, alignItems: 'center' }}>
            <Image style={{ width: 116, height: 68 }} source={require('../../assets/annoucement.png')} />
            <Label style={{ fontSize: 26, fontWeight: '600', color: theme.text.sea, textAlign: 'center' }}>{t('Attention')}</Label>
            <Label style={{ fontSize: 18, fontWeight: '600', color: theme.text.darkTurquoiseTwo, textAlign: 'center' }}>
              {t('Some-missions-require-a-specific-profile')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 14, textAlign: 'center' }}>{t('Please-complete-your-profile-by-answering-the-foll')}</Label>
          </View>
        </ModalContainer>
      )
    );
    dispatch(lightBoxActions.setVisibility(true));
  };

  const handleAcceptMission = (periodMissionConfigurationId, branchId) => {
    let data = acceptMissionViewModels.find((x) => x.periodMissionConfigurationId === periodMissionConfigurationId);
    if (data?.acceptMissionStatus === AcceptMissionStatus.MissionEligible) {
      acceptMission.mutate({ branchId: branchId, periodMissionConfigurationId: periodMissionConfigurationId });
    } else {
      setLoading(false);

      if (data.acceptMissionStatus === AcceptMissionStatus.IncompleteProfileRequirements) {
        if (data.missingProfileMembers[0]) {
          if (advertisementTracking.showAdvertisement('ProfileRequirement')) {
            showExplanationPopup(data.missingProfileMembers[0]);
          } else {
            // profileMember.showProfileMemberDrawer({
            //   value: '',
            //   profileMember: data.missingProfileMembers[0],
            // });
          }
        }
      }

      if (data.acceptMissionStatus === AcceptMissionStatus.UserIneligibleForMission) {
        dispatch(
          lightBoxActions.setContent(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Label style={{ fontSize: 40, fontWeight: '600' }}>{t('UserIneligibleForMission')}</Label>
            </View>
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      }

      if (data.acceptMissionStatus === AcceptMissionStatus.MissionAlreadyInProgress) {
        dispatch(
          lightBoxActions.setContent(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Label style={{ fontSize: 40, fontWeight: '600' }}>{t('MissionAlreadyInProgress')}</Label>
            </View>
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      }

      if (data.acceptMissionStatus === AcceptMissionStatus.MissionUnavailable) {
        dispatch(
          lightBoxActions.setContent(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Label style={{ fontSize: 40, fontWeight: '600' }}>{t('MissionUnavailable')}</Label>
            </View>
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      }

      if (data.acceptMissionStatus === AcceptMissionStatus.MissionLimitReached) {
        dispatch(
          lightBoxActions.setContent(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Label style={{ fontSize: 40, fontWeight: '600' }}>{t('MissionLimitReached')}</Label>
            </View>
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      }

      if (data.acceptMissionStatus === AcceptMissionStatus.UserNotAuthenticated) {
        dispatch(
          lightBoxActions.setContent(
            <View style={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }}>
              <Label style={{ fontSize: 40, fontWeight: '600' }}>{t('UserNotAuthenticated')}</Label>
            </View>
          )
        );
        dispatch(lightBoxActions.setVisibility(true));
      }
    }
  };

  useEffect(() => {
    if (remoteMissionsQuery.isSuccess) {
      if (remoteMissionsQuery.data) {
        console.log('remote mission', remoteMissionsQuery.data);
        setMissionBlocks(remoteMissionsQuery.data.missions);

        if (remoteMissionsQuery.data.missions && remoteMissionsQuery.data.missions.length > 0 && layout.width > 768) {
          setSelectedMissionId(`${remoteMissionsQuery.data.missions[0].periodMissionConfigurationId}-${remoteMissionsQuery.data.missions[0].branchId}`);
          setSelectedMission(remoteMissionsQuery.data.missions[0]);
        }

        const filter: FilterItemProps[] = remoteMissionsQuery.data.tags.map((x) => {
          return {
            id: x.id,
            name: x.name,
          };
        });
        setFilterItems(filter);
      }

      setPreLoaded(true);
      setLoading(false);
    }
  }, [remoteMissionsQuery.isSuccess, remoteMissionsQuery.data]);

  const missionDetailQuery = useQuery({
    enabled: !!selectedMission && !!authStore.idToken,
    queryKey: ['myRemoteMissionsDetail', selectedMission, missionsStore.missionListSeed],
    queryFn: ({ signal }) => {
      // Cancel the request if React Query signals to abort
      signal?.addEventListener('abort', () => {
        apiRemoteMissions.tokenSource?.cancel('Query was cancelled by React Query');
        apiRemoteMissions.onCancel();
      });

      setLoadingDetail(true);

      return apiRemoteMissions.getMissionDetails(
        selectedMission.branchId,
        `${userLocation.lat}&${userLocation.lng}`,
        false,
        apiRemoteMissions.tokenSource?.token
      );
    },
  });

  useEffect(() => {
    if (missionDetailQuery.isSuccess) {
      setLoadingDetail(false);
      setDetail(
        missionDetailQuery.data.missionDetails.map((x) => {
          return {
            detailState: MissionDetailsState.Mission,
            detail: x,
          };
        })
      );
    }
  }, [missionDetailQuery.isSuccess, missionDetailQuery.data]);

  const getLogo = (branch: any) => {
    if (branch?.logoUrl && branch?.hasLogo) {
      return <Image style={styles.newVersion.list.logo.image} source={{ uri: branch.logoUrl }} />;
    } else {
      return defaultLogo;
    }
  };

  const setDetailsState = (detail: DetailStates, state: MissionDetailsState) => {
    setDetail((prev) => {
      let newDetail = prev.find((x) => x.detail.periodMissionConfigurationId === detail.detail.periodMissionConfigurationId);
      newDetail.detailState = state;
      return prev.slice();
    });
  };

  const getDetailComponent = (detail: DetailStates) => {
    switch (detail.detailState) {
      case MissionDetailsState.Scenario:
        return (
          <>
            <Label variant={LabelType.title} style={styles.newVersion.details.header.subTitle}>
              Scenario
            </Label>
            <CustomMarkdown>{detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text}</CustomMarkdown>
            <NavigationButton
              style={styles.newVersion.details.content.details.infoBlocks.baseLinks}
              icon={faChevronLeft}
              iconSize={16}
              color={theme.text.titleLight}
              labelVariant={LabelType.titleSmall}
              onPress={() => setDetailsState(detail, MissionDetailsState.Mission)}
              text={t('back')}
            />
          </>
        );
      case MissionDetailsState.Conditions:
        return (
          <>
            <Label variant={LabelType.title} style={styles.newVersion.details.header.subTitle}>
              {t('Terms-and-Conditions')}
            </Label>
            <CustomMarkdown>{detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.TermsAndConditions)?.text}</CustomMarkdown>
            <NavigationButton
              style={styles.newVersion.details.content.details.infoBlocks.baseLinks}
              icon={faChevronLeft}
              iconSize={16}
              color={theme.text.titleLight}
              labelVariant={LabelType.titleSmall}
              onPress={() => setDetailsState(detail, MissionDetailsState.Mission)}
              text={t('back')}
            />
          </>
        );
      case MissionDetailsState.Admissibility:
        return (
          <>
            <Label variant={LabelType.title} style={styles.newVersion.details.header.subTitle}>
              {t('Admissibility')}
            </Label>
            <CustomMarkdown>{detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.Eligibility)?.text}</CustomMarkdown>
            <NavigationButton
              style={styles.newVersion.details.content.details.infoBlocks.baseLinks}
              icon={faChevronLeft}
              iconSize={16}
              color={theme.text.titleLight}
              labelVariant={LabelType.titleSmall}
              onPress={() => setDetailsState(detail, MissionDetailsState.Mission)}
              text={t('back')}
            />
          </>
        );
      case MissionDetailsState.Mission:
        return (
          <>
            {detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.Objective) && (
              <View style={styles.newVersion.details.content.details.infoBlocksBase}>
                <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                  <FontAwesomeIcon icon={faBullseye} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                  <Label variant={LabelType.titleDark}>{t('Your-objective')}</Label>
                  <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                    {`## Rursus septem

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`}
                  </MoreInfoButton>
                </View>
                <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                  <CustomMarkdown>{detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.Objective)?.text}</CustomMarkdown>
                </View>
              </View>
            )}
            {detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.ToNote) && (
              <View style={styles.newVersion.details.content.details.infoBlocksBase}>
                <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                  <FontAwesomeIcon icon={faPenNib} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                  <Label variant={LabelType.titleDark}>{t('Please-note')}</Label>
                  <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                    {`## Rursus septem

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`}
                  </MoreInfoButton>
                </View>
                <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                  <CustomMarkdown>{detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.ToNote)?.text}</CustomMarkdown>
                </View>
              </View>
            )}
            {detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.Scenario) && (
              <View style={styles.newVersion.details.content.details.infoBlocksBase}>
                <View style={styles.newVersion.details.content.details.infoBlocks.title}>
                  <FontAwesomeIcon icon={faUserSecret} style={styles.newVersion.details.content.details.infoBlocks.titleIcon} />
                  <Label variant={LabelType.titleDark}>{t('The-scenario')}</Label>
                  <MoreInfoButton style={{ marginLeft: 8 }} title={''}>
                    {`## Rursus septem

**Freta sanguine** prece non tibi circumfuso socios. Ubi quodsi longo quid
repugnat esse isto modo sensisse ecquis. Securi cremet. Ego poscat omnis, sui
tene faciat in lacrimis sibi. Flectitur pennis tendentes exstitit ordine.`}
                  </MoreInfoButton>
                </View>
                <View style={styles.newVersion.details.content.details.infoBlocks.content}>
                  <CustomMarkdown>{detail?.detail?.details?.find((x) => x.type === MissionConfigurationDetailType.Scenario)?.text}</CustomMarkdown>
                  <Pressable
                    style={styles.newVersion.details.content.details.infoBlocks.link}
                    onPress={() => setDetailsState(detail, MissionDetailsState.Scenario)}
                  >
                    <Label variant={LabelType.caption}>{t('Read-more')}</Label>
                  </Pressable>
                </View>
              </View>
            )}
            <View style={styles.newVersion.details.content.details.infoBlocks.baseLinks}>
              <Pressable
                style={styles.newVersion.details.content.details.infoBlocks.link}
                onPress={() => setDetailsState(detail, MissionDetailsState.Admissibility)}
              >
                <Label variant={LabelType.caption}>{t('Admissibility')}</Label>
              </Pressable>
              <Label variant={LabelType.caption}>&nbsp;{t('and')}&nbsp;</Label>
              <Pressable
                style={styles.newVersion.details.content.details.infoBlocks.link}
                onPress={() => setDetailsState(detail, MissionDetailsState.Conditions)}
              >
                <Label variant={LabelType.caption} style={{ textTransform: 'lowercase' }}>
                  {t('conditions')}
                </Label>
              </Pressable>
            </View>
          </>
        );
    }
  };

  const buttonIsDisabled = (status: AcceptMissionStatus) => {
    return status != AcceptMissionStatus.MissionEligible && status != AcceptMissionStatus.IncompleteProfileRequirements;
  };

  const missionStatusText = (status: AcceptMissionStatus) => {
    switch (status) {
      case AcceptMissionStatus.MissionLimitReached:
        return t('MissionLimitReached');
      case AcceptMissionStatus.MissionAlreadyInProgress:
        return t('MissionAlreadyInProgress');
      case AcceptMissionStatus.MissionLimitRotationReach:
        return t('MissionLimitRotationReach');
      case AcceptMissionStatus.MissionUnavailable:
        return t('MissionUnavailable');
      case AcceptMissionStatus.UserIneligibleForMission:
        return t('UserIneligibleForMission');
      case AcceptMissionStatus.UserNotAuthenticated:
        return t('UserNotAuthenticated');
      case AcceptMissionStatus.Retry:
        return t('AcceptMissionRetry');
    }

    return undefined;
  };

  const getEmptyComponent = () => {
    return (
      <View
        style={{
          height: '100%',
          flexGrow: 1,
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          ...StylesHelper.padding(16, 24),
        }}
      >
        <Label variant={LabelType.titleLargeNoCapsPrimary} style={styles.newVersion.list.title}>
          {t('remote_missions')}
        </Label>
        <View style={{ flexGrow: 1, flexShrink: 0, flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'center', marginTop: 16 }}>
          <View style={{ alignContent: 'center', justifyContent: 'center' }}>
            <Image style={{ width: 229, height: 274, alignSelf: 'center' }} source={require('../../assets/NoValidations.png')} />
            <Label variant={LabelType.titleLargeNoCapsPrimary} style={{ textAlign: 'center', fontSize: 30 }}>
              {t('oh')}
            </Label>
            <Label variant={LabelType.titleLargeLight} style={{ textAlign: 'center', fontWeight: '700', fontSize: 20 }}>
              {t('remote.no_remote_missions')}
            </Label>
            <Label style={{ textAlign: 'center', alignSelf: 'center', fontSize: 14, width: 175, paddingVertical: 24 }}>
              {t('oppotunity-explore-other-missions')}
            </Label>
            <CustomButton
              title={t('missions-available')}
              onPress={() => {
                navigation.navigate('map' as never);
              }}
            />
          </View>
        </View>
      </View>
    );
  };

  return (
    <AuthGuard>
      {authStore.logged === LoggedState.Anonymous && navigationEnd && (
        <LoggedInRestrictedArea preloaded={false} message={t('need-login-before', { action: t('see-your-missions') })} />
      )}
      {authStore.logged !== LoggedState.Anonymous && (
        <>
          {layout.width <= 768 && navigationState === NavigationState.Details && (
            <NavigationButton
              style={styles.newVersion['detailsWrapper.backButton']}
              icon={faCircleChevronLeft}
              iconSize={24}
              labelVariant={LabelType.titleSmall}
              onPress={() => {
                setNavigationState(NavigationState.List);
                setSelectedMission(undefined);
                setSelectedMissionId(undefined);
              }}
              text={t('back')}
            />
          )}
          <View style={styles.containerBase}>
            {(layout.width > 768 || (layout.width <= 768 && navigationState === NavigationState.List)) && !loading && missionBlocks.length > 0 && (
              <View style={styles.container.sideBar}>
                {!loading ? (
                  <ScrollView>
                    <View style={{ flexDirection: 'row' }}>
                      <Label variant={LabelType.titleLargeNoCapsPrimary} style={styles.newVersion.list.title}>
                        {t('remote_missions')}
                      </Label>
                      <MoreInfoButton title={t('help_support')}>
                        {`# Rudis inferius

## Multis sis velox quercus usus frequens Minervaetransformabantur

Lorem markdownum obligor repetam dignabere qua Sigea crinem Cillan succrescere
tollit meliora quem. Auro ferunt? At Aventino ferunt tamen
[nodosque](http://salutant.net/est), et cognovit rurisque **in illud manibus**
ager, [omnia](http://quamquam.net/) in vulnera. Telum conscendunt accensis
hinnitus gravis claudit. Est tantum Perimelen iuratus Cerberei signum atra moras
finibus volat [navis margine omnes](http://in.net/) Cybeleius.

> Unde **mea onus** Verque, nec obstaret, sint, unguibus ut dente Pithecusas
> nulla, cadunt nec agitur. Reminiscor quantas ab vulnera Oeneus et poterat sub
> media eminet, Phoebe. Hiatu excipiunt quodque [efficiet
> arma](http://www.quo.net/in-dixerunt.html), tridente ut undas nutritur
> Credulitas pectora undarum quae durat cultu motus! Volvitur et populis ducis
> matri reserato **inque**, magnum quantumque, nomenque apparuit. Vitamque et
> iniqua magnanimi et aether tacitorum tibi Aethiopum, eum illi domo gaudete
> tenus miserum.

Oculi iam vidit nisi, artesque ac vicibus reice, altum sentit crinitas prope,
vix. Sed o commentaque, superatae exierat vulgi, quotiensque nais haerentem
quaque cunasque lucibus, memorare se. Satis coniunx: nec sic manu tuum est
duritiem diversa.`}
                      </MoreInfoButton>
                    </View>
                    <Pressable style={styles.newVersion.list.filter} onPress={handleFilterButtonPress}>
                      <FontAwesomeIcon icon={faSliders} style={styles.newVersion.list.filterIcon} />
                      <Label variant={LabelType.title}>{t('filter_by_business_type')}</Label>
                      <View
                        style={[
                          styles.newVersion.list.popupTagFilterBase,
                          !showTagPopup ? styles.newVersion.list.popupTag.hide : styles.newVersion.list.popupTag,
                        ]}
                      >
                        <Pressable id={'selectAll_button'} style={styles.newVersion.list.popupTagFilter.selectAll} onPress={handleSelectAll}>
                          <Label variant={LabelType.titleDarkSmall}>
                            {filterItems?.length > (selectedFilterItems?.length ?? 0) ? t('select_all') : t('deselect_all')}
                          </Label>
                        </Pressable>
                        {filterItems.map((item) => {
                          return (
                            <CustomCheckbox
                              small
                              id={`tag_${item.id}`}
                              key={`tag_${item.id}`}
                              checked={(selectedFilterItems?.findIndex((s) => s.id === item.id) ?? -1) > -1}
                              value={item.id}
                              label={item.name}
                              style={styles.newVersion.list.popupTag.item}
                              onChange={(checked, value) => {
                                handleFilterChange(checked, value);
                              }}
                            />
                          );
                        })}
                      </View>
                      {showTagPopup && (
                        <Pressable
                          style={{
                            backgroundColor: 'transparent',
                            position: Platform.OS === 'web' ? ('fixed' as any) : 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            zIndex: 1000,
                          }}
                          onPress={() => setShowTagPopup(false)}
                        ></Pressable>
                      )}
                    </Pressable>
                    {missionBlocks && missionBlocks.length > 0 ? (
                      missionBlocks.map((mission, blockIndex) => {
                        return (
                          <Pressable
                            key={`remote_Mission_${blockIndex}`}
                            style={[
                              styles.newVersion.list.item,
                              `${mission.periodMissionConfigurationId}-${mission.branchId}` === selectedMissionId ? styles.newVersion.list.itemSelected : {},
                            ]}
                            onPress={() => {
                              console.log('click', mission);
                              setSelectedMissionId(`${mission.periodMissionConfigurationId}-${mission.branchId}`);
                              setSelectedMission(mission);
                              setCanAcceptMissionQuerySeed(StringHelper.generateRandomString());

                              if (layout.width <= 768) {
                                setNavigationState(NavigationState.Details);
                              }
                            }}
                          >
                            <View style={styles.newVersion.list.logoBase}>{getLogo(mission.branch)}</View>
                            <View style={{ flexShrink: 1 }}>
                              <Label
                                numberOfLines={2}
                                style={{
                                  color: theme.text.primary,
                                  fontSize: 13,
                                  fontWeight: `${mission.periodMissionConfigurationId}-${mission.branchId}` === selectedMissionId ? '600' : '400',
                                }}
                              >
                                {mission.name}
                              </Label>
                              <Label style={{ color: theme.text.primary, fontSize: 10, fontWeight: '600' }}>{`${mission.branch.name}`}</Label>
                            </View>
                          </Pressable>
                        );
                      })
                    ) : (
                      <>
                        {/* <Label variant={LabelType.titleLargeNoCapsPrimary} style={styles.newVersion.list.status}>
                          {t('remote_missions')}
                        </Label> */}
                        <View style={{ flexGrow: 1, flexShrink: 1, alignItems: 'center', justifyContent: 'center', paddingTop: 16 }}>
                          <FontAwesomeIcon icon={faWarning} size={32} color={theme.borderColor.toString()} />
                          <Label
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              margin: 'auto',
                              paddingLeft: 16,
                              paddingRight: 16,
                              marginTop: 10,
                              color: theme.borderColor,
                            }}
                            variant={LabelType.title}
                          >
                            {t('remote.no_remote_missions')}
                          </Label>
                        </View>
                      </>
                    )}
                  </ScrollView>
                ) : (
                  <View style={{ flexGrow: 1 }}>
                    <Loading text={tMission('loading_missions')} />
                  </View>
                )}
              </View>
            )}
            {layout.width <= 768 && navigationState === NavigationState.List && !loading && missionBlocks.length === 0 && getEmptyComponent()}
            {(layout.width > 768 || (layout.width <= 768 && navigationState === NavigationState.Details)) && (
              <>
                {detail && detail.length > 0 && (
                  <ScrollView>
                    <View style={styles.newVersion.detailsContainer}>
                      {!loadingDetail ? (
                        <>
                          {detail.map((detail) => {
                            return (
                              <View style={styles.newVersion.detailsWrapper}>
                                <View style={styles.newVersion.details.header.container}>
                                  <View>
                                    <FontAwesomeIcon
                                      style={styles.newVersion.details.header.pinContainer.icon}
                                      icon={MissionsHelper.getMissionIcon(undefined).faIcon}
                                    />
                                    <Image style={styles.newVersion.details.header.pin} source={pin} />
                                  </View>
                                  <View style={styles.newVersion.details.header.title}>
                                    <Label variant={LabelType.title} style={styles.newVersion.details.header.titleNoUpper}>
                                      {detail?.detail?.missionConfigurationName}
                                    </Label>
                                    <Label style={{ flexShrink: 1 }} variant={LabelType.titleSmall}>
                                      {detail?.detail?.name}
                                    </Label>
                                    <Label style={{ flexShrink: 1 }} variant={LabelType.body1}>
                                      {detail?.detail?.address}
                                    </Label>
                                  </View>
                                </View>
                                <View style={styles.newVersion.details.content.container}>
                                  <View style={styles.newVersion.details.content.leftContainer}>
                                    <View style={styles.newVersion.details.content.summary.container}>
                                      {detail.detail.tags && (
                                        <View style={styles.newVersion.details.content.summary.item}>
                                          <FontAwesomeIcon
                                            icon={faStore}
                                            color={theme.text.primary.toString()}
                                            style={styles.newVersion.details.content.summary.icon}
                                          />
                                          <Label numberOfLines={3} variant={LabelType.titleDarkSmall} style={{ marginLeft: 4 }}>
                                            {detail.detail.tags}
                                          </Label>
                                        </View>
                                      )}
                                      {detail.detail.salaryAmount > 0 && (
                                        <View style={styles.newVersion.details.content.summary.item}>
                                          <FontAwesomeIcon
                                            icon={faAward}
                                            color={theme.text.primary.toString()}
                                            style={styles.newVersion.details.content.summary.icon}
                                          />
                                          <Label numberOfLines={3} variant={LabelType.titleDarkSmall} style={{ marginLeft: 4 }}>
                                            ${detail.detail.salaryAmount} ({detail.detail.salaryPoints} pts)
                                          </Label>
                                        </View>
                                      )}
                                      {detail.detail.time && (
                                        <View style={styles.newVersion.details.content.summary.item}>
                                          <FontAwesomeIcon
                                            icon={faCalendar}
                                            color={theme.text.primary.toString()}
                                            style={styles.newVersion.details.content.summary.icon}
                                          />
                                          <Label numberOfLines={3} variant={LabelType.titleDarkSmall} style={{ marginLeft: 4 }}>
                                            {detail.detail.time}
                                          </Label>
                                        </View>
                                      )}
                                    </View>
                                    <View style={styles.newVersion.details.content.actionButtonsContainerAccept}>
                                      {missionStatusText(
                                        acceptMissionViewModels?.find((x) => x.periodMissionConfigurationId === detail.detail.periodMissionConfigurationId)
                                          ?.acceptMissionStatus
                                      ) && (
                                        <View
                                          style={{
                                            width: '100%',
                                            marginBottom: 16,
                                          }}
                                        >
                                          <View
                                            style={{
                                              flexDirection: 'row',
                                              gap: 16,
                                              alignItems: 'center',
                                              backgroundColor: theme.background.lightSea,
                                              ...StylesHelper.padding(12, 16),
                                              ...StylesHelper.borderRadius(8),
                                              flexGrow: 1,
                                            }}
                                          >
                                            <FontAwesomeIcon icon={faCircleExclamation} size={28} color={theme.text.error.toString()} />
                                            <View style={{ flexShrink: 1 }}>
                                              <Label style={{ fontSize: 14, color: theme.text.error, fontWeight: '600' }}>
                                                {t('This-mission-is-not-accessible')}
                                              </Label>
                                              <Label style={{ color: theme.text.error, flexShrink: 1 }} numberOfLines={5}>
                                                {missionStatusText(
                                                  acceptMissionViewModels?.find(
                                                    (x) => x.periodMissionConfigurationId === detail.detail.periodMissionConfigurationId
                                                  )?.acceptMissionStatus
                                                )}
                                              </Label>
                                            </View>
                                          </View>
                                        </View>
                                      )}
                                      <CustomButton
                                        disabled={buttonIsDisabled(
                                          acceptMissionViewModels?.find((x) => x.periodMissionConfigurationId === detail.detail.periodMissionConfigurationId)
                                            ?.acceptMissionStatus
                                        )}
                                        title={t('accept_the_mission')}
                                        onPress={() => {
                                          handleAcceptMission(detail.detail.periodMissionConfigurationId, selectedMission.branchId);
                                        }}
                                      />
                                    </View>
                                  </View>
                                  <View style={styles.newVersion.details.content.rightContainer}>
                                    <View style={styles.newVersion.details.content.details.container}>
                                      <View style={styles.newVersion.details.content.details.container}>{getDetailComponent(detail)}</View>
                                    </View>
                                  </View>
                                </View>
                              </View>
                            );
                          })}
                        </>
                      ) : (
                        <View style={{ flexGrow: 1 }}>
                          <Loading />
                        </View>
                      )}
                    </View>
                  </ScrollView>
                )}
                {!loadingDetail || (missionBlocks && missionBlocks.length === 0 && getEmptyComponent())}
              </>
            )}
          </View>
        </>
      )}
    </AuthGuard>
  );
};

export default RemoteMissions;

import React, { useEffect, useState } from 'react';
import { View, Image, Pressable } from 'react-native';
import { GetTransactionListTransactionDto, GetTransactionListTransactionType } from '../../../api/api.g';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import Label, { LabelType } from '../../common/Label';
import moment from 'moment';
import 'moment/min/locales';
import { useAppSelector } from '../../../hooks/store-hook';
import { StylesHelper } from '../../../models/helpers/stylesHelper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import {
  faCheckDouble,
  faCirclePlus,
  faDash,
  faLocationDot,
  faMapLocationDot,
  faMoneyCheckDollarPen,
  faQuestion,
  faSackDollar,
  faUpFromBracket,
} from '@fortawesome/pro-regular-svg-icons';
import { useNavigation } from '@react-navigation/native';
import useMyAccountActions from '../../../hooks/myAccountActions-hook';

export interface MyTransactionsItemProps {
  transactions: GetTransactionListTransactionDto[];
}

// Used to control the date titles
let lastDate: Date;

const MyTransactionsItem = (props: MyTransactionsItemProps) => {
  const theme = useCustomTheme();
  const t = useTranslation();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const navigation = useNavigation();
  const myAccountActions = useMyAccountActions();

  const [transactions, setTransactions] = useState(props.transactions);
  const [transactionsComponent, setTransactionsComponent] = useState(<></>);

  useEffect(() => {
    moment.locale(userPrefs.uiLanguage);
  }, [userPrefs.uiLanguage]);

  useEffect(() => {
    setTransactions(props.transactions);
  }, [props.transactions]);

  useEffect(() => {
    setTransactionsComponent(
      <>
        {transactions.map((transaction, i) => {
          return (
            <View
              key={transaction.id}
              style={{ backgroundColor: theme.background.missionDetail, ...StylesHelper.padding(8), ...StylesHelper.borderRadius(5), marginBottom: 8 }}
            >
              {getDateComponent(lastDate?.toDateString() !== transaction?.date?.toDateString(), transaction.date)}
              <View
                key={`${transaction.type}_${i}_${String.prototype.generateRandomString(5)}`}
                style={{
                  ...StylesHelper.padding(0, 0, 8, 0),
                  // ...getStyle(i < transactions.length - 1),
                }}
              >
                <View>{getTransactionItem(transaction)}</View>
              </View>
            </View>
          );
        })}
      </>
    );
  }, [transactions]);

  const getTransactionItem = (transaction: GetTransactionListTransactionDto) => {
    const innerContent = (
      <View style={{ marginTop: 8, display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        {getTransactionIcon(transaction)}
        <View style={{ flexGrow: 1 }}>
          <Label variant={LabelType.titleLargeNoCaps} style={{ flexGrow: 1, fontSize: 14, marginLeft: 8, fontWeight: '600' }}>
            {getTransactionTitle(transaction)}
          </Label>
          {getSecondLine(transaction)}
        </View>
        <Label
          variant={LabelType.titleLargeNoCaps}
          style={{ fontSize: 15, paddingRight: 6, fontWeight: '500', color: getColor(transaction.amountPoints) }}
        >{`${t('points-value', {
          value: transaction.amountPoints,
        })}`}</Label>
        <FontAwesomeIcon {...getArrow(transaction.amountPoints)} size={16} />
      </View>
    );

    return transaction.type === GetTransactionListTransactionType.Mission ? (
      <Pressable
        onPress={() => {
          navigation.navigate({ name: 'mymissions', params: { id: transaction.missionAssignationId } } as never);
          myAccountActions.setVisibility(false);
        }}
      >
        {innerContent}
      </Pressable>
    ) : (
      innerContent
    );
  };

  const getTransactionIcon = (transaction: GetTransactionListTransactionDto) => {
    switch (transaction.type) {
      case GetTransactionListTransactionType.Mission:
        return transaction.hasLogo ? (
          <Image style={{ width: 28, height: 28, ...StylesHelper.borderRadius(14) }} source={{ uri: transaction.logoUrl }} />
        ) : (
          <FontAwesomeIcon icon={getIcon(transaction.type)} color={theme.text.primary as any} size={24} />
        );
      case GetTransactionListTransactionType.CashOut:
        return (
          <View style={{ width: 28, height: 28, ...StylesHelper.borderRadius(14), backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={getIcon(transaction.type)} color={theme.secondaryLight as any} size={16} />
          </View>
        );
      default:
        return (
          <View style={{ width: 28, height: 28, ...StylesHelper.borderRadius(14), backgroundColor: 'white', alignItems: 'center', justifyContent: 'center' }}>
            <FontAwesomeIcon icon={getIcon(transaction.type)} color={theme.text.primary as any} size={24} />
          </View>
        );
    }
  };

  const getTransactionTitle = (transaction: GetTransactionListTransactionDto) => {
    switch (transaction.type) {
      case GetTransactionListTransactionType.Mission:
        return transaction.missionConfigurationName;
      default:
        return GetTransactionListTransactionType[transaction.type];
    }
  };

  const getSecondLine = (transaction: GetTransactionListTransactionDto) => {
    return (
      <View style={{ paddingRight: 4 }}>
        {transaction.type === GetTransactionListTransactionType.Mission && (
          <Pressable
            style={{ flexDirection: 'row', alignItems: 'center' }}
            onPress={() => {
              navigation.navigate({
                name: 'map',
                params: { pos: transaction.branchId, location: `${transaction.branchGeocoordinate.y}|${transaction.branchGeocoordinate.x}` },
              } as never);
              myAccountActions.setVisibility(false);
            }}
          >
            <Label style={{ marginLeft: 8, marginRight: 4, maxWidth: 185 }} variant={LabelType.caption}>
              {`${t(transaction.name)} - ${transaction.branchAddress}`}
            </Label>
            <FontAwesomeIcon icon={faMapLocationDot} color={theme.text.titleDark as any} size={12} />
          </Pressable>
        )}
        {transaction.reason && (
          <Label style={{ marginLeft: 8 }} variant={LabelType.caption}>
            {t(transaction.reason)}
          </Label>
        )}
      </View>
    );
  };

  const getDateComponent = (showDate: boolean, date: Date) => {
    if (showDate) {
      lastDate = date;
      return (
        <Label
          variant={LabelType.titleDarkSmall}
          style={{
            paddingBottom: 8,
            borderBottomColor: theme.borderColorLight,
            borderBottomWidth: 1,
          }}
        >
          {moment(date).format('dddd, MMMM Do YYYY')}
        </Label>
      );
    } else {
      return <></>;
    }
  };

  const getStyle = (withBorder: boolean) => {
    return withBorder ? { borderBottomColor: theme.borderColorLight, borderBottomWidth: 1 } : {};
  };

  const getIcon = (type: GetTransactionListTransactionType) => {
    switch (type) {
      case GetTransactionListTransactionType.CashOut:
        return faSackDollar;
      case GetTransactionListTransactionType.Mission:
        return faLocationDot;
      case GetTransactionListTransactionType.Validation:
        return faCheckDouble;
      case GetTransactionListTransactionType.Adjustment:
        return faMoneyCheckDollarPen;
      default:
        return faQuestion;
    }
  };

  const getColor = (amount: number) => {
    const style = { paddingLeft: 4 };

    if (amount < 0) {
      return theme.colorErrorPrimary;
    } else {
      if (amount > 0) {
        return theme.text.titleLight;
      } else {
        return theme.text.primary;
      }
    }
  };

  const getArrow = (amount: number) => {
    const style = {
      /*paddingLeft: 6*/
    };

    if (amount < 0) {
      return { icon: faSackDollar, style: { ...style, color: theme.colorErrorPrimary as any } };
    } else {
      if (amount > 0) {
        return { icon: faCirclePlus, style: { ...style, color: theme.text.titleLight as any } };
      } else {
        return { icon: faDash, style: { ...style, color: theme.text.primary as any } };
      }
    }
  };

  return <View>{transactionsComponent}</View>;
};

export default MyTransactionsItem;

import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import useTranslation from '../../../hooks/translation-hook';
import useUserPrefsService from '../../../hooks/userPrefsService-hook';
import NavigationElements from '../../../models/NavigationElements';
import { userPrefsActions } from '../../../store/userPrefsSlice';
import CustomRadioButton from '../../common/CustomRadioButton';
import MyAccountPageWrapper from '../MyAccountPageWrapper';
import { View } from 'react-native';
import Loading from '../../common/Loading';
import useCustomTheme from '../../../hooks/theme-hook';

const SettingsLanguage = () => {
  const t = useTranslation();
  const theme = useCustomTheme();
  const dispatch = useAppDispatch();
  const userPerfsService = useUserPrefsService();
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const [enChecked, setEnChecked] = useState(userPrefs.uiLanguage === 'en');
  const [langChange, setLangChange] = useState(false);
  const [preLoaded, setPreLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (langChange) {
      const tempLanguages = userPrefs.languages.filter((item) => item !== (enChecked ? 'en' : 'fr'));
      tempLanguages.unshift(enChecked ? 'en' : 'fr');
      dispatch(userPrefsActions.setLanguages(tempLanguages));
    } else {
      setPreLoaded(true);
    }
  }, [langChange]);

  useEffect(() => {
    if (langChange) {
      userPerfsService.saveBasicInfo(() => {
        dispatch(userPrefsActions.setUiLanguage(userPrefs.languages[0]));
        setLangChange(false);
        setIsLoading(false);
      });
    }
  }, [userPrefs.languages]);

  const handleLanguageClick = (checked: boolean, value: string) => {
    if (checked && preLoaded) {
      setEnChecked(value === 'en');
      setIsLoading(true);
    }
  };

  return (
    <>
      {isLoading && (
        <Loading
          onLayout={() => {
            if (isLoading) {
              setLangChange(true);
            }
          }}
          style={{ position: 'absolute', top: 0, left: 0, zIndex: 2000, width: '100%', height: '100%', backgroundColor: 'rgba(233, 245, 243, 0.8)' }}
        />
      )}
      <MyAccountPageWrapper
        navigationTitle={t('settings')}
        title={t('language')}
        navigationAction={NavigationElements.settings}
        titleStyle={{ textTransform: 'none' }}
      >
        <View>
          <View style={{ marginVertical: 8, marginTop: 16 }}>
            <CustomRadioButton
              textStyle={{ fontSize: 16, textTransform: 'capitalize' }}
              label={'Français'}
              value={'fr'}
              checked={!enChecked}
              onChange={handleLanguageClick}
            />
          </View>
          <View style={{ marginVertical: 8 }}>
            <CustomRadioButton
              textStyle={{ fontSize: 16, textTransform: 'capitalize' }}
              label={'English'}
              value={'en'}
              checked={enChecked}
              onChange={handleLanguageClick}
            />
          </View>
        </View>
      </MyAccountPageWrapper>
    </>
  );
};

export default SettingsLanguage;

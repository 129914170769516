import React, { ReactElement } from 'react';
import { useWindowDimensions, View } from 'react-native';
import Loading from './Loading';
import useCustomTheme from '../../hooks/theme-hook';

export interface PopupProps {
  loading?: boolean;
  children: ReactElement;
}

const Popup = (props: PopupProps) => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();

  return (
    <View
      style={{
        borderRadius: 8,
        backgroundColor: theme.background.modal,
        overflow: 'hidden',
        zIndex: 2000,
        maxHeight: layout.height - 40,
      }}
    >
      {props.loading && <Loading style={{ width: '100%', height: '100%', backgroundColor: 'rgba(233, 245, 243, 0.8)', zIndex: 4000 }} />}
      {props.children}
    </View>
  );
};

export default Popup;

import { GetMysteryShopperDto, SaveBasicInformationDto } from '../../api/api.g';
import { UserPreferencesState } from '../../store/userPrefsSlice';

export class UserPrefsHelper {
  public static mapToSaveBasicInformationDto(u: UserPreferencesState): SaveBasicInformationDto {
    const data = new SaveBasicInformationDto();

    data.init({
      firstName: u.firstName,
      languages: u.languages,
      lastName: u.lastName,
      phoneNumber: u.phoneNumber,
      theme: u.theme,
    });

    return data;
  }

  public static mapFromToDto<T>(u: SaveBasicInformationDto | GetMysteryShopperDto): T {
    const data = {
      firstName: u.firstName,
      languages: u.languages,
      lastName: u.lastName,
      phoneNumber: u.phoneNumber,
      theme: u.theme,
      profiles: undefined,
      addresses: undefined,
    };

    if (u instanceof GetMysteryShopperDto) {
      data.profiles = u.profiles;
      data.addresses = u.addresses;
    }

    return data as T;
  }
}

import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useDrawerStyles = () => {
  const theme = useCustomTheme();

  const drawer = {
    containerBase: {
      width: '100%',
      backgroundColor: theme.background.modal,
      zIndex: 1100,
      overflow: 'hidden' as any,
    },
    container: {
      hanger: {
        zIndex: 1150,
        backgroundColor: theme.background.modal,
        position: 'relative' as any,
      },
      ['hanger.bullet']: {
        backgroundColor: theme.text.primary,
        width: 40,
        height: 6,
        borderRadius: 5,
        alignSelf: 'center' as any,
        marginTop: 6,
        marginBottom: 4,
      },
      dockBottom: {
        borderTopLeftRadius: 16,
        borderTopRightRadius: 16,
        position: 'absolute' as any,
        bottom: 0,
        ...StylesHelper.shadow({ width: 0, height: -3 }, 0.1, 5, 'black', 8),
      },
      asPopup: {
        ...StylesHelper.borderRadius(16),
        position: 'absolute' as any,
        ...StylesHelper.shadow({ width: 0, height: -3 }, 0.1, 5, 'black', 8),
      },
      dockRight: {
        borderTopLeftRadius: 16,
        borderBottomLeftRadius: 16,
        borderTopRightRadius: 0,
        position: 'absolute' as any,
        top: 60,
        right: 0,
        width: '32%',
        ...StylesHelper.shadow({ width: -3, height: 0 }, 0.1, 5, 'black', 8),
      },
    },
    innerContainer: {
      position: 'relative' as any,
      display: 'flex' as any,
      flexGrow: 1,
      flexShrink: 1,
      backgroundColor: theme.background.modal,
      flexDirection: 'column' as any,
    },
    ['innerContainer.androidExtraPadding']: {
      paddingBottom: 16,
    },
    header: {
      position: 'relative' as any,
      backgroundColor: theme.background.modal,
      flexDirection: 'row' as any,
      alignItems: 'center' as any,
      zIndex: 1151,
      flexGrow: 0,
      flexShrink: 0,
      color: theme.text.primary,
      borderBottomWidth: 1,
      borderBottomColor: theme.borderColorLight,
      ...StylesHelper.padding(12, 16, 16, 16),
    },
    iconBase: {
      zIndex: 1150,
      flexDirection: 'row' as any,
    },
    icon: {
      closeBase: {
        height: 24,
        width: 24,
      },
      close: {
        dimension: {
          resizeMode: 'contain' as any,
          width: '100%' as any,
          height: '100%' as any,
        },
      },
      iconExtraPadding: {
        paddingLeft: 16,
      },
      titleBase: { width: 30, height: 30, flexDirection: 'row' as any, zIndex: 10, position: 'relative' as any, paddingRight: 16 },
      title: {
        dimension: {
          resizeMode: 'contain' as any,
          width: '100%',
          height: '100%',
        },
      },
    },
    content: {
      flexGrow: 1,
      zIndex: 1009,
      backgroundColor: theme.background.modal,
      flexBasis: 'auto' as any,
      ...StylesHelper.padding(0),
    },
  };

  return drawer as typeof drawer;
};

export default useDrawerStyles;

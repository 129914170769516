import { getLocales } from 'expo-localization';
import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, ScrollView, View, useColorScheme, Image, useWindowDimensions, Linking } from 'react-native';
import { AuthenticateClient, DeviceTheme, ExternalExistsRequest, ExternalLoginRequest, RegistrationDto } from '../api/api.g';
import CustomButton from '../components/common/CustomButton';
import CustomTextInput, { InputTypeValidation } from '../components/common/CustomTextInput';
import Label, { LabelType } from '../components/common/Label';
import Loading from '../components/common/Loading';
import { useAppDispatch, useAppSelector } from '../hooks/store-hook';
import { authActions } from '../models/helpers/platformImportHelper';
import { userPrefsActions } from '../store/userPrefsSlice';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';
import useTranslation from '../hooks/translation-hook';
import { LoggedState } from '../models/AuthModelsCommon';
import useMyAccountActions from '../hooks/myAccountActions-hook';
import { missionsActions } from '../store/missionsSlice';
import { drawerActions } from '../store/drawerSlice';
import { modalActions } from '../store/modalSlice';
import { useFocusEffect, useIsFocused, useNavigation } from '@react-navigation/native';
import useApi from '../hooks/api-hook';
import { AnalyticsHelper } from '../helpers/analyticsHelper';
import { useMutation, useQuery } from '@tanstack/react-query';
import { StringHelper } from '../helpers/stringHelper';
import CustomMarkdown from '../components/common/CustomMarkDown';
import Logo from '../../assets/login-page-logo.svg';
import { getAuth, getMultiFactorResolver, PhoneAuthProvider, RecaptchaVerifier, PhoneMultiFactorGenerator } from 'firebase/auth';

export enum LoginPageState {
  Login,
  Register,
  RegisterNewPassword,
  ForgotPassword,
  ShowTermsAndConditions,
  OldWeakPassword,
  twoFactorAuth,
}

const Login = ({ route }) => {
  const auth = getAuth();
  const theme = useCustomTheme();
  const authStore = useAppSelector((selector) => selector.auth);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const t = useTranslation();
  const tLogin = useTranslation({ defaultPath: 'login' });
  const myAccountActions = useMyAccountActions();
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();

  const apiAuth = useApi(AuthenticateClient);

  // const [passwordLogin, setPasswordLogin] = useState(false);
  // const [register, setRegister] = useState(false);
  const [pageState, setPageState] = useState(LoginPageState.Login);
  const [recaptchaVerifier, setRecaptchaVerifier] = useState(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loginComponent, setLoginComponent] = useState(<></>);
  const [returnButtonComponent, setReturnButtonComponent] = useState(<></>);
  const [loading, setLoading] = useState(true);
  const [goNextStep, setGoNextStep] = useState(false);

  const [showSignUpButton, setShowSignUpButton] = useState(true);

  const [formTouched, setFormTouched] = useState(false);
  const [userMessage, setUserMessage] = useState<string | undefined>();

  const [emailLoginValidationError, setEmailLoginValidationError] = useState<string | undefined>(undefined);
  const [passwordLoginInputValidationError, setPasswordLoginInputValidationError] = useState<string | undefined>(undefined);
  const [passwordLoginValidationError, setPasswordLoginValidationError] = useState<string | undefined>(undefined);
  const [emailRegisterValidationError, setEmailRegisterValidationError] = useState<string | undefined>(undefined);
  const [passwordRegisterValidationError, setPasswordRegisterValidationError] = useState<string | undefined>(undefined);
  const [passwordRegisterConfValidationError, setPasswordRegisterConfValidationError] = useState<string | undefined>(undefined);

  const [formPasswordTouched, setFormPasswordTouched] = useState(false);
  const [formPasswordConfirmTouched, setFormPasswordConfirmTouched] = useState(false);

  const [signUpSource, setSignUpSource] = useState<string>();
  const [loginMethod, setLoginMethod] = useState<string>();
  const [goToSignUp, setGoToSignUp] = useState<boolean>();
  const [updateForm, setUpdateForm] = useState(false);
  const [phoneInfoOptions, setPhoneInfoOptions] = useState<any>({});
  const [phoneVerificationId, setPhoneVerificationId] = useState<string>();
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationCodeError, setVerificationCodeError] = useState<string | undefined>(undefined);
  const [resolver, setResolver] = useState(null);

  const isFocused = useIsFocused();
  const dispatch = useAppDispatch();

  useEffect(() => {
    setRecaptchaVerifier(
      new RecaptchaVerifier(auth, '2fa-recaptcha-container', {
        size: 'invisible',
        callback: (response) => {},
      })
    );
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setPageState(LoginPageState.Login);
    }, [])
  );

  useFocusEffect(
    React.useCallback(() => {
      if (authStore.logged !== undefined && authStore.logged !== LoggedState.NotLogged) {
        console.log(`%cNavigation end - ${route.name}`, 'color: #ffa600;');

        AnalyticsHelper.logScreenView(route.name).then(() => {
          console.log('Analytics', 'Page view: Login');
        });

        setLoading(true);
        dispatch(modalActions.resetModal());
      }
    }, [authStore.logged])
  );

  useEffect(() => {
    if (route?.params?.reset) {
      setUserMessage(tLogin('password_redefined'));
    }

    if (route?.params?.newLogin) {
      setLoading(false);
      setUpdateForm(true);
    }

    if (route?.params?.signUpSource) {
      const signUpValue = route?.params?.signUpSource;
      setSignUpSource(signUpValue);
      setGoToSignUp(true);
      dispatch(authActions.setSignUpSource(signUpValue));
    }

    if (route?.params?.signUp) {
      setGoToSignUp(true);
    }

    if (route?.params?.accountDeleted) {
      setUserMessage(t('deleted-account-confirmation-message'));
    }

    if (route?.params?.failToRegister) {
      setUserMessage(t('fail-to-register-message'));
    }

    if (route?.params?.changeEmail) {
      setUserMessage(t('email-change-validation'));
    }

    if (route?.params?.emailChanged) {
      setUserMessage(t('email-change-confirmation'));
    }

    if (route?.params?.emailVerified) {
      setUserMessage(t('Email-verified'));
    }

    if (route?.params?.secondFactorRemoved) {
      setUserMessage(t('Two-step-verification-has-been-reverted'));
    }
  }, [route.params]);

  useEffect(() => {
    if (goToSignUp) {
      handleSignUpClick();
    }
  }, [goToSignUp]);

  useEffect(() => {
    if (authStore.logged === LoggedState.NotLogged) {
      // Hiding the side menu
      myAccountActions.setVisibility(false);

      if (navigation.getState().index === 0) {
        setDefaultLanguageAndTheme();
      } else {
        dispatch(
          userPrefsActions.setBasicInfo({
            firstName: undefined,
            lastName: undefined,
            uiLanguage: userPrefs.uiLanguage,
            email: '',
            theme: undefined,
            languages: userPrefs.languages,
          })
        );
      }
      setFormTouched(false);

      setLoading(false);

      setEmailLoginValidationError(undefined);
      setPasswordLoginValidationError(undefined);

      setEmailRegisterValidationError(undefined);
      setPasswordRegisterValidationError(undefined);
      setPasswordRegisterConfValidationError(undefined);

      if (
        pageState !== LoginPageState.OldWeakPassword &&
        pageState !== LoginPageState.RegisterNewPassword &&
        !route?.params?.failToRegister &&
        !route?.params?.changeEmail &&
        !route?.params?.emailChanged &&
        !route?.params?.emailVerified &&
        !route?.params?.secondFactorRemoved
      ) {
        setUserMessage(undefined);
      }

      // Login page is the same for all users
      dispatch(userPrefsActions.changeTheme(DeviceTheme.Light));

      // Restarting drawer state
      dispatch(drawerActions.resetState());

      // Restarting map screen
      dispatch(missionsActions.setResetMap(true));
    }

    if (authStore.logged === LoggedState.Logged || authStore.logged === LoggedState.Anonymous) {
      let rPath = authStore.returnPath;

      if (!rPath || rPath === '' || rPath.indexOf('login') > -1 || rPath.indexOf('logout') > -1) {
        rPath = 'map';
      }

      // if (authStore.user.isAnonymous) {
      //   console.log('Anonymous', authStore.user.isAnonymous);
      //   setDefaultLanguageAndTheme();
      // }

      console.log(`%cLogged in redirecting to ${rPath}...`, 'color: #ffa600;');

      if (pageState === LoginPageState.Register || pageState === LoginPageState.RegisterNewPassword || pageState === LoginPageState.OldWeakPassword) {
        AnalyticsHelper.logSignUp(loginMethod ?? 'password');
        navigation.navigate(rPath as never);
      } else {
        navigation.navigate({ name: rPath, params: authStore.params } as never);
      }
    }
  }, [authStore.logged, pageState]);

  useEffect(() => {
    setUpdateForm(false);

    const backButton = (
      <CustomButton style={{ marginTop: 24 }} title={t('back')} textStyle={{ color: theme.primary }} secondary={true} onPress={handleReturn} />
    );

    switch (pageState) {
      case LoginPageState.twoFactorAuth:
        setLoginComponent(
          <View style={{ marginBottom: 24 }}>
            <Label style={{ marginTop: 12, fontSize: 18, color: 'white', fontWeight: '400', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {t('We-have-sent-a-verification-code-to')}
            </Label>
            <Label style={{ marginTop: 12, fontSize: 18, color: 'white', fontWeight: '600', width: '100%', flexShrink: 0 }} numberOfLines={10}>
              {`${phoneInfoOptions.multiFactorHint.phoneNumber}`}
            </Label>
            <CustomTextInput
              style={{ marginTop: 24, marginBottom: 24 }}
              placeholder={t('Verification-Code')}
              onChange={(e) => {
                setVerificationCode(e.nativeEvent.text);
              }}
              value={verificationCode}
            />
            {verificationCodeError != undefined && <Label variant={LabelType.validationError}>{verificationCodeError}</Label>}
          </View>
        );

        setReturnButtonComponent(backButton);
        break;
      case LoginPageState.Login:
        setLoginComponent(
          <View>
            <View style={{ marginBottom: 24 }}>
              {windowDimensions.width >= 1366 && (
                <View style={{ marginBottom: 30 }}>
                  <Label style={{ ...StylesHelper.text(40, theme.primary, 600) }}>{t('welcome')}</Label>
                  <Label style={{ ...StylesHelper.text(40, 'white', 600), marginTop: -10 }}>{t('mystery-shopper').toLowerCase()}</Label>
                </View>
              )}
              {windowDimensions.width < 1366 && (
                <View style={{ marginBottom: 60, alignContent: 'center' }}>
                  <Label style={{ ...StylesHelper.text(30, theme.primary, 600), textAlign: 'center' }}>{t('welcome')}</Label>
                  <Label style={{ ...StylesHelper.text(30, 'white', 600), marginTop: -10, textAlign: 'center' }}>{t('mystery-shopper').toLowerCase()}</Label>
                </View>
              )}
              <CustomTextInput
                required
                inputTypeValidation={InputTypeValidation.email}
                onValidationChange={(v) => (v.valid ? setEmailLoginValidationError(undefined) : setEmailLoginValidationError(v.message))}
                placeholder={t('email')}
                requiredMessage={tLogin('email_required')}
                key={2}
                value={email}
                onChange={(e) => {
                  const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                  setEmail(text);
                  if ((text?.length ?? 0) > 0) {
                    setFormTouched(true);
                  }
                }}
                onKeyPress={(e) => {
                  {
                    if ((e as any).code === 'Enter') {
                      setGoNextStep(true);
                    }
                  }
                }}
              />
              {emailLoginValidationError && <Label variant={LabelType.validationError}>{emailLoginValidationError}</Label>}
            </View>
            <CustomTextInput
              required
              requiredMessage={tLogin('password_required')}
              onValidationChange={(v) => (v.valid ? setPasswordLoginInputValidationError(undefined) : setPasswordLoginInputValidationError(v.message))}
              secureTextEntry={true}
              placeholder={t('password')}
              key={1}
              value={password}
              onChange={(e) => {
                const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;

                if ((text?.length ?? 0) > 0) {
                  setFormPasswordTouched(true);
                }

                setFormTouched(true);
                setPassword(text);
              }}
              onBlur={(e) => {
                if ((e.nativeEvent.text?.length ?? 0) > 0) {
                  setFormPasswordTouched(true);
                }
              }}
              onKeyPress={(e) => {
                if ((e as any).code === 'Enter') {
                  setGoNextStep(true);
                }
              }}
            />
            {(passwordLoginValidationError || passwordLoginInputValidationError) && (
              <Label variant={LabelType.validationError} numberOfLines={10}>
                {passwordLoginValidationError ? passwordLoginValidationError : passwordLoginInputValidationError}
              </Label>
            )}
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Pressable onPress={handleSignUpClick} style={{ marginBottom: 24, marginTop: 8 }}>
                <Label style={{ fontSize: 16, color: 'white', textDecorationLine: 'underline', textAlign: 'right' }}>{tLogin('sign_up')}</Label>
              </Pressable>
              <Pressable onPress={startResetPassword} style={{ marginBottom: 24, marginTop: 8 }}>
                <Label style={{ fontSize: 16, color: 'white', textDecorationLine: 'underline', textAlign: 'right' }}>{tLogin('forgot_password')}</Label>
              </Pressable>
            </View>
          </View>
        );

        setReturnButtonComponent(<></>);

        break;

      case LoginPageState.OldWeakPassword:
      case LoginPageState.Register:
      case LoginPageState.RegisterNewPassword:
        setLoginComponent(
          <View>
            <Label
              style={{ fontSize: 20, color: 'white', fontWeight: 'normal', textTransform: 'uppercase', textAlign: 'center', marginBottom: 24 }}
              variant={LabelType.titleDark}
            >
              {pageState === LoginPageState.Register ? t('create_your_account') : t('create_your_password')}
            </Label>
            <View style={{ marginBottom: 24 }}>
              <Label style={{ width: '100%', textAlign: 'center', textTransform: 'uppercase', marginBottom: 8 }} variant={LabelType.labelWhite}>
                {pageState === LoginPageState.OldWeakPassword ? t('email') : t('enter_your_email')}
              </Label>
              <CustomTextInput
                required
                editable={pageState === LoginPageState.OldWeakPassword ? false : true}
                autoComplete={pageState === LoginPageState.OldWeakPassword ? 'off' : undefined}
                inputTypeValidation={InputTypeValidation.email}
                onValidationChange={(v) => (v.valid ? setEmailRegisterValidationError(undefined) : setEmailRegisterValidationError(v.message))}
                placeholder={t('email')}
                requiredMessage={tLogin('email_required')}
                key={3}
                value={email}
                onChange={(e) => {
                  const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;

                  if ((text?.length ?? 0) > 0) {
                    setFormTouched(true);
                  }
                  setEmail(text);
                }}
                onBlur={(e) => {
                  if ((e.nativeEvent.text?.length ?? 0) === 0) {
                    setEmailRegisterValidationError(tLogin('email_required'));
                  }
                }}
              />
              {emailRegisterValidationError && <Label variant={LabelType.validationError}>{emailRegisterValidationError}</Label>}
            </View>
            <View style={{ marginBottom: 24 }}>
              <Label style={{ width: '100%', textAlign: 'center', textTransform: 'uppercase', marginBottom: 8 }} variant={LabelType.labelWhite}>
                {t('create_your_password')}
              </Label>
              <CustomTextInput
                placeholder={t('password')}
                required
                requiredMessage={tLogin('password_required')}
                onValidationChange={(v) => (v.valid ? setPasswordRegisterValidationError(undefined) : setPasswordRegisterValidationError(v.message))}
                secureTextEntry={true}
                key={4}
                value={password}
                onChange={(e) => {
                  const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                  if ((text?.length ?? 0) > 0) {
                    setFormPasswordTouched(true);
                  }

                  setFormTouched(true);
                  setPassword(text);
                }}
                onBlur={(e) => {
                  if ((e.nativeEvent.text?.length ?? 0) > 0) {
                    setFormPasswordTouched(true);
                  }
                }}
                onKeyPress={(e) => {
                  if ((e as any).code === 'Enter') {
                    setGoNextStep(true);
                  }
                }}
              />
              {passwordRegisterValidationError && <Label variant={LabelType.validationError}>{passwordRegisterValidationError}</Label>}
            </View>
            <View style={{ marginBottom: 24 }}>
              <Label style={{ width: '100%', textAlign: 'center', textTransform: 'uppercase', marginBottom: 8 }} variant={LabelType.labelWhite}>
                {t('confirm_your_password')}
              </Label>
              <CustomTextInput
                placeholder={t('password')}
                required
                requiredMessage={t('must_confirm_password')}
                secureTextEntry={true}
                key={5}
                value={confirmPassword}
                onChange={(e) => {
                  const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                  setFormPasswordConfirmTouched(false);
                  if ((text?.length ?? 0) > 0) {
                    setFormTouched(true);
                  }
                  setConfirmPassword(text);
                }}
                // onChangeText={(text) => {
                //   setFormPasswordConfirmTouched(false);
                //   if ((text?.length ?? 0) > 0) {
                //     setFormTouched(true);
                //   }
                //   setConfirmPassword(text);
                // }}
                onBlur={(e) => {
                  if ((e.nativeEvent.text?.length ?? 0) > 0) {
                    setFormPasswordConfirmTouched(true);
                  }
                }}
              />
              {passwordRegisterConfValidationError && <Label variant={LabelType.validationError}>{passwordRegisterConfValidationError}</Label>}
            </View>
          </View>
        );

        setReturnButtonComponent(backButton);

        break;

      case LoginPageState.ForgotPassword:
        setLoginComponent(
          <View>
            <View style={{ marginBottom: 24 }}>
              {windowDimensions.width >= 1366 && (
                <View style={{ marginBottom: 30 }}>
                  <Label style={{ ...StylesHelper.text(40, theme.primary, 600) }}>{tLogin('reset_password')}</Label>
                </View>
              )}
              {windowDimensions.width < 1366 && (
                <View style={{ marginBottom: 60, alignContent: 'center' }}>
                  <Label style={{ ...StylesHelper.text(30, theme.primary, 600), textAlign: 'center' }}>{tLogin('reset_password')}</Label>
                </View>
              )}
              <Label variant={LabelType.labelWhite} style={{ marginBottom: 16, textAlign: 'center' }}>
                {tLogin('email_reset_instructions')}
              </Label>
              <CustomTextInput
                required
                inputTypeValidation={InputTypeValidation.email}
                onValidationChange={(v) => (v.valid ? setEmailLoginValidationError(undefined) : setEmailLoginValidationError(v.message))}
                placeholder={t('email')}
                requiredMessage={tLogin('email_required')}
                key={2}
                value={email}
                onChange={(e) => {
                  const text = Platform.OS != 'web' ? e.nativeEvent.text : (e.currentTarget as any).value;
                  setEmail(text);
                  if ((text?.length ?? 0) > 0) {
                    setFormTouched(true);
                  }
                }}
                // onChangeText={(text) => {
                //   setEmail(text);
                //   if ((text?.length ?? 0) > 0) {
                //     setFormTouched(true);
                //   }
                // }}
                onKeyPress={(e) => {
                  {
                    if ((e as any).code === 'Enter') {
                      setGoNextStep(true);
                    }
                  }
                }}
              />
              {emailLoginValidationError && <Label variant={LabelType.validationError}>{emailLoginValidationError}</Label>}
            </View>
          </View>
        );

        setReturnButtonComponent(backButton);
        break;
    }
  }, [
    pageState,
    emailLoginValidationError,
    passwordLoginValidationError,
    emailRegisterValidationError,
    passwordRegisterValidationError,
    passwordRegisterConfValidationError,
    userPrefs.theme,
    userPrefs.uiLanguage,
    t('email'),
    updateForm,
    verificationCodeError,
  ]);

  useEffect(() => {
    if (goNextStep) {
      setGoNextStep(false);
      handleContinue();
    }
  }, [goNextStep]);

  useEffect(() => {
    if (formPasswordTouched && formPasswordConfirmTouched && confirmPassword !== password) {
      setPasswordRegisterConfValidationError(t('passwords_must_match_error'));
    } else {
      setPasswordRegisterConfValidationError(undefined);
    }
  }, [confirmPassword, formPasswordConfirmTouched, formPasswordTouched]);

  useEffect(() => {
    if (email === '') {
      setUpdateForm(true);
    }
  }, [email]);

  const formIsValid = (): boolean => {
    return (
      !emailLoginValidationError &&
      !passwordLoginValidationError &&
      !emailRegisterValidationError &&
      !passwordRegisterValidationError &&
      !passwordRegisterConfValidationError
    );
  };

  const confirmAutoLogin = (user) => {
    if (user.code == 'auth/weak-password') {
      setUserMessage(tLogin('weak-old-password'));
      setPassword('');
      setPageState(LoginPageState.OldWeakPassword);
      return;
    }

    // First login on the phone - need top be different because the way that Firebase works for native
    if (Platform.OS !== 'web' && authStore.user) {
      authStore.user.getIdTokenResult().then((token) => {
        dispatch(authActions.setLogged({ idToken: token.token, user }));
      });
    }
  };

  const confirmAutoSignUpAndReset = (user) => {
    setEmail(user.email);
    resetPassword();
  };

  const gotLoginInfo = (user) => {
    console.log('login response', user);
    if (user.code == 'auth/multi-factor-auth-required') {
      if (phoneVerificationId == undefined) {
        let resolver = getMultiFactorResolver(auth, user);
        setResolver(resolver);
        let phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        setPhoneInfoOptions(phoneInfoOptions);
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier).then(function (verificationId) {
          setPhoneVerificationId(verificationId);
        });
      }
      setPageState(LoginPageState.twoFactorAuth);
    }

    // If the password is wrong
    if (user.code == 'auth/wrong-password') {
      // setPasswordLoginValidationError(tLogin('wrong_password'));
      // setPasswordRegisterValidationError(tLogin('wrong_password'));
      setUserMessage(tLogin('invalid-credentials'));
      setLoading(false);
      return;
    }

    if (user.code === 'auth/too-many-requests') {
      setUserMessage(tLogin('too-many-request-login'));
      setLoading(false);
      return;
    }

    if (user.code == 'auth/user-not-found') {
      const requestData = new ExternalLoginRequest();
      requestData.email = email;
      requestData.password = password;

      apiAuth
        .externalLogin(requestData)
        .then((result) => {
          // Not able to login with old credentials
          if (!result) {
            const requestData = new ExternalExistsRequest();
            requestData.email = email;
            // Try to see that the account exists and it's wrong password
            apiAuth.externalExists(requestData).then((result) => {
              if (!result) {
                // The account doesn't exists on the old DB
                // setEmailLoginValidationError(tLogin('email_not_found'));
                // setEmailRegisterValidationError(tLogin('email_not_found'));
                setUserMessage(tLogin('invalid-credentials'));
                setLoading(false);
                return;
              } else {
                // In case the account exists but the password is wrong, create an account on Firebase and reset a random password
                user = undefined;
                setEmailLoginValidationError(undefined);
                setEmailRegisterValidationError(undefined);

                dispatch(authActions.register({ email: email.trim(), password: StringHelper.generateRandomString(8), callback: confirmAutoSignUpAndReset }));
                return;
              }
            });
          } else {
            // In this case automatically signup user
            user = undefined;
            setEmailLoginValidationError(undefined);
            setEmailRegisterValidationError(undefined);

            dispatch(authActions.register({ email: email.trim(), password, callback: confirmAutoLogin }));
            return;
          }
        })
        .catch(() => {
          setUserMessage(tLogin('invalid-credentials'));
          setLoading(false);
        });

      return;
    }

    if (user.code == 'auth/weak-password') {
      setPasswordRegisterValidationError(tLogin('weak_password'));
      setLoading(false);
      return;
    }

    if (user.code == 'auth/email-already-in-use') {
      setEmailRegisterValidationError(tLogin('email_in_use'));
      setLoading(false);
      return;
    }

    if (user.code) {
      setPasswordLoginValidationError(user.message);
      setPasswordRegisterValidationError(user.message);
      setLoading(false);
      return;
    }

    // First login on the phone - need top be different because the way that Firebase works for native
    if (Platform.OS !== 'web' && authStore.user) {
      authStore.user.getIdTokenResult().then((token) => {
        dispatch(authActions.setLogged({ idToken: token.token, user }));
      });
    }
  };

  useEffect(() => {
    if (pageState === LoginPageState.ForgotPassword && (!email || email.trimEnd() === '')) {
      setUpdateForm(true);
    }
  }, [email]);

  useEffect(() => {
    if (pageState === LoginPageState.ForgotPassword) {
      setEmail('');
      setPassword('');
    }
  }, [pageState]);

  const gotLoginMethod = (methods) => {
    // If the login popupo was closed
    if (methods.code === 'auth/popup-closed-by-user') {
      setLoading(false);
    }

    if ((methods?.length ?? 0) === 0) {
      setPageState(LoginPageState.Register);
    } else {
      setLoginMethod(methods[0]);
      switch (methods[0]) {
        case 'password':
          // setPasswordLogin(true);
          setPageState(LoginPageState.Login);
          break;
        // case 'google.com':
        //   dispatch(authActions.loginWithGoogle({ callback: gotLoginInfo }));
        //   break;
      }
    }
  };

  const loginAnonymously = () => {
    setUserMessage(undefined);
    setLoading(true);
    dispatch(authActions.anonymousLogin({ callback: gotLoginInfo }));
  };

  const startResetPassword = () => {
    setPageState(LoginPageState.ForgotPassword);
  };

  const resetPassword = () => {
    setLoading(true);

    dispatch(
      authActions.sendPasswordResetEmail({
        email,
        lang: userPrefs.uiLanguage,
        callback: (e) => {
          if (e?.code) {
            let message = e?.message ?? tLogin('generic_error');

            if (e.code === 'auth/missing-email') {
              message = tLogin('email_required');
            }

            if (e.code === 'auth/user-not-found') {
              const requestData = new ExternalExistsRequest();
              requestData.email = email;

              try {
                // Try to see that the account exists and it's wrong password
                apiAuth.externalExists(requestData).then((result) => {
                  if (!result) {
                    // The account doesn't exists on the old DB
                    // setEmailLoginValidationError(tLogin('email_not_found'));
                    // setEmailRegisterValidationError(tLogin('email_not_found'));
                    message = tLogin('email_not_found');
                    setUserMessage(message);
                    setLoading(false);
                  } else {
                    setEmailLoginValidationError(undefined);
                    setEmailRegisterValidationError(undefined);

                    setLoading(false);
                    setEmail(email);
                    setUserMessage(tLogin('create_new_password'));
                    setPageState(LoginPageState.RegisterNewPassword);
                  }
                });
              } catch {
                setUserMessage(t('fail-to-register-message'));
                setPageState(LoginPageState.Login);
              }

              return;
            }

            if (e.code === 'auth/invalid-email') {
              message = tLogin('email_required');
            }

            if (e.code === 'auth/too-many-requests') {
              message = tLogin('email_required');
            }

            setLoading(false);
            setUserMessage(message);
          } else {
            setLoading(false);
            setEmail('');
            setUserMessage(tLogin('forgot_password_message', { email }));
          }
        },
      })
    );
  };

  const handleContinue = () => {
    console.log('test', verificationCode);
    if (!formTouched || !formIsValid()) {
      if (!email) {
        setEmailLoginValidationError(tLogin('email_required'));
        return;
      }
    }

    if (pageState !== LoginPageState.ForgotPassword && (!password || password.trim() === '')) {
      setPasswordLoginValidationError(tLogin('password_required'));
      setPasswordRegisterValidationError(tLogin('password_required'));
      return;
    }

    if (
      (pageState !== LoginPageState.ForgotPassword && email && password && password.trim() !== '') ||
      (pageState === LoginPageState.twoFactorAuth && verificationCode.length == 6)
    ) {
      switch (pageState) {
        case LoginPageState.twoFactorAuth:
          setVerificationCodeError(undefined);
          const cred = PhoneAuthProvider.credential(phoneVerificationId, verificationCode);
          const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
          resolver
            .resolveSignIn(multiFactorAssertion)
            .then(function (userCredential) {
              setPhoneVerificationId(undefined);
              gotLoginInfo(userCredential);
            })
            .catch(function (error) {
              if (error.code == 'auth/invalid-verification-code') {
                setVerificationCodeError(t('Invalid-verification-code'));
              } else {
                setVerificationCodeError(t('Something-went-wrong-please-try-again-later'));
              }
            });
        case LoginPageState.Login:
          setLoading(true);
          dispatch(authActions.loginPassword({ email: email.trim(), password, callback: gotLoginInfo }));
          break;
        case LoginPageState.OldWeakPassword:
        case LoginPageState.Register:
        case LoginPageState.RegisterNewPassword:
          if (confirmPassword !== password) {
            setPasswordRegisterConfValidationError(t('passwords_must_match_error'));
            return;
          } else {
            setPasswordRegisterConfValidationError(undefined);
          }

          dispatch(authActions.register({ email: email.trim(), password, callback: gotLoginInfo }));
          break;
      }
    } else {
      if (email) {
        switch (pageState) {
          case LoginPageState.ForgotPassword:
            resetPassword();
            break;
          default:
            dispatch(authActions.fetchSignInMethods({ email: email.trim(), callback: gotLoginMethod }));
            break;
        }
      } else {
        setEmailLoginValidationError(tLogin('email_required'));
        return;
      }
    }

    if (pageState !== LoginPageState.OldWeakPassword && pageState !== LoginPageState.RegisterNewPassword) {
      setUserMessage(undefined);
    }

    setShowSignUpButton(false);
  };

  const handleSignUpClick = () => {
    gotLoginMethod([]);

    setUserMessage(undefined);
    setShowSignUpButton(false);
    setPageState(LoginPageState.ShowTermsAndConditions);
  };

  const handleReturn = () => {
    // if (pageState === LoginPageState.Register) {
    setEmail(undefined);
    setPassword('');
    setConfirmPassword('');
    // }

    setShowSignUpButton(true);
    setUserMessage(undefined);
    setGoToSignUp(false);

    setPageState(LoginPageState.Login);
  };

  const getLocale = () => {
    const locales = getLocales();
    return locales[0].languageCode;
  };

  const setDefaultLanguageAndTheme = () => {
    const language = Platform.OS === 'web' ? navigator.language : getLocale();

    if (!userPrefs.uiLanguage) {
      const newLanguages = [language.indexOf('-') === -1 ? language : language.substring(0, language.indexOf('-'))];
      dispatch(userPrefsActions.setLanguages(newLanguages));
      dispatch(userPrefsActions.setUiLanguage(newLanguages[0]));
    }

    dispatch(userPrefsActions.changeTheme(DeviceTheme.Light));
    dispatch(authActions.setReturnPath('map'));
  };

  const getDesktopInfo = (title: string, body: string) => {
    return (
      <View style={{ maxWidth: '50%', paddingRight: 32 }}>
        <Label numberOfLines={3} style={{ color: theme.primary, fontSize: 16, marginRight: 8, fontWeight: '600' }}>
          {title}
        </Label>
        <Label numberOfLines={3} style={{ color: 'white', fontSize: 16, fontWeight: '300', marginBottom: 16 }}>
          {body}
        </Label>
      </View>
    );
  };

  const getOtherLanguageButton = () => {
    switch (userPrefs.uiLanguage) {
      case 'en':
        return (
          <Pressable
            onPress={() => {
              dispatch(userPrefsActions.setUiLanguageAndLanguages('fr'));
            }}
          >
            <Label variant={LabelType.titleWhiteSmall}>FR</Label>
          </Pressable>
        );
      default:
        return (
          <Pressable
            onPress={() => {
              dispatch(userPrefsActions.setUiLanguageAndLanguages('en'));
            }}
          >
            <Label variant={LabelType.titleWhiteSmall}>EN</Label>
          </Pressable>
        );
    }
  };

  return (
    <LinearGradient end={{ x: 0.1, y: 0.4 }} style={{ height: '100%', width: '100%' }} colors={['#022d38', '#034050', '#044c5e', '#04647c', '#43a49e']}>
      <View id={'2fa-recaptcha-container'}>{/* recaptcha used for account verification */}</View>
      {loading && <Loading textVariant={LabelType.loginLoading} iconColor={'white'} />}
      {pageState !== LoginPageState.ShowTermsAndConditions && !loading && (
        <>
          <ScrollView style={{ height: '100%', width: '100%', flexShrink: 0 }} keyboardShouldPersistTaps={'handled'}>
            <View
              style={{
                height: '100%',
                width: '100%',
                padding: 16,
                marginTop: 44,
                alignItems: 'center',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              <View
                style={{
                  height: '100%',
                  maxWidth: 312,
                  marginRight: windowDimensions.width >= 1366 ? 128 : 0,
                  marginLeft: windowDimensions.width >= 1366 ? -64 : 0,
                  paddingTop: 68,
                  flexGrow: 1,
                }}
              >
                {route.name === 'login' && (
                  <View
                    style={{
                      width: '100%',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      zIndex: 1000,
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      paddingVertical: 16,
                      // paddingHorizontal: 16,
                    }}
                  >
                    {getOtherLanguageButton()}
                  </View>
                )}
                {windowDimensions.width >= 1366 && (
                  <>
                    {Platform.OS !== 'web' ? (
                      <View style={{ position: 'absolute', top: 0, left: 0 }}>
                        <Logo width={85} height={40} />
                      </View>
                    ) : (
                      <Image style={{ position: 'absolute', top: 0, left: 0, width: 85, height: 40 }} source={require('../../assets/login-page-logo.svg')} />
                    )}
                  </>
                )}
                {userMessage && (userMessage?.length ?? 0) > 0 && (
                  <Label
                    numberOfLines={5}
                    variant={LabelType.titleSmall}
                    style={{ padding: 16, marginBottom: 24, backgroundColor: theme.primary, ...StylesHelper.borderRadius(8) }}
                  >
                    {userMessage}
                  </Label>
                )}
                <View>
                  {loginComponent}
                  <CustomButton title={t('continue')} onPress={handleContinue} />
                  {returnButtonComponent}
                </View>
                {pageState === LoginPageState.Login && (signUpSource == '' || signUpSource == undefined) && (
                  <View>
                    <Pressable style={{ marginTop: 24 }} onPress={loginAnonymously}>
                      <Label
                        style={{
                          fontSize: 14,
                          color: 'white',
                          textDecorationLine: 'underline',
                          textAlign: windowDimensions.width >= 1366 ? 'left' : 'center',
                        }}
                      >
                        {t('try_the_app_before_signing_up')}
                      </Label>
                    </Pressable>
                  </View>
                )}
                <View style={{ flexDirection: 'row', alignItems: 'baseline', paddingTop: 32, justifyContent: 'center' }}>
                  <Pressable onPress={() => Linking.openURL(t('lanla_website_terms_of_use_url'))}>
                    <Label variant={LabelType.titleWhiteSmall} style={{ textDecorationLine: 'underline' }}>
                      {t('Terms-and-Conditions')}
                    </Label>
                  </Pressable>
                  <Label style={{ marginLeft: 8, marginRight: 8 }} variant={LabelType.titleWhiteSmall}>
                    {t('and')}
                  </Label>
                  <Pressable onPress={() => Linking.openURL(t('lanla_website_privacy_policy_url'))}>
                    <Label variant={LabelType.titleWhiteSmall} style={{ textDecorationLine: 'underline' }}>
                      {t('footer.privacy_policy')}
                    </Label>
                  </Pressable>
                </View>
                {windowDimensions.width < 1366 && (
                  <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                    {Platform.OS !== 'web' ? (
                      <View style={{ marginTop: 77 }}>
                        <Logo width={134} height={63} />
                      </View>
                    ) : (
                      <Image style={{ width: 134, height: 63, marginTop: 77 }} source={require('../../assets/login-page-logo.svg')} />
                    )}
                  </View>
                )}
              </View>
              {windowDimensions.width >= 1366 && (
                <View style={{ height: '100%', paddingTop: 68, flexGrow: 0 }}>
                  <View
                    style={{
                      width: 532,
                      minHeight: 500,
                      ...StylesHelper.borderRadius(5),
                      ...StylesHelper.borderWidth(2),
                      ...StylesHelper.padding(24),
                      borderColor: theme.secondaryLight,
                    }}
                  >
                    <Label numberOfLines={3} variant={LabelType.titleLargeNoCaps} style={{ color: 'white', fontSize: 26, maxWidth: 210, marginBottom: 24 }}>
                      {t('Discover-the-new-Lanla')}
                    </Label>
                    {getDesktopInfo(t('receive'), t('receive-notification-desc'))}
                    {getDesktopInfo(t('cash-out'), t('cash-out-desc'))}
                    {getDesktopInfo(t('login-complete'), t('login-complete-desc'))}
                    <Label style={{ color: 'white', fontSize: 16, fontWeight: '600', marginBottom: 24 }}>{t('several-features')}</Label>
                  </View>
                  <Image
                    style={{ position: 'absolute', width: 438, height: 518, top: 0, right: -148 }}
                    source={require('../../assets/image-home-woman-pin.png')}
                  ></Image>
                </View>
              )}
            </View>
          </ScrollView>
        </>
      )}
      {pageState === LoginPageState.ShowTermsAndConditions && !loading && (
        <ScrollView style={{ height: '100%', width: '100%', flexShrink: 0 }}>
          <View
            style={{
              width: '100%',
              paddingHorizontal: Platform.OS === 'web' ? 16 : 0,
              paddingVertical: Platform.OS === 'web' ? 16 : 0,
              marginTop: 44,
              alignItems: 'center',
              flexDirection: 'column',
              alignContent: 'center',
            }}
          >
            <View
              style={{
                maxWidth: windowDimensions.width >= 1366 ? 1000 : '100%',
                paddingTop: windowDimensions.width >= 1366 ? 68 : 0,
                flexGrow: 1,
                paddingHorizontal: Platform.OS === 'web' ? 12 : 24,
                paddingVertical: Platform.OS === 'web' ? 12 : 24,
                flexDirection: 'row',
              }}
            >
              <View
                style={{
                  width: '100%',
                  flexDirection: 'row',
                  justifyContent: Platform.OS === 'web' ? 'flex-end' : 'flex-start',
                  zIndex: 1000,
                  position: 'absolute',
                  top: Platform.OS === 'web' ? 0 : -28,
                  left: Platform.OS === 'web' ? 0 : 97,
                  paddingVertical: 16,
                  paddingHorizontal: 16,
                }}
              >
                {getOtherLanguageButton()}
              </View>
              {Platform.OS !== 'web' ? (
                <View style={{ position: 'absolute', top: windowDimensions.width >= 1366 ? 0 : -28, left: 16 }}>
                  <Logo width={85} height={40} />
                </View>
              ) : (
                <Image
                  style={{ position: 'absolute', top: windowDimensions.width >= 1366 ? 0 : -40, left: 24, width: 85, height: 40 }}
                  source={require('../../assets/login-page-logo.svg')}
                />
              )}
              <View
                style={{
                  width: '100%',
                  borderWidth: 1,
                  borderRadius: 8,
                  borderStyle: 'solid',
                  // marginTop: windowDimensions.width >= 1366 ? 0 : 40,
                  marginTop: 40,
                  marginBottom: 40,
                  marginLeft: -8,
                  paddingVertical: Platform.OS === 'web' ? 32 : 12,
                  paddingHorizontal: Platform.OS === 'web' ? 32 : 12,
                  borderColor: 'white',
                  flexDirection: 'column',
                }}
              >
                <Image
                  style={{
                    position: 'absolute',
                    top: -50,
                    right: -40,
                    width: Platform.OS === 'web' && windowDimensions.width >= 1366 ? 204 : 138,
                    height: Platform.OS === 'web' && windowDimensions.width >= 1366 ? 241 : 163,
                  }}
                  source={require('../../assets/SingUpTerms.png')}
                />
                <View style={{ maxWidth: Platform.OS === 'web' ? '80%' : '70%' }}>
                  <Label style={{ ...StylesHelper.text(30, theme.primary, 600) }}>{tLogin('terms-conditions-title')}</Label>
                </View>
                <View style={{ marginBottom: 16, maxWidth: Platform.OS === 'web' ? '80%' : '100%' }}>
                  <Label numberOfLines={1} style={{ ...StylesHelper.text(30, 'white', 600), marginTop: windowDimensions.width >= 1366 ? -10 : 0 }}>
                    {t('mystery-shopper').toLowerCase()}
                  </Label>
                </View>
                <CustomMarkdown
                  style={{
                    body: { color: 'white', fontSize: 14, paddingBottom: 16, flexGrow: 1 },
                    list_item: { marginBottom: 12 },
                  }}
                >
                  {tLogin('terms-conditions')}
                </CustomMarkdown>
                <View style={{ flexDirection: 'row', alignItems: 'baseline', flexShrink: 1, flexWrap: 'wrap' }}>
                  <Label variant={LabelType.titleWhiteSmall}>{tLogin('terms-conditions-plus')}</Label>
                  <Pressable onPress={() => Linking.openURL(t('lanla_website_terms_of_use_url'))}>
                    <Label variant={LabelType.titleWhiteSmall} style={{ textDecorationLine: 'underline' }}>
                      {t('Terms-and-Conditions')}
                    </Label>
                  </Pressable>
                  <Label style={{ marginLeft: 8, marginRight: 8 }} variant={LabelType.titleWhiteSmall}>
                    {t('and')}
                  </Label>
                  <Pressable onPress={() => Linking.openURL(t('lanla_website_privacy_policy_url'))}>
                    <Label variant={LabelType.titleWhiteSmall} style={{ textDecorationLine: 'underline' }}>
                      {t('footer.privacy_policy')}
                    </Label>
                  </Pressable>
                </View>
                <CustomButton
                  style={{ maxWidth: windowDimensions.width >= 1366 ? '40%' : '100%', textAlign: 'center', marginTop: 24 }}
                  title={tLogin('terms-conditions-button')}
                  onPress={() => setPageState(LoginPageState.Register)}
                />
              </View>
            </View>
            {(windowDimensions.width < 1366 || Platform.OS !== 'web') && (
              <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                {Platform.OS !== 'web' ? (
                  <View style={{ marginTop: 32, marginBottom: 24 }}>
                    <Logo width={134} height={63} />
                  </View>
                ) : (
                  <Image style={{ width: 134, height: 63, marginTop: 32, marginBottom: 24 }} source={require('../../assets/login-page-logo.svg')} />
                )}
              </View>
            )}
          </View>
        </ScrollView>
      )}
    </LinearGradient>
  );
};

export default Login;

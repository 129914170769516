import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { useWindowDimensions, View, Pressable, Linking, ScrollView, Image } from 'react-native';
import { useAppSelector, useAppDispatch } from '../../../hooks/store-hook';
import useCustomTheme from '../../../hooks/theme-hook';
import useTranslation from '../../../hooks/translation-hook';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import CustomButton from '../../common/CustomButton';
import Label from '../../common/Label';

export interface CashoutSuccessPopupProps {
  onConfirm?(): void;
}

const CashoutSuccessPopup = (props: CashoutSuccessPopupProps) => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();
  const authStore = useAppSelector((selector) => selector.auth);
  const dispatch = useAppDispatch();
  const t = useTranslation();

  return (
    <View style={{ backgroundColor: theme.background.lightSea, width: layout.width > 1200 ? 715 : 350, padding: 36, flexShrink: 1 }}>
      {layout.width > 1200 ? (
        <Image style={{ position: 'absolute', top: -1, right: -1, width: 462, height: 496 }} source={require('../../../../assets/cashout-d.png')} />
      ) : (
        <Image style={{ position: 'absolute', top: -50, right: 0, width: 375, height: 411 }} source={require('../../../../assets/cashout-m.png')} />
      )}
      <Pressable
        style={{ position: 'absolute', top: 8, right: 8 }}
        onPress={() => {
          dispatch(lightBoxActions.setVisibility(false));
        }}
      >
        <FontAwesomeIcon color={'white'} icon={faXmark} size={24} />
      </Pressable>
      <ScrollView style={{ marginTop: layout.width > 1200 ? 300 : 350, flexShrink: 1 }}>
        <Label
          numberOfLines={4}
          style={{ width: 300, fontSize: 26, color: theme.text.sea, fontWeight: '700', textAlign: layout.width > 1200 ? 'left' : 'center' }}
        >
          {t('Congratulations-your-money-is-on-its-way')}
        </Label>
        <Label numberOfLines={6} style={{ width: 325, marginTop: 12, fontSize: 16, textAlign: layout.width > 1200 ? 'left' : 'center' }}>
          {t('Look-for-it-in-your-inbox-at-0').format(authStore.user?.email)}
        </Label>
        <Label
          numberOfLines={6}
          style={{ width: 325, marginTop: 12, fontSize: 22, color: theme.text.darkTurquoiseTwo, textAlign: layout.width > 1200 ? 'left' : 'center' }}
        >
          {t('password-cashout')}
        </Label>
      </ScrollView>
      <View style={{ flexDirection: layout.width > 1200 ? 'row' : 'column', gap: 20, marginTop: 24, alignItems: 'center' }}>
        <CustomButton
          style={{ width: 147 }}
          title={t('got-it')}
          onPress={() => {
            props.onConfirm && props.onConfirm();
            dispatch(lightBoxActions.setVisibility(false));
          }}
        />
      </View>
    </View>
  );
};

export default CashoutSuccessPopup;

import useCustomTheme from '../hooks/theme-hook';

const useCustomCheckboxStyles = () => {
  const theme = useCustomTheme();

  const customCheckbox = {
    base: {
      justifyContent: 'center' as any,
      alignItems: 'center' as any,
      borderRadius: 4,
      borderWidth: 1,
      borderColor: theme.background.sea,
      backgroundColor: theme.background.primaryLight,
      width: 20,
      height: 20,
    },
    baseSmall: {
      width: 16,
      height: 16,
    },

    marker: {
      width: 30,
      height: 30,
    },
    markerSmall: {
      width: 16,
      height: 16,
    },

    container: {
      flexDirection: 'row-reverse' as any,
      alignItems: 'center' as any,
      justifyContent: 'space-between' as any,
      height: 28,
      width: '100%' as any,
      gap: 8
    },
    containerSmall: {
      height: 32,
    },
    label: {
      fontSize: 14,
      flexGrow: 1,
    }
  };

  return customCheckbox as typeof customCheckbox;
};

export default useCustomCheckboxStyles;

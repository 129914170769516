import { DimensionValue, Platform } from 'react-native';
import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useMissionDetailCardStyles = () => {
  const theme = useCustomTheme();

  let mapControl = {
    container: {
      borderWidth: 1,
      borderColor: 'transparent' as any,
      backgroundColor: theme.secondary,
      borderRadius: 12,
      marginTop: 8,
      ...StylesHelper.padding(16, 12),
      selected: {
        borderRightColor: theme.secondaryLight,
        borderRightWidth: 3,
      },
    },
    indicator: { height: 16, width: 16, position: 'absolute' as any, right: 8, bottom: 8 },
    missionNotLogged: {
      containerBase: {
        paddingTop: 32,
        display: 'flex' as any,
        flexDirection: 'column' as any,
        alignItems: 'center' as any,
        textAlign: 'center' as any,
        color: theme.colorErrorPrimary,
      },
      container: {
        icon: {
          marginBottom: 16,
          flexGrow: 1,
        },
        button: {
          marginTop: 24,
        },
      },
    },
    missionsBranchDetail: {
      container: {
        flexGrow: 1,
        ...StylesHelper.padding(12),
        backgroundColor: theme.background.primary,
      },
      backButton: {
        backgroundColor: theme.background.primary,
        paddingBottom: 16,
      },
      content: {
        body: {
          ...Platform.select({
            web: {
              backgroundColor: theme.background.primary,
            },
            native: {
              backgroundColor: theme.background.primary,
            },
          }),
          paddingBottom: 16,
        },
        moreInfo: {
          backgroundColor: theme.background.lightSea,
          borderRadius: 10,
          padding: 16,
        },
        missionsCountBase: {
          paddingVertical: 12,
          display: 'flex' as any,
          flexDirection: 'row' as any,
          alignItems: 'center' as any,
        },
        missionsCount: {
          icons: {
            width: 48,
            display: 'flex' as any,
            flexDirection: 'row-reverse' as any,
            justifyContent: 'flex-end' as any,
          },
          icon: {
            width: 24,
            height: 24,
            marginRight: -12,
          },
          label: {
            paddingLeft: 8,
          },
        },
        infoContainerBase: {
          backgroundColor: theme.background.missionDetail,
          ...StylesHelper.borderRadius(8),
          marginTop: 12,
        },
        infoContainer: {
          title: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            alignItems: 'center' as any,
            ...StylesHelper.padding(8),
            paddingRight: 16,
          },
          icon: {
            width: 32,
            height: 32,
            marginRight: 4,
          },
          summary: {
            backgroundColor: theme.background.missionDetailSummary,
            ...StylesHelper.padding(4),
            flexDirection: 'column' as any,
            flexWrap: 'wrap' as any,
            marginBottom: 12,
          },
          summaryItem: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            alignItems: 'center' as any,
            flexShrink: 1,
            flexGrow: 1,
            ...StylesHelper.padding(8),
          },
          summaryItemValue: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            flexGrow: 1,
            flexShrink: 1,
            alignItems: 'center' as any,
          },
          summaryItemValueSecondary: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            flexWrap: 'wrap' as any,
            flexShrink: 1,
            flexGrow: 1,
          },
          summaryItemIcon: {
            flexShrink: 0,
          },
          infoBlocksBase: {
            display: 'flex' as any,
            flexDirection: 'column' as any,
            ...StylesHelper.padding(0, 12, 12, 12),
          },
          infoBlocks: {
            title: {
              gap: 8,
              flexDirection: 'row' as any,
              alignItems: 'center' as any,
              marginBottom: 6,
            },
            titleIcon: {
              width: 18,
              height: 18,
              paddingRight: 6,
              color: theme.text.titleDark.toString(),
            },
            content: {
              marginBottom: 8,
            },
            link: {
              paddingVertical: 12,
              textDecorationLine: 'underline' as any,
              textDecorationStyle: 'solid',
              textDecorationColor: theme.text.primary,
            },
            baseLinks: {
              display: 'flex' as any,
              flexDirection: 'row-reverse' as any,
              paddingHorizontal: 16,
              alignItems: 'baseline' as any,
              marginBottom: -12,
            },
            buttonContainer: {
              display: 'flex' as any,
              alignItems: 'center' as any,
              gap: 16,
            },
            button: {
              flexBasis: '50%' as DimensionValue,
              color: 'white',
              // ...StylesHelper.text(13, 'white', 600, 'none'),
            },
          },
        },
        upcoming: {
          container: {
            display: 'flex' as any,
            flexDirection: 'row' as any,
            justifyContent: 'flex-end' as any,
            alignItems: 'center' as any,
            ...StylesHelper.padding(6),
            marginBottom: 4,
            backgroundColor: theme.background.primary,
            ...StylesHelper.borderRadius(8),
          },
          date: {
            container: {
              display: 'flex' as any,
              flexDirection: 'row' as any,
              alignItems: 'center' as any,
            },
            icon: {
              marginRight: 4,
            },
          },
        },
      },
    },
  };

  return mapControl as typeof mapControl;
};

export default useMissionDetailCardStyles;

import { ReactElement } from 'react';
import { myAccountActions } from '../store/myAccountSlice';
import { useAppDispatch } from './store-hook';

export interface MyAccountActionsHook {
  navigate(action: ReactElement): void;
  setVisibility(visible: boolean): void;
  setProps(props: any | undefined): void;
}

const useMyAccountActions = () => {
  const dispatch = useAppDispatch();

  const navigate = (action: ReactElement) => {
    dispatch(myAccountActions.navigate(action));
  };

  const setVisibility = (visible: boolean) => {
    dispatch(myAccountActions.setVisibility(visible));
  };

  const setProps = (props: any | undefined) => {
    dispatch(myAccountActions.setProps(props));
  };

  return {
    navigate,
    setVisibility,
    setProps
  } as MyAccountActionsHook;
};

export default useMyAccountActions;

import { useFocusEffect, useNavigation, useNavigationState } from '@react-navigation/native';
import React, { useEffect, useState } from 'react';
import { Alert, BackHandler, Platform, View } from 'react-native';
import { useAppDispatch, useAppSelector } from '../../hooks/store-hook';
import { authActions } from '../../models/helpers/platformImportHelper';
import { LoggedState } from '../../models/AuthModelsCommon';
import useTranslation from '../../hooks/translation-hook';
import { modalActions } from '../../store/modalSlice';
import { myAccountActions } from '../../store/myAccountSlice';
import { DrawerOpenState, drawerActions } from '../../store/drawerSlice';

const AuthGuard = (props: { children; forceLogout?: boolean }) => {
  const t = useTranslation();
  const navStore = useAppSelector((selector) => selector.navBar);
  const authStore = useAppSelector((selector) => selector.auth);
  const modalStore = useAppSelector((selector) => selector.modal);
  const myAccountStore = useAppSelector((selector) => selector.myAccount);
  const drawerStore = useAppSelector((selector) => selector.drawer);
  const path = Platform.OS === 'web' ? window.location.pathname : undefined;
  const dispatch = useAppDispatch();
  const navigation = useNavigation();
  const navigationState = useNavigationState((state) => state?.routes);

  const [canGoBack, setCanGoBack] = useState(false);

  useFocusEffect(
    React.useCallback(() => {
      const newPath = path?.indexOf('/') > -1 ?? false ? path.substring(1) : path;
      if (!authStore.idToken && authStore.logged === LoggedState.NotLogged && newPath !== 'useractionreturn' && newPath !== 'unsubscribe') {
        console.log('AuthGardLoginRedirect', newPath);
        if (navStore.isWebView) {
          //@ts-ignore
          window.ReactNativeWebView.postMessage('error');
        } else {
          dispatch(authActions.setReturnPath(newPath));

          if (Platform.OS === 'web') {
            let queryParams = {};
            const params = new URLSearchParams(window.location.search);
            for (let [key, value] of params.entries()) {
              queryParams[key] = value;
            }
            dispatch(authActions.setParams(queryParams));
          }

          navigation.navigate({ name: 'login', params: navigationState[0].params } as never);
        }
      } else {
        dispatch(authActions.getToken());
      }
    }, [authStore.logged])
  );

  useEffect(() => {
    if (navigationState && navigationState?.length > 2) {
      setCanGoBack(true);
    } else {
      setCanGoBack(false);
    }
  }, [navigationState]);

  // Handling the Back button to exit the app and not going to login page
  useEffect(() => {
    if (Platform.OS !== 'web') {
      const backAction = () => {
        if (drawerStore.state !== DrawerOpenState.closed) {
          if (drawerStore.onClose) {
            drawerStore.onClose();
          }

          dispatch(drawerActions.resetState());
          return true;
        }

        if (myAccountStore.visible) {
          dispatch(myAccountActions.setVisibility(false));
          return true;
        }

        if (modalStore.visible) {
          dispatch(modalActions.setVisibility(false));
          return true;
        }

        if (canGoBack) {
          return false;
        }

        Alert.alert(t('hold-on'), t('want-exit-app-question'), [
          {
            text: t('Cancel'),
            onPress: () => null,
            style: 'cancel',
          },
          { text: t('YES'), onPress: () => BackHandler.exitApp() },
        ]);
        return true;
      };

      const backHandler = BackHandler.addEventListener('hardwareBackPress', backAction);

      return () => backHandler.remove();
    }
  }, [canGoBack, modalStore.visible, myAccountStore.visible, drawerStore.state]);

  return <>{props.children}</>;
};

export default AuthGuard;

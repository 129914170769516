import React, { ReactNode } from 'react';
import useCustomTheme from '../../hooks/theme-hook';
import useStyles from '../../hooks/styles-hook';
import Markdown from 'react-native-markdown-display';

export interface CustomMarkdownProps {
  children: string;
  style?: any;
}

const CustomMarkdown = (props: CustomMarkdownProps) => {
  const theme = useCustomTheme();
  const styles = useStyles((section) => section.customMarkdown);

  return (
    <Markdown
      style={{
        body: { color: theme.text.primary as any, lineHeight: 16 },
        bullet_list: { marginBottom: 4 },
        heading4: { marginVertical: 4, fontWeight: 600 },
        list_item: { marginTop: 4 },
        bullet_list_icon: { marginLeft: 0, marginRight: 4 },
        ...(props.style ?? {}),
      }}
    >
      {props.children}
    </Markdown>
  );
};

export default CustomMarkdown;

import useCustomTheme from '../hooks/theme-hook';
import { StylesHelper } from '../models/helpers/stylesHelper';

const useCustomMarkdownStyles = () => {
  const theme = useCustomTheme();

  let customMarkdown = {
    heading: {
      marginTop: 16,
      marginBottom: 8,
    },
    text: {
      color: theme.text.primary,
      marginTop: 8,
    },
    strong: {
      marginTop: 8,
      fontWeight: 'bold',
    },
    listItem: {
      flexDirection: 'row',
      alignItems: 'center',
    },
    listItemNumber: {
      marginTop: 8,
    },
    listItemBullet: {
      marginTop: 8,
    },
    listItemText: {
      flexGrow: 1,
      flexShrink: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      maxWidth: '100%',
    },
  };

  return customMarkdown as typeof customMarkdown;
};

export default useCustomMarkdownStyles;

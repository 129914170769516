import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import React from 'react';
import { View, Pressable, Image, useWindowDimensions, ScrollView, Linking } from 'react-native';
import { lightBoxActions } from '../../../store/lightBoxSlice';
import CustomButton from '../../common/CustomButton';
import Label from '../../common/Label';
import useCustomTheme from '../../../hooks/theme-hook';
import { useAppDispatch, useAppSelector } from '../../../hooks/store-hook';
import useTranslation from '../../../hooks/translation-hook';
import Constants from 'expo-constants';

export interface CashoutPopupProps {
  onConfirm?(): void;
}

const CashoutPopup = (props: CashoutPopupProps) => {
  const theme = useCustomTheme();
  const layout = useWindowDimensions();
  const authStore = useAppSelector((selector) => selector.auth);
  const userPrefs = useAppSelector((selector) => selector.userPrefs);
  const dispatch = useAppDispatch();
  const t = useTranslation();

  return (
    <View style={{ backgroundColor: theme.background.lightSea, width: layout.width > 1200 ? 715 : 350, padding: 36, flexShrink: 1 }}>
      {layout.width > 1200 ? (
        <Image style={{ position: 'absolute', top: -1, right: -1, width: 462, height: 496 }} source={require('../../../../assets/cashout-d.png')} />
      ) : (
        <Image style={{ position: 'absolute', top: -200, right: 0, width: 375, height: 411 }} source={require('../../../../assets/cashout-m.png')} />
      )}
      <Pressable
        style={{ position: 'absolute', top: 8, right: 8 }}
        onPress={() => {
          dispatch(lightBoxActions.setVisibility(false));
        }}
      >
        <FontAwesomeIcon color={'white'} icon={faXmark} size={24} />
      </Pressable>
      <ScrollView style={{ marginTop: layout.width > 1200 ? 300 : 200, flexShrink: 1 }}>
        <Label numberOfLines={4} style={{ fontSize: 26, color: theme.text.sea, fontWeight: '700', textAlign: layout.width > 1200 ? 'left' : 'center' }}>
          {t('Lets-cashout')}
        </Label>
        <Label
          numberOfLines={6}
          style={{ width: 300, marginTop: 12, fontSize: 22, color: theme.text.darkTurquoiseTwo, textAlign: layout.width > 1200 ? 'left' : 'center' }}
        >
          {t('We-will-send-money-to-0').format(authStore.user?.email)}
        </Label>
        <Label
          numberOfLines={10}
          style={{ width: layout.width > 1200 ? 450 : '100%', marginTop: 24, fontSize: 16, textAlign: layout.width > 1200 ? 'left' : 'center' }}
        >
          {t('cashout-instruction')}
        </Label>
        <Pressable
          onPress={() => {
            Linking.openURL(t('lanla_website_cashout'));
          }}
        >
          <Label
            numberOfLines={1}
            style={{ width: layout.width > 1200 ? 460 : '100%', fontSize: 16, textAlign: layout.width > 1200 ? 'left' : 'center', color: theme.text.sea }}
          >
            {t('visit-this-link')}
          </Label>
        </Pressable>
        <View style={{ flexDirection: 'row', gap: 20, marginTop: 12 }}>
          <CustomButton
            style={{ width: 147 }}
            title={t('confirm')}
            onPress={() => {
              props.onConfirm && props.onConfirm();
              dispatch(lightBoxActions.setIsLoading(true));
            }}
          />
          <CustomButton
            style={{ width: 147 }}
            secondary
            title={t('cancel')}
            onPress={() => {
              dispatch(lightBoxActions.setVisibility(false));
            }}
          />
        </View>
        <Label
          numberOfLines={10}
          style={{ width: layout.width > 1200 ? 435 : '100%', marginTop: 12, fontSize: 12, textAlign: layout.width > 1200 ? 'left' : 'center' }}
        >
          {t('cashout-safety')}
        </Label>
      </ScrollView>
    </View>
  );
};

export default CashoutPopup;

import React from 'react';
import { View, Image, ScrollView } from 'react-native';
import useTranslation from '../../hooks/translation-hook';
import CustomButton from '../common/CustomButton';
import Label from '../common/Label';

export interface LocationPermissionProps {
  onClose?(): void;
}

const LocationPermission = (props: LocationPermissionProps) => {
  const t = useTranslation();

  return (
    <ScrollView style={{ flexGrow: 0, backgroundColor: '#eeeef0', maxWidth: 350 }}>
      <Image source={require('../../../assets/onBoarding_mobile_2.png')} style={{ aspectRatio: '350/234', width: '100%', height: 'auto' }} />
      <View style={{ flexGrow: 1, marginTop: 8 }}>
        <View style={{ margin: 'auto', paddingLeft: 12, paddingRight: 12, paddingBottom: 24 }}>
          <Label style={{ fontSize: 28, color: '#5e51a3', fontWeight: '600' }} numberOfLines={3}>
            {t('We-will-need-to-access-your-location')}
          </Label>
          <Label style={{ marginTop: 20, fontSize: 18, color: '#33264d', fontWeight: '400' }} numberOfLines={10}>
            {t('location-permission-message')}
          </Label>
          <CustomButton
            style={{ marginTop: 24, backgroundColor: '#5e51a3', borderColor: '#5e51a3' }}
            textStyle={{ color: 'white' }}
            title={t('got-it')}
            onPress={() => {
              props.onClose && props.onClose();
            }}
          />
        </View>
      </View>
    </ScrollView>
  );
};

export default LocationPermission;

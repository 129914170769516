import useCustomTheme from '../hooks/theme-hook';

const useCustomButtonStyles = () => {
  const theme = useCustomTheme();

  const customButton = {
    button: {
      main: {
        backgroundColor: theme.primary,
        borderRadius: 50,
        justifyContent: 'center' as any,
        alignItems: 'center' as any,
        overflow: 'hidden' as any,
        flexShrink: 1,
        minHeight: 44,
        borderWidth: 2,
        borderColor: theme.primary,
      },
      secondary: {
        backgroundColor: 'transparent' as any,
      },
      secondaryColor: {
        backgroundColor: theme.primary,
      },
      secondaryColorLight: {
        backgroundColor: theme.secondaryLight,
      },
      disabled: {
        backgroundColor: 'rgba(200, 200, 200, 0.1)',
      },
      backgroundImage: {
        flex: 1,
        justifyContent: 'center' as any,
        alignItems: 'center' as any,
        height: '100%' as any,
        width: '100%' as any,
      },
    },
    text: {
      main: {
        color: theme.text.primary,
        fontSize: 14,
        fontWeight: '600' as any,
        flexShrink: 0,
        paddingHorizontal: 20,
        paddingVertical: 12,
      },
      secondary: {
        textTransform: 'none' as any,
        color: theme.text.primary,
      },
      secondaryColor: {
        textTransform: 'none' as any,
        color: theme.text.primary as any,
      },
      disabled: {
        textTransform: 'none' as any,
        color: theme.text.titleLight as any,
      },
    },
    roundButton: {
      container: {
        width: 54,
        height: 54,
        padding: 4,
        borderRadius: 200,
        display: 'flex' as any,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
        flexDirection: 'row' as any,
        backgroundColor: 'white' as any,
      },
      button: {
        width: '100%' as any,
        height: '100%' as any,
        borderRadius: 200,
        borderWidth: 1,
        display: 'flex' as any,
        alignItems: 'center' as any,
        justifyContent: 'center' as any,
        flexDirection: 'row' as any,
      },
      text: {
        flexGrow: 1,
        textAlign: 'center' as any,
        textTransform: 'uppercase' as any,
      },
    },
  };

  return customButton as typeof customButton;
};

export default useCustomButtonStyles;
